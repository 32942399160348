<template>
    <div class="card card-default">
        <div class="card-header">
            <h3 class="card-title">Domain</h3>
            <div class="card-tools">
                <div class="overlay" v-if="isLoading">
                    <i class="fas fa-sync-alt fa-spin fa-1x"></i>
                </div>

                <button class="btn btn-tool btn-sm" v-if="!isLoading" @click.prevent="clear()">
                    <i class="fa fa-plus"></i>
                </button>
            </div>
        </div>
        <!-- /.card-header -->
        <div class="card-body">
            <div class="form-group row">
                <div class="col-md-5">
                    <ul class="nav nav-tabs" id="custom-content-below-tab" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" id="light-logo-tab" data-toggle="pill" href="#light-logo" role="tab" aria-controls="light-logo-home" aria-selected="true">Light Logo</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="dark-logo-tab" data-toggle="pill" href="#dark-logo" role="tab" aria-controls="dark-logo" aria-selected="false" data-ur1313m3t="true">Dark Logo</a>
                        </li>
                    </ul>
                    <div class="tab-content" id="custom-content-below-tabContent">
                        <div class="tab-pane fade active show" id="light-logo" role="tabpanel" aria-labelledby="light-logo-tab">
                            <input  type="file" ref="fileLight" style="display: none;" @change="onChangeFileUpload('light')" />
                            <img alt="Whitelabel logo light" :src="whitelist.logo || imageLightPath" @click="$refs.fileLight.click()"
                            class="cursor-pointer" style="border: 1px solid #ccc; height: 256px; width: 100%;">
                            <a href="#" class="btn btn-info btn-block mt-2" @click.prevent="onUpload('light')" v-if="whitelist.id">Upload</a>
                        </div>
                        <div class="tab-pane fade" id="dark-logo" role="tabpanel" aria-labelledby="dark-logo-tab">
                            <input  type="file" ref="fileDark" style="display: none;" @change="onChangeFileUpload('dark')" />
                            <img alt="Whitelabel logo dark" :src="whitelist.logo_dark || imageDarkPath" @click="$refs.fileDark.click()"
                            class="cursor-pointer" style="border: 1px solid #ccc; height: 256px; width: 100%;">
                            <a href="#" class="btn btn-info btn-block mt-2" @click.prevent="onUpload('dark')" v-if="whitelist.id">Upload</a>
                        </div>
                    </div>
                </div>
                <div class="col-md-7">
                    <div class="form-group">
                        <label>Company Name: </label>
                        <input type="text" class="form-control" v-model="whitelist.company_name" />
                    </div>
                    <div class="form-group">
                        <label>Terms of Services Link: </label>
                        <input type="text" class="form-control" v-model="whitelist.terms_link" />
                    </div>
                    <div class="form-group">
                        <label>Privacy Policy Link: </label>
                        <input type="text" class="form-control" v-model="whitelist.privacy_link" />
                    </div>
                    <div class="form-group">
                        <label>Domain Name: </label>
                        <input type="text" class="form-control" v-model="whitelist.domain" placeholder="Ex. http(s)://youdomain.com or http(s)://sub.yourdomain.com" />
                    </div>
                    <div class="form-group">
                    <label>Network: </label>
                        <select class="custom-select" v-model="whitelist.network">
                            <option :value="random">None</option>
                            <option v-for="network in networks" :value="network.id" :key="network.id">
                                {{ network.first_name }} {{ network.last_name }} 
                                <i v-if="network.is_active"> (Active) </i>
                                <i v-if="!network.is_active"> (Suspended) </i>
                            </option>
                        </select>
                    </div>
                    <div class="form-group">
                        <div class="custom-control custom-switch">
                            <input type="checkbox" class="custom-control-input" id="customSwitch1" v-model="whitelist.allow_signup">
                            <label class="custom-control-label" for="customSwitch1">Allow Signup</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-9">
                    <div class="btn-group btn-group-toggle">
                        <label class="btn btn-sm btn-default"  :class="{'text-green': whitelist.status === 'active'}">
                            <input type="radio" value="active" v-model="whitelist.status"> Active
                        </label>
                        <label class="btn btn-sm btn-default" :class="{'text-warning': whitelist.status === 'pending'}">
                            <input type="radio" value="pending" v-model="whitelist.status"> Pending
                        </label>
                        <label class="btn btn-sm btn-default" :class="{'text-red': whitelist.status === 'suspended'}">
                            <input type="radio" value="suspended" v-model="whitelist.status"> Suspended
                        </label>
                    </div>
                </div>
                <div class="col-md-3">
                    <a href="#" class="btn btn-success btn-block" @click.prevent="submit()"> 
                        Save 
                    </a>
                </div>
            </div>
            <div class="form-group" v-if="whitelist.id">
                <a href="#" class="text-danger" @click.prevent="deleteDomain(whitelist.id)">
                    Delete domain
                </a>
            </div>
        </div>
    </div>
</template>
<script>
import { RepositoryFactory } from '@/services/repositories/ManagementAPI/RepositoryFactory'
const WhitelistRepo = RepositoryFactory.get("whitelist")

export default {
    props: {
        whitelist: {
            type: Object,
            default: () => {}
        },
        networks: {
            type: Array,
            default: () => []
        },
        isLoading: {
            type: Boolean
        }
    },
    data() {
        return {
            selectedLightFile: null,
            selectedDarkFile: null,
            imageLightPath: '/assets/img/boxed-bg.png',
            imageDarkPath: '/assets/img/boxed-bg.png',

        }
    },
    methods: {
        submit: function() {
            if(typeof this.whitelist.id === 'undefined') {
                this.create(this.whitelist)
            } else {
                this.update(this.whitelist)
            }
        },
        clear: function() {
            this.whitelist = {}
        },
        create: function(whitelist) {
            WhitelistRepo.post(whitelist).then(result => {
                this.$emit('updateList', this.whitelist)
                this.$notify({
                    group: 'ops',
                    type: 'success',
                    title: "Successfuly created whitelist.",
                    text: whitelist.domain
                })
            }).catch(err => {
                this.$notify({
                    group: 'ops',
                    type: 'error',
                    title: "Faild to create whitelist for domain",
                    text: err.response.data
                })
            })
        },
        update: function(whitelist) {
            delete whitelist.logo
            delete whitelist.logo_dark
            WhitelistRepo.patch(this.whitelist.id, whitelist).then(result => {
                if(typeof result.data.id !== 'undefined' ) 
                    this.$emit('refreshList', true)
                    this.$emit('objectUpdated', result.data)
                    this.$notify({
                        group: 'ops',
                        type: 'success',
                        title: "Successfuly updated whitelist.",
                        text: whitelist.domain
                    })
            }).catch(err => {
                this.$notify({
                    group: 'ops',
                    type: 'error',
                    title: "Faild to create whitelist for domain",
                    text: err.response.data
                })
            })
        },
        deleteDomain: function(id) {
            WhitelistRepo.delete(id).then(result => {
                this.$emit('refreshList', true)
                this.$notify({
                    group: 'ops',
                    type: 'success',
                    title: "Successfuly updated whitelist.",
                    text: whitelist.domain
                })
            }).catch(err => {
                this.$notify({
                    group: 'ops',
                    type: 'error',
                    title: "Faild to create whitelist for domain",
                    text: err.response.data
                })
            })
        },
        onUpload(theme) {
            let formData = new FormData();
            if(theme == 'dark') {
                formData.append('logo_dark', this.selectedDarkFile, this.selectedDarkFile.name);
            } else if (theme == 'light') {
                formData.append('logo', this.selectedLightFile, this.selectedLightFile.name);
            }
            WhitelistRepo.upload(this.whitelist.id, formData, { onUploadProgress: this.onUploadProgress}).then(result => {
                this.$notify({
                    group: 'ops',
                    type: 'success',
                    title: "Logo Uploaded Successfuly.",
                })
            }).catch(err => {
                console.log(err)
            })
        },
        onChangeFileUpload(theme) {
            if(theme == 'dark') {
                this.selectedDarkFile = this.$refs.fileDark.files[0];
                this.imageDarkPath = URL.createObjectURL(this.selectedDarkFile);
            } else if (theme == 'light') {
                this.selectedLightFile = this.$refs.fileLight.files[0];
                this.imageLightPath = URL.createObjectURL(this.selectedLightFile);
            }
        },
        onUploadProgress(uploadEvent) {
            console.log("Upload Progress: " + (uploadEvent.loaded / uploadEvent.total * 100) + '%')
        },
    },
    computed: {
        random: function() {
            return Math.floor(Math.random() * 99999);
        }
    }
}
</script>