<template>
    <form class="" v-if="displayForm" v-animate-css="'slideInRight'">
        <div v-if="isVideoLoaded" v-animate-css="'slideInRight'">
            <div class="d-flex justify-content-end d-flex align-items-baseline px-2">
                <div class="cursor-pointer mr-auto"><i @click.prevent="hideForm()" class="fas fa-chevron-left"></i></div>
                <button @click.prevent="uploadNewVideo()" class="btn btn-primary">Upload New Videos</button>
            </div>

            <div  class="card mt-4" >
                <div class="m-3 py-2">
                    <div class="d-flex justify-content-between d-flex align-items-baseline text-lg font-weight-bold">
                        <div class="text-danger ">
                            <i class="fas fa-film"></i>
                            <h3 class="d-inline ml-2">video Details</h3>
                        </div>
                        <i class="float-right fas fa-angle-down"></i>
                    </div>
                </div>
                <div class="card-body pb-8">
                    <div>
                        <div class="form-group mb-6">
                            <label for="video_title">Video Title</label>
                            <input v-model="video.title" type="text" class="form-control border-top-0 border-right-0 border-left-0" id="video_title" aria-describedby="video_title_help" placeholder="video title">
                        </div>
                        <div class="form-group mb-6">
                            <label for="video_description">Video description</label>
                            <textarea v-model="video.description" type="text" class="form-control border-top-0 border-right-0 border-left-0" id="video_description" placeholder="video description" />
                        </div>


                        <div class="form-group mb-6">
                            <label for="tags">Tags</label>
                            <input v-model="video.tags" type="text" class="form-control border-top-0 border-right-0 border-left-0" id="tags"  placeholder="tags">
                        </div>
                        <div class="form-group mb-6">
                            <label for="category">Category</label>
                            <input v-model="video.category" type="text" class="form-control border-top-0 border-right-0 border-left-0" id="category"  placeholder="category">
                        </div>
                        <div class="row mb-6 pt-6  d-flex justify-content-start d-flex align-items-start">
                            <div class="">
                                <div class="">
                                    <label class="" for="channel">Channel</label>
                                    <select v-model="video.channel" class="custom-select" id="channel">
                                        <option selected>Choose...</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </select>
                                </div>
                            
                            </div>
                            <div class="mx-4">
                                <div class="">
                                    <label class="" for="playlist">PlayList</label>
                                    <select v-model="video.playlist" class="custom-select " id="playlist">
                                        <option selected>Choose...</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </select>
                                </div>
                            </div>
                            <div class="custom-file w-32">
                                <h4 class="font-weight-bold mb-3" >Upload Poster</h4>
                                <label v-if="!isPosterLoaded"  class="text-dark font-weight-normal font-italic py-1 px-12 border-bottom" for="uploadPoster">Upload</label>
                                <label v-else class="text-dark font-weight-normal font-italic py-1 px-12 border-bottom bg-success" for="uploadPoster">Uploaded</label>
                                <input ref="posterfile" @change="onPosterAttached()" type="file" class="d-none" id="uploadPoster" />
                            </div>
                        </div>
                    
                    </div>
                </div>
                <div class="overlay" v-if="isVideoUploading">
                    <point-spinner  />
                </div>
            </div>
        </div>
        <div v-else> 
            <div class="d-flex justify-content-end  align-items-baseline px-2 ">
                <div class="cursor-pointer mr-auto mb-1"><i @click.prevent="hideForm()" class="fas fa-chevron-left"></i></div>
            </div>
            <div class="card d-flex justify-content-center align-items-center mt-8 h-screen" @drop="drop($event)"  @dragover="allowDrop($event)">
                <i class="text-xl  fas fa-cloud-upload-alt"></i>
                <h1 class="display-5 text-lg font-weight-bold mt-12">Drag and Drop Files Here To Upload...</h1>
                <h1 class="display-5 text-lg font-weight-bold mt-12">Or</h1>
                <div  class="custom-file text-center mt-12 ">
                    <label class="text-dark font-weight-normal border p-2 rounded-md shadow-md" for="uploadVideo">Select Files To upload</label>
                    <input ref="videofile" @change="onVideoAttached()" type="file" class="d-none" id="uploadVideo" />
                </div>
                <div class="progress mt-12 w-75" v-if="progressWidth > 0">
                    <div class="progress-bar" role="progressbar" :style="{width: progressWidth + '%'}" :aria-valuenow="progressWidth" aria-valuemin="0" aria-valuemax="100">{{progressWidth}} %</div>
                </div>
                <div class="mt-12 mx-4" v-if="selectedFile">
                    <table class="truncate">
                        <thead>
                            <tr>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="font-weight-bold"> Name: </td>
                                <td class="px-2">{{selectedFile.name}}</td>
                            </tr>
                            <tr>
                                <td class="font-weight-bold"> Size: </td>
                                <td class="px-2">{{Math.round(selectedFile.size / (1024 * 1024))}} MB</td>
                            </tr>
                            <tr>
                                <td class="font-weight-bold"> File Type: </td>
                                <td class="px-2">{{selectedFile.type}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div> 
            </div>
        </div>
    </form>
</template>
<script>
import { RepositoryFactory } from '@/services/repositories/PlayerAPI/RepositoryFactory' 
const VideosRepo = RepositoryFactory.get('video');

export default {
    props: {
        displayForm: {
            type: Boolean,
            required: true
        }
    },
    data(){
        return {
            isPosterLoaded: false,
            isVideoLoaded: false,
            isVideoUploading: false, 
            selectedFile: null,
            progressWidth: 0,
            video: {
                title: '',
                description: '',
                tags: '',
                category: '',
                channel: '',
                playlist: '',
                poster: '',
                url: ''
            }
        }
    },
    methods: {
        drop(e ) {
            this.isVideoLoaded = true
            e.preventDefault(); 
            this.selectedFile = e.dataTransfer.files[0];
            this.video.url = URL.createObjectURL(this.selectedFile);
            console.log(this.selectedFile)
            URL.revokeObjectURL(this.video.url)
        },
        allowDrop(e) {
            e.preventDefault();
        },
        onVideoAttached() {
            this.isVideoLoaded = true
            this.selectedFile = this.$refs.videofile.files[0];
            this.video.url = URL.createObjectURL(this.selectedFile);
            URL.revokeObjectURL(this.video.url)
        },
        onPosterAttached() {
            this.isPosterLoaded = true
            this.selectedFile = this.$refs.posterfile.files[0];
            this.video.poster = URL.createObjectURL(this.selectedFile);
            URL.revokeObjectURL(this.video.poster)
        },
        uploadNewVideo() {
            this.onUpload()
        },
        onUpload() {
            let formData = new FormData();
            formData.append('file', this.selectedFile);
            formData.append('website_id', 100);
            console.log(formData.values())
            VideosRepo.post(formData).then(result => {
                console.log(result)
            }).catch(err => {
                console.log(err)
            })
            // const config = {
            //     onUploadProgress: function(progressEvent) {
            //         let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            //         console.log(percentCompleted)
            //     }
            // }
        },
        hideForm() {
            this.$emit('display-form' , false) 
        }

    },
    watch: {
        // 'video.url' : function() {
        //     let intervalID = setInterval(()=> {
        //         if(this.progressWidth < 100)
        //             this.progressWidth = this.progressWidth + 5
        //         else {
        //             clearInterval(intervalID)    
        //             this.isVideoLoaded = true
        //             this.video.title = this.selectedFile.name
        //         }
        //     } , 1000)
            
        // }
    },
}
</script>