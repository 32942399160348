<template>
<div>
    <div class="card" v-for="log in logs" :key="log.id" >
        <div class="card-header p-2 header-color">
            <span class="badge bg-primary"> {{ log.service | capitalizeAndReplace }} </span> <i class="fa fa-lock ml-5"></i> IP: {{ log.data.request.ip }}
            <div class="card-tools mr-2">
                <i class="far fa-clock"></i> {{ log.created_at | formatDateTimezone }}
            </div>
        </div>
        <div class="card-body">
            <p>
                <span class="mr-3"> Platform: {{ log.data.request.platform }} </span>
                <span class="mr-3"> Browser: {{ log.data.request.browser }} </span>
                <span class="mr-3"> Device: {{ log.data.request.device }} </span>
            </p>
            <p> Action: {{ log.action | capitalizeAndReplace }}, Code ({{ log.action }})</p>
            <p> Data: {{ logData(log.data) | capitalize }} </p>
        </div>
    </div>

    <div class="card-footer">
        <ul class="pagination pagination-sm m-0 float-right">
            <li class="page-item">
                <a class="page-link" href="#" @click.prevent="previousPage">Previous</a>
            </li>
            <li class="page-item">
                <a class="page-link">{{ currentPage }} / {{ pageCount }}</a>
            </li>
            <li class="page-item">
                <a class="page-link" href="#" @click.prevent="nextPage">Next</a>
            </li>
        </ul>
    </div>
</div>
</template>
<script>
import { RepositoryFactory } from '@/services/repositories/AuthAPI/RepositoryFactory'
const LogsRepo = RepositoryFactory.get("logs")

export default {
    props: {
        user: {
            type: Object,
        }
    },
    data() {
        return {
            logs: [],
            currentPage: 1,
            totalRecords: 0,
            resultsLimit: 15,
            isLoading: false,
        }
    },
    methods: {
        loadLogs: function() {
            LogsRepo.page(this.user.id, this.currentPage, this.resultsLimit).then(result => {
                this.totalRecords = result.data.count
                this.logs = result.data.results
            }).catch()
        },
        nextPage: function() {
            if(this.currentPage != this.pageCount || this.currentPage > this.pageCount) {
                this.currentPage = this.currentPage + 1
                this.loadLogs()
            }
        },
        previousPage: function() {
            if(this.currentPage != 1 ) {
                this.currentPage = this.currentPage - 1
                this.loadLogs()
            }
        },
        logData: function(data) {
            // remove request and return 
            // other keys and element as string
            var keys = Object.keys(data)
            var result = ""
            keys.forEach(element => {
                if(element !== 'request') {
                    result += element + ` (${data[element]}) `
                }
            });
            return result
        },
    },
    computed: {
        pageCount: function() {
            return Math.ceil(this.totalRecords / this.resultsLimit)
        },
    },
    watch: {
        "user": {
            handler: function (user) {
                if(typeof user !== 'undefined') {
                    this.loadLogs(user.id)
                }
            },
            deep: true,
            immediate: true
        },
    }

}
</script>
<style>
.header-color {
    background-color: #f8f8f8;
}
</style>