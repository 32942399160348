<template>
<div class="col-md-4">
    <div class="card">
        <div class="card-header">
            <h3 class="card-title">Recently Added Websites</h3>
            <div class="card-tools">
                <button type="button" class="btn btn-tool" data-card-widget="collapse">
                    <i class="fas fa-minus"></i>
                </button>
                <button type="button" class="btn btn-tool" data-card-widget="remove">
                    <i class="fas fa-times"></i>
                </button>
            </div>
        </div>
        <!-- /.card-header -->

        <div class="card-body p-0">
            <ul class="products-list product-list-in-card pl-2 pr-2">
                <li class="item" v-for="website in websites" :key="website.id">
                    <div class="product-info">
                        <router-link :to="{name: 'websites', params: {'id': website.id} }" class="product-title"> {{ website.name }} (  {{ website.category  | capitalize}} )
                            <span class="badge badge-warning float-right" :class="{
                                'alert-success': website.status == 'active',
                                'alert-warning': website.status == 'pending',
                                'alert-danger': website.status == 'suspended'}"> {{ website.status | capitalize }} 
                            </span>
                        </router-link>
                        <router-link :to="{name: 'user', params: {'id': website.assignment.network}}" v-if="website.assignment.network">
                            <span class="badge badge-warning float-right alert-info mx-1">
                                Network
                            </span>
                        </router-link>
                        <router-link :to="{name: 'user', params: {'id': website.assignment.publisher}}" v-else-if="website.assignment.publisher">
                            <span class="badge badge-warning float-right alert-success mx-1">
                                Publisher
                            </span>
                        </router-link>
                        <span class="product-description">
                            {{ website.url }}
                            <span class="float-right">
                                {{ website.created_at | formatDate }} 
                            </span>
                        </span>
                    </div>
                </li>
                <!-- /.item -->
            </ul>
        </div>
        <!-- /.card-body -->

        <div class="card-footer text-center">
            <router-link :to="{name: 'websites'}" class="uppercase">View All</router-link>
        </div>
        <!-- /.card-footer -->

        <div class="overlay" v-if="!isLoaded">
            <point-spinner />
        </div>
    </div>
</div>
</template>
<script>
import { RepositoryFactory } from '@/services/repositories/ManagementAPI/RepositoryFactory';
const WebsitesRepo = RepositoryFactory.get('websites');

export default {
    data() {
        return {
            websites: [],
            isLoaded: false,
        }
    },
    created() {
        this.loadWebsites()
    },
    methods: {
        loadWebsites: function() {
            this.isLoaded = false
            WebsitesRepo.status().then(result => {
                this.isLoaded = true
                this.websites = result.data.suspended
            }).catch(err => {
                this.websites = []
            })
        },
    },
}
</script>
<style>

</style>