import Repository from '../Repository';

const resource = "/data_statistics";
export default {
    get(id) {
        return Repository.get(`${resource}/${id}/`)
    },
    post(data) {
        return Repository.post(`${resource}/`, data)
    },
    update(id, data) {
        return Repository.put(`${resource}/${id}/`, data)
    },
    patch(id, data) {
        return Repository.patch(`${resource}/${id}/`, data)
    },
    page(id, page, limit=30, start_date='', end_date='', complete='', order='-date') {
        var offset = (page - 1) * limit
        var date_filter = ''
        
        if(start_date && end_date) {
            date_filter = `&start_date=${start_date}&end_date=${end_date}`
        }
        if(start_date && !end_date){
            date_filter = `&date=${start_date}`
        }
        if(complete) {
            complete = `&complete=${complete}`
        } else {
            complete = ''
        }
        return Repository.get(`${resource}/?pgdid=${id}${date_filter}${complete}&limit=${limit}&offset=${offset}&o=${order}`);
    },
    pageWithPGDList(id, page, limit=30, start_date='', end_date='', complete='', order='-date') {
        var offset = (page - 1) * limit
        var date_filter = ''
        
        if(start_date && end_date) {
            date_filter = `&start_date=${start_date}&end_date=${end_date}`
        }
        if(start_date && !end_date){
            date_filter = `&date=${start_date}`
        }
        if(complete) {
            complete = `&complete=${complete}`
        } else {
            complete = ''
        }
        return Repository.get(`${resource}/?pgdid_list=${id}${date_filter}${complete}&limit=${limit}&offset=${offset}&o=${order}`);
    },
    status() {
        return Repository.get(`${resource}/status/`)
    }
}