<template>
    <div class="card ">
        <div class="card-header">
          <h3 class="card-title">List</h3>

          <div class="card-tools">
            <div class="input-group input-group-sm" style="width: 150px;">
                <input type="search" name="table_search" class="form-control float-right" placeholder="Search" v-model="search_text">
                <div class="input-group-append">
                  <button class="btn btn-default" @click.prevent="loadSearchResult()"><i class="fas fa-search"></i></button>
                </div>
              </div>
          </div>
        </div>
        <!-- /.card-header -->
        <div class="card-body table-responsive p-0">
          <ul class="nav nav-pills flex-column">
            <li class="nav-item active" v-if="websites.length == 0">
              <a href="#" class="nav-link">
                No Available websites
              </a>
            </li>
          </ul>
              
          <table class="table table-hover responsive">
              <thead>
              <tr>
                  <th>Website</th>
                  <th>URL</th>
                  <th>Category</th>
                  <th>Status</th>
                  <th class=""></th>
              </tr>
              </thead>
              <tbody>
                  <tr v-for="website in websites" :key="website.id" v-animate-css="'slideInLeft'">
                    <td style="overflow-wrap: anywhere;">{{ website.name }}</td>
                    <td style="overflow-wrap: anywhere;">{{ website.url | urlStrip }}</td>
                    <td>{{ website.category | capitalize }}</td>
                    <td>
                      <span class="alert alert-success btn-xs" :class="{
                          'alert-success': website.status == 'active',
                          'alert-warning': website.status == 'pending',
                          'alert-danger': website.status == 'suspended'}"> {{ website.status | capitalize }} 
                      </span>
                    </td>
                    <td class="text-right">
                      <div class="dropdown">
                        <button class="btn btn-tool dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="fas fa-ellipsis-v"></i>
                        </button>
                        <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                          <li class="dropdown-item">
                            <router-link :to="{name: 'websites', params: {'id': website.id} }" class="btn-block">
                              <i class="fa fa-edit mr-2"></i> Edit
                            </router-link>
                          </li>
                          <li class="dropdown-item">
                            <router-link :to="{name: 'tags', query: {'website': website.id} }" class="btn-block">
                              <i class="fas fa-tags mr-2"></i>Tags
                            </router-link>
                          </li>  
                          <li class="dropdown-item">
                            <router-link :to="{name: 'placements_groups', query: {'website': website.id} }" class="btn-block">
                              <i class="fas fa-layer-group mr-2"></i>Groups
                            </router-link>
                          </li>
                          <li class="dropdown-item">
                            <router-link :to="{name: 'players', query: {'website': website.id} }" class="btn-block">
                              <i class="fas fa-film mr-2"></i>Players
                            </router-link>
                          </li>
                          <li class="dropdown-item">
                            <router-link :to="{name: 'videos', query: {'website': website.id} }" class="btn-block">
                              <i class="fas fa-photo-video mr-2"></i>Content
                            </router-link>  
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
              </tbody>
          </table>

        </div>
        
        <div class="overlay" v-if="!isLoaded">
          <point-spinner />
        </div>

        <!-- <div class="overlay dark" v-if="hasError">
          <i class="fas fa-2x fa-exclamation-triangle"></i>
        </div> -->
    </div>
</template>

<script>
export default {
    props: {
        websites: {
          required: true,
          type: Array
        },
        isLoaded: {
          required: false,
          type: Boolean
        }
    },
    data() {
        return {
          search_text: ""
        }
    },
    methods: {
      loadSearchResult() {
        this.$emit("search", this.search_text)
      }
    },
    watch: {
      search_text: function() {
        this.loadSearchResult()
      }
    }
}
</script>

<style scoped>
.table-responsive{
  overflow : visible;
}
.dropdown-toggle::after {
  display: none;
}
</style>
