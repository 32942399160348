<template>
<div class="row">
    <div class="col-md-6">
        <TagsList 
            :website="website" 
            :tags="tags" 
            :isLoaded="isLoaded"
            @search="loadSearchResult" 
         />

        <ul class="pagination pagination-sm m-0 float-right">
          <li class="page-item">
            <a class="page-link" href="#" @click.prevent="previousPage">Previous</a>
          </li>
          <li class="page-item">
            <a class="page-link">{{ currentPage }} / {{ pageCount }}</a>
          </li>
          <li class="page-item">
            <a class="page-link" href="#" @click.prevent="nextPage">Next</a>
          </li>
        </ul>
    </div>

    <div class="col-md-6">
        <TagView :tag="tag" :website="website" :isLoading="isLoading" @updateList="updateList" @refreshList="refreshList"/>
    </div>
</div>
</template>

<script>
import TagsList from '@/components/tags/List'
import TagView from '@/components/tags/Tag'

import { RepositoryFactory } from '@/services/repositories/ManagementAPI/RepositoryFactory' 
const WebsitesRepo = RepositoryFactory.get('websites')
const TagsRepo = RepositoryFactory.get('tags')

export default {
    name: "Tags",
    components: {
        TagsList,
        TagView
    },
    data: function() {
        return {
            website_id: 0,
            website: {},
            tags: [],
            tag: {
                name: "",
                code: "",
                website_id: 0,
                status: ""
            },
            isLoaded: false,
            isLoading: false,

            currentPage: 1,
            totalRecords: 0,
            resultsLimit: 10,
            isLoaded: false,
            websites: [],
        }
    },
    methods: {
        loadWebsiteData: function() {
            WebsitesRepo.get(this.website_id).then(result => {
                this.website = result.data
                this.tag.website_id = this.website.id
            })
        },
        loadTags: function() {
            this.isLoaded = false
            TagsRepo.page(this.website_id, this.currentPage).then(result => {
                this.totalRecords = result.data.count
                this.tags = result.data.results
                this.isLoaded = true
            }).catch(err => {
                this.isLoaded = true
            })
        },
        loadTag: function(id) {
            this.isLoading = true
            TagsRepo.get(id).then(result => {
                this.tag = result.data
                this.isLoading = false
            }).catch(err => {
                this.isLoading = false
            })
        },
        updateList: function(tag) {
            this.tags.push(tag)
            this.$router.replace({params: {'id': tag.id}, query: {'website': this.website_id}})
        },
        refreshList: function(action) {
            if(action)
                this.loadTags()
        },
        loadSearchResult: function(search_text) {
            TagsRepo.page(this.website_id, this.currentPage, 10, search_text).then(result => {
                this.totalRecords = result.data.count
                this.tags = result.data.results
            }).catch(err => {})
        },
        nextPage: function() {
            if(this.currentPage != this.pageCount || this.currentPage > this.pageCount) {
                this.currentPage = this.currentPage + 1
                this.loadTags()
            }
        },
        previousPage: function() {
            if(this.currentPage != 1 ) {
                this.currentPage = this.currentPage - 1
                this.loadTags()
            }
        }
    },
    computed: {
        pageCount: function() {
            return Math.ceil(this.totalRecords / this.resultsLimit)
        },
    },
    watch: {
        '$route.query.website': { 
            handler: function (website) {
                if(typeof website !== 'undefined') {
                    // check if user is not loaded 
                    // website tags before
                    if(this.website_id !== website) {
                        // match website with passed from query
                        this.website_id = website

                        // load website and tags
                        this.loadWebsiteData()
                        this.loadTags()
                    }
                } else {
                    this.$router.push({name: "website"})
                }
            },
            deep: true,
            immediate: true
        },
        '$route.params.id': {
            handler: function (id) {
                if(typeof id !== 'undefined') {
                    var element = this.tags.filter(element => { return element.id === id })
                    if(element.length > 0) {
                        this.loadWebsiteData()
                        this.loadTag(id)
                    } else {
                        this.tag = {}
                    }
                }
            },
            deep: true,
            immediate: true
        },
    }
}
</script>

<style>

</style>