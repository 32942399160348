<template>
   <div class="">
    <div class=" block xl:grid grid-cols-2 gap-0">
      <!-- BEGIN: Login Info -->
      <div class=" xl:col-span-1 hidden xl:flex flex-col items-center justify-center z-10">
        <img class="image-bgC object-cover" src="assets/img/background-1.jpg " />
      </div>
      <!-- END: Login Info -->
      <!-- BEGIN: Login Form -->
      <div class="min-h-screen flex flex-col justify-between xl:col-span-1 bg-white login-bg-dark" v-if="!signUp" key="1" >
          <a href="" class="-intro-x bg-white login-bg-dark self-center mt-10">
            <company-logo  />
          </a>
          <div class="my-10">
              <div class="-intro-x text-center tracking-tighter leading-none font-light text-3xl text-gray-500">
                Welcome to {{ company }}!
                <br>
                sign in to your account.
              </div>
              <div class="intro-x mt-6 flex flex-col w-2/3 xl:w-1/2 mx-auto">
                <float-label class="mt-8">
                  <input type="text" id="username" class="intro-x login__input input input--lg border border-gray-300 block w-full" placeholder="Username" v-model="username">
                </float-label>  
                <float-label class="mt-8">
                  <input type="password" class="intro-x login__input input input--lg border border-gray-300 block w-full" placeholder="Password" v-model="password">
                </float-label>
                <div class="intro-x text-gray-700 dark:text-gray-200 text-md  mt-2 hover:text-theme-1 text-right w-full">
                  <a @click.prevent="signUp=true" class="cursor-pointer">I Forgot my password</a>
                </div>
              </div>
              
              <!--login/sign up buttons-->
              <div class="intro-x mt-3 xl:mt-5 text-center ">
                <button class="btn btn-primary py-2 px-10" @click.prevent='emitEvent' >Login</button>
              </div>
              <!--end of login/sign up buttons -->
          </div>
          <div class="mt-3 mx-auto ">
            <h4 class="text-center text-gray-500">VERFIED BY</h4>
            <div class="">
              <img class="w-6 sm:w-16 inline mx-3" src="/assets/img/logos/iab-logo.png" alt="">
              <img class="w-6 sm:w-14  inline mx-3" src="/assets/img/logos/google-logo.png" alt="">
              <img class="w-12 sm:w-24 inline mx-3" src="/assets/img/logos/forensiq-logo1.png" alt="">
              <img class="w-12 sm:w-24 inline mx-3" src="/assets/img/logos/pubmatic-logo.png" alt="">
              <img class="w-12 sm:w-24 inline mx-3" src="/assets/img/logos/freewheel-logo1.png" alt="">
            </div>
          </div>
          <div class="mx-auto p-3">
            <div class="intro-x flex items-center text-gray-700 mt-4 text-xs sm:text-sm">
              <Terms />
            </div>
          </div>
      </div>
      <!-- END: Login Form -->
      <!-- BEGIN: Register Form -->
      <div class="xl:col-span-1 bg-white login-bg-dark" v-else key="2">
        <forgot-password @resetBtn="signUp=false"  />
      </div>
    <!-- END: Register Form -->
    </div>
  </div>
</template>

<script>
import CompanyLogo from '@/components/core/CompanyLogo'
import ForgotPassword from './ForgotPassword.vue'
import Terms from '@/components/core/Terms'

export default {
  name: 'loginPage',
  components:{
    CompanyLogo,
    ForgotPassword,
    Terms
  },
  data: () => {
    return {
      signUp: false,
      username: '',
      password: '',
    }
  },
  methods:{
    emitEvent(){
      this.$emit('login' ,{username:this.username.toLowerCase() , password:this.password});
    },
  },
  computed: {
    company: function() {
        if(this.$store.getters.whitelist.company_name != null && this.$store.getters.whitelist.company_name != '') {
          return this.$store.getters.whitelist.company_name
        } else {
        return ''
      }
    },
    allow_signup: function() {
      return this.$store.getters.whitelist.allow_signup
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import url("https://unpkg.com/tailwindcss@^1.0/dist/tailwind.min.css");
.image-bgC{
      height: 150%;
      width: 100%;
}
.input {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  border-radius: 0.375rem;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
.login .login__input {
  min-width: 350px;
  box-shadow: 0px 3px 5px #00000007;
}
input[id="username"] {
    text-transform: lowercase;
}
</style>