<template>
    <!-- Blocklist -->
    <div class="border mb-2 rounded-lg font-weight-bold text-dark" v-if="blocklist">
        <nav class="m-2">
            <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                <li class="nav-item has-treeview menu-close">
                    <a href="#" class="nav-link flex align-items-center text-orange-700">
                    <i class="nav-icon fa fa-list"></i>
                    <div class=""> Blocklist </div><i class="fas fa-angle-left ml-auto"></i> 
                    </a>
                    <ul class="m-3 nav nav-treeview">
                        <li class="nav-item font-weight-normal pb-6">
                            <div class="d-flex justify-content-start align-items-baseline input-group">
                                <div class="col-md-10">
                                    <label for="text">Ad ID</label>
                                    <input class="form-control" type="number" style="height: 34px; min-height: 40px;" required="" v-model="ad" />
                                </div>
                                <div class="col-md-2">
                                    <label></label>
                                    <button class="btn btn-success btn-block pull-right" style="" @click.prevent="addAd()">Add</button>
                                </div>            
                                <div class="col-md-12">
                                    <ul class="custom-select-list">
                                        <li v-for="ad in blocklist.ads" :key="ad.id" v-animate-css="'bounceInLeft'"> 
                                            {{ ad }}
                                            <a href="#" class="float-right remove-btn" @click.prevent="deleteAd(ad)" ><i class="fa fa-close"></i> Delete</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                        <li>
                            <hr><br>
                            <button class="btn btn-success float-right" @click.prevent="update()">Save</button>
                            <br>
                        </li>
                    </ul>
                </li>
            </ul>
        </nav>
    </div>
    <!-- Format -->
</template>
<script>
import { RepositoryFactory } from '@/services/repositories/PlayerAPI/RepositoryFactory'
const BlocklistRepo = RepositoryFactory.get('blocklist')

export default {
    props: {
        blocklist: {
            type: Object,
            required: false
        },
    },
    data () {
        return {
            ad: '',
        }
    },
    created () {},
    methods: {
        update: function () {
            BlocklistRepo.patch(this.blocklist.id, this.blocklist).then(result => {
                this.blocklist = result.data
                this.$notify({
                    group: 'ops',
                    type: 'success',
                    title: 'Operation success',
                    text: 'Successfully updated the player blocked list ads.'
                })
            }).catch(error => {
                this.$notify({
                    group: 'ops',
                    type: 'error',
                    title: 'Operation failed',
                    text: 'Failed to update the player blocked list ads.'
                })
            })
        },
        addAd: function() {               
            this.blocklist.ads.push(parseInt(this.ad))
            this.ad = ''
        },
        deleteAd: function(ad) {
            const index = this.blocklist.ads.indexOf(ad);
            if (index > -1) {
                this.blocklist.ads.splice(index, 1);
            }
        },
    }
}
</script>