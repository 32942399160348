import Repository from './Repository';

const resource = "/adscripts"


export default {
    query(params) {
        if(params.limit == undefined) params.limit = 50
        if(params.page == undefined) params.page = 1
        
        params.offset = (params.page - 1) * params.limit
        params = new URLSearchParams(params).toString()
        return Repository.get(`${resource}/?${params}`)
    },
    get(id) {
        return Repository.get(`${resource}/${id}/`);
    },
    post(data) {
        return Repository.post(`${resource}/`, data);
    },
    update(id, data) {
        return Repository.put(`${resource}/${id}/`, data);
    },
    patch(id, data) {
        return Repository.patch(`${resource}/${id}/`, data);
    },
    delete(id) {
        return Repository.delete(`${resource}/${id}/`);
    },
    status() {
        return Repository.get(`${resource}/status/`);
    }
}