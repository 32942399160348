import AuthRepo from './../AuthAPI/AuthRepo'
import UsersRepo from './../AuthAPI/UsersRepo'
import PermissionsRepo from './../AuthAPI/PermissionsRepo'
import AssignmentRepo from './AssignmentsRepo'

import WebsitesRepo from './WebsitesRepo'
import SuppliersRepo from './SuppliersRepo'
import LocalAPIsRepo from './APIs/LocalAPIsRepo.js'
import ExternalAPIsRepo from './APIs/ExternalAPIsRepo.js'

import GroupsRepo from './GroupsRepo'
import GroupsDataRepo from './GroupsDataRepo'
import ServeMetricsRepo from './ServeMetricsRepo'

import AdsRepo from './AdsRepo'
import TagsRepo from './TagsRepo'

import WalletsRepo from './Wallets/WalletsRepo'
import InvoicesRepo from './Wallets/InvoicesRepo'
import AccountsRepo from './Wallets/AccountsRepo'

import StatisticsRepo from './Statistics/StatisticsRepo'
import GroupsStatistics from './Statistics/GroupsStatisticsRepo'
//import UserGroupsStatistics from './Statistics/User/GroupsStatisticsRepo'
import WebsitesStatistics from './Statistics/WebsitesStatisticsRepo'

import WhitelistRepo from './WhitelistRepo'
import SystemRepo from './SystemRepo'

import AdCrewSharesRepo from './Shares/AdCrewRepo'
import PMPSharesRepo from './Shares/PMPRepo'
import NetworkSharesRepo from './Shares/NetworkRepo'
import SuppliersSharesRepo from './Shares/SuppliersRepo'

import FillRateRepo from './FillRateRepo'

import GroupsTypesRepo from './GroupsTypesRepo'

import OperationsRepo from './OperationsRepo'
import PurgeCacheRepo from './PurgeCacheRepo'

const repositories = {
    websites: WebsitesRepo,
    tags: TagsRepo,
    ads: AdsRepo,

    wallets: WalletsRepo,
    invoices: InvoicesRepo,
    account_wallets: AccountsRepo,

    suppliers: SuppliersRepo,
    local_apis: LocalAPIsRepo,
    external_apis: ExternalAPIsRepo,

    groups: GroupsRepo,
    groups_data: GroupsDataRepo,
    serve_metrics: ServeMetricsRepo,

    statistics: StatisticsRepo,
    groups_statistics: GroupsStatistics,
    //user_groups_statistics: UserGroupsStatistics,
    websites_statistics: WebsitesStatistics,
    
    users: UsersRepo,
    assign: AssignmentRepo,
    permissions: PermissionsRepo,
    whitelist: WhitelistRepo,
    system: SystemRepo,
    
    adcrew_shares: AdCrewSharesRepo,
    pmp_shares: PMPSharesRepo,
    network_shares: NetworkSharesRepo,
    suppliers_shares: SuppliersSharesRepo,

    fill_rate: FillRateRepo,

    groups_types: GroupsTypesRepo,

    operations: OperationsRepo,

    auth_status: AuthRepo,
    purge_cache: PurgeCacheRepo
}

export const RepositoryFactory = {
    get: name => repositories[name],
};

export const ManageRepoFactory = {
    get: name => repositories[name],
};
