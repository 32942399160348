<template>
<div class="col-md-6">
    <div class="card">
        <div class="card-header">
            <h3 class="card-title">AD FORMAT YESTERDAY</h3>
            <div class="card-tools">
                <i v-if="isLoading" class="fa fa-spinner fa-pulse"></i>
            </div>
        </div>
        <!-- /.card-header -->
        <div class="card-body table-responsive p-0">
            <table class="table table-striped">
                <thead>
                <tr>
                    <th style="width: 10px">#</th>
                    <th>Ad Format</th>
                    <th>Website</th>
                    <th>Revenue</th>
                    <th>Change</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(ad_format, index) in ad_formats" :key="index" :class="{displayNone:index>=listCounter}">
                    <td>{{ index+1 }}</td>
                    <td>{{ PlacementGroupName(ad_format.placement_group_id) }}</td>
                    <td>{{ WebsiteName(ad_format.website_id) }}</td>
                    <td>
                        <span v-if="ad_format.revenue">${{ ad_format.revenue }}</span>
                        <span v-if="!ad_format.revenue">$0</span>
                    </td>
                    <td>
                        <small v-if="ad_format.revenue_diff == 0" class="text-success mr-1">
                            {{ ad_format.revenue_diff }}%
                      </small>
                        <small v-if="ad_format.revenue_diff > 0" class="text-success mr-1">
                            <i class="fas fa-arrow-up"></i>
                            {{ ad_format.revenue_diff }}%
                      </small>
                        <small v-if="ad_format.revenue_diff < 0" class="text-danger mr-1">
                            <i class="fas fa-arrow-down"></i>
                            {{ ad_format.revenue_diff }}%
                      </small>
                    </td>
                </tr>
                </tbody>
            </table>
            <div class="" >
                <a href="" v-if="viewMoreBtn" ref="viewMoreBtn"  @click.prevent="viewMore()" class="btn btn-outline-secondary btn-sm btn-block">{{message ||'View More'}}</a>
                <a href="" v-if="!viewMoreBtn"  @click.prevent="showLess()" class="btn btn-outline-secondary btn-sm btn-block">Show Less</a>    
            </div>           
        </div>
        <!-- /.card-body -->
    </div>
</div>
</template>

<script>
import { RepositoryFactory } from '@/services/repositories/ManagementAPI/RepositoryFactory'
const PlacementsGroupRepo = RepositoryFactory.get('groups')
const GroupStatisticsRepo = RepositoryFactory.get('groups_statistics')
const WebsitesRepo = RepositoryFactory.get('websites')

export default {
    data() {
        return {
            ad_formats: [],
            placement_groups: [],
            placement_groups_ids: [],

            websites: [],
            websites_ids: [],
            websites_list: [],

            isLoading: false,
            viewMoreBtn: true,
            listCounter: 10,
            message: ""
        }
    },
    created() {
        this.loadGroupStatisticsStatus()
    },
    methods: {
        loadGroupStatisticsStatus: function() {
            this.isLoading = true
            GroupStatisticsRepo.status().then(result => {
                this.ad_formats = result.data.ad_format
                this.filterPlacementGroupsList()
            }).catch(err => {
                this.isLoading = false
            })
        },

        filterPlacementGroupsList: function() {
            let ad_formats_list = []
            let websites_list = []
            this.ad_formats.forEach(function(element) {
                ad_formats_list.push(element.placement_group_id)
                websites_list.push(element.website_id)
            })
            this.placement_groups_ids = ad_formats_list
            this.websites_ids = websites_list
            this.loadPlacementGroups()
            this.loadWebsites()
        },
        loadPlacementGroups: function() {
            PlacementsGroupRepo.ids(this.placement_groups_ids).then(result => {
                this.placement_groups = result.data.results
                this.isLoading = false
            }).catch(err => {
                this.isLoading = false
            })
        },
        loadWebsites: function() {
            WebsitesRepo.ids(this.websites_ids).then(result => {
                this.websites_list = result.data.results
                this.isLoading = false
            }).catch(err => {
                this.isLoading = false
            })
        },

        PlacementGroup: function(placement_group_id) {
            var placement_group = this.placement_groups.filter(element => { return element.id == placement_group_id })[0]
            return placement_group
        },
        PlacementGroupName: function(placement_group_id) {
            var placement_group = this.placement_groups.filter(element => { return element.id == placement_group_id })[0]
            if(placement_group)
                return placement_group.name
        },
        WebsiteName: function(website_id) {
            var website = this.websites_list.filter(element => { return element.id == website_id })[0]
            if(website)
                return website.name
        },
        viewMore: function(){
            if (this.ad_formats.length > this.listCounter){
                this.listCounter = this.listCounter + this.listCounter;    
                if(this.listCounter >= this.ad_formats.length)
                    this.viewMoreBtn = false
            }
            else{
                this.message = "No More Views" 
                this.$refs.viewMoreBtn.style.cursor = "not-allowed"
            }    
        },
        showLess: function(){
            this.listCounter = 10;
            this.viewMoreBtn = true;
        },
    },
}
</script>
<style lang="css" scoped>
.displayNone{
    display:none;
    }
</style>