<template>
    <div class="card">
        <div class="card-header">
        <h3 class="card-title">
            ( <router-link :to="{name: 'placements_groups', query: {'website': placement_group.website_id}}"> {{ placement_group.name }} </router-link> ) Data List
        </h3>

        <div class="card-tools">
            <div class="input-group input-group-sm" style="width: 150px;">
                <input type="search" name="table_search" class="form-control float-right" placeholder="Search" v-model="search_text">
                <div class="input-group-append">
                    <button class="btn btn-default" @click.prevent="loadSearchResult()"><i class="fas fa-search"></i></button>
                </div>
            </div>
        </div>
        </div>
        <!-- /.card-header -->
        <div class="card-body table-responsive p-0">
            
        <table class="table table-hover responsive">
            <thead>
            <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Supplier</th>
                <th>DFP ID</th>
                <th>Status</th>
                <th class="text-center">Action</th>
            </tr>
            </thead>
            <tbody>
                <tr v-for="pg_data in pg_data_list" :key="pg_data.id" v-animate-css="'slideInLeft'">
                    <td>{{ pg_data.name }}</td>
                    <td>{{ pg_data.type | uppercase }}</td>
                    <td>{{ SupplierName(pg_data.supplier_id) }}</td>
                    <td>{{ pg_data.dfp_placement_id }}</td>
                    <td>
                    <span class="alert alert-success btn-xs" :class="{
                        'alert-success': pg_data.status == 'active',
                        'alert-danger': pg_data.status == 'suspended'}"> {{ pg_data.status | capitalize }} 
                    </span>
                    </td>
                    <td class="text-center">
                    <div class="btn-group btn-block">
                        <router-link :to="{name: 'placements_groups_data', params: {'id': pg_data.id}, query: {'placement_group': pg_data.placement_group_id} }" class="btn btn-default btn-sm">
                        <i class="fa fa-edit"></i> Edit</router-link>
                    </div>
                    <div class="btn-group btn-block">
                        <button class="btn btn-default btn-sm" @click.prevent="displayReport(pg_data)"><i class="fa fa-chart-bar"></i></button>
                        <router-link :to="{name: 'data_statistics', params: {'id': pg_data.id} }" class="btn btn-default btn-sm">
                            <i class="fa fa-plus"></i>
                        </router-link>
                    </div>

                    </td>
                </tr>
            </tbody>
        </table>

        </div>

        <div class="overlay" v-if="!isLoaded">
            <point-spinner />
        </div>

        <!-- <div class="overlay dark" v-if="hasError">
        <i class="fas fa-2x fa-exclamation-triangle"></i>
        </div> -->
    </div>
</template>

<script>
export default {
    props: {
        placement_group: {
            required: true,
            type: Object
        },
        pg_data_list: {
          required: true,
          type: Array
        },
        suppliers: {
          required: true,
          type: Array
        },
        isLoaded: {
          required: false,
          type: Boolean
        }
    },
    data() {
        return {
            search_text: ""
        }
    },
    methods: {
        SupplierName: function(supplier_id) {
            var supplier = this.suppliers.filter(element => { return element.id == supplier_id })[0]
            if(supplier)
                return supplier.name
        },
        displayReport(pgd) {
            var data = {
                name: pgd.name,
                id: pgd.id
            }
            this.$emit('displayReport', pgd)
        },
        loadSearchResult() {
            this.$emit("search", this.search_text)
        }
    },
    watch: {
      search_text: function() {
        this.loadSearchResult()
      }
    }
}
</script>

<style scoped>

</style>