<template>
    <div style="width: 100%; height: 550px;">
        <canvas id="websiteStats"></canvas>
    </div>
</template>

<script>
import statisticsToGraphData from './data'
export default {
    name: 'ChartJS',
    props: {
        data: {
            type: Array,
            required: true
        }
    },
    created() {

    },
    mounted: function() {
        this.init()
    },
    methods: {
        init: function() {
            let ctx = document.getElementById('websiteStats').getContext('2d') 
            this.currenGraph(ctx)
        },
        currenGraph: function(element) {
            let last30DaysStatistics = statisticsToGraphData(this.data, true, false, true)
            var labels = last30DaysStatistics.date
            var dataSet = [{
                yAxisID: 'y-axis-1',
                label: "Impressions",
                borderColor: '#2caece',
                backgroundColor: '#2caece',
                fill: false,
                data: last30DaysStatistics.impressions
            }, {
                yAxisID: 'y-axis-2',
                label: "Revenue",
                borderColor: '#21bb9b',
                backgroundColor: '#21bb9b',
                fill: false,
                data: last30DaysStatistics.revenue
            }];
            var options = {
                responsive: true,
                maintainAspectRatio: false,
                hoverMode: 'index',
                stacked: true,
                title:{
                    display: false,
                    text:'Last 30 Days'
                },
                scales: {
                    yAxes: [{
                        type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
                        display: true,
                        title: "Impressions",
                        position: "left",
                        id: "y-axis-1",
                        ticks: {
                            beginAtZero: true,
                        }
                    }, {
                        type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
                        display: true,
                        title: "Revenue",
                        position: "right",
                        id: "y-axis-2",
                        gridLines: {
                            display: false
                        },
                        ticks: {
                            beginAtZero: true,
                        }
                    }],
                    xAxes: [{
                        display: true,
                        id: "x-axis",
                        type: 'time',
                        time: {
                            unit: 'day'
                        },
                        gridLines: {
                            display: true
                        },
                    }],
                }
            };

            new Chart.Line(element, {
                data: {
                    labels: labels,
                    datasets: dataSet
                },
                options: options
            })
        }
    },
    watch: {
        data: function(data) {
            this.init()
        }
    }
}
</script>

<style>

</style>