import Repository from './Repository';

const resource = "/purge_cache";
export default {
    purge(link) {
        var data = {
            "url": link
        }
        return Repository.post(`${resource}/purge/`, data);
    },
}