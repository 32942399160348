import Repository from './Repository';

const resource = "/placements_groups_data/data";
export default {
    page(pgid, page=1, limit=10, search='') {
        var offset = (page - 1) * limit
        if(search) { search = `&search=${search}` }
        return Repository.get(`${resource}/?pgid=${pgid}&limit=${limit}&offset=${offset}${search}`);
    },
    get(id) {
        return Repository.get(`${resource}/${id}/`);
    },
    post(data) {
        return Repository.post(`${resource}/`, data);
    },
    update(id, data) {
        return Repository.put(`${resource}/${id}/`, data);
    },
    patch(id, data) {
        return Repository.patch(`${resource}/${id}/`, data);
    },

    supplier(id) {
        return Repository.get(`${resource}/?supplier=${id}`);
    },
    withGroup(group) {
        return Repository.get(`${resource}/?pgid=${group}`);
    },
    withGroupAndSupplier(group, supplier) {
        return Repository.get(`${resource}/?pgid=${group}&supplier=${supplier}`);
    },
    withGroupAndType(group, type) {
        return Repository.get(`${resource}/?pgid=${group}&type=${type}`);
    }
}