import Repository from './Repository';

const resource = "/groups";
export default {
    all() {
        return Repository.get(`${resource}/`);
    },
    get(id) {
        return Repository.get(`${resource}/${id}/`);
    },
    create(data) {
        return Repository.post(`${resource}/`, data);
    },
    update(id, data) {
        return Repository.put(`${resource}/${id}/`, data);
    },
    delete(id) {
        return Repository.delete(`${resource}/${id}/`)
    }
}