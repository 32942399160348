<template>
<div class="row">
    <div class="col-md-2 col-sm-12">
        <WalletsTotal v-if="account_totals" :wallet="account_totals" :user="user" v-animate-css="'slideInLeft'" />
    </div>
    <div class="col-md-10 col-sm-12">
        <div class="card">
            <div class="card-header">
            <h3 class="card-title">List</h3>

                <div class="card-tools">
                    <div class="input-group input-group-sm" style="width: 150px;">
                        <input type="search" name="table_search" class="form-control float-right" placeholder="Search">
                        <div class="input-group-append">
                            <button type="submit" class="btn btn-default"><i class="fas fa-search"></i></button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- /.card-header -->
            <div class="card-body table-responsive p-0">
            <table class="table table-hover">
                <thead>
                <tr>
                    <th>ID</th>
                    <th>Website</th>
                    <th class="text-center">Total Amount</th>
                    <th class="text-center">Status</th>
                    <th class="text-center">Action</th>
                </tr>
                </thead>
                <tbody>
                    <tr v-for="wallet in wallets" :key="wallet.id">
                        <td>{{ wallet.id }}</td>
                        <td>{{ WebsiteName(wallet.website_id) }}</td>
                        <td class="text-center">${{ wallet.total | withComma }}</td>
                        <td class="text-center">
                            <span class="alert alert-success btn-xs" :class="{
                                'alert-success': wallet.status == 'active',
                                'alert-danger': wallet.status == 'suspended'}"> {{ wallet.status | capitalize }} 
                            </span>
                        </td>
                        <td class="text-center">
                            <div class="btn-group btn-group-sm">
                                <router-link class="btn btn-primary" :to="{name: 'payment', params: {id: wallet.id}}"><i class="fa fa-edit"></i> View</router-link>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            </div>
            <!-- /.card-body -->
            <div class="card-footer">
                <ul class="pagination pagination-sm m-0 float-right">
                    <li class="page-item">
                        <a class="page-link" href="#" @click.prevent="previousPage">Previous</a>
                    </li>
                    <li class="page-item">
                        <a class="page-link">{{ currentPage }} / {{ pageCount }}</a>
                    </li>
                    <li class="page-item">
                        <a class="page-link" href="#" @click.prevent="nextPage">Next</a>
                    </li>
                </ul>
            </div>


            <div class="overlay" v-if="!isLoaded">
                <point-spinner />
            </div>
        </div>
        <!-- /.card -->
    </div>
</div>
</template>
<script>
import WalletsTotal from '@/components/wallets/side/WalletsTotal'
import { RepositoryFactory } from '@/services/repositories/ManagementAPI/RepositoryFactory' 
const WalletsRepo = RepositoryFactory.get('wallets')
const WebsitesRepo = RepositoryFactory.get('websites')
const AccountWalletsRepo = RepositoryFactory.get('account_wallets')
const UsersRepo = RepositoryFactory.get('users')

export default {
    components: {
        WalletsTotal,
    },
    data() {
        return {
            wallets: [],
            websites: [],

            account: 0,
            account_totals: {},
            user: {},

            currentPage: 1,
            totalRecords: 0,
            resultsLimit: 10,
            isLoaded: true,
        }
    },
    created() {
        this.loadWebsites()        
    },
    methods: {
        loadWallets: function() {
            this.isLoaded = false
            WalletsRepo.page(this.currentPage).then(result => {
                this.totalRecords = result.data.count
                this.wallets = result.data.results
                this.account_totals = result.data.total
                
                this.isLoaded = true
            }).catch(err => {
                this.isLoaded = true
            })
        },
        loadAccountWallets: function() {
            this.isLoaded = false
            AccountWalletsRepo.get(this.account).then(result => {
                this.totalRecords = result.data.count
                this.wallets = result.data.results
                this.account_totals = result.data.total

                this.isLoaded = true
            }).catch(err => {
                this.isLoaded = true
            })
        },
        loadWebsites: function() {
            WebsitesRepo.all().then(result => {
                this.websites = result.data.results
            })
        },
        loadUserData: function() {
            UsersRepo.get(this.account).then(result => {
                this.user = result.data
            })
        },
        WebsiteName: function(website_id) {
            var website = this.websites.filter(element => { return element.id == website_id })[0]
            if(website)
                return website.name
        },
        nextPage: function() {
            if(this.currentPage != this.pageCount || this.currentPage > this.pageCount) {
                this.currentPage = this.currentPage + 1
                if(this.account) {
                    this.loadAccountWallets()
                } else {
                    this.loadWallets()
                }
            }
        },
        previousPage: function() {
            if(this.currentPage != 1 ) {
                this.currentPage = this.currentPage - 1
                if(this.account) {
                    this.loadAccountWallets()
                } else {
                    this.loadWallets()
                }
                
            }
        },
  },
  computed: {
    pageCount: function() {
            return Math.ceil(this.totalRecords / this.resultsLimit)
        },
    },
    watch: {
        '$route.query.account': {
            handler: function (account) {
                if(typeof account !== 'undefined') {
                    this.account = account
                    this.loadUserData()
                    this.loadAccountWallets()
                } else {
                    this.loadWallets()
                }
            },
            deep: true,
            immediate: true
        },
    }

}
</script>