<template>
    <div>
        <div class="row">
            <div class="col-md-5">
            <PlayersList :players='players' :isLoaded='isLoaded' @search="loadSearchResult" @reload="reload" />

            <ul class="pagination pagination-sm m-0 float-right">
                <li class="page-item">
                    <a class="page-link" href="#" @click.prevent="previousPage">Previous</a>
                </li>
                <li class="page-item">
                    <a class="page-link">{{ currentPage }} / {{ pageCount }}</a>
                </li>
                <li class="page-item">
                    <a class="page-link" href="#" @click.prevent="nextPage">Next</a>
                </li>
            </ul>
            <br />
            </div>
            
            <div class="col-md-7">
                <PlayerOptions :player="player" :website_id=website_id :isLoading="isLoading" @updateList="updateList" @refreshList="refreshList" />
            </div>
        </div>
    </div>
</template>
<script>
import PlayersList from '@/components/players/List.vue'
import PlayerOptions from '@/components/players/Options.vue'

import { RepositoryFactory } from '@/services/repositories/PlayerAPI/RepositoryFactory'
const PlayersRepo = RepositoryFactory.get('players')

export default {
    components:{
        PlayersList,
        PlayerOptions
    },
    data() {
        return {
            players: [],
            player: {},
            website_id: null,
            isLoaded: true,
            isLoading: false,
            currentPage: 1,
            totalRecords: 0,
            resultsLimit: 10,
            query: {},
            search_text: ""
        }
    },
    created: function() {
        this.loadPlayers()
    },
    methods:{
        loadPlayers: function() {
            this.isLoaded = false
            this.query = {
                page: this.currentPage,
                limit: this.resultsLimit,
                search: this.search_text
            }
            if(this.website_id) {
                this.query = {
                    website: this.website_id,
                    page: this.currentPage, 
                    limit: this.resultsLimit,
                }
            }

            PlayersRepo.query(this.query).then(result => {
                this.totalRecords = result.data.count
                this.isLoaded = true
                this.players = result.data.results
            }).catch(err => {
                this.players = []
            })
        },
        loadPlayer: function(player_id) {
            this.isLoading = true
            PlayersRepo.get(player_id).then(result => {
                this.player = result.data
                this.isLoading = false
            }).catch(err => {
                this.player = {}
            })
        },
        loadSearchResult(search_text) {
            this.search_text = search_text
            this.currentPage = 1

            this.query = {
                page: this.currentPage, 
                limit: this.resultsLimit,
                search: search_text
            }

            if(this.website_id) {
                this.query = {
                    website: this.website_id,
                    page: this.currentPage, 
                    limit: 10,
                    search: search_text
                }
            }
            PlayersRepo.query(this.query).then(result => {
                this.totalRecords = result.data.count
                this.players = result.data.results
            }).catch(err => {
                this.players = []
            })
        },
        updateList: function(player) {
            this.loadPlayers()
            if(player.id)
                this.loadPlayer(player.id)
        },
        refreshList: function(action) {
            if(action)
                this.loadPlayers()
        },
        reload: function() {
            this.currentPage = 1
            this.resultsLimit = 10
            this.loadPlayers()
        },
        nextPage: function() {
            if(this.currentPage != this.pageCount || this.currentPage > this.pageCount) {
                this.currentPage = this.currentPage + 1
                this.loadPlayers()
            }
        },
        previousPage: function() {
            if(this.currentPage != 1 ) {
                this.currentPage = this.currentPage - 1
                this.loadPlayers()
            }
        },
    },
    computed: {
        pageCount: function() {
            return Math.ceil(this.totalRecords / this.resultsLimit)
        },
    },
    watch: {
    '$route.query.website': { 
        handler: function (website) {
            if(typeof website !== 'undefined') {
                // check if user is not loaded 
                // website placements_groups before
                if(this.website_id !== website) {
                    // match website with passed from query
                    this.website_id = website
                }
            } else {
                // this.$router.push({name: "website"})
            }
        },
        deep: true,
        immediate: true
    },
    '$route.params.id': {
        handler: function (id) {
            if(typeof id !== 'undefined') {
                this.loadPlayer(id)
            }
        },
        deep: true,
        immediate: true
        },
    }
}
</script>
<style scoped>

</style>