<template>
<div class="col-md-6">
    <div class="card">
        <div class="card-header">
            <h3 class="card-title">WEBSITES YESTERDAY</h3>
            <div class="card-tools">
                <i v-if="isLoading" class="fa fa-spinner fa-pulse"></i>
            </div>
        </div>
        <!-- /.card-header -->
        <div class="card-body table-responsive p-0">
            <table class="table table-striped" id="datatable">
                <thead>
                <tr>
                    <th style="width: 10px">#</th>
                    <th>Website</th>
                    <th>Revenue</th>
                    <th>Change</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(website, index) in websites" :key="index" :class="{ displayNone: index >= listCounter }">
                    <td>{{ index+1 }}</td>
                    <td>{{ WebsiteName(website.website_id) }}</td>
                    <td>
                        <span v-if="website.revenue">${{ website.revenue }}</span>
                        <span v-if="website.revenue == null">$0</span>
                    </td>
                    <td>
                        <small v-if="website.revenue_diff == 0" class="text-success mr-1">
                            {{ website.revenue_diff }}%
                      </small>
                        <small v-if="website.revenue_diff > 0" class="text-success mr-1">
                            <i class="fas fa-arrow-up"></i>
                            {{ website.revenue_diff }}%
                      </small>
                        <small v-if="website.revenue_diff < 0" class="text-danger mr-1">
                            <i class="fas fa-arrow-down"></i>
                            {{ website.revenue_diff }}%
                      </small>
                    </td>
                </tr>
                </tbody>
            </table>
            <a href="" v-if="viewMoreBtn" ref="viewMoreBtn"  @click.prevent="viewMore()" class="btn btn-outline-secondary btn-sm btn-block">{{message ||'View More'}}</a>
            <a href="" v-if="!viewMoreBtn"  @click.prevent="showLess()" class="btn btn-outline-secondary btn-sm btn-block">Show Less</a>    
        </div>
        <!-- /.card-body -->
    </div>
</div>
</template>

<script>
import { RepositoryFactory } from '@/services/repositories/ManagementAPI/RepositoryFactory'
const WebsitesRepo = RepositoryFactory.get('websites')
const WebsiteStatisticsRepo = RepositoryFactory.get('websites_statistics')

export default {
    data() {
        return {
            websites: [],
            websites_ids: [],
            websites_list: [],
            
            isLoading: false,
            viewMoreBtn: true,
            listCounter: 10,
            message: ""
        }
    },
    created() {
        this.loadWebsiteStatisticsStatus()
    },
    methods: {
        loadWebsiteStatisticsStatus: function() {
            this.isLoading = true
            WebsiteStatisticsRepo.status().then(result => {
                this.websites = result.data.websites.filter(element => element.website_id !== null);
                this.filterWebsitesList()
            }).catch(err => {
                this.isLoading = false
            })
        },
        filterWebsitesList: function() {
            let list = []
            this.websites.forEach(function(element) {
                if(element.website_id != null)
                    list.push(element.website_id)
            })
            this.websites_ids = list
            this.loadWebsites()
        },
        loadWebsites: function() {
            WebsitesRepo.ids(this.websites_ids).then(result => {
                this.websites_list = result.data.results.filter(v => v !== null);
                this.isLoading = false
            }).catch(err => {
                this.isLoading = false
            })
        },
        Website: function(website_id) {
            var website = this.websites_list.filter(element => { return element.id == website_id })[0]
            if(website)
                return website
        },
        WebsiteName: function(website_id) {
            var website = this.websites_list.filter(element => { return element.id == website_id })[0]
            if(website)
                return website.name
        },
        viewMore: function() {
            if (this.websites_list.length > this.listCounter){
                this.listCounter = this.listCounter + this.listCounter;    
                if(this.listCounter >= this.websites_list.length)
                    this.viewMoreBtn = false
            }
            else {
                this.message = "No More Views" 
                this.$refs.viewMoreBtn.style.cursor = "not-allowed"
            }    
        },
        showLess: function() {
            this.listCounter = 10;
            this.viewMoreBtn = true;
        },
    },
}
</script>
<style lang="css" scoped>
.displayNone{
    display:none;
    }
</style>
