import Repository from './Repository';

const resource = "";

export default {
    login(data) {
        return Repository.post(`${resource}/token/`, data);
    },
    logout(data=null) {
        return Repository.post(`${resource}/logout/`, data);
    },
    verify_token(data) {
        return Repository.post(`${resource}/token/verify/`, data);
    },
    verify(data) {
        return Repository.post(`${resource}/verify/`, data);
    },
    signup(data) {
        return Repository.post(`/registration/`, data);
    },
    forgot(data) {
        return Repository.post(`/forgot/`, data);
    },
    reset(data) {
        return Repository.put(`/reset/`, data);
    },
    status() {
        // return Repository.get(`http://127.0.0.1:8050/api/v1/auth/status/`);
        return Repository.get(`${resource}/status/`);
    },
    impersonate(user_id) {
        let data = {
            "user_id": user_id
        }
        return Repository.post(`/impersonation/`, data);
    }  
}