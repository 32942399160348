<template>
<div class="container-fluid">
<div class="row">
    <div class=" col-md-4">
        <!-- Groups list -->
        <div class="card">
            <div class="card-body p-0">
                <table class="table table-striped table-valign-middle">
                    <thead>
                    <tr>
                    <th>Name</th>
                    <th>code</th>
                    <th>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="group in groups" :key="group.id">
                        <td>
                            {{ group.name }}
                        </td>
                        <td>{{ group.code }}</td>
                        <td>
                            <a href="#" class="text-muted" @click="edit(group.id)">
                            <i class="fas fa-edit"></i>
                            </a>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            
            <div class="overlay" v-if="isFailed">
                <i class="fas fa-2x fa-exclamation-triangle"></i>
            </div>
            <div class="overlay" v-if="isLoading">
                <i class="fas fa-2x fa-sync-alt fa-spin"></i>
            </div>
        </div>
        <!-- Groups list END -->
    </div>
    <div class="col-md-8">
        <div class="card">
            <div class="card-header">
                <h3 class="card-title">{{ group.name }} Group</h3>
                <div class="card-tools">
                    <button class="btn btn-tool" @click.prevent="resetForm()"> <i class="fa fa-plus"></i> </button>
                </div>
            </div>
            <!-- /.card-header -->
            <div class="card-body">
                <!-- FORM row -->
                <div class="row">
                    <div class="form-group col-md-6">
                        <label for="name">Name</label>
                        <input type="text" class="form-control" id="name" v-model="group.name" placeholder="Group name">
                    </div>            
                    <div class="form-group col-md-6">
                        <label for="code">Code</label>
                        <input type="text" class="form-control" id="code" v-model="group.code" placeholder="Group code">
                    </div>
                    <hr >
                    <div class="form-group col-md-12">
                        <label>
                            Permissions 
                            <span class="float-right" v-if="group">
                                ( {{ this.group.permissions.length  }} )
                            </span>
                        </label>
                        <multiSelect id="multi-select" :permissions="permissions"
                         :selectedPermissions="selectedPermissions"
                         @itemselected="updateSelectedPermissions"
                         v-if="multiSelectComp"  />
                    </div>
                    <div class="col-md-12">
                        <!-- display errors and messages -->
                        <errors :errors="errors"/>
                        <messages :messages="messages" />
                    </div>
                <!-- FROM row END -->
                </div>   
            </div>
            <!-- /.card-body -->
            <div class="card-footer">
                <button class="btn btn-primary float-right" @click.prevent="submit(group.id)" >Save</button>
            </div>
            <!-- /.card-footer -->
        </div>
        <!-- /.card -->
    </div>
</div>
</div>
</template>

<script>
import multiSelect from '@/components/credentials/multi-select'

import { RepositoryFactory } from '@/services/repositories/AuthAPI/RepositoryFactory'
const GroupsRepo = RepositoryFactory.get('groups')
const PermissionsRepo = RepositoryFactory.get('permissions')

import store from '@/store/modules/credentials'

export default {
    components: {
        multiSelect,
    },
    data() {
        return {
            group: {
                name: '',
                code: '',
                permissions: []
            },
            selectedPermissions: [],
            multiSelectComp : true,
            errors: [],
            messages: [],
            isLoading: true,
            isFailed: false
        }
    },
    mounted() {
        this.isLoading = true
        GroupsRepo.all().then(result => {
            store.commit('SET_GROUPS', result.data.results)
        // eslint-disable-next-line no-unused-vars
        }).catch(err => {
            this.isFailed = false
        })
        this.isLoading = false
        
        PermissionsRepo.all().then(result => {
            store.commit('SET_PERMISSIONS', result.data.results)
            //reload multi-select component after async data 
            this.reloadPermissions()
        }) 
    },
    methods: {
        edit: function(id) {
            // get and filter the selected group
            this.group = this.groups.find(obj => obj.id == id)
            // convert permissions array to array of strings 
            // eslint-disable-next-line no-unused-vars
            this.selectedPermissions = this.group.permissions.map(element => { return element.toString() })
            //console.log(this.selectedPermissions);
            // remove any selected from other operations
            //$('.permissions').multiSelect('deselect_all');
            // select permissions for the choosen group
            //$('.permissions').multiSelect('select', permissions);
        },
        submit: function(id) {
            this.errors = []
            this.messages = []

            /*var selected = []
            // eslint-disable-next-line no-undef
            $('.permissions option:selected').each(function(){
                selected.push($(this).val())
            });*/
            this.group.permissions = this.selectedPermissions ;

            if(id) {
                // eslint-disable-next-line no-unused-vars
                GroupsRepo.update(id, this.group).then(result => {
                    this.messages.push("Successfuly saved.")
                // eslint-disable-next-line no-unused-vars
                }).catch(err => {
                    this.errors.push("Failed to save, please try agian.")
                })
            } else {
                this.group.permissions = this.selectedPermissions ;
                // eslint-disable-next-line no-unused-vars
                GroupsRepo.create(this.group).then(result => {
                    this.messages.push("Successfuly saved.")
                // eslint-disable-next-line no-unused-vars
                }).catch(err => {
                    this.errors.push("Failed to save, please try agian.")
                })
            }
            // eslint-disable-next-line no-console
            setTimeout(this.resetForm(),1000);
        },
        reloadPermissions() {
            this.multiSelectComp = false ;
            this.$nextTick(()=>{
                this.multiSelectComp =true;
            });
        },
        resetForm: function() {
            this.group = {
                name: '',
                code: '',
                permissions: []
            }
            this.errors = []
            this.messages = []
            this.selectedPermissions = [];
        },
        updateSelectedPermissions(event){
            this.selectedPermissions = event ;
        },
    },
    computed: {
        groups: function() {
            return store.getters.groups
        },
        permissions: function() {
            return store.getters.permissions
        },
    },
    watch: {
        selectedPermissions: { 
            handler: function() {
                this.reloadPermissions()
            },
            deep: true,
        }
    }
}
</script>

<style>
.ms-container {
    width: 100%;
}
/*#multi-select{
    margin-left: 3rem;
    margin-top: 3rem;
    transform: scale(1.5);
}*/
</style>