<template>
<div>
    <div class="card" v-animate-css="'slideInDown'">
        <div class="card-header">
        <h3 class="card-title">
            {{ name }} Results 
        </h3>
        <div class="card-tools">
            <button class="btn btn-tool" @click.prevent="loadStatistics()">
                <i :class="{'fa-spin': isLoading}" class="fas fa-sync" aria-hidden="true"></i>
            </button>
            <button type="button" class="btn btn-tool" data-card-widget="maximize"><i class="fas fa-expand"></i></button>
        </div>
        </div>
        <!-- /.card-header -->
        <div class="card-body table-responsive p-0">
        <table class="table table-hover">
            <thead>
            <tr>
                <th class="text-center">Date</th>
                <th class="text-center">Supplier</th>
                <th class="text-center">Data Name</th>
                <th class="text-center" v-for="metric in metrics" :key="metric"> {{ metric | capitalizeAndReplace }} </th>
            </tr>
            </thead>
            <tbody>
                <tr v-if="statistics.length <= 0">
                    <td :colspan="metrics.length+5" class="text-center">
                        <span v-if="!isLoading">No statistics available</span>
                        <span v-if="isLoading"><i class="fa fa-spinner fa-3x fa-pulse"></i></span>
                    </td>
                </tr>
                <tr v-for="statistic in statistics" :key="statistic.id"  v-animate-css="'slideInLeft'">
                    <td class="text-center">{{ start_date }} <br /> {{ end_date }}</td>
                    <td class="text-center">{{ statistic.supplier_name }}</td>
                    <td class="text-center">{{ statistic.placement_group_data_name }}</td>
                    <td class="text-center" v-for="metric in metrics" :key="metric"> {{ signs[metric] }}{{ statistic[metric] | withComma }}  </td>
                </tr>
            </tbody>
            <tfoot v-if="statistics.length > 0">
                <tr>
                    <td class="text-center">Total</td>
                    <td class="text-center"></td>
                    <td class="text-center"></td>
                    <td class="w-40 text-center" v-for="metric in metrics" :key="metric"> 
                        <span v-if="statisticsSum[metric] >= 0">{{ signs[metric] }}</span>{{ statisticsSum[metric] | withComma}}
                    </td>
                </tr>
            </tfoot>
        </table>
        </div>
        <!-- /.card-body -->
        <div class="card-footer">
            <ul class="pagination pagination-sm m-0 float-right">
                <li class="page-item">
                    <a class="page-link" href="#" @click.prevent="previousPage">Previous</a>
                </li>
                <li class="page-item">
                    <a class="page-link">{{ currentPage }} / {{ pageCount }}</a>
                </li>
                <li class="page-item">
                    <a class="page-link" href="#" @click.prevent="nextPage">Next</a>
                </li>
            </ul>
        </div>
    </div>
    <!-- /.card -->

</div>
</template>

<script>

import { RepositoryFactory } from '@/services/repositories/ManagementAPI/RepositoryFactory'
const SystemRepo = RepositoryFactory.get('system')
const Statistics = RepositoryFactory.get('statistics')
const GroupsStatistics = RepositoryFactory.get('groups_statistics')
const UserGroupsStatistics = RepositoryFactory.get('user_groups_statistics')
const WebsitesStatistics = RepositoryFactory.get('websites_statistics')

export default {
    props: {
        type: {
            type: String,
            required: true,
        },
        search: {
            type: Number
        },
        website: {
            type: Number | String,
        },
        group: {
            type: Number | String,
        },
        data: {
            type: Number | String,
        },
        start_date: {
            type: String,
            required: false
        },
        end_date: {
            type: String,
            required: false
        },
        complete: {
            type: Boolean | String,
            required: false
        },
        order: {
            type: String,
            required: false,
            default: "-date"
        },
        limit: {
            type: Number,
            required: false,
            default: 30
        },
        name: {
            type: String,
            default: ""
        },
        query: {
            type: Object
        },
        full_data: {
            type: Object
        },
    },
    data() {
        return {
            metrics: [],
            signs: {},
            
            statistics: [],
            statisticsSum: {},
            statistic: {},
            showStatisticsModel: false,

            currentPage: 1,
            totalRecords: 0,
            resultsLimit: 30,
            isLoading: false
        }
    },
    created() {
        this.loadSystemMetrics()
    },
    updated() {
        $('[data-toggle="tooltip"]').tooltip()
    },
    methods: {
        loadSystemMetrics: function() {
            SystemRepo.metrics().then(result => {
                this.metrics = result.data.schema
                this.signs = result.data.signs
            }).catch(err => {

            })
        },
        loadStatistics: function() {
            this.statistics = []
            if(this.type === 'statistics') {
                this.isLoading = true
                Statistics.pageWithPGDList(this.full_data.selected_data.join(','), this.currentPage, this.limit, 
                                this.start_date, this.end_date, this.complete, this.order).then(result => {
                    
                    this.totalRecords = result.data.count
                    //this.statistics = result.data.results
                    this.DataJoining(result.data.results)
                    this.statisticsSum = result.data.statistics_sum


                    this.isLoading = false
                }).catch(err => {
                    this.isLoading = false
                })
            }
            if(this.type === 'groups') {
                this.isLoading = true
                GroupsStatistics.page(this.group, this.currentPage, this.limit, 
                                      this.start_date, this.end_date, this.order).then(result => {
                    
                    this.totalRecords = result.data.count
                    this.statistics = result.data.results
                    this.statisticsSum = result.data.statistics_sum

                    this.isLoading = false
                }).catch(err => {
                    this.isLoading = false
                })
            }
            if(this.type === 'user_groups') {
                this.isLoading = true
                UserGroupsStatistics.page(this.group, this.currentPage, this.limit, 
                                          this.start_date, this.end_date, this.order).then(result => {
                    
                    this.totalRecords = result.data.count
                    this.statistics = result.data.results
                    this.statisticsSum = result.data.statistics_sum

                    this.isLoading = false
                }).catch(err => {
                    this.isLoading = false
                })
            }
            if(this.type === 'website') {
                this.isLoading = true
                WebsitesStatistics.page(this.website, this.currentPage, this.limit,
                                        this.start_date, this.end_date, this.order).then(result => {
                    
                    this.totalRecords = result.data.count
                    this.statistics = result.data.results
                    this.statisticsSum = result.data.statistics_sum

                    this.isLoading = false
                }).catch(err => {
                    this.isLoading = false
                })
            }
        },
        loadStatistic(id) {
            Statistics.get(id).then(result => {
                this.statistic = result.data
                this.showStatisticsModel = true
            }).catch(err => {})
        },
        DataJoining(statistics) {
            let PGDataMap = {}
            this.full_data.data.forEach(data => {
                PGDataMap[data.id] = data
            })

            let SuppliersMap = {}
            this.full_data.suppliers.forEach(supplier => {
                SuppliersMap[supplier.id] = supplier
            })

            let StatisticsMap = []
            statistics.forEach(statistic => { 
                try {
                    statistic.placement_group_data = PGDataMap[statistic.placement_group_data_id]
                    statistic.placement_group_data_name = PGDataMap[statistic.placement_group_data_id].name

                    statistic.supplier_id = statistic.placement_group_data.supplier_id
                    statistic.supplier_name = SuppliersMap[statistic.supplier_id].name
                    
                    StatisticsMap.push(statistic)
                } catch(err) {
                    console.log(err)
                }
             })

            try {
                let res = this.alasql('SELECT *, \
                 SUM(impressions::NUMBER) as impressions, SUM(revenue::NUMBER) as revenue, SUM(clicks::NUMBER) as clicks \
                 FROM ? GROUP BY supplier_id ORDER BY supplier_name ASC', [StatisticsMap])
                 this.statistics = res
                console.log(res)

            } catch(err) {
                console.log(err)
            }
        },
        Supplier() {
            let supplier = this.suppliers.filter(element => { return element.id == id })[0]
            if(supplier !== undefined)
                return supplier
        },
        StatisticsModelClosed() {
            this.showStatisticsModel = false
            this.statistic = {}
        },
        nextPage: function() {
            if(this.currentPage != this.pageCount || this.currentPage > this.pageCount) {
                this.currentPage = this.currentPage + 1
                this.loadStatistics()
            }
        },
        previousPage: function() {
            if(this.currentPage != 1 ) {
                this.currentPage = this.currentPage - 1
                this.loadStatistics()
            }
        },
    },
    computed: {
        pageCount: function() {
            return Math.ceil(this.totalRecords / this.limit)
        },
    },
    watch: {
        'type': function(type) {
            if(type) {
                this.loadStatistics()
            }
        },
        'search': function(search) {
            if(search) {
                this.statistics = []
                this.loadStatistics()
            }
        }
    }
}
</script>

<style scoped>
.x_large_icon {
    font-size: x-large;
}
</style>