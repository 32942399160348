<template>
    <div class="login-box">
        <div class="register-box">
            <div class="card">

            <div class="card-body register-card-body" v-if="!verified">
                <div class="alert alert-danger"> This url is not valid or has expired! </div>
                <div class="dropdown-divider"></div>
                    <router-link :to="{name: 'login'}" class="text-center">Return to login</router-link>
                </div>
            </div>

            <div class="card-body register-card-body" v-if="verified">
            <p class="login-box-msg">Set your new password</p>

            <form action="" method="post">
                <div class="input-group mb-3">
                    <input type="password" v-model="user.password" class="form-control" placeholder="Password">
                    <div class="input-group-append">
                        <div class="input-group-text">
                        <span class="fas fa-lock"></span>
                        </div>
                    </div>
                </div>

                <div class="input-group mb-3">
                    <input type="password" v-model="user.confirmed_password" class="form-control" placeholder="Confirm Password">
                    <div class="input-group-append">
                        <div class="input-group-text">
                        <span class="fas fa-lock"></span>
                        </div>
                    </div>
                </div>

                <errors :errors="errors"/>
                <messages :messages="messages" />

                <div class="row">
                    <!-- /.col -->
                    <div class="col-12">
                        <button @click.prevent="reset()" class="btn btn-success btn-block btn-flat">Reset</button>
                    </div>
                    <!-- /.col -->
                </div>
            </form>
            <div class="dropdown-divider"></div>
            <router-link :to="{name: 'login'}" class="text-center">Return to login</router-link>
            </div>
            <!-- /.form-box -->
        </div><!-- /.card -->
</div>
</template>
<script>

export default {
    data() {
        return {
            errors: [],
            messages: [],
            verified: false,
            user: {
                key: null,
                password: null,
                confirmed_password: null,
            }
        }
    }, 
    created() {
        this.verifiedKey()
    },
    methods: {
        verifiedKey: function() {
            this.user.key = this.$route.params.key
            this.$store.dispatch('VERIFY', this.user).then(result => {
                this.verified = true
            })
            .catch(err => {
                this.verified = false
            })
        },

        reset: function() {
            this.errors = []
            this.messages = []

            this.$store.dispatch('RESET', this.user).then(result => {
                this.messages.push(result.data.message)
                setTimeout(function() {
                    this.$router.push({name: 'login'})
                }, 3000)
            }).catch(err => {
                this.errors.push(err.response.data.message)
            })
        }
    },
    computed: {

    }
}
</script>
<style>
    body {
        background-color: #e4e4e4;
    }
      .login-box {
    margin: 10% auto;
  }

</style>