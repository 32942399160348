<template>
    <div class="mt-16">
        <NewPlayList :playList="newPlayList" :displayForm="displayForm" v-if="newPlayList.videos.length > 0" @save-playlist="$emit('save-playlist', $event)" @cancel-playlist="$emit('cancel-playlist')" v-animate-css="'slideInUp'" />
        <div class="" v-if="playLists.length > 0">
            <!-- <div class="table-responsive">
                <table class="table caption-top">
                    <thead>
                        <tr>
                        <th scope="col"></th>
                        <th scope="col">PLAYLIST</th>
                        <th scope="col">PUBLISH DATE</th>
                        <th scope="col">GET EMBED</th>
                        </tr>
                    </thead>
                    <tbody  v-for="playlist in playLists" :key="playlist.id">
                        <tr >
                            <th scope="row"></th>
                            <td>
                                <div class="d-flex flex-row">
                                    <div class="w-56 h-32 rounded-lg shadow-md mr-4">
                                        <img :src="playlist.posters.pic1" class="w-1/2 h-1/2 d-inline-block" alt="">
                                        <img :src="playlist.posters.pic2" class="w-1/2 h-1/2 d-inline-block" alt="">
                                        <img :src="playlist.posters.pic3" class="w-1/2 h-1/2 d-inline-block" alt="">
                                        <img :src="playlist.posters.pic4" class="w-1/2 h-1/2 d-inline-block" alt="">
                                    </div>
                                  <h3>{{playlist.name}}</h3>  
                                </div>
                            </td>
                            <td>{{playlist.createdAt}}</td>
                            <td class=" text-primary font-weight-bold"><i class="fas cursor-pointer fa-code"> embed</i> </td>
                            <td class=" text-primary font-weight-bold" @click="$emit('delete-playlist' , playlist)" ><i class="fa cursor-pointer fa-eraser"> delete</i> </td>
                        </tr>
                    </tbody>
                </table>
            </div> -->
            <div class="row">
                <div :class="{'col-md-4': displayForm , 'col-md-2': !displayForm}" v-for="playlist in playLists" :key="playlist.id">
                    <div class="card cursor-pointer border-bottom"  >
                        <!--card body-->
                        <div class="flex justify-content-center align-items-center h-56 m-auto px-4 overflow-hidden">
                            <img :src="playlist.posters.pic1" class="rounded-md shadow-md">
                            <img :src="playlist.posters.pic2" class="rounded-md shadow-md">
                            <img :src="playlist.posters.pic3" class="rounded-md shadow-md">
                            <img :src="playlist.posters.pic4" class="rounded-md shadow-md">
                        </div>
                        <div class="m-auto text-dark text-md font-weight-normal ">{{playlist.name}}</div>
                        <div class="m-auto text-muted text-sm pb-2">{{playlist.createdAt}}</div>
                        <!--card body-->
                    </div>
                </div>
            </div>            
            <div class="overlay" v-if="!isLoaded">
                <point-spinner />
            </div>
        </div>
        <div class="card d-flex justify-content-center align-items-center mt-3 h-screen" v-else>
            <i class="fas fa-hand-point-left text-xl"></i>
            <h1 class="display-5 text-lg font-weight-bold mt-12">there is no playlist please create one</h1>
            <div  class="mt-12">
                <button   class="btn btn-primary" id="backtovideos" @click="$emit('back-to-videos')" >Go Back To Videos</button>
            </div>
        </div>         
        <ul class="pagination pagination-sm m-0 float-right">
          <li class="page-item">
            <a class="page-link" href="#" @click.prevent="previousPage">Previous</a>
          </li>
          <li class="page-item">
            <a class="page-link">{{ currentPage }} / {{ pageCount }}</a>
          </li>
          <li class="page-item">
            <a class="page-link" href="#" @click.prevent="nextPage">Next</a>
          </li>
        </ul>                   
    </div>
</template>
<script>
import NewPlayList from './NewPlayList.vue'
export default {
    components: {
        NewPlayList
    },
    props: {
        displayForm: {
            type: Boolean,
            required: true
        },
        playLists: {
            type: Array,
            required: true
        },
        newPlayList: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            isLoaded: true,
            currentPage: 1,
            totalRecords: 0,
            resultsLimit: 10,            
        }
    },
    methods: {
        nextPage: function() {
            if(this.currentPage != this.pageCount || this.currentPage > this.pageCount) {
                this.currentPage = this.currentPage + 1
                //this.loadPlaylists()
            }
        },
        previousPage: function() {
            if(this.currentPage != 1 ) {
                this.currentPage = this.currentPage - 1
                //this.loadPlaylists()
            }
        },        
    },
    computed: {
        pageCount: function() {
            return Math.ceil(this.totalRecords / this.resultsLimit)
        },
    }
    
}
</script>