<template>
<div>
</div>
</template>

<script>
import { RepositoryFactory } from '@/services/repositories/AuthAPI/RepositoryFactory';
const UserRepo = RepositoryFactory.get('user');
const ProfileRepo = RepositoryFactory.get('profile');

export default {
    name: 'ProfileInfo',
    props: {
        user: {
            type: Object,
            default: () => ({})
        },
        profile: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            errors: [],
            messages: [],
        }
    },
    methods: {
        saveProfileInfo: function() {
            this.errors = []
            this.messages = []
            ProfileRepo.update(this.user.id, this.profile).then(result => {
                UserRepo.update(this.user.id, {
                    first_name: this.user.first_name,
                    last_name: this.user.last_name
                })
                this.$notify({
                    group: 'ops',
                    type: 'success',
                    title: 'Operation success',
                    text: 'Successfuly saved.'
                })
            }).catch(err => {
                this.$notify({
                    group: 'ops',
                    type: 'error',
                    title: 'Operation failed',
                    text: 'Failed to save, please try agian.'
                })
            })
        },
    }
}
</script>