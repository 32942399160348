<template>
    <div class="card">
        <div class="card-header">
            <h3 class="card-title">API Keys List</h3>

            <div class="card-tools">
                <div class="input-group input-group-sm" style="width: 150px;">
                    <input type="text" name="table_search" class="form-control float-right" placeholder="Search">
                    <div class="input-group-append">
                        <button type="submit" class="btn btn-default"><i class="fas fa-search"></i></button>
                    </div>
                </div>
            </div>
        </div>
        <!-- /.card-header -->
        <div class="card-body p-0">
            
        <table class="table table-hover">
            <thead>
            <tr>
                <th>Name</th>
                <th>User</th>
                <th>Status</th>
                <th class="text-center">Action</th>
            </tr>
            </thead>
            <tbody>
                <tr v-for="api_key in api_keys" :key="api_key.id" v-animate-css="'slideInLeft'">
                    <td>{{ api_key.name }}</td>
                    <td>{{ User(api_key.user) }}</td>
                    <td>
                    <span class="alert alert-success btn-xs" :class="{
                        'alert-success': api_key.status == 'active',
                        'alert-warning': api_key.status == 'pending',
                        'alert-danger': api_key.status == 'suspended'}"> {{ api_key.status | capitalize }} 
                    </span>
                    </td>
                    <td class="text-center">
                    <div class="btn-group">
                        <router-link :to="{name: 'api_keys', params: {'id': api_key.id} }" class="btn btn-default btn-sm">
                        <i class="fa fa-edit"></i> Edit</router-link>
                    </div>
                    </td>
                </tr>
            </tbody>
        </table>

        </div>

        <div class="overlay" v-if="!isLoaded">
        <i class="fas fa-2x fa-sync-alt fa-spin"></i>
        </div>

        <!-- <div class="overlay dark" v-if="hasError">
        <i class="fas fa-2x fa-exclamation-triangle"></i>
        </div> -->
    </div>
</template>

<script>
export default {
    props: {
        api_keys: {
          required: true,
          type: Array
        },
        users: {
          required: true,
          type: Array
        },
        isLoaded: {
          required: false,
          type: Boolean
        }
    },
    components: {},
    data() {
        return {
            api_key: {},
        }
    },
    methods: {
        User(user_id) {
            var user = this.users.filter(element => { return element.id == user_id })[0]
            if(user)
                return user.first_name
        }
    }
}
</script>

<style scoped>

</style>