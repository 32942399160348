import Repository from './Repository';

const resource = "/ads";

export default {
    supplier(id) {
        return Repository.get(`${resource}/ads/?supplier=${id}&limit=1000`);
    },
    text() {
        return Repository.get(`${resource}/txt/`);
    },
    get(id) {
        return Repository.get(`${resource}/ads/${id}/`);
    },
    post(data) {
        return Repository.post(`${resource}/ads/`, data);
    },
    update(id, data) {
        return Repository.put(`${resource}/ads/${id}/`, data);
    },
    patch(id, data) {
        return Repository.patch(`${resource}/ads/${id}/`, data);
    },
    delete(id) {
        return Repository.delete(`${resource}/ads/${id}/`);
    },
}