<template>
<div>
    <div class="card" v-animate-css="'slideInDown'">
        <div class="card-header">
        <h3 class="card-title">
            {{ name }} Results 
        </h3>
        <div class="card-tools">
            <button class="btn btn-tool" @click.prevent="loadStatistics()">
                <i :class="{'fa-spin': isLoading}" class="fas fa-sync" aria-hidden="true"></i>
            </button>
            <button type="button" class="btn btn-tool" data-card-widget="maximize"><i class="fas fa-expand"></i></button>
            <button type="button" class="btn btn-tool">
                <div class="dropdown ">
                    <button class="btn btn-tool" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fas fa-ellipsis-v"></i>
                    </button>
                    <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                        <template v-if="type == 'statistics'">
                            <li class="dropdown-item">
                                <span @click="shares.supplier_share = !shares.supplier_share">
                                    <input type="checkbox" :checked="shares.supplier_share" /> Supplier Share
                                </span>
                            </li>
                        </template>
                        <template v-else>
                                <li class="dropdown-item">
                                    <span @click="shares.supplier_share = !shares.supplier_share">
                                        <input type="checkbox" :checked="shares.supplier_share" /> Supplier Share
                                    </span>
                                </li>
                                <li class="dropdown-item">
                                    <span @click="shares.pmp_share = !shares.pmp_share">
                                        <input type="checkbox" :checked="shares.pmp_share" /> PMP Share
                                    </span>
                                </li>
                                <li class="dropdown-item">
                                    <span @click="shares.adcrew_share = !shares.adcrew_share">
                                        <input type="checkbox" :checked="shares.adcrew_share" /> AdCrew Share
                                    </span>
                                </li>
                                <li class="dropdown-item">
                                    <span @click="shares.network_share = !shares.network_share">
                                        <input type="checkbox" :checked="shares.network_share" /> Network Share
                                    </span>
                                </li>
                        </template>
                    </ul>

                </div>
            </button>
        </div>
        </div>
        <!-- /.card-header -->
        <div class="card-body table-responsive p-0">
        <table class="table table-hover">
            <thead>
            <tr>
                <th class="text-center">Date</th>
                <template v-for="metric in metrics">
                    <template v-if="metric == 'revenue'">
                        <template v-if="shares.supplier_share">
                            <th class="text-center">Supplier Revenue Before</th>
                            <th class="text-center">Supplier Share</th>
                            <th class="text-center">Supplier Revenue After</th>
                        </template>
                        
                        <template v-if="shares.pmp_share">
                            <th class="text-center">PMP Revenue Before</th>
                            <th class="text-center">PMP Share</th>
                            <th class="text-center">PMP Revenue After</th>
                        </template>

                        <template v-if="shares.adcrew_share">
                            <th class="text-center">AdCrew Revenue Before</th>
                            <th class="text-center">AdCrew Share</th>
                            <th class="text-center">AdCrew Revenue After</th>
                        </template>

                        <template v-if="shares.network_share">
                            <th class="text-center">Network Revenue Before</th>
                            <th class="text-center">Network Share</th>
                            <th class="text-center">Network Revenue After</th>
                        </template>
                        <template>
                            <th class="text-center"> {{ metric | capitalizeAndReplace }} </th>
                        </template>
                    
                    </template>
                    <template v-else>
                        <th class="text-center"> {{ metric | capitalizeAndReplace }} </th>
                    </template>
                </template>
                
                <th class="text-center">Status</th>
                <th class="text-center">Complete</th>
                <th class="text-center">Updated at</th>
                <th class="text-center" v-if="type=='statistics'"></th>
            </tr>
            </thead>
            <tbody>
                <tr v-if="statistics.length <= 0">
                    <td :colspan="metrics.length+5" class="text-center">
                        <span v-if="!isLoading">No statistics available</span>
                        <span v-if="isLoading"><i class="fa fa-spinner fa-3x fa-pulse"></i></span>
                    </td>
                </tr>
                <tr v-for="statistic in statistics" :key="statistic.id"  v-animate-css="'slideInLeft'">
                    <td class="text-center">{{ statistic.date }}</td>
                    <template v-for="metric in metrics">
                        <template v-if="metric == 'revenue'">
                            <template v-if="shares.supplier_share">
                                <td class="text-center">
                                    <span v-if="statistic.supplier_share">
                                        ${{ statistic.supplier_share.revenue_before }}
                                    </span>
                                    <span v-else>$0</span>
                                </td>
                                <td class="text-center">
                                    <span v-if="statistic.supplier_share">
                                        %{{ statistic.supplier_share.share }}
                                    </span>
                                    <span v-else>%0</span>
                                </td>
                                <td class="text-center">
                                    <span v-if="statistic.supplier_share">
                                        ${{ statistic.supplier_share.revenue_after }}
                                    </span>
                                    <span v-else>$0</span>
                                </td>
                            </template>
                            <template v-if="shares.pmp_share">
                                <td class="text-center">
                                    <span v-if="statistic.pmp_share">
                                        ${{ statistic.pmp_share.revenue_before }}
                                    </span>
                                    <span v-else>$0</span>
                                </td>
                                <td class="text-center">
                                    <span v-if="statistic.pmp_share">
                                        %{{ statistic.pmp_share.share }}
                                    </span>
                                    <span v-else>%0</span>
                                </td>
                                <td class="text-center">
                                    <span v-if="statistic.pmp_share">
                                        ${{ statistic.pmp_share.revenue_after }}
                                    </span>
                                    <span v-else>$0</span>
                                </td>
                            </template>
                            <template v-if="shares.adcrew_share">
                                <td class="text-center">
                                    <span v-if="statistic.adcrew_share">
                                        ${{ statistic.adcrew_share.revenue_before }}
                                    </span>
                                    <span v-else>$0</span>
                                </td>
                                <td class="text-center">
                                    <span v-if="statistic.adcrew_share">
                                        %{{ statistic.adcrew_share.share }}
                                    </span>
                                    <span v-else>%0</span>
                                </td>
                                <td class="text-center">
                                    <span v-if="statistic.adcrew_share">
                                        ${{ statistic.adcrew_share.revenue_after }}
                                    </span>
                                    <span v-else>$0</span>
                                </td>
                            </template>
                            <template v-if="shares.network_share">
                                <td class="text-center">
                                    <span v-if="statistic.network_share">
                                        ${{ statistic.network_share.revenue_before }}
                                    </span>
                                    <span v-else>$0</span>
                                </td>
                                <td class="text-center">
                                    <span v-if="statistic.network_share">
                                        %{{ statistic.network_share.share }}
                                    </span>
                                    <span v-else>%0</span>
                                </td>
                                <td class="text-center">
                                    <span v-if="statistic.network_share">
                                        ${{ statistic.network_share.revenue_after }}
                                    </span>
                                    <span v-else>$0</span>
                                </td>
                            </template>
                            <td class="text-center">
                                {{ signs[metric] }}{{ statistic[metric] | withComma }}
                            </td>
                        </template>
                        <template v-else>
                            <td class="text-center">
                                {{ signs[metric] }}{{ statistic[metric] | withComma }}
                            </td>
                        </template>
                    </template>
                    <td class="text-center">
                        <strong :class="statistic.status === 'active'? 'text-success' : 'text-danger'">
                            {{ statistic.status | capitalize }}
                        </strong>
                    </td>
                    <td class="text-center">
                        <i class="fa x_large_icon" :class="{'fa-check-circle text-success': statistic.complete, 'fa-info-circle text-warning': !statistic.complete}"
                            data-toggle="tooltip" data-placement="top" :title="statistic.complete? 'Completed' : 'Unfinished' ">
                        </i>
                    </td>
                    <td class="text-center">{{ statistic.modified_at | formatDate }}</td>
                    <td class="text-right" v-if="type=='statistics'">
                        <button class="btn btn-tool" @click.prevent="requestData(statistic)"><i class="fa fa-sync"></i></button>
                        <button class="btn btn-tool" @click.prevent="loadStatistic(statistic.id)"><i class="fa fa-edit"></i></button>
                    </td>
                    <td class="text-right" v-else-if="type=='groups'">
                        <button class="btn btn-tool" @click.prevent="requestPlacement(statistic)"><i class="fa fa-sync"></i></button>
                    </td>
                    <td class="text-right" v-else-if="type=='website'">
                        <button class="btn btn-tool" @click.prevent="requestWebsite(statistic)"><i class="fa fa-sync"></i></button>
                    </td>
                </tr>
            </tbody>
            <tfoot v-if="statistics.length > 0">
                <tr>
                    <td class="text-center">Total</td>
                    <td class="w-40 text-center" v-for="metric in metrics" :key="metric"> 
                        <span v-if="statisticsSum[metric] >= 0">{{ signs[metric] }}</span>{{ statisticsSum[metric] | withComma}}
                    </td>

                    <template v-if="shares.supplier_share">
                        <td></td>
                        <td></td>
                        <td></td>
                    </template>

                    <template v-if="shares.pmp_share">
                        <td></td>
                        <td></td>
                        <td></td>
                    </template>

                    <template v-if="shares.adcrew_share">
                        <td></td>
                        <td></td>
                        <td></td>
                    </template>

                    <template v-if="shares.network_share">
                        <td></td>
                        <td></td>
                        <td></td>
                    </template>

                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
            </tfoot>
        </table>
        </div>
        <!-- /.card-body -->
        <div class="card-footer">
            <ul class="pagination pagination-sm m-0 float-right">
                <li class="page-item">
                    <a class="page-link" href="#" @click.prevent="previousPage">Previous</a>
                </li>
                <li class="page-item">
                    <a class="page-link">{{ currentPage }} / {{ pageCount }}</a>
                </li>
                <li class="page-item">
                    <a class="page-link" href="#" @click.prevent="nextPage">Next</a>
                </li>
            </ul>
        </div>
    </div>
    <!-- /.card -->

    <StatisticsModel
        v-if="statistic && data"
        :statistic="statistic"
        :data_id="data"
        :show="showStatisticsModel" 
        @close="StatisticsModelClosed" 
    />

</div>
</template>

<script>
import StatisticsModel from '@/components/statistics/data/Model'

import { RepositoryFactory } from '@/services/repositories/ManagementAPI/RepositoryFactory'
const SystemRepo = RepositoryFactory.get('system')
const Statistics = RepositoryFactory.get('statistics')
const GroupsStatistics = RepositoryFactory.get('groups_statistics')
const UserGroupsStatistics = RepositoryFactory.get('user_groups_statistics')
const WebsitesStatistics = RepositoryFactory.get('websites_statistics')
const OperationsRepo = RepositoryFactory.get('operations')

export default {
    props: {
        type: {
            type: String,
            required: true,
        },
        search: {
            type: Number
        },
        website: {
            type: Number | String,
        },
        group: {
            type: Number | String,
        },
        data: {
            type: Number | String,
        },
        start_date: {
            type: String,
            required: false
        },
        end_date: {
            type: String,
            required: false
        },
        complete: {
            type: Boolean | String,
            required: false
        },
        order: {
            type: String,
            required: false,
            default: "-date"
        },
        limit: {
            type: Number,
            required: false,
            default: 30
        },
        name: {
            type: String,
            default: ""
        },
        query: {
            type: Object
        }
    },
    components: {
        StatisticsModel
    },
    data() {
        return {
            metrics: [],
            signs: {},
            
            statistics: [],
            statisticsSum: {},
            statistic: {},
            showStatisticsModel: false,

            currentPage: 1,
            totalRecords: 0,
            resultsLimit: 30,
            isLoading: false,
            shares: {
                supplier_share: false,
                pmp_share: false,
                adcrew_share: false,
                network_share: false
            }
        }
    },
    created() {
        this.loadSystemMetrics()
    },
    updated() {
        $('[data-toggle="tooltip"]').tooltip()
    },
    methods: {
        loadSystemMetrics: function() {
            SystemRepo.metrics().then(result => {
                this.metrics = result.data.display
                this.signs = result.data.signs
            }).catch(err => {

            })
        },
        loadStatistics: function() {
            this.statistics = []
            if(this.type === 'statistics') {
                this.isLoading = true
                Statistics.page(this.data, this.currentPage, this.limit, 
                                this.start_date, this.end_date, this.complete, this.order).then(result => {
                    
                    this.totalRecords = result.data.count
                    this.statistics = result.data.results
                    this.statisticsSum = result.data.statistics_sum

                    this.isLoading = false
                }).catch(err => {
                    this.isLoading = false
                })
            }
            if(this.type === 'groups') {
                this.isLoading = true
                GroupsStatistics.page(this.group, this.currentPage, this.limit, 
                                      this.start_date, this.end_date, this.order).then(result => {
                    
                    this.totalRecords = result.data.count
                    this.statistics = result.data.results
                    this.statisticsSum = result.data.statistics_sum

                    this.isLoading = false
                }).catch(err => {
                    this.isLoading = false
                })
            }
            if(this.type === 'user_groups') {
                this.isLoading = true
                UserGroupsStatistics.page(this.group, this.currentPage, this.limit, 
                                          this.start_date, this.end_date, this.order).then(result => {
                    
                    this.totalRecords = result.data.count
                    this.statistics = result.data.results
                    this.statisticsSum = result.data.statistics_sum

                    this.isLoading = false
                }).catch(err => {
                    this.isLoading = false
                })
            }
            if(this.type === 'website') {
                this.isLoading = true
                WebsitesStatistics.page(this.website, this.currentPage, this.limit,
                                        this.start_date, this.end_date, this.order).then(result => {
                    
                    this.totalRecords = result.data.count
                    this.statistics = result.data.results
                    this.statisticsSum = result.data.statistics_sum

                    this.isLoading = false
                }).catch(err => {
                    this.isLoading = false
                })
            }
        },
        loadStatistic(id) {
            Statistics.get(id).then(result => {
                this.statistic = result.data
                this.showStatisticsModel = true
            }).catch(err => {})
        },
        requestData(statistic) {
            var data = {
                'id': statistic.placement_group_data_id,
                'date': statistic.date
            }
            OperationsRepo.request_data(data).then(result => {
                this.$notify({
                    group: 'ops',
                    type: 'success',
                    title: 'Operation success',
                    text: 'Successfully created, Track token: ' + result.data.token
                })
            }).catch(err => {
                this.$notify({
                    group: 'ops',
                    type: 'error',
                    title: 'Operation failed',
                    text: 'Faild to request data please try again.'
                })
            })
        },
        requestPlacement(statistic) {
            var data = {
                'id': statistic.placement_group_id,
                'date': statistic.date
            }
            OperationsRepo.request_placement(data).then(result => {
                this.$notify({
                    group: 'ops',
                    type: 'success',
                    title: 'Operation success',
                    text: 'Successfully created, Track token: ' + result.data.token
                })
            }).catch(err => {
                this.$notify({
                    group: 'ops',
                    type: 'error',
                    title: 'Operation failed',
                    text: 'Faild to request Placemnt please try again.'
                })
            })
        },
        requestWebsite(statistic) {
            var data = {
                'id': statistic.website_id,
                'date': statistic.date
            }
            OperationsRepo.request_website(data).then(result => {
                this.$notify({
                    group: 'ops',
                    type: 'success',
                    title: 'Operation success',
                    text: 'Successfully created, Track token: ' + result.data.token
                })
            }).catch(err => {
                this.$notify({
                    group: 'ops',
                    type: 'error',
                    title: 'Operation failed',
                    text: 'Faild to request website please try again.'
                })
            })
        },
        StatisticsModelClosed() {
            this.showStatisticsModel = false
            this.statistic = {}
        },
        nextPage: function() {
            if(this.currentPage != this.pageCount || this.currentPage > this.pageCount) {
                this.currentPage = this.currentPage + 1
                this.loadStatistics()
            }
        },
        previousPage: function() {
            if(this.currentPage != 1 ) {
                this.currentPage = this.currentPage - 1
                this.loadStatistics()
            }
        },
    },
    computed: {
        pageCount: function() {
            return Math.ceil(this.totalRecords / this.limit)
        },
    },
    watch: {
        'type': function(type) {
            if(type) {
                this.loadStatistics()
            }
        },
        'search': function(search) {
            if(search) {
                this.statistics = []
                this.loadStatistics()
            }
        }
    }
}
</script>

<style scoped>
.x_large_icon {
    font-size: x-large;
}
</style>