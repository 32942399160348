<template>
    <!-- Content selection -->
<div class="border mb-2 rounded-lg font-weight-bold text-dark over-flow-visible" v-if="player">
    <nav class="m-2">
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
            <li class="nav-item has-treeview menu-close">
                <a class="nav-link flex align-items-center" style="cursor: pointer;">
                    <i class="nav-icon fas fa-film"></i>
                    <div class=""> Player Preview </div><i class="fas fa-angle-left ml-auto"></i> 
                </a>
                <ul class="m-3 nav nav-treeview">
                    <li class="nav-item py-4 font-weight-normal">
                        <div class="d-flex justify-content-start align-items-center">
                            <div class="col-md-11">
                                Reload player
                            </div>
                            <div class="col-md-1">
                                <div class="card-tools float-right">
                                    <button class="btn btn-tool btn-sm" @click="reloadPlayer()">
                                        <i class="fas fa-sync"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <hr /> 
                    </li>
                    <li :style="{'height': player.settings.desktop.height + 'px'}">
                        <div :id="'player-id-'+player.id"></div>
                        <script type="application/javascript" src="https://player.adcrew.co/player.js"></script>
                    </li>
                </ul>
            </li>
        </ul>
    </nav>
</div>
<!-- Content selection -->
</template>
<script>

export default {
components: {
},
props: {
    player: {
        type: Object,
    }
},
data() {
    return {
    }
},
created() {
},
methods: {
    reloadPlayer: function() {
        window.adp(this.player.id, {
            "container_id": 'player-id-' + this.player.id,
            "no_cache": true
        })
    },
},
watch: {
    "player": function() {

    }
}
}
</script>