<template>
    <div class="card" v-animate-css="'fadeInLeft'">
        <div class="card-header">
            <h3 class="card-title">
                <span v-if="!placement_group.id"> Add </span>
                <span v-if="placement_group.id"> Edit </span>
                Placement Group
            </h3>
            <div class="card-tools">
                <div class="overlay" v-if="isLoading">
                    <i class="fas fa-sync-alt fa-spin fa-1x"></i>
                </div>

                <button class="btn btn-tool btn-sm" v-if="!isLoading" @click.prevent="clear()">
                    <i class="fa fa-plus"></i>
                </button>
            </div>
            <!-- /.card-tools -->
        </div>
        <!-- /.card-header -->
        <div class="card-body">
            <form role="form">
                <div class="row">
                    <div class="col-md-7">
                        <!-- text input -->
                        <div class="form-group">
                        <label>Name</label>
                        <input type="text" class="form-control" v-model="placement_group.name">
                        </div>
                    </div>
                    <div class="col-md-5">
                        <div class="form-group">
                        <label>Group</label>
                            <select class="form-control" v-model="placement_group.group_id">
                                <option v-for="group in groups" :key="group.id" :value="group.id"
                                :selected="placement_group.group_id"> {{ group.name }} </option>
                            </select>
                        </div>
                    </div>
            </div>
            <div class="row">
                <div class="col-md-8">
                    <div class="form-group">
                        <div class="btn-group btn-group-toggle">
                            <label class="btn btn-sm btn-default"  :class="{'text-green': placement_group.status === 'active'}">
                                <input type="radio" value="active" v-model="placement_group.status"> Active
                            </label>
                            <label class="btn btn-sm btn-default" :class="{'text-red': placement_group.status === 'suspended'}">
                                <input type="radio" value="suspended" v-model="placement_group.status"> Suspended
                            </label>
                            </div>
                        </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group float-right">
                        <button class="btn btn-success" @click.prevent="submit()"> Save </button>
                    </div>
                </div>

                <div class="col-md-12">
                    <errors :errors="errors"/>
                    <messages :messages="messages" />
                </div>
            </div>

            </form>
        </div>
    <!-- /.card-body -->
    </div>
    <!-- /.card -->

</template>

<script>
import { RepositoryFactory } from '@/services/repositories/ManagementAPI/RepositoryFactory' 
const PlacementsGroupsRepo = RepositoryFactory.get('groups')

export default {
    props: {
        placement_group: {
            required: true,
            type: Object
        },
        groups: {
            required: true,
            type: Array
        },
        website: {
            required: true,
            type: Number | String
        },
        isLoading: {
            required: false,
            type: Boolean
        },
    },
    data() {
        return {
            messages: [],
            errors: []
        }
    },
    methods: {
        submit: function() {
            if(typeof this.placement_group.id === 'undefined') {
                this.create(this.placement_group)
            } else {
                this.update(this.placement_group)
            }
        },
        clear: function() {
            this.errors = []
            this.messages = []

            this.placement_group = {
                website_id: this.website,
                group_id: this.groups[this.placement_group.id]
            }
        },
        create: function(placement_group) {
            this.errors = []
            this.messages = []

            PlacementsGroupsRepo.post(placement_group).then(result => {
                if(typeof result.data.id !== 'undefined' ) 
                    this.placement_group = result.data
                    this.$notify({
                        group: 'ops',
                        type: 'success',
                        title: 'Operation success',
                        text: 'Successfully added with ID: ' + result.data.id
                    })
                    this.$emit('updateList', result.data)
            }).catch(err => {
                this.$notify({
                    group: 'ops',
                    type: 'error',
                    title: 'Operation failed',
                    text: err.response.data
                })
                // this.errors.push(err)
                // this.errors.push( err.response.data )
            })
        },
        update: function(placement_group) {
            this.errors = []
            this.messages = []

            PlacementsGroupsRepo.patch(this.placement_group.id, placement_group).then(result => {
                if(typeof result.data.id !== 'undefined' ) 
                    this.placement_group = result.data
                    this.messages.push("Successfully updated.")
                        this.$notify({
                        group: 'ops',
                        type: 'success',
                        title: 'Operation success',
                        text: 'Successfully updated'
                    })
                    this.$emit('refreshList', true)
            }).catch(err => {
                this.$notify({
                    group: 'ops',
                    type: 'error',
                    title: 'Operation failed',
                    text: err.response.data
                })
                // this.errors.push(err)
                // this.errors.push( err.response.data )
            })
        },
        delete: function(id) {
        },
    },
}
</script>