<template>
<div class="container-fluid">
    <div class="row ">
        <div class="col-md-6">
            <WhiteLabelList :whitelists="whitelists" :networks="users" :isLoaded="isLoaded" />

            <ul class="pagination pagination-sm m-0 float-right">
                <li class="page-item">
                    <a class="page-link" href="#" @click.prevent="previousPage">Previous</a>
                </li>
                <li class="page-item">
                    <a class="page-link">{{ currentPage }} / {{ pageCount }}</a>
                </li>
                <li class="page-item">
                    <a class="page-link" href="#" @click.prevent="nextPage">Next</a>
                </li>
            </ul>
        </div>
        <div class="col-md-6">
            <WhiteLabelView v-if="whitelist" 
                :whitelist="whitelist" 
                :networks="users" 
                :isLoading="isLoading"
                @updateList="updateList"
                @refreshList="refreshList"
                @objectUpdated=objectUpdated
            />
        </div>
    </div>
</div>
</template>

<script>
import { isValidDomain } from '@/validators/domain'
import WhiteLabelList from '@/components/whitelists/List'
import WhiteLabelView from '@/components/whitelists/Domain'

import { RepositoryFactory } from '@/services/repositories/ManagementAPI/RepositoryFactory'
const UsersRepo = RepositoryFactory.get('users')
const WhiteListRepo = RepositoryFactory.get('whitelist')

export default {
    components: {
        WhiteLabelList,
        WhiteLabelView,
    },
    data() {
        return {
            whitelist: {
                domain: "",
                status: ''
            },
            whitelists: [],
            users: [],

            isLoaded: false,
            isLoading: false,

            currentPage: 1,
            totalRecords: 0,
            resultsLimit: 10,
        }
    },
    created() {
        this.loadWhiteLists()
        this.loadUsers()
    },
    methods: {
        loadUsers: function() {
            this.isLoaded = false
            UsersRepo.query({'role': 'network'}, 1, 1000).then(result => {
                this.users = result.data.results //.filter(user => { return user.profile.user_role === 'network' })
            }).catch(err => {
                this.isLoaded = true
            })
        },
        loadWhiteLists: function() {
            this.isLoaded = false
            WhiteListRepo.page(this.currentPage).then(result => {
                this.totalRecords = result.data.count
                this.whitelists = result.data.results
                this.isLoaded = true
            }).catch(err => {
                this.isLoaded = true
            })
        },
        loadWhitelist(id) {
            this.isLoading = true
            WhiteListRepo.get(id).then(result => {
                this.whitelist = result.data
                this.isLoading = false
            }).catch(err => {})
        },
        updateList: function(whitelist) {
            this.whitelists.push(whitelist)
            this.$router.replace({params: {'id': whitelist.id}})
        },
        refreshList: function(action) {
            if(action)
                this.loadWhiteLists()
        },
        objectUpdated: function(object) {
            this.whitelist = object
        },
        nextPage: function() {
            if(this.currentPage != this.pageCount || this.currentPage > this.pageCount) {
                this.currentPage = this.currentPage + 1
                this.loadWhiteLists()
            }
        },
        previousPage: function() {
            if(this.currentPage != 1 ) {
                this.currentPage = this.currentPage - 1
                this.loadWhiteLists()
            }
        }
    },
    computed: {
        pageCount: function() {
            return Math.ceil(this.totalRecords / this.resultsLimit)
        },
    },
    watch: {
        '$route.params.id': {
            handler: function (id) {
                if(typeof id !== 'undefined') {
                    // check if it exist under this list 
                    var element = this.whitelists.filter(element => { return element.id == id })[0]
                    if(element) {
                        this.whitelist = element
                    } else {
                        this.loadWhitelist(id)
                    }
                }
            },
            deep: true,
            immediate: true
        },
    }
}
</script>