import UserRepo from './UserRepo';
import UsersRepo from './UsersRepo';
import ProfileRepo from './ProfileRepo';
import BankRepo from './BankRepo';
import GroupsRepo from './GroupsRepo';
import PermissionsRepo from './PermissionsRepo';
import ExceptionsRepo from './ExceptionsRepo';
import ServicesRepo from './ServicesRepo';
import AssignedRepo from './AssignedRepo';

import AuthRepo from './AuthRepo';
import LogsRepo from './LogsRepo';

const repositories = {
    auth: AuthRepo,
    user: UserRepo,
    users: UsersRepo,

    profile: ProfileRepo,
    bank: BankRepo,

    groups: GroupsRepo,
    permissions: PermissionsRepo,
    exceptions: ExceptionsRepo,
    
    services: ServicesRepo,
    assignments: AssignedRepo,

    logs: LogsRepo
}

export const RepositoryFactory = {
    get: name => repositories[name],
};
export const AuthRepoFactory = {
    get: name => repositories[name],
};