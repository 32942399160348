import Repository from './Repository';

const resource = "/operations";
export default {
    request_data(data) {
        return Repository.post(`${resource}/request/data/`, data);
    },
    request_placement(data) {
        return Repository.post(`${resource}/request/placement/`, data);
    },
    request_website(data) {
        return Repository.post(`${resource}/request/website/`, data);
    }
}