/*this component input attribute is : array of object of items(multi select with opt-group)

 like the follow:
 {
            "id": 4009206018,
            "service": 1181941559,
            "codename": "add_suppliers.suppliers",
            "name": "Can add suppliers.suppliers"
},

and array of selected items like the follow : selected:[1608109838,1674826063,793298265],

the output is edited selected items array as value attached to event named : 'itemselected'

dependcies is tail.select.css && tail.select.js

/* eslint-disable no-undef */
<template>
  <div class="multi-select">
    <!-- container of multiselect && search bar -->
        <select class="select-move" v-model="selectedItems"  multiple>
          <optgroup v-for="permission in permissions" :key="permission.id"
              :label='getService(permission.codename)'>
          <option :value="permission.id" :data-description='permission.id'>{{getPermission(permission.name)}}</option>
          </optgroup>
        </select>
       <!-- search form (Optional) -->
        <input class="form-control" v-model="search" type="text" placeholder="Search" aria-label="Search">

        <!--selected itemes go here -->
      <div class="tail-move-container "></div>
      <!--selected itemes go here -->
        <!-- /.search form -->
    <!-- end of container of multiselect && search bar-->
  </div>
</template>
<script>
import '../../../public/assets/plugins/tail.select/js/tail.select-full'
export default {
    name: 'multiSelect',
    props: {
        permissions: {
          required: true,
          type: Array
        },
        selectedPermissions: {
          required: false,
          type: Array
        }
      },
    data(){
      return{
        search:"",
        selectedItems : this.selectedPermissions,
      }
    },
      methods:{
      getService(codename){
          //result is "'_' + all char after it" then remove "_" and split it with "."
          let result = /_.+$/.exec(codename)[0].substr(1).split(".");
          //if splitted result is the same return one of them with some edit
          if(result[0]===result[1])
            return result[0].replace(/_/g, " ");
          //else return all result with some edit  
          else
            return (result[0] + ' ' + result[1]).replace(/_/g, " ");
        },
      getPermission(perm ){
        //result is " ' ' + all char after it" then remove " " and split it with "."
        let result = / .+$/.exec(perm)[0].substr(1).split(".");
        //if splitted result[0] contain any of result [1 =] return the first one with some edit
        if(result[0].includes(result[1]))
          return result[0]
        //else return all result with some edit  
        else
          return result.toString().replace(/,/g, " ")
      },
      selectedOutputEvent(){
        document.querySelectorAll('.select-handle').forEach((item)=>{
              item.style.display = "block"
            })  
        this.$emit("itemselected", this.selectedItems);
      },
    },
    watch:{
      search:(val)=>{
        let results = document.querySelectorAll('.select-handle');
          results.forEach(item=>{
            if( item.innerHTML.includes(val.toLowerCase())){
                item.style.display= "block";
            }
            else{
              item.style.display = "none"; 
            }   
          });
      },
      selectedItems:{
        handler:'selectedOutputEvent',
        deep:true
      },
    },
    mounted:()=>{
    // eslint-disable-next-line no-undef
    tail.select(".select-move", {
    animate: true,              // [0.3.0]      Boolean
    classNames: true,           // [0.2.0]      Boolean, String, Array, null
    csvOutput: true,           // [0.3.4]      Boolean
    csvSeparator: ",",          // [0.3.4]      String
    descriptions: false,        // [0.3.0]      Boolean
    deselect: false,            // [0.3.0]      Boolean
    disabled: false,            // [0.5.0]      Boolean
    height: 350,                // [0.2.0]      Integer, null
    hideDisabled: true,        // [0.3.0]      Boolean
    hideSelected: true,        // [0.3.0]      Boolean
    items: {},                  // [0.3.0]      Object
    locale: "en",               // [0.5.0]      String
    linguisticRules: {          // [0.5.9]      Object
        "е": "ё",
        "a": "ä",
        "o": "ö",
        "u": "ü",
        "ss": "ß"
    },
    multiple: true,            // [0.2.0]      Boolean
    multiLimit: Infinity,       // [0.3.0]      Integer, Infinity
    multiPinSelected: true,    // [0.5.0]      Boolean
    multiContainer: [".tail-move-container"],      // [0.3.0]      Boolean, String
    multiShowCount: true,       // [0.3.0]      Boolean
    multiShowLimit: true,      // [0.5.0]      Boolean
    multiSelectAll: true,      // [0.4.0]      Boolean
    multiSelectGroup: true,     // [0.4.0]      Boolean
    openAbove: false,            // [0.3.0]      Boolean, null
    placeholder: null,          // [0.2.0]      String, null
    search: true,              // [0.3.0]      Boolean
    searchConfig: [             // [0.5.13]     Array
        "text", "value"
    ],
    searchDisabled: true,       // [0.5.5]      Boolean
    searchFocus: true,          // [0.3.0]      Boolean
    searchMarked: true,         // [0.3.0]      Boolean
    searchMinLength: 1,         // [0.5.13]     Integer
    sortItems: true,           // [0.3.0]      String, Function, false
    sortGroups: true,          // [0.3.0]      String, Function, false
    sourceBind: false,          // [0.5.0]      Boolean
    sourceHide: true,           // [0.5.0]      Boolean
    startOpen: false,           // [0.3.0]      Boolean
    stayOpen: false,            // [0.3.0]      Boolean
    width: "250px",                // [0.2.0]      Integer, String, null
    cbComplete: undefined,      // [0.5.0]      Function
    cbEmpty: undefined,         // [0.5.0]      Function
    cbLoopItem: undefined,      // [0.4.0]      Function
    cbLoopGroup: undefined      // [0.4.0]      Function
});
    document.querySelectorAll('.select-handle').forEach((item)=>{
      item.style.display = "block"
    });
    },
   
}
</script>
<style scoped>
 @import '../../../public/assets/plugins/tail.select/css/default/tail.select-light.css';
 .multi-select{
  border: black solid 0px;
  padding: 10px;
  width: fit-content;
  background-color:whitesmoke;
}
  
  .form-control{
    width: 250px;
    margin-top: 7px;
    border: solid 0px #d9d9d9;
    border-radius: 3px;
    box-shadow: 0 1px 1px 1px rgba(0,0,0,.1);
    transition: background 142ms linear, box-shadow 142ms linear;
    font-size: 14px;
    line-height: 22px;
    font-family: inherit;
    font-weight: 400;
     height: 32px;
   }
   .tail-move-container{
     padding: 0;
     height: 7rem;
     overflow: scroll;
   }
    
</style>