<template>
<section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-3">
            <UserCard v-if="user" :user="user" :profile="profile" />
          </div>
          <!-- /.col -->
          <div class="col-md-9">
            <div class="card">
              <div class="card-header p-2">
                <ul class="nav nav-pills">
                  <li class="nav-item"><a class="nav-link active" href="#profile" data-toggle="tab">Your info</a></li>
                  <li class="nav-item"><a class="nav-link" href="#login" data-toggle="tab">Login Info</a></li>
                  <li class="nav-item"><a class="nav-link" href="#bank" data-toggle="tab">Bank info</a></li>
                </ul>
              </div><!-- /.card-header -->
              <div class="card-body">
                <div class="tab-content">
                  <div class="active tab-pane" id="profile">
                    <ProfileInfo v-if="user" :user="user" v-animate-css="'fadeIn'" />
                  </div>
                  <!-- /.tab-pane -->
                  <div class="tab-pane" id="login">
                    <LoginInfo v-if="user" :user="user" v-animate-css="'fadeIn'" />
                  </div>
                  <!-- /.tab-pane -->

                  <div class="tab-pane" id="bank">
                    <BankInfo v-if="user" :user="user" v-animate-css="'fadeIn'" />
                  </div>
                  <!-- /.tab-pane -->
                </div>
                <!-- /.tab-content -->
              </div><!-- /.card-body -->
            </div>
            <!-- /.nav-tabs-custom -->
          </div>
          <!-- /.col -->
        </div>
        <!-- /.row -->
      </div><!-- /.container-fluid -->
    </section>
</template>

<script>
import UserCard from '@/components/account/profile/UserCard'
import BankInfo from '@/components/account/profile/bank'
import ProfileInfo from '@/components/account/profile/profile'
import LoginInfo from '@/components/account/profile/login'

import { RepositoryFactory } from '@/services/repositories/AuthAPI/RepositoryFactory';
const ProfileRepository = RepositoryFactory.get('profile');
const BankRepository = RepositoryFactory.get('bank');


export default {
    name: 'Profile',
    components: {
      UserCard,
      ProfileInfo,
      BankInfo,
      LoginInfo
    },
    data() {
      return {
        profile: {},
        bank_info: {}
      }
    },
    created() {
      this.getProfileInfo()
    },
    methods: {
        getProfileInfo: async function() {
          const { data } = await ProfileRepository.get(this.user.id)
          this.profile = data
        },
    },
    computed: {
      user: function() {
        return this.$store.getters.user
      },
    }
}
</script>

<style scoped>

</style>