import Repository from './Repository';

const resource = "/websites"

export default {
    all(page, limit=10000) {
        var offset = (page - 1) * limit
        return Repository.get(`${resource}/?limit=${limit}&offset=${offset}`);
    },
    page(page, limit=10, search='') {
        var offset = (page - 1) * limit
        if(search) { search = `&search=${search}` }
        return Repository.get(`${resource}/?o=-created_at&limit=${limit}&offset=${offset}${search}`);
    },
    query(filter, page, limit=10, search='') {
        var offset = (page - 1) * limit
        if(search) { search = `&search=${search}` }
        
        filter = new URLSearchParams(filter).toString()
        filter = `&${filter}`
        return Repository.get(`${resource}/?limit=${limit}&offset=${offset}${search}${filter}`);
    },
    filter(ids) {
        return Repository.get(`${resource}/?ids=${ids}&limit=1000`);
    },
    ids(ids) {
        return Repository.get(`${resource}/?ids=${ids}&limit=1000`);
    },
    stages(data) {
        return Repository.post(`${resource}/stages/`, data);
    },
    categories() {
        return Repository.options(`${resource}/`);
    },

    get(id) {
        return Repository.get(`${resource}/${id}/`);
    },
    post(data) {
        return Repository.post(`${resource}/`, data);
    },
    update(id, data) {
        return Repository.put(`${resource}/${id}/`, data);
    },
    patch(id, data) {
        return Repository.patch(`${resource}/${id}/`, data);
    },
    status() {
        return Repository.get(`http://127.0.0.1:2543/api/v1${resource}/status/`);
        return Repository.get(`${resource}/status/`);
    }
}