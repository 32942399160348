<template>
  <!-- Main Footer -->
  <footer class="main-footer"  v-animate-css="'slideInUp'">
    <strong>Copyright &copy; 2016-{{ new Date().getFullYear() }} <a href="https://adcrew.co">AdCrew</a>.</strong>
    All rights reserved.
    <div class="float-right d-none d-sm-inline-block">
      <b>Version</b> 2.0
    </div>
  </footer>
</template>
<script>
export default {
    
}
</script>

<style scoped>

</style>