import Repository from './Repository';

const resource = "/public";
export default {
    get(id) {
        return Repository.get(`${resource}/${id}/`);
    },
    clearCache(id) {
        return Repository.get(`${resource}/${id}/?cache=no`);
    }
}