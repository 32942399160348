<template>
<div class="modal fade" id="videoUploadModal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="max-width: 60%;" role="document">
        <div class="modal-content">
            <div class="modal-body" v-if="isVideoLoaded">
                <div class="modal-header">
                    <div class="text-danger ">
                        <i class="fas fa-film"></i>
                        <h3 class="d-inline ml-2">Video Details</h3>
                    </div>
                </div>

                <div  class="card mt-4" >
                    <div class="card-body">
                        <div class="form-group">
                                <label for="video_title">Video Title</label>
                                <input v-model="video.name" type="text" class="form-control border-top-0 border-right-0 border-left-0" id="video_title" aria-describedby="video_title_help" placeholder="Ttile">
                        </div>
                        <div class="form-group">
                            <button @click="uploadVideo()" class="btn btn-primary float-right">Upload</button>
                        </div>
                    </div>
                    <div class="overlay" v-if="isVideoUploading">
                        <point-spinner  />
                    </div>
                </div>
            </div>
            <div class="modal-body" v-else> 
                <div class="card d-flex justify-content-center align-items-center h-screen" @drop="drop($event)"  @dragover="allowDrop($event)">
                    <i class="text-xl  fas fa-cloud-upload-alt"></i>
                    <h1 class="display-5 text-lg font-weight-bold mt-12">Drag and Drop files here to upload...</h1>
                    <h1 class="display-5 text-lg font-weight-bold mt-12">Or</h1>
                    <div  class="custom-file text-center mt-12 ">
                        <label class="text-dark font-weight-normal border p-2 rounded-md shadow-md" for="uploadVideo">Select file</label>
                        <input ref="videofile" @change="onVideoAttached()" type="file" class="d-none" id="uploadVideo" />
                    </div>
                    <div class="progress mt-12 w-75" v-if="progressWidth > 0">
                        <div class="progress-bar" role="progressbar" :style="{width: progressWidth + '%'}" :aria-valuenow="progressWidth" aria-valuemin="0" aria-valuemax="100">{{progressWidth}} %</div>
                    </div>
                    <div class="mt-12 mx-4" v-if="selectedFile">
                        <table class="truncate">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="font-weight-bold"> Name: </td>
                                    <td class="px-2">{{selectedFile.name}}</td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold"> Size: </td>
                                    <td class="px-2">{{Math.round(selectedFile.size / (1024 * 1024))}} MB</td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold"> File Type: </td>
                                    <td class="px-2">{{selectedFile.type}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div> 
                </div>
            </div>
        </div> 
    </div>
</div>
</template>
<script>
import { PlayerRepoFactory } from '@/services/repositories/PlayerAPI/RepositoryFactory' 
const VideosRepo = PlayerRepoFactory.get('videos');

export default {
    data(){
        return {
            isVideoLoaded: false,
            isVideoUploading: false, 
            selectedFile: null,
            progressWidth: 0,
            video: {
                name: '',
            }
        }
    },
    methods: {
        drop(e ) {
            this.isVideoLoaded = true
            e.preventDefault(); 
            this.selectedFile = e.dataTransfer.files[0];
            this.video.url = URL.createObjectURL(this.selectedFile);
            console.log(this.selectedFile)
            URL.revokeObjectURL(this.video.url)
        },
        allowDrop(e) {
            e.preventDefault();
        },
        onVideoAttached() {
            this.isVideoLoaded = true
            this.selectedFile = this.$refs.videofile.files[0];
            this.video.url = URL.createObjectURL(this.selectedFile);
            URL.revokeObjectURL(this.video.url)
        },
        uploadVideo() {
            this.onUpload()
        },
        onUpload() {
            this.isVideoUploading = true
            let formData = new FormData();
            formData.append('file', this.selectedFile);
            formData.append('website_id', 0);
            formData.append('name', this.video.name);
            VideosRepo.post(formData).then(result => {
                this.$notify({
                    group: 'ops',
                    type: 'success',
                    title: 'Operation success',
                    text: 'Successfully uploaded video.'
                })
                this.videoUploaded()
                this.closeUploadVideo()
                this.isVideoUploading = false
            }).catch(err => {
                this.$notify({
                    group: 'ops',
                    type: 'error',
                    title: 'Operation failed',
                    text: 'Failed to upload video.'
                })
                this.isVideoUploading = false
            })
        },
        closeUploadVideo() {
            this.$emit("closed")
        },
        videoUploaded() {
            this.$emit("uploaded")
        }
    },
    watch: {
    },
}
</script>