import axios from "axios"

const baseDomain = "https://services.adcrew.co/api/v1/players";
const baseURL = process.env.VUE_APP_PLAYER_API_URL


const instance = axios.create({
    baseURL,
    withCredentials: true,
    headers: { 
        'Content-Type': 'application/json', 
    }
});

instance.interceptors.request.use(
    (config) => {
        let token = localStorage.getItem('accessToken');

        if (token) {
        config.headers['Authorization'] = `Bearer ${ token }`;
        }

        return config;
    }, 

    (error) => {
        return Promise.reject(error);
    }
);


export default instance
