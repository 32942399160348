<template>
<div class="col-md-12">
    <div class="card" v-animate-css="'fadeInLeft'">
        <div class="card-header">
            <h3 class="card-title">
               {{ supplier.name }} Ads
            </h3>
            <!-- /.card-tools -->
        </div>
        <!-- /.card-header -->
        <div class="card-body">
            <div class="row">
                <div class="col-md-10">
                    <label for="text">Text</label>
                    <textarea class="form-control" style="height: 34px; min-height: 40px;" required="" v-model="ad.text"></textarea>
                </div>
                <div class="col-md-2">
                    <button class="btn btn-success btn-block pull-right" style="margin-top: 32px" @click.prevent="addAd()">Add</button>
                </div>            
                <div class="col-md-12">
                    <ul class="custom-select-list">
                        <li v-for="ad in ads" :key="ad.id" v-animate-css="'bounceInLeft'"> 
                            {{ ad.text }}
                            <a href="#" class="float-right remove-btn" @click.prevent="deleteAd(ad.id)" ><i class="fa fa-close"></i> Delete</a>
                        </li>
                    </ul>
                </div>
                <div class="col-md-12" style="margin-top: 10px;">
                    <div id="download-links" class="float-left"></div>
                    <a class="float-right" href="#" @click.prevent="getAdsTxt()"><i class="fa fa-download"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { RepositoryFactory } from '@/services/repositories/ManagementAPI/RepositoryFactory';
const AdsRepo = RepositoryFactory.get('ads');

export default {
    components: {
    },
    props: {
        supplier: {
            type: Object
        },
    },
    data() {
        return {
            ads: [],
            ad: {
                text: "",
                supplier_id: 0,
            },
        }
    },
    methods: {
        loadAds: function(id) {
            AdsRepo.supplier(id).then(result => {
                this.ads = result.data.results
            })
        },
        addAd: function() {
            // prepare list and supplier id
            var ads_list = []
            var supplier_id = this.supplier.id
            
            // split the ads if are bulk
            var lines = this.ad.text.split("\n")
            lines.forEach(function(ad) {
                // remove any empty values
                if(ad !== "") {
                    var ad = {
                            text: ad,
                            supplier_id: supplier_id
                        }
                    ads_list.push(ad)
                }
            })

            AdsRepo.post(ads_list).then(result => {
                this.ads.push.apply(this.ads, result.data)
                this.$notify({
                    group: 'ops',
                    type: 'success',
                    title: 'Operations Success',
                    text: 'Successfuly added new ad.'
                })
            }).catch(err => {
                this.$notify({
                    group: 'ops',
                    type: 'error',
                    title: 'Operations failed',
                    text: 'Failed added new ad.'
                })
            })
        },
        deleteAd: function(id) {
            AdsRepo.delete(id).then(result => {
                this.ads.pop({'id': id})
                this.$notify({
                    group: 'ops',
                    type: 'success',
                    title: 'Operations Success',
                    text: 'Successfuly deleted ad.'
                })
            }).catch(err => {
                this.$notify({
                    group: 'ops',
                    type: 'error',
                    title: 'Operations failed',
                    text: 'Failed to delete ad.'
                })
            })
        },
        getAdsTxt: function() {
            AdsRepo.text().then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                
                link.href = url;
                link.setAttribute('download', 'ads.txt');
                link.innerHTML = 'Download ads.txt'
                link.click()

                document.getElementById("download-links").innerHTML = ""
                document.getElementById("download-links").appendChild(link)
            })
        },
    },
    computed: {
    }, 
    watch: {
        'supplier': {
            handler: function (supplier) {
                if(typeof supplier.id !== 'undefined') {
                    this.loadAds(supplier.id)
                }
            },
            deep: true,
            immediate: true
        },
    }
}
</script>

<style>

.input-bulk {
    height: 100px; 
    min-height: 100px;
}
.custom-select-list {
    list-style-type: none;
    text-align: left;
    margin: 5px 0 0 0;
    padding: 10px;
    border: 1px #CCCCCC solid;
    height: 300px;
    overflow-y: scroll;
    border-radius: 2px;
}

.custom-select-list li:first-child {
    padding: 15px;
}

.custom-select-list li {
    display: block;
    padding: 15px;
    min-height: 45px;
    border-bottom: 1px #f2f2f2 solid;
}

.custom-select-list .remove-btn {
    color: #ad3737;
}
</style>