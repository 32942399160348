<template>
  <div v-if="isAuthenticated">
      <Nav :user="userData" />
      <Sidebar :user="userData" />

      <div class="content-wrapper">
        <Breadcrumb :pageName="currentRouteName | capitalize" />
        <!-- Main content -->
        <section class="content">
          <router-view  v-animate-css="'bounceInLeft'" />
        </section>
      </div>

      <Footer />
  </div>
</template>

<script>
import Sidebar from "@/components/core/Sidebar"
import Nav from "@/components/core/Nav"
import Footer from "@/components/core/Footer"
import Breadcrumb from "@/components/core/Breadcrumb"

export default {
  name: 'home',
  data() {
    return {
      user: {},
    }
  },
  components: {
    Sidebar,
    Nav,
    Footer,
    Breadcrumb
  },
  created() {
  },
  methods: {

  },
  computed: {
    isAuthenticated() {
      if(this.$store.getters.isAuthenticated === true) {
        return true
      }
      this.$router.push('/login')
      return false
    },
    userData() {
      return this.$store.getters.user
    },
    currentRouteName() {
      return this.$route.name;
    }
  },
  filters: {
    capitalize: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  }
}
</script>
