import Repository from './Repository';

const resource = "/exceptions";
export default {
    user(id) {
        return Repository.get(`${resource}/?user=${id}`);
    },
    get(id) {
        return Repository.get(`${resource}/${id}/`);
    },
    add(data) {
        return Repository.post(`${resource}/`, data);
    },
    update(id, data) {
        return Repository.put(`${resource}/${id}/`, data);
    },
    delete(id) {
        return Repository.delete(`${resource}/${id}/`)
    }
}