<template>
<div>
    <div class="card">
        <div class="card-header">
            <h3 class="card-title"> Search </h3>
            <div class="card-tools">
                <button class="btn btn-tool" @click.prevent="clear()"><i class="fa fa-eraser"></i></button>
            </div>
        </div>
        <div class="card-body table-responsive">
            <div class="row">
                <div class="form-group col-md-3">
                    <label>Suppliers</label>
                    <select id="supplier" class="form-control select2bs4" >
                        <option selected disabled hidden> -- Suppliers --</option>
                        <option v-for="supplier in suppliers" :key="supplier.id" :value="supplier.id" >{{ supplier.name }} ({{ supplier.status | capitalize }})</option>
                    </select>
                </div>
                <div class="form-group col-md-3">
                    <label>Websites</label>
                    <select id="website" name="website" class="form-control select2bs4">
                        <option selected disabled hidden> -- Websites --</option>
                        <option v-for="website in websites" :key="website.id" :value="website.id" >{{ website.name }} ({{ website.status | capitalize }})</option>
                    </select>
                </div>
                <div class="form-group col-md-3">
                    <label>Groups</label>
                    <select id="group" class="form-control select2bs4" :disabled="!query.website">
                        <option selected disabled hidden> -- Groups -- </option>
                        <option v-for="group in groups" :key="group.id" :value="group.id" >{{ group.name }} ({{ group.status | capitalize }})</option>
                    </select>
                </div>
                <div class="form-group col-md-3">
                    <label>Data</label>
                    <select id="data" class="form-control select2bs4" :disabled="!query.group">
                        <option selected value="all"> All </option>
                        <option v-for="data_r in data" :key="data_r.id" :value="data_r.id" >{{ data_r.name }} ({{ data_r.status | capitalize }})</option>
                    </select>
                </div>
                <div class="col-md-2">
                    <label>Status </label>
                    <div class="btn-group btn-group-toggle">
                        <label class="btn btn-default" :class="{'text-green': query.status == 'true'}">
                            <input type="radio" value="true" v-model="query.status"> Complete
                        </label>
                        <label class="btn btn-default" :class="{'text-warning': query.status == 'false'}">
                            <input type="radio" value="false" v-model="query.status"> Incomplete
                        </label>
                        <label class="btn btn-default" :class="{'text-blue': query.status == ''}">
                            <input type="radio" value="" v-model="query.status"> All
                        </label>
                    </div>
                </div>
                <div class="col-md-4"></div>
                <div class="form-group col-md-3">
                    <label>Date range:</label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">
                                <i class="far fa-calendar-alt"></i>
                            </span>
                        </div>
                        <input type="text" class="form-control float-right" id="daterange">
                  </div>
                </div>
                <div class="form-group col-md-1">
                    <label>Limit</label>
                    <select class="form-control" style="width: 100%;" v-model="query.limit">
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option selected value="30">30</option>
                    <option value="60">60</option>
                    <option value="100">100</option>
                    </select>
                </div>
                <div class="form-group col-md-1">
                    <label>Order</label>
                    <select class="form-control" style="width: 100%;" v-model="query.order">
                        <option selected="selected" value="-date">Newest</option>
                        <option value="date">Older</option>
                    </select>
                </div>
                <div class="form-group col-md-1">
                    <hr style="visibility: hidden;" />
                    <button class="btn btn-primary mr-2"
                    @click.prevent="results()"><i class="fa fa-search"></i> </button>
                </div>

            </div>
        </div>
    </div>
    <Statistics
        type="statistics"
        :search="query.search"
        :website="query.website"
        :group="query.group"
        :data="query.data"
        :start_date="query.start_date" :end_date="query.end_date" :complete="query.status"
        :order="query.order" :limit="query.limit"
     />
</div>
</template>

<script>
import Statistics from '@/components/analytics/Statistics'
import { RepositoryFactory } from '@/services/repositories/ManagementAPI/RepositoryFactory';
const SuppliersRepo = RepositoryFactory.get('suppliers');
const PGDataRepo = RepositoryFactory.get('groups_data');
const WebsitesRepo = RepositoryFactory.get('websites');
const GroupsRepo = RepositoryFactory.get('groups');

export default {
    components: {
        Statistics
    },
    data() {
        return {
            suppliers: [],
            websites: [],
            groups: [],
            data: [],

            query: {
                supplier: 0,
                website: 0,
                group: 0,
                data: 0,
                status: '',
                start_date: "",
                end_date: "",
                limit: 30,
                order: '-date',
                search: 0
            },
        }
    },
    created() {
        this.loadSuppliers()
    },
    mounted() {
        //Initialize Select2 Elements
        $('.select2bs4').select2({
            theme: 'bootstrap4'
        })

        // Date range as a button
        var self = this
        function daterange(start, end) {
            self.start_date = start.format('YYYY-MM-DD')
            self.end_date = end.format('YYYY-MM-DD')
        }

        var startDate = this.$moment().subtract(29, 'days')
        var endDate = this.$moment()
        $('#daterange').daterangepicker({
                ranges   : {
                    'Today'       : [this.$moment(), this.$moment()],
                    'Yesterday'   : [this.$moment().subtract(1, 'days'), this.$moment().subtract(1, 'days')],
                    'Last 7 Days' : [this.$moment().subtract(6, 'days'), this.$moment()],
                    'Last 30 Days': [this.$moment().subtract(29, 'days'), this.$moment()],
                    'This Month'  : [this.$moment().startOf('month'), this.$moment().endOf('month')],
                    'Last Month'  : [this.$moment().subtract(1, 'month').startOf('month'), this.$moment().subtract(1, 'month').endOf('month')]
                },
                startDate: startDate,
                endDate  : endDate
            },
            daterange
        )
        daterange(startDate, endDate)
        
        var self = this
        $('#supplier').on("change", function(e) { 
            self.query.supplier = $(this).val()
        });
        $('#website').on("change", function(e) { 
            self.query.website = $(this).val()
        });
        
        $('#group').on("change", function(e) { 
            self.query.group = $(this).val()
        });

        $('#data').on("change", function(e) { 
            self.query.data = $(this).val()
        });
    },
    methods: {
        loadSuppliers: function() {
            SuppliersRepo.all().then(result => {
                this.suppliers = result.data.results
            })
        },
        loadWebsitesWithSupplier: function(id) {
            PGDataRepo.supplier(id).then(result => {
                var data_websites = result.data.results
                var list = []
                data_websites.forEach(element => {
                    list.push(element.website_id)
                })
                WebsitesRepo.filter(list).then(result => {
                    this.websites = result.data.results
                })
            })
        },
        loadGroups: function(website) {
            GroupsRepo.withWebsite(website).then(result => {
                this.groups = result.data.results
            })
        },
        loadData: function(group, supplier) {
            PGDataRepo.withGroupAndSupplier(group, supplier).then(result => {
                this.data = result.data.results
            })
        },
        results: function() {
            this.query.search += 1
        },
        clear: function() {
            this.query = {
                supplier: 0,
                website: 0,
                group: 0,
                data: 0,
                status: 0,
                start_date: "",
                end_date: "",
                limit: 30,
                order: '-date',
                search: 0
            }
        },
    },
    watch: {
        "query.supplier": function(supplier) {
            if(supplier) {
                this.loadWebsitesWithSupplier(supplier)
            }
        },
        "query.website": function(website) {
            if(website) {
                this.loadGroups(website)
            }
        },
        "query.group": function(group) {
            if(group) {
                this.loadData(group, this.query.supplier)
            }
        },
    },
}
</script>