<template>
    <div class="card" v-animate-css="'fadeInLeft'">
        <div class="card-header">
            <h3 class="card-title">
                <span v-if="!tag.id"> Add </span>
                <span v-if="tag.id"> Edit </span>
                Tag
            </h3>
            <div class="card-tools">
                <div class="overlay" v-if="isLoading">
                    <i class="fas fa-sync-alt fa-spin fa-1x"></i>
                </div>

                <button class="btn btn-tool btn-sm" v-if="!isLoading" @click.prevent="clear()">
                    <i class="fa fa-plus"></i>
                </button>
            </div>
            <!-- /.card-tools -->
        </div>
        <!-- /.card-header -->
        <div class="card-body">
            <form role="form">
                <input type="hidden" v-model="tag.website_id" />
                <div class="row">
                    <div class="col-md-12">
                        <!-- text input -->
                        <div class="form-group">
                        <label>Name</label>
                        <input type="text" class="form-control" v-model="tag.name">
                        </div>
                    </div>
                <div class="col-md-12">
                    <!-- textarea -->
                    <div class="form-group">
                    <label>Code</label>
                    <textarea class="form-control" rows="6" v-model="tag.code"></textarea>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-8">
                    <div class="form-group">
                        <div class="btn-group btn-group-toggle">
                            <label class="btn btn-sm btn-default"  :class="{'text-green': tag.status === 'active'}">
                                <input type="radio" value="active" v-model="tag.status"> Active
                            </label>
                            <label class="btn btn-sm btn-default" :class="{'text-red': tag.status === 'suspended'}">
                                <input type="radio" value="suspended" v-model="tag.status"> Suspended
                            </label>
                            </div>
                        </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group float-right">
                        <button class="btn btn-success" @click.prevent="submit()"> Save </button>
                    </div>
                </div>

                <div class="col-md-12">
                <errors :errors="errors"/>
                <messages :messages="messages" />
                </div>
            </div>

            </form>
        </div>
    <!-- /.card-body -->
    </div>
    <!-- /.card -->

</template>

<script>
import { RepositoryFactory } from '@/services/repositories/ManagementAPI/RepositoryFactory' 
const TagsRepo = RepositoryFactory.get('tags')

export default {
    props: {
        tag: {
            required: true,
            type: Object
        },
        website: {
            required: true,
            type: Object
        },
        isLoading: {
            required: false,
            type: Boolean
        },
    },
    data() {
        return {
            messages: [],
            errors: []
        }
    },
    methods: {
        submit: function() {
            if(typeof this.tag.id === 'undefined') {
                this.create(this.tag)
            } else {
                this.update(this.tag)
            }
        },
        clear: function() {
            this.errors = []
            this.messages = []

            this.tag = {
                website_id: this.website.id
            }
        },
        create: function(tag) {
            this.errors = []
            this.messages = []

            TagsRepo.post(tag).then(result => {
                if(typeof result.data.id !== 'undefined' )
                    this.tag = result.data
                    this.$notify({
                        group: 'ops',
                        type: 'success',
                        title: 'Operation success',
                        text: 'Successfully added with ID: ' + result.data.id
                    })
                    this.$emit('updateList', result.data)
            }).catch(err => {
                this.$notify({
                    group: 'ops',
                    type: 'error',
                    title: 'Operation failed',
                    text: err.response.data
                })
            })
        },
        update: function(tag) {
            this.errors = []
            this.messages = []

            TagsRepo.patch(this.tag.id, tag).then(result => {
                if(typeof result.data.id !== 'undefined' ) 
                    this.tag = result.data
                    this.$notify({
                        group: 'ops',
                        type: 'success',
                        title: 'Operation success',
                        text: 'Successfully updated'
                    })
                    this.$emit('refreshList', true)
            }).catch(err => {
                this.$notify({
                    group: 'ops',
                    type: 'error',
                    title: 'Operation failed',
                    text: err.response.data
                })
            })
        },
        delete: function(id) {
            this.errors = []
            this.messages = []

            TagsRepo.delete(id).then(result => {
                this.$notify({
                    group: 'ops',
                    type: 'success',
                    title: 'Operation success',
                    text: 'Successfully deleted : ' + result.data
                })
            }).catch(err => {
                this.$notify({
                    group: 'ops',
                    type: 'error',
                    title: 'Operation failed',
                    text: err.response.data
                })
            })
        },
    },
}
</script>