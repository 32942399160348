import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store/store'
import "./filters"
import "./mixins"

// imported libs 
import VAnimateCss from 'v-animate-css'
import Notifications from 'vue-notification'
import Vuelidate from 'vuelidate'
import VueSwal from 'vue-swal'

import moment from 'moment'

import VueFloatLabel from 'vue-float-label'

import { library } from '@fortawesome/fontawesome-svg-core'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import feather from 'feather-icons'

import './assets/index.css';

// custom componetns
import PointSpinner from '@/components/core/util/Spinners/Points'
import CompanyLogo from '@/components/core/CompanyLogo'
import DisplayError from '@/components/core/display/Error'
import DisplayMessage from '@/components/core/display/Message'
import DisplayInfo from '@/components/core/display/Info'

library.add(fas)
library.add(far)
Vue.component('fa-icon', FontAwesomeIcon)

// custom componetns
Vue.component('point-spinner', PointSpinner)
Vue.component('errors', DisplayError)
Vue.component('messages', DisplayMessage)
Vue.component('info', DisplayInfo)
Vue.component('company-logo', CompanyLogo)

Vue.use(VueSwal)
Vue.use(VAnimateCss);
Vue.use(Vuelidate)
Vue.use(Notifications)
Vue.use(VueFloatLabel)

Vue.config.productionTip = false

Vue.component('point-spinner', PointSpinner)

router.beforeEach((to, from, next) => {

  if(to.matched.some(record => record.meta.requiresAuth === true)) {
    store.dispatch('VERIFY_TOKEN')
    if (store.getters.isAuthenticated === false) {
      router.push({name: 'login'})
    }
  }

  if(to.matched.some(record => record.meta.requiresAuth) === false) {
    if (store.getters.isAuthenticated === true) {
      router.push({name: 'dashboard'})
    }
  }

  next() 
})

Object.defineProperty(Vue.prototype, '$feather', { value: feather })
Object.defineProperty(Vue.prototype, '$moment', { value: moment })

import alasqlJs from '../node_modules/alasql/dist/alasql.min.js'
const alasql = {
  install (v) {
    Vue.prototype.alasql = alasqlJs
  }
}
Vue.use(alasql)

new Vue({
  router,
  store,
  axios,
  feather,
  render: h => h(App)
}).$mount('#app')
