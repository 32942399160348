<template>
    <div class="row tile_count col-md-12" style="margin: 10px 0;">
        <hr />
        <div class="col-md-3 col-sm-3 col-xs-6 tile">
            <h2 style="font-size: 30px;">Last 14 days</h2>
        </div>
        <div class="col-md-3 col-sm-3 col-xs-6 tile ng-scope">
            <span>IMPRESSIONS</span>
            <h2></h2>
            <span id="impressionsBar" class="sparkline_one" style="height: height: 40px;"></span>
        </div>
        <div class="col-md-3 col-sm-3 col-xs-6 tile ng-scope">
            <span>REVENUE</span>
            <h2></h2>
            <span id="revenueBar" class="sparkline_one" style="height: 40px;"></span>
        </div>
        <div class="col-md-3 col-sm-3 col-xs-6 tile ng-scope">
            <span>CPM</span>
            <h2></h2>
            <span id="cpmBar" class="sparkline_one" style="height: 40px;"></span>
        </div>
    </div>
</template>
<script>
import statisticsToGraphData from './data'

export default {
    name: "Sparklines",
    props: {
        data: {
            type: Array,
            required: true
        }
    },
    methods: { 
        init: function() {
            var statistics = statisticsToGraphData(this.data, true)
            $('#impressionsBar').sparkline(statistics.impressions, {
                type:"bar",
                height:"125",
                barWidth:13,
                colorMap:
                    {
                        7:"#a1a1a1"
                    },
                barSpacing:2,
                barColor:"#26B99A"
            } );
            $('#revenueBar').sparkline(statistics.revenue, {
                type:"bar",
                height:"125",
                barWidth:13,
                colorMap:
                    {
                        7:"#a1a1a1"
                    },
                barSpacing:2,
                barColor:"#26B99A"
            } );
            $('#cpmBar').sparkline(statistics.cpm, {
                type:"bar",
                height:"125",
                barWidth:13,
                colorMap:
                    {
                        7:"#a1a1a1"
                    },
                barSpacing:2,
                barColor:"#26B99A"
            } );
        }
    },
    mounted() {
        this.init()
    },
    watch: {
        data: function(data) {
            this.init()
        }
    }
}
</script>
<style scoped>
.tile_count {
    margin-bottom: 20px;
    margin-top: 20px;
}
</style>