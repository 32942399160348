<template>
<div class="row">
    <div class="col-md-6">
        <PlacementsGroupsList 
            :placement_groups="placement_groups"
            :website="website"  
            :groups="groups_types" 
            :isLoaded="isLoaded"
            @displayReport="displayReport"
            @displayWebsiteReport="displayWebsiteReport"
            @search="loadSearchResult"
        />

        <ul class="pagination pagination-sm m-0 float-right">
          <li class="page-item">
            <a class="page-link" href="#" @click.prevent="previousPage">Previous</a>
          </li>
          <li class="page-item">
            <a class="page-link">{{ currentPage }} / {{ pageCount }}</a>
          </li>
          <li class="page-item">
            <a class="page-link" href="#" @click.prevent="nextPage">Next</a>
          </li>
        </ul>
    </div>

    <div class="col-md-6">
        <PlacementsGroupsView 
            :placement_group="placement_group" 
            :website="website_id" 
            :groups="groups_types" 
            :isLoading="isLoading" 
            @updateList="updateList"
            @refreshList="refreshList"
        />
    </div>
    <div class="col-md-12">
        <hr style="visibility: hidden;" />
        <StatisticsReport
            v-if="query.type"
            :name="query.name"
            :type="query.type"
            :search="query.search"
            :website="query.website"
            :group="query.group"
        />
    </div>
</div>
</template>

<script>
import PlacementsGroupsList from '@/components/placements_groups/List'
import PlacementsGroupsView from '@/components/placements_groups/Placement_Group'
import StatisticsReport from '@/components/analytics/Statistics'

import { RepositoryFactory } from '@/services/repositories/ManagementAPI/RepositoryFactory' 
const WebsitesRepo = RepositoryFactory.get('websites')
const GroupsTypesRepo = RepositoryFactory.get('groups_types')
const PlacementsGroupsRepo = RepositoryFactory.get('groups')

export default {
    name: "Placements_Groups",
    components: {
        PlacementsGroupsList,
        PlacementsGroupsView,
        StatisticsReport
    },
    data: function() {
        return {
            website_id: 0,
            website: {},

            groups_types: [],
            group_type: {},

            placement_groups: [],
            placement_group: {
                name: "",
                group_id: 0,
                website_id: 0,
                status: ""
            },
            isLoaded: false,
            isLoading: false,

            currentPage: 1,
            totalRecords: 0,
            resultsLimit: 10,
            isLoaded: false,
            websites: [],

            query: {
                type: "",
                name: "",
                website: 0,
                group: 0,
                search: 0
            },
            statistics_types: {
                statistics: "statistics",
                groups: "groups",
                user_group: "user_groups",
                website: "website"
            },
        }
    },
    methods: {
        loadWebsiteData: function() {
            WebsitesRepo.get(this.website_id).then(result => {
                this.website = result.data
                this.placement_group.website_id = this.website.id
            })
        },
        loadGroupsTypesData: function() {
            GroupsTypesRepo.page(1, 1000).then(result => {
                this.groups_types = result.data.results
            })
        },
        loadPlacementsGroups: function() {
            this.isLoaded = false
            PlacementsGroupsRepo.page(this.website_id, this.currentPage).then(result => {
                this.totalRecords = result.data.count
                this.placement_groups = result.data.results
                this.isLoaded = true
            }).catch(err => {
                this.isLoaded = true
            })
        },
        loadPlacementGroup: function(id) {
            this.isLoading = true
            PlacementsGroupsRepo.get(id).then(result => {
                this.placement_group = result.data
                this.isLoading = false
            }).catch(err => {
                this.isLoading = false
            })
        },
        updateList: function(placement_group) {
            this.placement_groups.push(placement_group)
            this.$router.replace({params: {'id': placement_group.id}, query: {'website': placement_group.website_id}})
        },
        refreshList: function(action) {
            if(action)
                this.loadPlacementsGroups()
        },
        displayReport: function(pg) {
            this.query.name = pg.name
            this.query.group = pg.id
            this.query.website = this.website_id
            this.query.type = this.statistics_types.groups
            this.query.search += 1
        },
        displayWebsiteReport: function(website) {
            this.query.name = website.name
            this.query.group = website.id
            this.query.website = this.website_id
            this.query.type = this.statistics_types.website
            this.query.search += 1
        },
        loadSearchResult(search_text) {
            PlacementsGroupsRepo.page(this.website_id, this.currentPage, 10, search_text).then(result => {
                this.totalRecords = result.data.count
                this.placement_groups = result.data.results
            }).catch(err => {})
        },
        nextPage: function() {
            if(this.currentPage != this.pageCount || this.currentPage > this.pageCount) {
                this.currentPage = this.currentPage + 1
                this.loadPlacementsGroups()
            }
        },
        previousPage: function() {
            if(this.currentPage != 1 ) {
                this.currentPage = this.currentPage - 1
                this.loadPlacementsGroups()
            }
        },
  },
  computed: {
    pageCount: function() {
        return Math.ceil(this.totalRecords / this.resultsLimit)
      },
    },
    watch: {
        '$route.query.website': { 
            handler: function (website) {
                if(typeof website !== 'undefined') {
                    // check if user is not loaded 
                    // website placements_groups before
                    if(this.website_id !== website) {
                        // match website with passed from query
                        this.website_id = website

                        // load website and placements_groups
                        this.loadWebsiteData()
                        this.loadGroupsTypesData()
                        this.loadPlacementsGroups()
                    }
                } else {
                    this.$router.push({name: "website"})
                }
            },
            deep: true,
            immediate: true
        },
        '$route.params.id': {
            handler: function (id) {
                if(typeof id !== 'undefined') {
                    // check if it exist under this list 
                    var element = this.placement_groups.filter(element => { return element.id === id })
                    if(element.length > 0) {
                        this.loadPlacementGroup(id)
                        this.loadGroupsTypesData()
                    } else {
                        this.placement_group = {}
                    }
                }
            },
            deep: true,
            immediate: true
        },
    }
}
</script>

<style>

</style>