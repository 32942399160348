<template>
    <div>
        <div class="row mb-2">
            <div class="col-md-12">
                Player Name
            </div>
            <div :class="{'col-md-12': !player.id, 'col-md-10': player.id}">
                <input type="text" class="form-control" id="playername" v-model="player.name">
            </div>
            <div class="col-md-2" v-if="player.id">
                <button class="btn btn-success float-right btn-block" @click.prevent="submit()">
                    <span v-if="player.id">Save</span>
                    <span v-else>Create</span>
                </button>
            </div>
        </div>

        <div class="col-md-12 border mb-2 rounded-lg font-weight-bold text-dark" v-if="!player.id">
            <nav class="m-2">
                <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                    <li class="nav-item has-treeview menu-open">
                        <a href="#" class="nav-link flex align-items-center text-warning">
                        <i class="nav-icon fas fa-plug"></i>
                        <div class=""> Format </div><i class="fas fa-angle-left ml-auto"></i> 
                        </a>
                        <ul class="m-3 nav nav-treeview">
                            <li class="nav-item font-weight-normal pb-6">
                                <div class="d-flex justify-content-start align-items-baseline input-group">
                                    <div class="col-md-6">
                                        <label class="font-weight-normal " for="playerFormat">Type</label>
                                    </div>
                                    <div class="col-md-6 ">
                                        <select  class="custom-select " id="playerFormat" v-model="player.player_type">
                                            <option disabled>Choose Format for Player</option>
                                            <option v-for="(type, index) in types" :key=index :value=type.value > {{ type.display_name }} </option>
                                        </select>                                            
                                    </div>
                                </div>
                            </li>
                        <li class="nav-item mb-4 font-weight-normal">
                            <div class="d-flex justify-content-start align-items-baseline input-group">
                                <div class="col-md-6">
                                    <label class="font-weight-normal " for="playerFormat">Player position</label>
                                </div>
                                <div class="col-md-6 ">
                                    <select  class="custom-select " id="playerFormat" v-model="player.position">
                                        <option disabled>Choose player position</option>
                                        <option v-for="(position, index) in positions" :key=index :value=position.value > {{ position.display_name }} </option>
                                    </select>
                                </div>
                            </div>
                        </li>
                        <li class="nav-item mb-4 font-weight-normal">
                            <div class="d-flex justify-content-start align-items-baseline input-group">
                                <div class="col-md-6">
                                    <label class="font-weight-normal " for="playerFormat">Companion position</label>
                                </div>
                                <div class="col-md-6 ">
                                    <select  class="custom-select " id="playerFormat" v-model="player.companion_position">
                                        <option disabled>Choose companion position</option>
                                        <option v-for="(position, index) in positions" :key=index :value=position.value > {{ position.display_name }} </option>
                                    </select>
                                </div>
                            </div>
                        </li>
                        <li class="nav-item mb-4 font-weight-normal">
                            <div class="d-flex justify-content-start align-items-baseline input-group">
                                <div class="col-md-6">
                                    <label class="font-weight-normal " for="playerFormat">Close button</label>
                                </div>
                                <div class="col-md-6">
                                    <div class="inline">
                                        <div class="custom-control custom-switch p-0 py-2">
                                            <label class="switch" for="autoplay">
                                                <input type="checkbox" class="primary" id="autoplay" 
                                                v-model="player.close_button">
                                                <span class="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                            <li>
                                <hr><br>
                                    <button class="btn btn-success float-right btn-block" @click.prevent="submit()">
                                        <span v-if="player.id">Save</span>
                                        <span v-else>Create</span>
                                    </button>
                                <br>
                            </li>
                        </ul>
                    </li>
                </ul>
            </nav>
        </div>

    </div>
</template>
<script>
import { RepositoryFactory } from '@/services/repositories/PlayerAPI/RepositoryFactory'
const PlayersRepo = RepositoryFactory.get('players')
const FormatsRepo = RepositoryFactory.get('formats')

export default {
    props: {
        player: {
            type:  Object,
            required: true
        },
    },
    data () {
        return {
            website_id: 0,
            types: [],
            positions: []
        }
    },
    created () {
        this.getFormatTypes()
        this.getFormatPositions()
    },
    methods: {
        submit() {
            if(this.player.id) {
                this.update()
            } else {
                this.create()
            }
        },
        create() {
            this.player.website_id = this.website_id
            PlayersRepo.post(this.player).then(result => {
                this.player = result.data
                this.$emit('created', this.player.id)
                this.$notify({
                    group: 'ops',
                    type: 'success',
                    title: 'Operation success',
                    text: 'Successfully create player.'
                })
                this.$emit('updateList', result.data)
                // this.router.push({name: 'players', id: this.player, params: {website: this.website_id}})
            }).catch(error => {
                this.$notify({
                    group: 'ops',
                    type: 'error',
                    title: 'Operation failed',
                    text: 'Failed to create player.'
                })
            })
        },
        update() {
            PlayersRepo.patch(this.player.id, this.player).then(result => {
                this.player = result.data
                this.$notify({
                    group: 'ops',
                    type: 'success',
                    title: 'Operation success',
                    text: 'Successfully updated the player name.'
                })
                this.$emit('updateList', result.data)
            }).catch(error => {
                this.$notify({
                    group: 'ops',
                    type: 'error',
                    title: 'Operation failed',
                    text: 'Failed to updated the player name.'
                })
            })
        },
        getFormatTypes() {
            FormatsRepo.options().then(result => {
                this.types = result.data.actions.POST.type.choices
            }).catch(error => {
                console.log(error)
            })
        },
        getFormatPositions: function () {
            FormatsRepo.options().then(result => {
                this.positions = result.data.actions.POST.position.choices
            }).catch(error => {
                console.log(error)
            })
        }
    },
    watch: {
        '$route.query.website': { 
            handler: function (website) {
                if(typeof website !== 'undefined') {
                    // check if user is not loaded 
                    // website placements_groups before
                    if(this.website_id !== website) {
                        // match website with passed from query
                        this.website_id = website
                    }
                } else {
                    this.$router.push({name: "website"})
                }
            },
            deep: true,
            immediate: true
        },
    }
}
</script>