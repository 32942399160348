<template>
<div>
    <div class="row">
        <div class="col-md-12">
            <!-- text input -->
            <div class="form-group">
                <label>Name</label>
                <input type="text" class="form-control" v-model="website.name">
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label>Category</label>
                <select class="form-control" v-model="website.category">
                    <option v-for="category in categories" :key="category.id" :value="category.value"
                    :selected="website.category"> {{ category.display_name }} </option>
                </select>
            </div>
        </div>
        <div class="col-md-8">
            <!-- text input -->
            <div class="form-group">
                <label>URL</label>
                <input type="text" class="form-control" v-model="website.url">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-8">
            <div class="form-group">
                <div class="btn-group btn-group-toggle">
                    <label class="btn btn-sm btn-default"  :class="{'text-green': website.status === 'active'}">
                        <input type="radio" value="active" v-model="website.status"> Active
                    </label>
                    <label class="btn btn-sm btn-default" :class="{'text-blue': website.status === 'pending'}">
                        <input type="radio" value="pending" v-model="website.status"> Pending
                    </label>
                    <label class="btn btn-sm btn-default" :class="{'text-red': website.status === 'suspended'}">
                        <input type="radio" value="suspended" v-model="website.status"> Suspended
                    </label>
                    <label class="btn btn-sm btn-default" :class="{'text-yellow': website.status === 'in-active'}">
                        <input type="radio" value="in-active" v-model="website.status"> In-Active
                    </label>
                    <label class="btn btn-sm btn-default" :class="{'text-red': website.status === 'draft'}">
                        <input type="radio" value="draft" v-model="website.status"> Draft
                    </label>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
    export default {
        props: {
            categories: []
        },
        data() {
            return {
                website: {}
            }
        }, 
        mounted() {},
        methods: {
            submit() {
                this.$emit("submit", this.website);
            }
        },
        watch: {
            'website': function () {
                this.submit()
            },
        }
    }
</script>