import Repository from './Repository';

const resource = "/placements_groups";
export default {
    page(website_id, page=1, limit=10, search='') {
        var offset = (page - 1) * limit
        if(search) { search = `&search=${search}` }
        return Repository.get(`${resource}/?website=${website_id}&limit=${limit}&offset=${offset}${search}`);
    },
    withWebsite(id) {
        return Repository.get(`${resource}/?website=${id}&limit=1000`);
    },
    ids(ids) {
        return Repository.get(`${resource}/?ids=${ids}&limit=1000`);
    },
    get(id) {
        return Repository.get(`${resource}/${id}/`);
    },
    post(data) {
        return Repository.post(`${resource}/`, data);
    },
    update(id, data) {
        return Repository.put(`${resource}/${id}/`, data);
    },
    patch(id, data) {
        return Repository.patch(`${resource}/${id}/`, data);
    },
}