<template>
<div class="card bg-gradient" >
    <div class="card-header border-0">
        <h3 class="card-title">
            <i class="fas fa-th mr-1"></i>
            Placement: {{placement_group.name}} {{FormatName(placement_group.group_id)}}
        </h3>
        <div class="card-tools">
            <button type="button" class="btn btn-tool btn-sm" data-card-widget="collapse">
                <i class="fas fa-minus"></i>
            </button>
            <button type="button" class="btn btn-tool btn-sm" @click="close()" data-card-widget="remove">
                <i class="fas fa-times"></i>
            </button>
        </div>
    </div>
    <div class="card-body">
        <div class="row">
            <div class="col-md-7">
                <!-- text input -->
                <div class="form-group">
                <label>Name</label>
                <input type="text" class="form-control" v-model="placement_group.name">
                </div>
            </div>
            <div class="col-md-5">
                <div class="form-group">
                <label>Group</label>
                    <select class="form-control" v-model="placement_group.group_id">
                        <option v-for="group in formats" :key="group.id" :value="group.id"
                        :selected="placement_group.group_id"> {{ group.name }} </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-8">
                <div class="form-group">
                    <div class="btn-group btn-group-toggle">
                        <label class="btn btn-sm btn-default"  :class="{'text-green': placement_group.status === 'active'}">
                            <input type="radio" value="active" v-model="placement_group.status"> Active
                        </label>
                        <label class="btn btn-sm btn-default" :class="{'text-red': placement_group.status === 'suspended'}">
                            <input type="radio" value="suspended" v-model="placement_group.status"> Suspended
                        </label>
                        </div>
                    </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>

import { RepositoryFactory } from '@/services/repositories/ManagementAPI/RepositoryFactory' 
const GroupsTypesRepo = RepositoryFactory.get('groups_types')

export default {
    props: {
        formats: [],
        collect: 0
    },

    components: {},
    data() {
        return {
            placement_group: {},
            isLoading: false
        }
    }, 
    created() {
    },
    methods: {
        submit() {
            this.$emit('submit', this.placement_group);
        },
        close() {
            this.placement_group = undefined
            this.$emit('close', '')      
        },
        FormatName(format_id) {
            var format = this.formats.filter(element => { return element.id == format_id })[0]
            if(format)
                return format.name
        }
    },
    watch: {
        'collect': function () {
            this.submit()
        },
    }
}
</script>