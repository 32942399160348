<template>
<div class="card bg-gradient" >
    <div class="card-header border-0">
        <h3 class="card-title">
            <i class="fas fa-th mr-1"></i>
            Fille rate {{ current_group_rate }}
        </h3>
        <div class="card-tools">
            <button type="button" class="btn btn-tool btn-sm" data-card-widget="collapse">
                <i class="fas fa-minus"></i>
            </button>
            <button type="button" class="btn btn-tool btn-sm" @click="close()" data-card-widget="remove">
                <i class="fas fa-times"></i>
            </button>
        </div>
    </div>
    <div class="card-body">
        <div class="row">
            <input type="hidden" v-model="rate.website_id" />
            <div class="col-md-8">
                <label for="text">Group</label>
                <select class="form-control" v-model="rate.group_id">
                    <option v-for="group in formats" :value="group.id" :key="group.id"> {{ group.name }} </option>
                </select>
            </div>
            <div class="col-md-4">
                <label for="text">Rate</label>
                <input class="form-control" required="" style="text-align: center;" v-model="rate.rate" />
            </div>
        </div>
    </div>
</div>
</template>
<script>
import { RepositoryFactory } from '@/services/repositories/ManagementAPI/RepositoryFactory' 
const GroupsTypesRepo = RepositoryFactory.get('groups_types')

export default {
    props: {
        formats: [],
        collect: 0
    },
    data() {
        return {
            rate: {},
        }
    },
    created() {},
    methods: {
        submit() {
            this.$emit('submit', this.rate);
        },
        close() {
            this.rate = undefined
            this.$emit('close', '')      
        },
        FormatName(id) {
            var format = this.formats.filter(element => { return element.id == id })[0]
            if(format)
                return format.name
        },
    },
    computed: {
        current_group_rate() {
            if(this.rate.group_id === undefined)
                return ''

            if(this.rate.rate === undefined)
                return ''

            return `- Group ${this.FormatName(this.rate.group_id)}, Rate ${this.rate.rate}%`
        }
    },
    watch: {
        'collect': function () {
            this.submit()
        },
    }
}
</script>

<style>
    .modal-dialog {
        max-width: 35%;
    }
    .served_metrics {
        display: block;
        background: #f8f8f8;
        color: #666;
        border-radius: 5px;
        border: 1px solid #e0dfdf;
    }
    .served_metrics_category {
        margin-top: 10px;
        float: left;
    }
</style>