<template>
<form class="form-horizontal">
    <div class="form-group row">
    <label for="inputName" class="col-sm-2 col-form-label">Bank Name</label>
    <div class="col-sm-10">
        <input type="text" class="form-control" v-model="bank_info.bank_name" placeholder="Bank Name">
    </div>
    </div>
    <div class="form-group row">
    <label for="inputEmail" class="col-sm-2 col-form-label">Bank Address</label>
    <div class="col-sm-10">
        <textarea class="form-control" v-model="bank_info.bank_address" placeholder="Bank Address"></textarea>
    </div>
    </div>
    <div class="form-group row">
    <label for="inputName2" class="col-sm-2 col-form-label">Account Name</label>
    <div class="col-sm-10">
        <input type="text" class="form-control" v-model="bank_info.account_name" placeholder="Account Name">
    </div>
    </div>
    <div class="form-group row">
    <label for="inputExperience" class="col-sm-2 col-form-label">Account Number</label>
    <div class="col-sm-10">
        <input type="text" class="form-control" v-model="bank_info.account_number" placeholder="Account Number">
    </div>
    </div>
    <div class="form-group row">
    <label for="inputSkills" class="col-sm-2 col-form-label">Swift Code</label>
    <div class="col-sm-10">
        <input type="text" class="form-control" v-model="bank_info.swift_code" placeholder="Swift Code">
    </div>
    </div>
    <div class="form-group row">
    </div>
    <div class="form-group row">
        <div class="offset-sm-2 col-sm-10">
            <button type="submit" @click.prevent="saveBankInfo()" class="btn btn-primary">Update</button>
        </div>
    </div>
    
</form>
</template>

<script>
import { RepositoryFactory } from '@/services/repositories/AuthAPI/RepositoryFactory';
const BankRepo = RepositoryFactory.get('bank');

export default {
    components: {
    },
    props: {
        user: {
            type: Object
        },
    },
    data() {
        return {
            bank_info: {
                type: Object,
            }
        }
    },
    methods: {
        loadBankInfo: function(id) {
            BankRepo.get(id).then(result => {
                this.bank_info = result.data
            }).catch(err => {})
        },
        saveBankInfo: function() {
            BankRepo.update(this.user.id, this.bank_info).then(result => {
                this.$notify({
                    group: 'ops',
                    type: 'success',
                    title: 'Operation success',
                    text: 'Successfuly saved.'
                })
            }).catch(err => {
                this.$notify({
                    group: 'ops',
                    type: 'error',
                    title: 'Operation failed',
                    text: 'Failed to save, please try agian.'
                })
            })
        },
    },
    watch: {
        user: {
            handler: function(user) {
                if(user) {
                    this.loadBankInfo(user.id)
                }
            },
            deep: true,
            immediate: true
        }
    }
}
</script>