import Repository from '../Repository';

const resource = "/apis";
export default {
    page(type, page=1, limit=10) {
        var offset = (page - 1) * limit
        return Repository.get(`${resource}/${type}/?limit=${limit}&offset=${offset}`);
    },
    types() {
        return Repository.get(`${resource}/types/`);
    },
    type(type) {
        return Repository.get(`${resource}/${type}/`);
    },
    get(type, id) {
        return Repository.get(`${resource}/${type}/${id}/`);
    },
    post(type, data) {
        return Repository.post(`${resource}/${type}/`, data);
    },
    update(type, id, data) {
        return Repository.put(`${resource}/${type}/${id}/`, data);
    },
    patch(type, id, data) {
        return Repository.patch(`${resource}/${type}/${id}/`, data);
    },
}