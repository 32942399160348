<template>
<div class="row">
    <div class="col-md-6">
        <PlacementsGroupsDataList 
            :pg_data_list="pg_data_list"
            :placement_group="placement_group"
            :suppliers="suppliers"
            :isLoaded="isLoaded" 
            @displayReport="displayReport"
            @search="loadSearchResult"
        />

        <ul class="pagination pagination-sm m-0 float-right">
          <li class="page-item">
            <a class="page-link" href="#" @click.prevent="previousPage">Previous</a>
          </li>
          <li class="page-item">
            <a class="page-link">{{ currentPage }} / {{ pageCount }}</a>
          </li>
          <li class="page-item">
            <a class="page-link" href="#" @click.prevent="nextPage">Next</a>
          </li>
        </ul>
    </div>

    <div class="col-md-6">
        <PlacementsGroupsDataView 
            :pg_data="pg_data"
            :placement_group="placement_group_id"
            :website="website_id"
            :suppliers="suppliers"
            :groups_types="groups_types"
            :isLoading="isLoading" 
            @updateList="updateList"
            @refreshList="refreshList"
        />
    </div>

    <div class="col-md-12">
        <hr style="visibility: hidden;" />
        <StatisticsReport
            v-if="query.type"
            :name="query.name"
            :type="query.type"
            :search="query.search"
            :data="query.data"
        />
    </div>

</div>
</template>

<script>
import PlacementsGroupsDataList from '@/components/placements_groups_data/List'
import PlacementsGroupsDataView from '@/components/placements_groups_data/Placement_Group_Data'
import StatisticsReport from '@/components/analytics/Statistics'

import { RepositoryFactory } from '@/services/repositories/ManagementAPI/RepositoryFactory' 
const SuppliersRepo = RepositoryFactory.get('suppliers')
const PlacementsGroupsRepo = RepositoryFactory.get('groups')
const PlacementsGroupsDataRepo = RepositoryFactory.get('groups_data')
const GroupsTypesRepo = RepositoryFactory.get('groups_types')

export default {
    name: "Placements_Groups_Data",
    components: {
        PlacementsGroupsDataList,
        PlacementsGroupsDataView,
        StatisticsReport
    },
    data: function() {
        return {
            placement_group_id: 0,
            website_id: 0,
            placement_group: {},

            suppliers: [],
            groups_types: [],
            pg_data_list: [],
            pg_data: {
                name: "",
                metrics: [],
                placement_group_id: 0,
                website_id: 0,
                status: ""
            },
            isLoaded: false,
            isLoading: false,

            currentPage: 1,
            totalRecords: 0,
            resultsLimit: 10,
            websites: [],

            query: {
                name: "",
                type: "",
                website: 0,
                group: 0,
                search: 0
            },
            statistics_types: {
                statistics: "statistics",
                groups: "groups",
                user_group: "user_groups",
                website: "website"
            },
            serveMetricsTypes: {}
        }
    },
    methods: {
        loadSuppliers: function() {
            SuppliersRepo.page(1, 1000).then(result => {
                this.suppliers = result.data.results
            })
        },
        loadGroupsTypes: function() {
            GroupsTypesRepo.page(1, 1000).then(result => {
                this.groups_types = result.data.results
            })
        },
        loadPlacementsGroupsData: function() {
            this.isLoaded = false
            PlacementsGroupsDataRepo.page(this.placement_group_id, this.currentPage).then(result => {
                this.totalRecords = result.data.count
                this.pg_data_list = result.data.results
                this.isLoaded = true
            }).catch(err => {
                this.isLoaded = true
            })
        },
        loadPGData: function(id) {
            this.isLoading = true
            PlacementsGroupsDataRepo.get(id).then(result => {
                this.pg_data = result.data
                this.isLoading = false
            }).catch(err => {
                this.isLoading = false
            })
        },
        loadPlacementGroup: function(id) {
            PlacementsGroupsRepo.get(id).then(result => {
                this.placement_group = result.data
                this.website_id = result.data.website_id
                this.pg_data.placement_group_id = this.placement_group.id
                this.pg_data.website_id = this.placement_group.website_id
            }).catch(err => {})
        },
        loadServeMetricsTypes: function() {
            ServeMetricsRepo.serve_metric_types().then(result => {
                console.log(result.data.actions.POST.type.choices)
            }).catch(err => {});
        },

        updateList: function(pg_data) {
            this.pg_data_list.push(pg_data)
            this.$router.replace({params: {'id': pg_data.id}, query: {'placement_group': pg_data.placement_group_id}})
        },
        refreshList: function(action) {
            if(action)
                this.loadPlacementsGroupsData()
        },
        displayReport: function(pgd) {
            this.query.data = pgd.id
            this.query.name = pgd.name
            this.query.type = this.statistics_types.statistics
            this.query.search += 1
        },
        loadSearchResult(search_text) {
            PlacementsGroupsDataRepo.page(this.placement_group_id, this.currentPage, 10, search_text).then(result => {
                this.totalRecords = result.data.count
                this.pg_data_list = result.data.results
            }).catch(err => {})
        },
        nextPage: function() {
        if(this.currentPage != this.pageCount || this.currentPage > this.pageCount) {
            this.currentPage = this.currentPage + 1
            this.loadPlacementsGroupsData()
        }
        },
        previousPage: function() {
        if(this.currentPage != 1 ) {
            this.currentPage = this.currentPage - 1
            this.loadPlacementsGroupsData()
        }
    },
  },
  computed: {
    pageCount: function() {
        return Math.ceil(this.totalRecords / this.resultsLimit)
      },
    },
    watch: {
        '$route.query.placement_group': { 
            handler: function (placement_group) {
                if(typeof placement_group !== 'undefined') {
                    // check if user is not loaded 
                    // website placements_groups before
                    if(this.placement_group_id !== placement_group) {
                        // match website with passed from query
                        this.placement_group_id = placement_group

                        // load placements groups and placements groups data
                        this.loadPlacementGroup(placement_group)
                        this.loadPlacementsGroupsData(placement_group)
                        this.loadSuppliers()
                        this.loadGroupsTypes()
                    }
                } else {
                    this.$router.push({name: "websites"})
                }
            },
            deep: true,
            immediate: true
        },
        '$route.params.id': {
            handler: function (id) {
                if(typeof id !== 'undefined') {
                    // check if it exist under this list 
                    var element = this.pg_data_list.filter(element => { return element.id == id })
                    if(element.length > 0) {
                        this.loadPGData(id)
                    } else {
                        this.pg_data = {}
                    }
                }
            },
            deep: true,
            immediate: true
        },
    }
}
</script>

<style>

</style>