import Repository from './Repository';

const resource = "/permissions";
export default {
    all(limit=1000) {
        return Repository.get(`${resource}/?limit=${limit}`);
    },
    get(id) {
        return Repository.get(`${resource}/${id}/`);
    },
    update(id, data) {
        return Repository.put(`${resource}/${id}/`, data);
    },
    delete(id) {
        return Repository.delete(`${resource}/${id}/`)
    }
}