<template>
    <div class="card">
        <div class="card-header">
            <h3 class="card-title">{{ website.name }} Tags List</h3>

            <div class="card-tools">
                <div class="input-group input-group-sm" style="width: 150px;">
                    <input type="search" name="table_search" class="form-control float-right" placeholder="Search" v-model="search_text">
                    
                    <div class="input-group-append">
                        <button class="btn btn-default" @click.prevent="loadSearchResult()"><i class="fas fa-search"></i></button>
                    </div>
                </div>
            </div>
        </div>
        <!-- /.card-header -->
        <div class="card-body table-responsive p-0">
            
        <table class="table table-hover">
            <thead>
            <tr>
                <th>Name</th>
                <th>Status</th>
                <th class="text-center">Action</th>
            </tr>
            </thead>
            <tbody>
                <tr v-for="tag in tags" :key="tag.id" v-animate-css="'slideInLeft'">
                    <td>{{ tag.name }}</td>
                    <td>
                    <span class="alert alert-success btn-xs" :class="{
                        'alert-success': tag.status == 'active',
                        'alert-warning': tag.status == 'pending',
                        'alert-danger': tag.status == 'suspended'}"> {{ tag.status | capitalize }} 
                    </span>
                    </td>
                    <td class="text-center">
                    <div class="btn-group">
                        <router-link :to="{name: 'tags', params: {'id': tag.id}, query: {'website': tag.website_id} }" class="btn btn-default btn-sm">
                        <i class="fa fa-edit"></i> Edit</router-link>
                        <button class="btn btn-danger btn-sm">
                        <i class="fa fa-trash-alt"></i></button>
                    </div>
                    </td>
                </tr>
            </tbody>
        </table>

        </div>

        <div class="overlay" v-if="!isLoaded">
            <point-spinner />
        </div>

        <!-- <div class="overlay dark" v-if="hasError">
        <i class="fas fa-2x fa-exclamation-triangle"></i>
        </div> -->
    </div>
</template>

<script>
export default {
    props: {
        website: {
            required: true,
            type: Object
        },
        tags: {
          required: true,
          type: Array
        },
        isLoaded: {
          required: false,
          type: Boolean
        }
    },
    data() {
        return {
            search_text: ""
        }
    },
    methods: {
        loadSearchResult: function() {
            this.$emit('search', this.search_text)
        },
    },
    watch: {
      search_text: function() {
        this.loadSearchResult()
      }
    }
}
</script>

<style scoped>

</style>