import CategoriesRepo from './CategoriesRepo.js'
import FormatsRepo from './FormatsRepo.js'
import PlayersRepo from './PlayersRepo.js'
import PublicPlayersRepo from './PublicPlayersRepo.js'
import PlaylistsRepo from './PlaylistsRepo.js'
import TagsRepo from './TagsRepo.js'
import VideoRepo from './VideosRepo.js'
import SettingsRepo from './SettingsRepo.js'
import AdCrewSettingsRepo from './AdCrewSettingsRepo.js'
import ContentsRepo from './ContentsRepo.js'
import AdTagsRepo from './AdTagsRepo.js'
import AdScriptsRepo from './AdScriptsRepo.js'
import MonetizationRepo from './MonetizationRepo.js'
import SchedulesRepo from './SchedulesRepo.js'
import StylesRepo from './StylesRepo.js'
import BlocklistRepo from './BlocklistRepo.js'
import ContentBrowserRepo from './ContentBrowserRepo.js'

const repositories = {
    players: PlayersRepo,
    content_browser: ContentBrowserRepo,
    public_player: PublicPlayersRepo,
    settings: SettingsRepo,
    adcrew_settings: AdCrewSettingsRepo,
    playlists: PlaylistsRepo,
    categories: CategoriesRepo,
    tags: TagsRepo,
    videos: VideoRepo,
    formats: FormatsRepo,
    contents: ContentsRepo,
    adtags: AdTagsRepo,
    adscripts: AdScriptsRepo,
    monetization: MonetizationRepo,
    schedules: SchedulesRepo,
    styles: StylesRepo,
    blocklist: BlocklistRepo
}

export const RepositoryFactory = {
    get: name => repositories[name],
};

export const PlayerRepoFactory = {
    get: name => repositories[name],
};
