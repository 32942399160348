<template>
<!-- Modal -->
<div id="supplierSharesModal" class="modal fade" role="dialog">
    <div class="modal-dialog">

        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">{{ supplier.name }} - Demand Sources Shares 
                    <button class="btn btn-tool btn-sm" @click.prevent="clear()"> <i class="fa fa-plus"></i> </button>
                </h4>
                
                <button type="button" class="close" @click.prevent="close()">&times;</button>
            </div>
            <div class="modal-body">

                <!-- /.card-header -->
                <div class="row">
                    <input type="hidden" v-model="share.supplier_id" />
                    <div class="col-md-6">
                        <label for="text">Group</label>
                        <select class="form-control" v-model="share.group_id">
                            <option v-for="group in groups_types" :value="group.id" :key="group.id"> {{ group.name }} </option>
                        </select>
                    </div>
                    <div class="col-md-4">
                        <label for="text">Share</label>
                        <input class="form-control" required="" style="text-align: center;" v-model="share.share" />
                    </div>
                    <div class="col-md-2">
                        <button class="btn btn-success btn-block pull-right" style="margin-top: 32px" @click.prevent="add()">Add</button>
                    </div>
                    <div class="col-md-12">
                        <ul class="custom-select-list">
                            <li v-for="share in shares" :key="share.id" v-animate-css="'bounceInLeft'"> 
                                {{ GroupName(share.group_id) }} - 
                                {{ share.share }}%
                                <a href="#" class="float-right remove-btn" @click.prevent="deleteShare(share.id)" ><i class="fa fa-close"></i> Delete</a>
                            </li>
                        </ul>
                    </div>

                    <div class="col-md-12">
                        <br />
                        <!-- display errors and messages -->
                        <errors :errors="errors"/>
                        <messages :messages="messages" />
                    </div>
                </div>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-default" @click.prevent="close()">Close</button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { RepositoryFactory } from '@/services/repositories/ManagementAPI/RepositoryFactory' 
const GroupsTypesRepo = RepositoryFactory.get('groups_types')
const SuppliersSharesRepo = RepositoryFactory.get('suppliers_shares')

export default {
    props: {
        supplier: {
            required: true,
            type: Object,
        },
        groups_types: {
            required: true,
            type: Array
        },
        show: {
            required: true,
            type: Boolean
        }
    },
    data() {
        return {
            share: {
                supplier_id: this.supplier.id
            },
            shares: [],

            messages: [],
            errors: []
        }
    },
    created() {
    },
    methods: {
        add() {
            this.errors = []
            this.messages = []
            SuppliersSharesRepo.post(this.share).then(result => {
                this.share = result.data
                this.messages.push("Successfully added with ID: " + result.data.id)
                this.shares.push(this.share)
            }).catch(err => {
                this.errors.push(err)
                this.errors.push( err.response.data )
            })
        },
        deleteShare(id) {
            this.errors = []
            this.messages = []

            SuppliersSharesRepo.delete(id).then(result => {
                this.shares.pop({id: id})
                this.messages.push("Successfully deleted the selected group share")
            }).catch(err => {
                this.errors.push(err)
                this.errors.push( err.response.data )
            })
        },
        clear() {
            this.errors = []
            this.messages = []

            this.share = {
                supplier_id: this.supplier.id,
                group_id: 0
            }
        },
        loadShares(id) {
            SuppliersSharesRepo.all(id).then(result => {
                this.shares = result.data.results
            }).catch(err => {})
        },
        GroupName(id) {
            if(id === 0) return;
            var group = this.groups_types.filter(element => { return element.id == id })[0]
            if(group)
                return group.name
        },
        // action to parent
        close() {
            this.$emit('close', true)
        }
    },
    watch: {
        'show': {
            handler: function(show) {
                $('#supplierSharesModal').modal('toggle')
            },
            deep: true,
            immediate: true
        },
        'supplier': {
            handler: function(supplier) {
                if(typeof supplier !== 'undefined' && supplier.id) {
                    $('#supplierSharesModal').modal('toggle')
                    this.loadShares(supplier.id)
                    this.clear()
                }
            },
            deep: true,
            immediate: true
        }
    }
}
</script>

<style>
    .modal-dialog {
        max-width: 35%;
    }
    .served_metrics {
        display: block;
        background: #f8f8f8;
        color: #666;
        border-radius: 5px;
        border: 1px solid #e0dfdf;
    }
    .served_metrics_category {
        margin-top: 10px;
        float: left;
    }
</style>