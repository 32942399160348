<template>
    <div class="min-h-screen flex flex-col items-center w-full pt-10 lg:pt-16">
        <div class="-intro-x">
                <CompanyLogo />
        </div>
        <div class="-intro-x pt-10 lg:pt-16 w-2/3 sm:w-1/2 lg:w-1/2" >

            <p class="intro-x p-2 text-gray-500 text-center">Please provide your email</p>

            <form action="" method="">
                <div class="relative my-3 rounded-md">
                        <div class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 dark:bg-gray-900 dark:border-gray-900 border text-gray-600">@</div> 
                        <input type="email" v-model="user.email" class="input pl-12 w-full border" placeholder="Email">
                </div>

                <div role="alert" class="flex items-center bg-blue-500 text-white text-sm font-bold px-4 py-2" v-if="messages.length > 0" v-for="(message, key) in messages" :key="key" v-animate-css="'pulse'">
                    <p>{{ message }}</p> 
                </div>
                <div role="alert" class="" v-if="errors.length > 0" v-for="(error, key) in errors" :key="key" v-animate-css="'swing'">
                    <div class="bg-red-500 text-white font-bold rounded-t px-4 py-2">{{ error }}</div> 
                </div>

                <div class="intro-x flex flex-row my-6">
                    <button class="button button--md w-32 text-gray-700  border border-gray-300 mr-2 rounded-md" ><a class="" @click="emitValue">Return to login</a></button>
                    <button @click.prevent="reset()" class="button button--md w-32 text-white bg-blue-600 mr-2 rounded-md">Reset</button>
                    <button v-if="messages.length > 0" class="button button--md w-32 text-white bg-blue-600 rounded-md" @click.prevent="reset(true)" >Resend</button>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
import CompanyLogo from '@/components/core/CompanyLogo'

export default {
    data() {
        return {
            errors: [],
            messages: [],
            user: {
                email: null,
            }
        }
    },
    components:{
        CompanyLogo,
    }, 
    methods: {
        reset: function(resend=false) {
            this.errors = []
            this.messages = []
            var data = {}

            if(!this.user.email) {
                this.errors.push('Please provide a valid email.')
                return;
            }

            if(resend) {
                data = {'email': this.user.email, 'resend': true}
            } else {
                data = {'email': this.user.email}
            }

            this.$store.dispatch('FORGOT', data).then(result => {
                this.messages.push(result.data.message)
            }).catch(err => {
                this.errors.push(err.response.data.message)
            })
        },
        emitValue(){
            this.$emit('resetBtn',true);
        },
    },
    computed: {
    }

}
</script>
<style scoped>
@import url("https://unpkg.com/tailwindcss@^1.0/dist/tailwind.min.css");
.input {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-right: 0.75rem;
  border-radius: 0.375rem;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
input:focus{
    outline: none;
}    
</style>