<template>
<div>
    <div>
        <div class="card-header">
            <h3 class="card-title">Assignment table</h3>
        </div>
        <div class="card-body row">
            <div class="col-md-4">
                <div class="col-md-12">
                    <label for="inputName" class="col-form-label">Organization</label>
                    <select class="custom-select" v-model="assignment.organization">
                        <option selected value="">-- None --</option>
                        <option v-for="manager in organizations" :value="manager.id" :key="manager.id"> 
                            {{ manager.first_name }} {{ manager.last_name }} 
                            ({{ manager.profile.user_role | capitalizeAndReplace }}) - 
                            <i v-if="manager.is_active"> Active </i>
                            <i v-if="!manager.is_active"> Suspended </i>
                        </option>
                    </select>
                </div>
                <div class="col-md-12">
                    <label for="inputName" class="col-form-label">Ad Operation</label>
                    <select class="custom-select" v-model="assignment.ad_operation">
                        <option selected value="">-- None --</option>
                        <option v-for="manager in ad_operations" :value="manager.id" :key="manager.id"> 
                            {{ manager.first_name }} {{ manager.last_name }} 
                            ({{ manager.profile.user_role | capitalizeAndReplace }}) - 
                            <i v-if="manager.is_active"> Active </i>
                            <i v-if="!manager.is_active"> Suspended </i>
                        </option>
                    </select>
                </div>
                <div class="col-md-12">
                    <label for="inputName" class="col-form-label">Account Manager</label>
                    <select class="custom-select" v-model="assignment.account_manager">
                        <option selected value="">-- None --</option>
                        <option v-for="manager in account_managers" :value="manager.id" :key="manager.id"> 
                            {{ manager.first_name }} {{ manager.last_name }} 
                            ({{ manager.profile.user_role | capitalizeAndReplace }}) - 
                            <i v-if="manager.is_active"> Active </i>
                            <i v-if="!manager.is_active"> Suspended </i>
                        </option>
                    </select>
                </div>
                <div class="col-md-12">
                    <label for="inputName" class="col-form-label">Network</label>
                    <select class="custom-select" v-model="assignment.network">
                        <option selected value="">-- None --</option>
                        <option v-for="network in networks" :value="network.id" :key="network.id"> 
                            {{ network.first_name }}  {{ network.last_name }} -
                            <i v-if="network.is_active"> Active </i>
                            <i v-if="!network.is_active"> Suspended </i>
                        </option>
                    </select>
                </div>
                <div class="col-md-12">
                    <label for="inputName" class="col-form-label">Publisher</label>
                    <select class="custom-select" v-model="assignment.publisher">
                        <option selected value="">-- None --</option>
                        <option v-for="publisher in publishers" :value="publisher.id" :key="publisher.id"> 
                            {{ publisher.first_name }}  {{ publisher.last_name }} 
                            ({{ publisher.profile.user_role | capitalizeAndReplace }}) - 
                            <i v-if="publisher.is_active"> Active </i>
                            <i v-if="!publisher.is_active"> Suspended </i>
                        </option>
                    </select>
                </div>
                <div class="col-md-12">
                    <label for="inputName" class="col-form-label">Website</label>
                    <select class="custom-select" v-model="assignment.website">
                        <option v-for="website in websites" :value="website.id" :key="website.id"> 
                            {{ website.name }} ({{ website.category }}) - 
                            {{ website.status | capitalize }}
                        </option>
                    </select>
                </div>
                <div class="col-md-12">
                    <label for="inputName" class="col-form-label"></label>
                    <a href="#" class="btn btn-success btn-block" @click.prevent="submit()" ><i class="fa fa-plus"></i></a>
                </div>
            </div>
            <!-- START list -->
            <div class="col-md-8">
                <label class="col-form-label">
                    <span> organization </span> | <span> Ad Operation </span> | <span> Account Manager </span> | <span> Network </span> | <span> Publisher </span> | <span> Website </span>
                </label>
                <div class="col-sm-12">
                    <ul class="custom-select-list">
                        <li v-for="assignment in assignments" :key="assignment.id" v-animate-css="'bounceInLeft'"> 
                            {{ AssignedUsers(assignment.organization) }} |
                            {{ AssignedUsers(assignment.ad_operation) }} |
                            {{ AssignedUsers(assignment.account_manager) }} |
                            {{ AssignedUsers(assignment.network) }} |
                            {{ AssignedUsers(assignment.publisher) }} |
                            {{ AssignedWebsite(assignment.website).name }}
                            <a href="#" class="float-right remove-btn  col-md-1" @click.prevent="deleteRecord(assignment.id)" ><i class="fa fa-trash-alt"></i></a>
                            <a href="#" class="float-right col-md-1" @click.prevent="edit(assignment.id)" ><i class="fa fa-edit"></i></a> 
                        </li>
                    </ul>
                </div>
            </div>
            <!-- END list -->
        </div>

    </div>
</div>
</template>

<script>
import { RepositoryFactory } from '@/services/repositories/ManagementAPI/RepositoryFactory'
const AssignedRepo = RepositoryFactory.get("assign")
const WebsitesRepo = RepositoryFactory.get("websites")

export default {
    props: {
        user: {
            type: Object,
            required: true,
            default: () => ({})
        },
        users: {
            type: Array,
            required: true,
        }
    },
    data() {
        return {
            websites: [],
            assignment: {
                organization: null,
                ad_operation: null,
                account_manager: null,
                network: null,
                publisher: null,
                website: null
            },
            assignments: []
        }
    },
    mounted() {
        this.loadWebsite()
    },
    methods: {
        edit: function(id) {
            this.assignment = this.assignments.filter(element => { return element.id === id})[0]
            $('.select2bs4').select2().trigger('change');
        },
        submit() {
            if(this.assignment.id) {
                this.update(this.assignment)
            } else {
                this.add(this.assignment)
            }
        },
        add: function(assignment) {
            AssignedRepo.post(assignment).then(result => {
                this.$notify({ 
                    group: 'ops',
                    type: 'success',
                    text: 'Created Successfully with ID: ' + result.data.id,
                })
                this.assignments.push(result.data)
            }).catch(err => {
                this.$notify({ 
                    group: 'ops',
                    type: 'error',
                    title: 'Faild to create record',
                    text: err.response.data
                })
            })
        },
        update: function(assignment) {
            AssignedRepo.update(assignment.id, assignment).then(result => {
                this.assignments.pop({id: assignment.id})
                this.assignments.push(assignment)
                this.$notify({ 
                    group: 'ops',
                    type: 'success',
                    text: 'Update Successfully' 
                })
            }).catch(err => {
                this.$notify({ 
                    group: 'ops',
                    type: 'error',
                    title: 'Faild to update record',
                    text: err.response.data
                })
            })
        },
        deleteRecord: function(id) {
            AssignedRepo.delete(id).then(result => {
                this.assignments.pop({id: id})
                this.$notify({ 
                    group: 'ops',
                    type: 'success',
                    text: 'Successfuly deleted the record with ID: ' + id
                })
            }).catch(err => {
                this.$notify({ 
                    group: 'ops',
                    type: 'error',
                    text: 'Faild to delete record' 
                })
            })
        },
        loadUserAssignments: function(user_id, user_role) {
            AssignedRepo.filter(user_id, user_role).then(result => {
                this.assignments = result.data.results
            })
        },
        loadWebsite: function() {
            WebsitesRepo.all().then(result => {
                this.websites = result.data.results
            }).catch(err => {})
        },
        AssignedWebsite: function(website_id) {
            var website = {
                name: 'None',
            }

            if(website_id != 0 || website_id != null) {
                website = this.websites.filter(element => { return element.id == website_id })[0]
            }

            if(website == undefined) {
                return { name: 'None', }
            }

            return website
        },
        AssignedUsers: function(user_id) {
            var user = this.users.filter(user => { return user.id === user_id })[0]
            if(user) {
                var full_name =  user.first_name + " " + user.last_name
                return full_name
            }
            return "None"
        },

    },
    computed: {
        networks: function() {
            return this.users.filter(element => { 
                return element.profile.user_role === "network" 
            })
        },
        publishers: function() {
            return this.users.filter(element => { 
                return element.profile.user_role === "publisher" || element.profile.user_role === "pre_activation" 
            })
        },
        ad_operations: function() {
            return this.users.filter(element => { 
                return element.profile.user_role === "ad_operation"
            })
        },
        account_managers: function() {
            return this.users.filter(element => { 
                return element.profile.user_role === "account_manager"
            })
        },
        organization: function() {
            return this.users.filter(element => { 
                return element.profile.user_role === "organization"
            })
        }
    },
    watch: {
        user: {
            handler: function(user) {
                if(user.id) {
                    this.loadUserAssignments(user.id, user.profile.user_role)
                }

                if(user.profile.user_role === 'admin' || 
                user.profile.user_role === 'ad_operation' || 
                user.profile.user_role == 'account_manager') {
                        this.assignment['manager'] = user.id
                }

                if(user.profile.user_role === 'network') {
                    this.assignment['network'] = user.id
                }

                if(user.profile.user_role === 'publisher' || 
                user.profile.user_role === 'pre_activation') {
                    this.assignment['publisher'] = user.id
                }
            },
            deep: true,
            immediate: true,
        }
    }
}
</script>