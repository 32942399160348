<template>
    <div class="card">
        <div class="card-header">

          <h3 class="card-title">
            Search List
            <button class="btn btn-tool btn-sm" @click.prevent="reload()">
                <i :class="{'fa-spin': !isLoaded}" class="fas fa-sync" aria-hidden="true"></i>
            </button>
          </h3>
          <div class="card-tools">
            <div class="input-group input-group-sm" style="width: 150px;">
                <input type="search" name="table_search" class="form-control float-right" placeholder="Search" v-model="search_text">
                <div class="input-group-append">
                  <button class="btn btn-default" @click.prevent="loadSearchResult()"><i class="fas fa-search"></i></button>
                </div>
              </div>
          </div>
          
        </div>
        <!-- /.card-header -->
        <div class="card-body table-responsive p-0">
          <ul class="nav nav-pills flex-column">
            <li class="nav-item active" v-if="players.length == 0">
              <a href="#" class="nav-link">
                No Available players
              </a>
            </li>
          </ul>
              
          <table class="table table-hover responsive">
              <thead>
              <tr>
                  <th>ID</th>
                  <th>Player</th>
                  <th>Desktop Size | Mobile Size</th>
                  <th>Format</th>
                  <th class="text-center">Status</th>
                  <th></th>
              </tr>
              </thead>
              <tbody>
                  <tr v-for="player in players" :key="player.id" v-animate-css="'slideInLeft'">
                    <td>{{ player.id }}</td>
                    <td>{{ player.name }}</td>
                    <td>
                      {{ player.desktop.width }} × {{ player.desktop.height }} | {{ player.mobile.width }} × {{ player.mobile.height }}
                    </td>
                    <td>{{ player.format | capitalize }}</td>
                    <td class="text-center">
                      <span class="alert alert-success btn-xs" :class="{
                          'alert-success': player.status == 'active',
                          'alert-warning': player.status == 'pending',
                          'alert-danger': player.status == 'suspended'}"> {{ player.status | capitalize }} 
                      </span>
                    </td>
                    <td class="text-right">
                       <div class="dropdown ">
                        <button class="btn btn-tool dropdown-toggle" type="button" 
                        id="dropdownMenuButton" 
                        data-toggle="dropdown" 
                        aria-haspopup="true" 
                        aria-expanded="false"
                        >
                          <i class="fas fa-ellipsis-v"></i>
                        </button>
                        <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                          <li class="dropdown-item">
                            <router-link :to="{name: 'players', params: {'id': player.id}, query: {'website': player.website_id}}" class="btn-block">
                              <i class="fa fa-edit mr-2"></i>Edit
                            </router-link>
                          </li>
                          <li class="dropdown-item">
                            <a @click="showEmbedCodeModel(player)" class="btn-block">
                            <i class="fas fa-code mr-2"></i>Embed</a>
                          </li>
                          <li class="dropdown-item">
                            <router-link :to="{name: 'analytics', query: {'player': player.id} }" class="btn-block">
                              <i class="fas fa-chart-line mr-2"></i>Analytics
                            </router-link>
                          </li>   
                          <li class="dropdown-item">
                            <a class="btn-block" @click.prevent="copyLink(player.id)">
                              <i class="fas fa-link mr-2"></i>Copy link
                            </a>
                          </li>   
                          <li class="dropdown-item">
                            <a class="btn-block" @click.prevent="clearCacheForPlayer(player.id)">
                              <i class="fas fa-eraser mr-2"></i>Clear cache
                            </a>
                          </li>   
                        </ul>        
                      </div>
                    </td>
                  </tr>
              </tbody>
          </table>
        </div>

        <embed-code-model :player=selectedPlayer @closed=closeEmbedCodeModel />

        <div class="overlay" v-if="!isLoaded">
          <point-spinner />
        </div>
    </div>
</template>
<script>
import EmbedCodeModel from './embed/Code.vue'
import { RepositoryFactory } from '@/services/repositories/ManagementAPI/RepositoryFactory'
const PurgeCacheRepo = RepositoryFactory.get('purge_cache')

export default {
    props: {
        players: {
          required: true,
          type: Array
        },
        isLoaded: {
          required: false,
          type: Boolean
        }
    },
    components: {
      EmbedCodeModel
    },
    data() {
        return {
            search_text: "",
            selectedPlayer: {}
        }
    },
    mounted(){
    },
    methods: {
      loadSearchResult() {
        this.$emit("search", this.search_text)
      },
      reload() {
        this.$emit("reload")
      },
      showEmbedCodeModel(player) {
        this.selectedPlayer = player
        $(`#embedCodeModal`).modal()
      },
      closeEmbedCodeModel() {},
      clearCacheForPlayer(player_id) {
        var link = `https://services.adcrew.co/api/v1/players/public/${player_id}/`
        PurgeCacheRepo.purge(link).then(result => {
          this.$notify({
              group: 'ops',
              type: 'success',
              title: 'Operation success',
              text: 'Successfully cleared cache.'
          })
        }).catch(error => {
            this.$notify({
                group: 'ops',
                type: 'error',
                title: 'Operation Faild',
                text: 'Faild to clear cache.'
            })
        });
      },
      copyLink(player_id) {
        var link = `https://services.adcrew.co/api/v1/players/public/${player_id}/`
        var testingCodeToCopy = document.createElement("textarea")
        testingCodeToCopy.value = link
        // Make sure we cant change the text of the textarea
        testingCodeToCopy.setAttribute('readonly', '');
        
        // Hide the textarea off the screnn
        testingCodeToCopy.style.position = 'absolute';
        testingCodeToCopy.style.left = '-9999px';
        
        // Add the textarea to the page
        document.body.appendChild(testingCodeToCopy);

        testingCodeToCopy.select()
        try {
            document.execCommand('copy')
            this.$notify({
                group: "notifications",
                type: 'success',
                title: "Clipboard",
                text: "Link Copied to clipboard"
            })
        } catch {
            this.$notify({
                group: "notifications",
                type: 'warn',
                title: "Clipboard",
                text: "Failed to copy link"
            })
        }
        testingCodeToCopy.remove()
      },
    },
    watch: {
        search_text: function() {
            this.loadSearchResult()
        },
        '$route.query.website': { 
          handler: function (website) {
              if(typeof website !== 'undefined') {
                  this.$emit("reload")
              }
          },
          deep: true,
          immediate: true
      },
    }
}
</script>
<style scoped>
.table-responsive{
  overflow : visible;
}
.dropdown-toggle::after {
  display: none;
}
a {
  cursor: pointer;
}
a:hover {
  color: #0056b3!important;
}
</style>