<template>
<div class="card col-md-6 col-sm-12 offset-md-3">
    <div class="card-body register-card-body">
    <h1>New user</h1>

    <form action="" method="post">
        <div class="row mb-3">
            <div class="col-6">
                <input type="text" v-model="user.first_name" class="form-control" placeholder="First name">
            </div>
            <div class="col-6">
                <input type="text" v-model="user.last_name" class="form-control" placeholder="Last name">
            </div>
        </div>
        <div class="input-group mb-3">
            <input type="username" v-model="user.username" class="form-control" placeholder="Username">
            <div class="input-group-append">
                <div class="input-group-text">
                <span class="fas fa-user"></span>
                </div>
            </div>
        </div>
        <div class="input-group mb-3">
            <input type="email" v-model="user.email" class="form-control" placeholder="Email">
            <div class="input-group-append">
                <div class="input-group-text">
                <span class="fas fa-envelope"></span>
                </div>
            </div>
        </div>
        <div class="input-group mb-3">
            <input type="password" v-model="user.password" class="form-control" placeholder="Password">
            <div class="input-group-append">
                <div class="input-group-text">
                <span class="fas fa-lock"></span>
                </div>
            </div>
        </div>
        <div class="input-group mb-3">
            <input type="password" v-model="user.password_confirm" class="form-control" placeholder="Retype password">
            <div class="input-group-append">
                <div class="input-group-text">
                <span class="fas fa-lock"></span>
                </div>
            </div>
        </div>
        <div class="input-group mb-3">
            <input type="phone" v-model="user.profile.phone_number" class="form-control" placeholder="Phone">
            <div class="input-group-append">
                <div class="input-group-text">
                <span class="fas fa-phone"></span>
                </div>
            </div>
        </div>

        <div class="input-group mb-3">
            <label for="inputName" class="col-12">Role</label>
            <select class="custom-select" v-model="user.profile.user_role">
                <option v-for="(role , key) in roles" :key="key" 
                :value="key"> {{ role }} </option>
            </select>
        </div>
        <div class="input-group mb-3">
            <label for="inputName" class="col-12">Groups</label>
            <select multiple class="custom-select" style="height: 250px;" v-model="user.profile.groups">
                <option v-for="group in groups" :key="group.id" :value="group.id"> {{ group.name }} </option>
            </select>
        </div>
        
        <div class="row">
        <div class="col-md-8">
            <div class="form-group">
                <div class="btn-group btn-group-toggle">
                    <label class="btn btn-sm btn-default"  :class="{'text-green': user.is_active}">
                        <input type="radio" value="true" v-model="user.is_active"> Active
                    </label>
                    <label class="btn btn-sm btn-default" :class="{'text-red': !user.is_active}">
                        <input type="radio" value="false" v-model="user.is_active"> Suspended
                    </label>
                    </div>
                </div>
        </div>
        <div class="col-md-4 col-sm-12">
            <button @click.prevent="add()" class="btn btn-primary btn-block">Add</button>
        </div>
        <!-- /.col -->
        </div>
    </form>
    </div>
    <!-- /.form-box -->
</div><!-- /.card -->

</template>

<script>
import { RepositoryFactory } from '@/services/repositories/AuthAPI/RepositoryFactory'
const UsersRepo = RepositoryFactory.get('users')
const GroupsRepo = RepositoryFactory.get('groups');

export default {
    components: {
    },
    data() {
        return {
            user_role: '',
            roles: {
                'admin': 'Admin',
                'organization': 'Organization',
                'developer': 'Developer',
                'account_manager': 'Account Manager',
                'ad_operation': 'Ad operation',
                'network': 'Network',
                'publisher': 'Publisher',
                'pre_activation': 'Pre Activation',
            },
            selectedRole: '',
            groups: [],

            user: {
                profile: {

                }
            },
            messages: [],
            errors: [],
        }
    },
    created() {
        this.getGroups()
    },
    methods: {
        clear: function() {
            this.user = {}
        },
        getGroups: function() {
            GroupsRepo.all().then(result => {
                this.groups = result.data.results
            })
        },
        add: function() {
            this.errors = []
            this.messages = []
            this.$notify({
                group: 'ops',
                clean: true,
            })

            UsersRepo.post(this.user).then(result => {
                this.$notify({
                    group: 'ops',
                    type: 'success',
                    title: 'Operations Success',
                    text: 'User created successful.'
                })

                this.$router.push( {name: 'user', params: {id: result.data.id}} )
            }).catch(err => {
                this.$notify({
                    group: 'ops',
                    type: 'error',
                    title: 'Operations faild',
                    text: err
                })
                this.errors.push( err.response.data )
            })
        },
    },
    computed: {

    },
    watch: {

    }
}
</script>

<style scoped>

</style>