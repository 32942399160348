<template>
    <!-- Modal -->
    <div class="modal fade" id="logoBrowserModal" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-lg" style="max-width: 60%;" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel"> View available logos</h5>
                    <button type="button" class="close" aria-label="Close" data-dismiss="modal" @click.prevent="closeModel()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div>
                        <div class="btn-group w-100 mb-2">
                            <a class="btn btn-info active" href="javascript:void(0)" data-filter="all"> All items </a>
                            <a class="btn btn-info" href="javascript:void(0)" data-filter="1"> Whitelabel </a>
                            <a class="btn btn-info" href="javascript:void(0)" data-filter="2"> Player Assets </a>
                        </div>
                    </div>
                    <div>
                        <div class="filter-container p-0 row">
                            <div v-for="logo, index in player_assets" v-if="index != 0" @click="setPlayerLogoAsset(logo.key)" class="logo-standerd filtr-item col-sm-2 flex w-full justify-center items-center hover:bg-gray-200 cursor-pointer" data-category="1">
                                <a data-toggle="lightbox" :data-title="logo.key">
                                    <img :src="logo.url" class="img-fluid mb-2" :alt="logo.key"/>
                                </a>
                            </div>
                            <div v-for="logo, index in whitelabel_assets" v-if="index != 0" @click="setPlayerLogoAsset(logo.key)" class="logo-standerd filtr-item col-sm-2 flex w-full justify-center items-center hover:bg-gray-200 cursor-pointer" data-category="2">
                                <a data-toggle="lightbox" :data-title="logo.key">
                                    <img :src="logo.url" class="img-fluid mb-2" :alt="logo.key"/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { RepositoryFactory } from '@/services/repositories/PlayerAPI/RepositoryFactory'
    const ContentBrowserRepo = RepositoryFactory.get('content_browser')

    export default {
        props: {
            player_id: {
                type: Number
            }
        },
        data () {
            return {
                player_assets: [],
                whitelabel_assets: [],
            }
        },
        mounted() {
            this.listLogos()
        },
        methods: {
            closeModel: function() {
                this.$emit("closed")
            },
            // copyToClip(containerid) {
            //     let node = document.getElementById(containerid)
            //     let copiedScript = ''
            //     node.childNodes.forEach((child)=> {
            //         copiedScript += child.innerText
            //     })
            //     navigator.clipboard.writeText(copiedScript).then(function() {
            //         document.getElementById('codeCopied').style.display = 'block'
            //         setTimeout(()=>{
            //             document.getElementById('codeCopied').style.display =  null
            //         },1500)
            //     }, function(err) {
            //         console.error('Async: Could not copy text: ', err);
            //     });
            // },
            listLogos() {
                ContentBrowserRepo.getLogos().then(result => {
                    this.whitelabel_assets = result.data['whitelabel']
                    this.player_assets = result.data['player_assets']
                })
            },
            setPlayerLogoAsset(key) {
                ContentBrowserRepo.changeLogo({key: key, player_id: this.player_id}).then( () => {
                    this.$notify({
                        group: 'ops',
                        type: 'success',
                        title: 'Operation success',
                        text: 'Successfully updated the player logo.'
                    })
                }).catch(() => {
                    this.$notify({
                        group: 'ops',
                        type: 'error',
                        title: 'Operation failed',
                        text: 'Failed to update the player logo.'
                    })
                })
                this.closeModel()
            },
            reloadLogo() {
                this.$emit("logo_updated")
            }
        },
        computed: {
        }
    }
    </script>
    <style scoped>
    @media (min-width: 301px) {
        .modal-lg, .modal-xl {
            max-width: 800px;
        }
    }
    .tag {
        color: #008;
        font-family: monospace;
    }
    .attr-name , .attr-value , .text {
        font-family: monospace;
    }
    .attr-name {
        color: #606;
    } 
    .attr-value {
        color: #080;
    }
    .opacity-trans {
        -webkit-animation: fadeIn 1500ms ;
        -moz-animation: fadeIn 1500ms ;
        animation: fadeIn 1500ms ;
    }
    @-moz-keyframes fadeIn {
        from {
            opacity:0;
        }
        to {
            opacity:1;
        }
    }
    @-webkit-keyframes fadeIn {
        from {
            opacity:0;
        }
        to {
            opacity:1;
        }
    }
    @keyframes fadeIn {
        from {
            opacity:0;
        }
        to {
            opacity:1;
        }
    } 

    .logo-standerd:hover {
        box-shadow: 0 3px 4px rgba(0,0,0,.16),0 3px 6px rgba(0,0,0,.23)!important;    
    }

    </style>