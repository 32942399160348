<template>
    <div class="card">
        <div class="card-header">
            <h3 class="card-title">Metrics List</h3>

            <div class="card-tools">
                <div class="input-group input-group-sm" style="width: 150px;">
                    <input type="search" name="table_search" class="form-control float-right" placeholder="Search">
                    <div class="input-group-append">
                        <button type="submit" class="btn btn-default"><i class="fas fa-search"></i></button>
                    </div>
                </div>
            </div>
        </div>
        <!-- /.card-header -->
        <div class="card-body table-responsive p-0">
            
        <table class="table table-hover">
            <thead>
            <tr>
                <th>Type</th>
                <th>Name</th>
                <th>Shortname</th>
                <th>Status</th>
                <th class="text-center">Action</th>
            </tr>
            </thead>
            <tbody>
                <tr v-for="metric in metrics" :key="metric.id" v-animate-css="'slideInLeft'">
                    <td>{{ metric.type }}</td>
                    <td>{{ metric.name }}</td>
                    <td>{{ metric.shortname }}</td>
                    <td>
                    <span class="alert alert-success btn-xs" :class="{
                        'alert-success': metric.status == 'active',
                        'alert-warning': metric.status == 'pending',
                        'alert-danger': metric.status == 'suspended'}"> {{ metric.status | capitalize }} 
                    </span>
                    </td>
                    <td class="text-center">
                    <div class="btn-group">
                        <router-link :to="{name: 'metrics', params: {'id': metric.id} }" class="btn btn-default btn-sm">
                        <i class="fa fa-edit"></i> Edit</router-link>
                    </div>
                    </td>
                </tr>
            </tbody>
        </table>

        </div>

        <div class="overlay" v-if="!isLoaded">
            <point-spinner />
        </div>

        <!-- <div class="overlay dark" v-if="hasError">
        <i class="fas fa-2x fa-exclamation-triangle"></i>
        </div> -->
    </div>
</template>

<script>
export default {
    props: {
        metrics: {
          required: true,
          type: Array
        },
        isLoaded: {
          required: false,
          type: Boolean
        }
    },
}
</script>

<style scoped>

</style>