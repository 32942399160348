<template>
    <div>
        <div class="card">
            <div class="card-header">
                <div class="float-right">
                    <button @click.prevent="$emit('save-playlist' , playList)" class="btn btn-primary  m-2">Save playlist</button>
                    <button @click.prevent="cancelPlayList()" class="btn btn-light m-2"><i class="text-muted text-md ml-auto fas fa-times"></i></button>
                </div>      
            </div>
            <hr class="" />
            <div class="card-body">
                <div class="mb-8 d-flex justify-content-start align-items-baseline input-group">
                    <div :class="{'w-full': displayForm , 'w-75': !displayForm}">
                        <div class="">
                            <input  v-model="playList.name" placeholder="Enter the name of the playlist" class="my-2 text-lg form-control" />
                            <div class="">
                                <label class="" for="Tags"></label>
                                <textarea placeholder="write tags of playlist here seprated by space" type="text" class="my-2 text-lg form-control " id="Tags" v-model="playList.tags" />
                            </div>
                        </div>
                    </div>
                </div>   
                <div class="container pt-2">
                    <h3 class="my-4 font-weight-bold text-danger" >*you can check the order and arrange videos before saving</h3>
                    <div class="row">
                        <div :class="{'col-md-4': displayForm , 'col-md-2': !displayForm}" v-for="(video, index) in playList.videos" :key="video.id" class="">
                            <div class="card cursor-pointer" @dragstart="drag($event ,index)" @drop="drop($event , index)" draggable="true" @dragover="$event.preventDefault()">
                                <!--card top icons-->
                                <div class="d-flex justify-content-between m-2">
                                    <i @click.prevent="removeVideo(index)" class="text-muted text-md ml-auto fas fa-times"></i>
                                </div>
                                <!--card top icons-->
                                <!--card body-->
                                <div class="flex justify-content-center align-items-center h-56 m-auto px-4 overflow-hidden">
                                    <img :src="video.poster" class="rounded-md shadow-md">
                                </div>
                                <div class="m-auto text-dark text-md font-weight-normal ">{{video.name}}</div>
                                <div class="m-auto text-muted text-sm pb-2">{{video.size}} KB</div>
                                <!--card body-->
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
        </div>
    </div>
</template>
<script>
export default {
    props: {
        displayForm: {
            type: Boolean,
            required: true
        },
        playList: {
            type: Object,
            required: true
        },
    },
    methods: {
        drag(e , index) {
            e.dataTransfer.setData("dragindex", index);
        },
        drop(e , index) {
            e.preventDefault();
            let dragIndex = e.dataTransfer.getData("dragindex");           
            this.playList.videos[parseInt(index)] = this.playList.videos.splice(parseInt(dragIndex), 1, this.playList.videos[parseInt(index)])[0];
        },
        cancelPlayList() {
            let reply =  confirm('Are You Sure ??')
            if(reply) {
                this.$emit('cancel-playlist' , this.playList)
            }    
        },
        removeVideo(index) {
            this.playList.videos.splice(index,1)
        }
    },
}
</script>