<template>
<div class="row tile_count col-md-12">
    <div class="col-md-3 col-sm-3 col-xs-6 tile_stats_count">
        <div class="count" style="font-size: 30px;">Yesterday</div>
    </div>
    <div class="col-md-3 col-sm-3 col-xs-6 tile_stats_count">
        <div class="count" style="font-size: 30px;">
            <span v-if="!statistics.impressions">-</span>{{ statistics.impressions | withComma }}
        </div>
    </div>
    <div class="col-md-3 col-sm-3 col-xs-6 tile_stats_count">
        <div class="count" style="font-size: 30px;">
            <span v-if="statistics.revenue">$</span>{{ statistics.revenue || '-'}}
        </div>
    </div>
    <div class="col-md-3 col-sm-3 col-xs-6 tile_stats_count">
        <div class="count" style="font-size: 30px;">
            <span v-if="statistics.cpm">$</span>{{ statistics.cpm || '-'}}
        </div>
    </div>
</div>
</template>
<script>
import statisticsToGraphData from './data'

export default {
    name: "Yesterday",
    props: {
        data: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            statistics: statisticsToGraphData(this.data, false, true)
        }
    },
    mounted() {

    },
    watch: {
        data: function() {
            this.statistics = statisticsToGraphData(this.data, false, true)
        }
    }
    
}
</script>
<style scoped>
.tile_count {
    margin-bottom: 20px;
    margin-top: 20px;
}
</style>