<template>
<div>
    <div class="">
        <div class="card-body">
            <div v-if="!additional">
                <span>No additional data available for the current user.</span>
            </div>
            <div v-if="additional">
                <label>Company Name</label><br />
                <span>{{ additional.company_name }}</span>
                <br /><br />

                <label>Company URL</label><br />
                <span>{{ additional.company_url }}</span>
                <br /><br />

                <label>Type Of Membership</label><br />
                <span>{{ additional.type_of_membership }}</span>
                <br /><br />

                <label>Interested in</label><br />
                <span>{{ additional.interested_in }}</span>
                <br /><br />

                <label>Websites</label><br />
                <span>{{ additional.websites }}</span>
                <br /><br />
            </div>
        </div>
    </div>
</div>
</template>
<script>
import { RepositoryFactory } from '@/services/repositories/AuthAPI/RepositoryFactory'
const LogsRepo = RepositoryFactory.get("logs")

export default {
    props: {
        user: {
            type: Object,
        }
    },
    data() {
        return {
            additional: [],
            isLoading: false,
        }
    },
    methods: {
    },
    computed: {
    },
    watch: {
        "user": {
            handler: function (user) {
                if(typeof user !== 'undefined' && user.profile.additional !== 'undefined' && user.profile.additional !== '') {
                    this.additional = JSON.parse(user.profile.additional)
                }
            },
            deep: true,
            immediate: true
        },
    }

}
</script>
<style>
.header-color {
    background-color: #f8f8f8;
}
</style>