<template>
      <!-- Main Sidebar Container -->
  <aside class="main-sidebar sidebar-dark-primary elevation-4" v-animate-css="'slideInLeft'">
    <!-- Brand Logo -->
    <a href="#" class="brand-link" style="height: 100%;">
      <CompanyLogo position="sidebar" />
      <span class="brand-text font-weight-light"></span>
    </a>

    <!-- Sidebar -->
    <div class="sidebar">
      <!-- Sidebar user panel (optional) -->
      <div class="user-panel mt-3 pb-3 mb-3 d-flex">
        <div class="image">
          <img :src="user.profile_image || '/assets/img/AdminLTELogo.png'" class="img-circle elevation-2" alt="User Image">
        </div>
        <div class="info">
          <router-link :to="{name: 'profile'}" class="d-block">{{ userFullName }} <span class="alert btn-xs pull-left" :class="user.is_verified? 'alert-success' : 'alert-danger'"> {{ verifiedUser }} </span> </router-link>
          <a href="#" class="pull-right" @click="logout()">Sign out <i class="fa fa-sign-out-alt" aria-hidden="true"></i></a>
        </div>
      </div>

      <!-- Sidebar Menu -->
      <nav class="mt-2">
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
          <!-- Add icons to the links using the .nav-icon class
               with font-awesome or any other icon font library -->
          <li class="nav-item">
            <router-link :to="{ name: 'dashboard'}" exact-active-class="active" class="nav-link">
              <i class="nav-icon fas fa-tachometer-alt"></i>
              <p>
                Dashboard
              </p>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'search'}" active-class="active" class="nav-link">
              <i class="nav-icon fas fa-search"></i>
              <p>
                Search
              </p>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'reports'}" active-class="active" class="nav-link">
              <i class="nav-icon fas fa-file"></i>
              <p>
                Reports
              </p>
            </router-link>
          </li>
          <!-- <li class="nav-item">
            <router-link :to="{ name: 'messages'}" active-class="active" class="nav-link">
              <i class="nav-icon fas fa-envelope"></i>
              <p>
                Messages
              </p>
            </router-link>
          </li> -->

          <li class="nav-item has-treeview">
            <a href="#" class="nav-link">
              <i class="nav-icon fas fa-globe"></i>
              <p>
                Websites
                <i class="fas fa-angle-left right"></i>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li class="nav-item">
              <router-link :to="{ name: 'websites'}" active-class="active" class="nav-link">
                <i class="nav-icon far fa-circle"></i>
                <p>
                  List
                </p>
              </router-link>
              </li>
              <li class="nav-item">
              <router-link :to="{ name: 'groups_types'}" active-class="active" class="nav-link">
                <i class="nav-icon far fa-circle"></i>
                <p>
                  Groups Types
                </p>
              </router-link>
              </li>
            </ul>
          </li>
          
          <li v-if="isAdmin" class="nav-item">
            <router-link :to="{ name: 'players', query: {website: ''}}" active-class="active" class="nav-link">
              <i class="nav-icon fas fa-play-circle"></i>
              <p>
                Players
              </p>
            </router-link>
          </li>
          
          <li class="nav-item">
            <router-link :to="{ name: 'users'}" active-class="active" class="nav-link">
              <i class="nav-icon fas fa-users"></i>
              <p>
                Users
              </p>
            </router-link>
          </li>
          <li class="nav-item has-treeview">
            <router-link :to="{ name: 'demand_sources'}" active-class="active" class="nav-link">
              <i class="nav-icon fas fa-truck"></i>
              <p>
                Demand Sources
              </p>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'Whitelabel'}" active-class="active" class="nav-link">
              <i class="nav-icon fas fa-clipboard-list"></i>
              <p>
                Whitelabel
              </p>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'payments'}" active-class="active" class="nav-link">
              <i class="nav-icon fas fa-wallet"></i>
              <p>
                Payments
              </p>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'profile'}" active-class="active" class="nav-link">
              <i class="nav-icon fas fa-user"></i>
              <p>
                Profile
              </p>
            </router-link>
          </li>
          
          <li class="nav-item has-treeview">
            <a href="#" class="nav-link">
              <i class="nav-icon fas fa-lock"></i>
              <p>
                Credentials
                <i class="fas fa-angle-left right"></i>
              </p>
            </a>
            <ul v-if="isAdmin" class="nav nav-treeview">
              <li class="nav-item">
                <router-link :to="{ name: 'groups_credentails'}" active-class="active" class="nav-link">
                  <i class="nav-icon far fa-circle"></i>
                  <p>
                    Groups
                  </p>
                </router-link>
              </li>
              <!-- <li class="nav-item">
                <router-link :to="{ name: 'assignments'}" exact-active-class="active" class="nav-link">
                  <i class="nav-icon far fa-circle"></i>
                  <p>
                    Assignments
                  </p>
                </router-link>
              </li> -->
              <li class="nav-item">
                <router-link :to="{ name: 'api_keys'}" active-class="active" class="nav-link">
                  <i class="nav-icon far fa-circle"></i>
                  <p>
                    API's
                  </p>
                </router-link>
              </li>
            </ul>
          </li>

          <li class="nav-item has-treeview">
            <a href="#" class="nav-link">
              <i class="nav-icon fas fa-cog"></i>
              <p>
                Settings
                <i class="fas fa-angle-left right"></i>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li class="nav-item">
              <router-link :to="{ name: 'metrics'}" active-class="active" class="nav-link">
                <i class="nav-icon far fa-circle"></i>
                <p>
                  Metrics
                </p>
              </router-link>
              </li>
              <li class="nav-item">
              <router-link :to="{ name: 'api'}" active-class="active" class="nav-link">
                <i class="nav-icon far fa-circle"></i>
                <p>
                  External API's
                </p>
              </router-link>
              </li>
            </ul>
          </li>

        </ul>
      </nav>
      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
  </aside>

</template>
<script>
import CompanyLogo from '@/components/core/CompanyLogo'

export default {
  name: 'Sidebar',
  cache: false,
  components: {
    CompanyLogo
  },
  props: {
    company_logo: {
      type: String,
    },
    user: {
      type: Object,
    },
  },
  created() {

  },
  methods: {
    logout() {
      if (event) event.preventDefault()
      this.$store.dispatch('AUTH_LOGOUT').then(() => {
        this.$router.push('/login')
      })
    }
  }, 
  computed: {
    userFullName() {
      return this.user.first_name + " " + this.user.last_name
    },
    verifiedUser() {
      if(this.user.is_verified) {
        return 'Verified'
      } else {
        return 'Not Verified'
      }
    },
    isOrganization() {
      if(this.user.role === 'organization') {
        return true
      }

      return false
    },
    isAdmin() {
      if(this.user.role === 'admin') {
        return true
      }

      return false
    },
  }
}
</script>

<style scoped>
.nav-treeview{
  /* margin-left: 15px; */
}
</style>