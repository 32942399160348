<template>
    <div class="card" v-animate-css="'slideInDown'">
        <div class="card-header">
        <h3 class="card-title">
            {{ website.name }} Statistics 
            <a href="" :class="{'fa-spin': isLoading}" @click.prevent="loadWebsiteStatistics()"><i class="fas fa-sync" aria-hidden="true"></i></a>
        </h3>

        <div class="card-tools">
            <div class="btn-group">
                <button class="btn btn-success active">Website</button>
                <button class="btn btn-success">Groups</button>
            </div>
        </div>
        </div>
        <!-- /.card-header -->
        <div class="card-body table-responsive p-0">
        <table class="table table-hover">
            <thead>
            <tr>
                <th class="text-center">Date</th>
                <th class="text-center" v-for="metric in metrics" :key="metric"> {{ metric | capitalizeAndReplace }} </th>
                <th class="text-center">Complete</th>
            </tr>
            </thead>
            <tbody>
                <tr v-for="statistic in statistics" :key="statistic.id">
                    <td class="text-center">{{ statistic.date }}</td>
                    <td class="text-center" v-for="metric in metrics" :key="metric"> {{ signs[metric] }}{{ statistic[metric] }}  </td>
                    <td class="text-center">
                        <span data-toggle="tooltip" title="" data-original-title="Final Report">
                            <i class="fa fa-2x" :class="{'fa-check-circle text-success': statistic.complete, 'fa-info-circle text-warning': !statistic.complete}" ></i>
                        </span>                    
                    </td>
                </tr>
            </tbody>
        </table>
        </div>
        <!-- /.card-body -->
        <div class="card-footer">
            <ul class="pagination pagination-sm m-0 float-right">
                <li class="page-item">
                    <a class="page-link" href="#" @click.prevent="previousPage">Previous</a>
                </li>
                <li class="page-item">
                    <a class="page-link">{{ currentPage }} / {{ pageCount }}</a>
                </li>
                <li class="page-item">
                    <a class="page-link" href="#" @click.prevent="nextPage">Next</a>
                </li>
            </ul>
        </div>
    </div>
    <!-- /.card -->
</template>

<script>
import { RepositoryFactory } from '@/services/repositories/ManagementAPI/RepositoryFactory'
const SystemRepo = RepositoryFactory.get('system')
const UserWebsiteStatistics = RepositoryFactory.get('user_websites_statistics')

export default {
    props: {
        website: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            metrics: [],
            signs: {},
            statistics: [],
            currentPage: 1,
            totalRecords: 0,
            resultsLimit: 30,
            isLoading: false
        }
    },
    created() {
        this.loadSystemMetrics()
        this.loadWebsiteStatistics()
    },
    methods: {
        loadSystemMetrics: function() {
            SystemRepo.metrics().then(result => {
                this.metrics = result.data.display
                this.signs = result.data.signs
            }).catch(err => {

            })
        },
        loadWebsiteStatistics: function() {
            this.isLoading = true
            UserWebsiteStatistics.page(this.website.id, this.currentPage, this.resultsLimit).then(result => {
                this.totalRecords = result.data.count
                this.statistics = result.data.results
            }).catch(err => {})
            this.isLoading = false
        },
        loadGroupStatistics: function(group) {

        },
        nextPage: function() {
            if(this.currentPage != this.pageCount || this.currentPage > this.pageCount) {
                this.currentPage = this.currentPage + 1
                this.loadWebsiteStatistics()
            }
        },
        previousPage: function() {
            if(this.currentPage != 1 ) {
                this.currentPage = this.currentPage - 1
                this.loadWebsiteStatistics()
            }
        },
    },
    computed: {
        pageCount: function() {
            return Math.ceil(this.totalRecords / this.resultsLimit)
        },
    },
    watch: {
        '$route.query.website': { 
            handler: function (website) {
                if(typeof website !== 'undefined') {

                } else {}
            },
            deep: true,
            immediate: true
        },
        '$route.params.id': {
            handler: function (id) {
                if(typeof id !== 'undefined') {

                }
            },
            deep: true,
            immediate: true
        },
    }

}
</script>