<template>
<!-- Profile Image -->
<div class="card card-primary card-outline">
    <div class="card-body box-profile">
    <div class="text-center">
        <div class="ribbon-wrapper ribbon-lg">
            <div class="ribbon text-lg" :class="profile.is_verified? 'bg-success' : 'bg-danger'">
                <small v-if="profile.is_verified"> Verified </small>
                <small v-if="!profile.is_verified"> Not Verified </small>
            </div>
        </div>
        <input type="file" ref="file" @change="onChangeFileUpload" style="display: none;" />
        <img class="profile-user-img img-fluid img-circle" :src="user.profile.image || imagePath" @click="$refs.file.click()" alt="User profile picture">
    </div>

    <h3 class="profile-username text-center">{{ user.first_name }} {{ user.last_name }} </h3>

    <p class="text-muted text-center">{{ profile.user_role | capitalizeAndReplace }}</p>

    <ul class="list-group list-group-unbordered mb-3">
        <li class="list-group-item">
            <b>Username</b> <a class="float-right">{{ user.username }}</a>
        </li>
        <li class="list-group-item">
            <b>Email</b> <a class="float-right">{{ user.email }}</a>
        </li>
        <li class="list-group-item">
            <b>Phone</b> <a class="float-right">{{ profile.phone_number }}</a>
        </li>
        <li class="list-group-item">
            <b>Country</b> <a class="float-right">{{ profile.country }}</a>
        </li>
        <li class="list-group-item">
            <b>Status</b>
            <span class="alert btn-xs float-right" :class="user.is_active? 'alert-success' : 'alert-danger'"> 
                <i v-if="user.is_active"> Active </i>
                <i v-if="!user.is_active"> Suspended </i>
            </span>
        </li>
    </ul>

    </div>
    <!-- /.card-body -->
</div>
<!-- /.card -->

</template>
<script>
import { RepositoryFactory } from '@/services/repositories/AuthAPI/RepositoryFactory';
const ProfileRepo = RepositoryFactory.get('profile');

export default {
    props: {
        user: {
            type: Object,
            required: true,
            default: {}
        },
        profile: {
            type: Object,
            required: true,
            default: {}
        }
    },
    data() {
        return {
            selectedFile: null,
            imagePath: '/assets/img/AdminLTELogo.png',
        }
    },
    methods: {
        onUpload() {
            let formData = new FormData();
            formData.append('image', this.selectedFile, this.selectedFile.name);
            ProfileRepo.upload(this.user.id, formData).then(result => {
                console.log(result)
            }).catch(err => {
                console.log(err)
            })
        },
        onChangeFileUpload() {
            this.selectedFile = this.$refs.file.files[0]
            this.imagePath = URL.createObjectURL(this.selectedFile)
            if(this.$store.getters.user.id === this.user)
                this.$store.commit('PROFILE_IMAGE', this.imagePath)
            this.onUpload()
        },
        onUploadProgress() {
            
        },
    },
}
</script>
<style scoped>

</style>