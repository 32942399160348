<template>
<div class="col-md-4">
    <div class="card">
        <div class="card-header">
        <h3 class="card-title">Recently Added Users ({{ totalRecords }})</h3>

        <div class="card-tools">
            <button type="button" class="btn btn-tool" data-card-widget="collapse">
            <i class="fas fa-minus"></i>
            </button>
            <button type="button" class="btn btn-tool" data-card-widget="remove">
            <i class="fas fa-times"></i>
            </button>
        </div>
        </div>
        <!-- /.card-header -->
        <div class="card-body p-0">
        <ul class="products-list product-list-in-card pl-2 pr-2">
            <li class="item" v-for="user in users" :key="user.id">
                <div class="product-img">
                    <img :src="user.profile.image || '/assets/img/AdminLTELogo.png'" alt="User Image" class="img-circle list-img-circle elevation-2">
                </div>
                <div class="product-info">
                    <router-link :to="{name: 'user', params: {id: user.id}}" class="product-title">
                        {{ user.first_name }} {{ user.last_name }} ( {{ user.username }} )
                        <span v-if="user.profile" class="badge float-right" :class="user.profile.is_verified? 'badge-success' : 'badge-danger' ">
                            <span v-if="user.profile.is_verified"> Verified </span>
                            <span v-if="!user.profile.is_verified"> Not Verified </span>
                        </span>
                    </router-link>
                    <span class="product-description">
                    {{ user.profile.user_role | capitalizeAndReplace }} | {{ user.profile.created_at | formatDate }}
                    </span>
                </div>
            </li>
            <!-- /.item -->
        </ul>
        </div>
        <!-- /.card-body -->
        <div class="card-footer text-center">
            <router-link :to="{name: 'users'}" class="uppercase">View All</router-link>
        </div>

        <!-- /.card-footer -->
        <div class="overlay" v-if="!isLoaded && !hasError">
            <point-spinner />
        </div>

    </div>
</div>
</template>
<script>
import { RepositoryFactory } from '@/services/repositories/ManagementAPI/RepositoryFactory';
const UsersRepo = RepositoryFactory.get('users');

export default {
    
    data() {
        return {
            users: [],
            totalRecords: 0,
            isLoaded: false,
            hasError: false
        }
    },
    created: function() {
        this.loadUsers()
    },
    methods: {
        loadUsers: function() {
            this.isLoaded = false
            var query = {
                role: 'pre_activation',
                page: 1,
                limit: 10
            }
            UsersRepo.query(query).then(result => {
                this.totalRecords = result.data.count
                this.users = result.data.results
                this.isLoaded = true
                this.hasError = false
            }).catch(err => {
                this.isLoaded = true
                this.hasError = true
                this.users = []
            })
        },
    },
}
</script>
<style>

</style>