<template>
  <div class="card">
    <div class="card-header">
      <h3 class="card-title"><i class="fas fa-globe"></i> {{ website.name }}</h3>

      <div class="card-tools">
        <p class="text-primary">#{{ wallet.id }}</p>
      </div>
      <!-- /.card-tools -->
    </div>
    <!-- /.card-header -->
    <div class="card-body">
      <div class="row">
        <div class="col-md-12 mb-2">
          <p> Pending Invoices </p>
          <h4 class="text-red">{{ wallet.unpaid_invoices | withComma }}</h4>
          <hr />
        </div>
        
        <div class="col-md-12 mb-2">
          <p> Due amount  </p>
          <h4 class="text-red">${{ wallet.unpaid_amount | withComma }}</h4>
          <hr />
        </div>
        <div class="col-md-12 mb-2">
          <p> Paid Invoices </p>
          <h4 class="">{{ wallet.paid_invoices | withComma }}</h4>
          <hr />
        </div>
        <div class="col-md-12 mb-2">
          <p> Total amount </p>
          <h4 class="">${{ wallet.total | withComma }}</h4>
        </div>
      </div>
      <!-- /.row -->
    </div>
    <!-- /.card-body -->
  </div>
  <!-- /.card -->
</template>
<script>
export default {
    props: {
      website: {
        required: true,
        type: Object
      },
      wallet: {
        required: true,
        type: Object
      }
    }
}
</script>
<style>

</style>