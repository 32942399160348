import Repository from './Repository';

const resource = "/auth/assigned"

export default {
    filter(id, role, limit=10000, offset=10) {
        return Repository.get(`${resource}/?${role}=${id}&limit=${limit}&offset=${offset}`);
    },
    query(query) {
        query = new URLSearchParams(query).toString()
        return Repository.get(`${resource}/?${query}`);
    },
    get(id) {
        return Repository.get(`${resource}/${id}/`);
    },
    post(data) {
        return Repository.post(`${resource}/`, data);
    },
    update(id, data) {
        return Repository.patch(`${resource}/${id}/`, data);
    },
    delete(id) {
        return Repository.delete(`${resource}/${id}/`);
    },
}