<template>
<div>
    <div class="card" v-animate-css="'fadeInLeft'">
        <div class="card-header">
            <h3 class="card-title">
                <span v-if="!api_key.id"> Add </span>
                <span v-if="api_key.id"> Edit </span>
                API Key
            </h3>
            <div class="card-tools">
                <div class="overlay" v-if="isLoading">
                    <i class="fas fa-sync-alt fa-spin fa-1x"></i>
                </div>

                <button class="btn btn-tool btn-sm" v-if="!isLoading" @click.prevent="clear()">
                    <i class="fa fa-plus"></i>
                </button>
            </div>
            <!-- /.card-tools -->
        </div>
        <!-- /.card-header -->
        <div class="card-body">
            <div class="row">
                <div class="col-md-6">
                    <!-- text input -->
                    <div class="form-group">
                        <label>Name</label>
                        <input type="text" class="form-control" v-model="api_key.name">
                    </div>
                </div>
                <div class="col-md-6">
                    <!-- text input -->
                    <div class="form-group">
                        <label>User</label>
                        <select class="form-control select2bs4" v-model="api_key.user">
                            <option v-for="user in users" :key="user.id" :value="user.id" :selected="api_key.user">
                                {{ user.first_name }} {{ user.last_name }} - {{ user.profile.user_role | capitalizeAndReplace }}
                            </option>
                        </select>
                    </div>
                </div>
                <!-- <div class="col-md-12">

                    <div class="form-group row">
                        <label for="inputName" class="col-sm-2 col-form-label">Add Permission</label>
                        <div class="col-md-11">
                            <select class="custom-select select2bs4" v-model="selectedPermission">
                                <option v-for="permission in permissions" :key="permission.id" :value="permission.id"> {{ permission.name }}</option>
                            </select>
                        </div>
                        <div class="col-md-1">
                            <a href="#" class="btn btn-success btn-block" @click.prevent="addPermission()" > <i class="fa fa-plus"></i></a>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-12">
                            <ul class="custom-select-list">
                                <li v-for="permission in api_key.permissions" :key="permission.id" v-animate-css="'bounceInLeft'"> 
                                    {{ permissions[permission] }}
                                    <a href="#" class="float-right remove-btn" @click.prevent="deletePermission(permission.id)" ><i class="fa fa-close"></i> Delete</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    
                </div> -->
                <div class="col-md-12">
                    <!-- text input -->
                    <div class="form-group">
                        <label>KEY</label>

                            <div class="input-group">
                                <input type="password" class="form-control" id="clipboard-code" readonly v-model="api_key.key" @click.prevent="copyKey()">
                                <div class="input-group-append">
                                    <button class="btn btn-default" @click.prevent="showKey()">
                                        <i class="fa fa-eye"></i>
                                    </button>
                                    <button class="btn btn-default" @click.prevent="copyKey()">
                                        <i class="fa fa-copy"></i>
                                    </button>
                                </div>
                            </div>     

                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <div class="btn-group btn-group-toggle">
                            <label class="btn btn-sm btn-default"  :class="{'text-green': api_key.status === 'active'}">
                                <input type="radio" value="active" v-model="api_key.status"> Active
                            </label>
                            <label class="btn btn-sm btn-default" :class="{'text-red': api_key.status === 'suspended'}">
                                <input type="radio" value="suspended" v-model="api_key.status"> Suspended
                            </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group float-right">
                            <button class="btn btn-success" @click.prevent="submit()"> Save </button>
                        </div>
                    </div>

                <div class="col-md-12">
                    <errors :errors="errors"/>
                    <messages :messages="messages" />
                </div>
            </div>
        </div>
    <!-- /.card-body -->
    </div>
    <!-- /.card -->

</div>
</template>

<script>
import { RepositoryFactory } from '@/services/repositories/ManagementAPI/RepositoryFactory' 
const APIRepo = RepositoryFactory.get('local_apis')

export default {
    props: {
        api_key: {
            required: true,
            type: Object
        },
        users: {
          required: true,
          type: Array
        },
        permissions: {
          required: true,
          type: Array
        },
        isLoading: {
            required: false,
            type: Boolean
        },
    },
    data() {
        return {
            permission: [],
            selectedPermission: [],

            messages: [],
            errors: []
        }
    },
    created() {},
    methods: {
        submit: function() {
            if(typeof this.api_key.id === 'undefined') {
                this.create(this.api_key)
            } else {
                this.update(this.api_key)
            }
        },
        clear: function() {
            this.errors = []
            this.messages = []

            this.api_key = {
                website_id: this.website.id
            }
        },
        create: function(api_key) {
            this.errors = []
            this.messages = []

            APIRepo.post(api_key).then(result => {
                if(typeof result.data.id !== 'undefined' ) 
                    this.api_key = result.data
                    this.messages.push("Successfully added with ID: " + result.data.id)
                    this.$emit('updateList', this.api_key)
            }).catch(err => {
                this.errors.push(err)
                this.errors.push( err.response.data )
            })
        },
        update: function(api_key) {
            this.errors = []
            this.messages = []

            APIRepo.patch(this.api_key.id, api_key).then(result => {
                if(typeof result.data.id !== 'undefined' ) 
                    this.api_key = result.data
                    this.messages.push("Successfully updated.")
                    this.$emit('refreshList', true)
            }).catch(err => {
                this.errors.push(err)
                this.errors.push( err.response.data )
            })
        },
        delete: function(id) {
            this.errors = []
            this.messages = []

            APIRepo.delete(id).then(result => {
                this.messages.push(result.data)
            }).catch(err => {
                this.errors.push(err)
                this.errors.push( err.response.data )
            })
        },
        addPermission: function() {
            this.api_key.permissions.push(permission)
        },
        deletePermission: function(id) {
            this.api_key.permissions.pop({'id': id})
        },
        copyKey: function() {
            let testingCodeToCopy = document.getElementById('clipboard-code')
            testingCodeToCopy.setAttribute('type', 'text')
            testingCodeToCopy.select()
            try {
                document.execCommand('copy')
                this.$notify({
                    group: "notifications",
                    type: 'success',
                    title: "Clipboard",
                    text: "Key copied to clipboard"
                })
            } catch {
                this.$notify({
                    group: "notifications",
                    type: 'warn',
                    title: "Clipboard",
                    text: "Failed to copy Key"
                })
            }
            testingCodeToCopy.setAttribute('type', 'password')
        },
        showKey: function() {
            let testingCodeToCopy = document.getElementById('clipboard-code')
            let isVisiable = testingCodeToCopy.getAttribute('type')
            if(isVisiable == 'text') {
                testingCodeToCopy.setAttribute('type', 'password')
            } else {
                testingCodeToCopy.setAttribute('type', 'text')
            }
        }
    },
}
</script>