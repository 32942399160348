<template>
<div class="card bg-gradient" >
    <div class="card-header border-0">
        <h3 class="card-title">
            <i class="fas fa-th mr-1"></i>
            Player: {{player.name}}
        </h3>
        <div class="card-tools">
            <button type="button" class="btn btn-tool btn-sm" data-card-widget="collapse">
                <i class="fas fa-minus"></i>
            </button>
            <button type="button" class="btn btn-tool btn-sm" data-card-widget="remove">
                <i class="fas fa-times"></i>
            </button>
        </div>
    </div>
    <div class="card-body">
        <div class="col-md-12 border mb-2 rounded-lg font-weight-bold text-dark" v-if="!player.id">
            <nav class="m-2">
                <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                    <li class="nav-item has-treeview menu-open">
                        <a href="#" class="nav-link flex align-items-center text-warning">
                        <i class="nav-icon fas fa-plug"></i>
                        <div class=""> Format </div><i class="fas fa-angle-left ml-auto"></i> 
                        </a>
                        <ul class="m-3 nav nav-treeview">
                            <li class="nav-item font-weight-normal pb-6">
                                <div class="d-flex justify-content-start align-items-baseline input-group">
                                    <div class="col-md-6">
                                        <label class="font-weight-normal " for="playerFormat">Name</label>
                                    </div>
                                    <div class="col-md-6">
                                        <input type="text" class="form-control" id="name" v-model="player.name">
                                    </div>
                                </div>
                            </li>
                            <li class="nav-item font-weight-normal pb-6">
                                <div class="d-flex justify-content-start align-items-baseline input-group">
                                    <div class="col-md-6">
                                        <label class="font-weight-normal " for="playerFormat">Type</label>
                                    </div>
                                    <div class="col-md-6 ">
                                        <select  class="custom-select " id="playerFormat" v-model="player.player_type">
                                            <option disabled>Choose Format for Player</option>
                                            <option v-for="(type, index) in types" :key=index :value=type.value > {{ type.display_name }} </option>
                                        </select>                                            
                                    </div>
                                </div>
                            </li>
                            <li class="nav-item mb-4 font-weight-normal">
                                <div class="d-flex justify-content-start align-items-baseline input-group">
                                    <div class="col-md-6">
                                        <label class="font-weight-normal " for="playerFormat">Player position</label>
                                    </div>
                                    <div class="col-md-6 ">
                                        <select  class="custom-select " id="playerFormat" v-model="player.position">
                                            <option disabled>Choose player position</option>
                                            <option v-for="(position, index) in positions" :key=index :value=position.value > {{ position.display_name }} </option>
                                        </select>
                                    </div>
                                </div>
                            </li>
                            <li class="nav-item mb-4 font-weight-normal">
                                <div class="d-flex justify-content-start align-items-baseline input-group">
                                    <div class="col-md-6">
                                        <label class="font-weight-normal " for="playerFormat">Companion position</label>
                                    </div>
                                    <div class="col-md-6 ">
                                        <select  class="custom-select " id="playerFormat" v-model="player.companion_position">
                                            <option disabled>Choose companion position</option>
                                            <option v-for="(position, index) in positions" :key=index :value=position.value > {{ position.display_name }} </option>
                                        </select>
                                    </div>
                                </div>
                            </li>
                            <li class="nav-item mb-4 font-weight-normal">
                                <div class="d-flex justify-content-start align-items-baseline input-group">
                                    <div class="col-md-6">
                                        <label class="font-weight-normal " for="playerFormat">Close button</label>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="inline">
                                            <div class="custom-control custom-switch p-0 py-2">
                                                <label class="switch" for="autoplay">
                                                    <input type="checkbox" class="primary" id="autoplay" 
                                                    v-model="player.close_button">
                                                    <span class="slider round"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</div>
</template>
<script>
export default {
    props: {
        types: [],
        positions: [],
        collect: 0
    },
    data () {
        return {
            website_id: 0,
            player: {},
        }
    },
    created () {},
    methods: {
        submit() {
            this.$emit("submit", this.player);
        },
        close() {
            this.player = undefined
            this.$emit('close', '')      
        }
    },
    watch: {
        'collect': function () {
            this.submit()
        },
    }
}
</script>