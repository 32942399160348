<template>
<div class="row">
    <div class="col-md-2 col-sm-12">
        <InvoicesTotal v-if="wallet.id" :wallet="wallet" :website="website" v-animate-css="'slideInLeft'" />
    </div>
    <div class="col-md-10 col-sm-12">
        <div class="card">
            <div class="card-header">
                <div class="card-title row">
                    <div class="form-group col-md-6">
                        <label>Date range:</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    <i class="far fa-calendar-alt"></i>
                                </span>
                            </div>
                            <input type="text" class="form-control float-right" id="daterange">
                    </div>
                    </div>
                    <div class="form-group col-md-2">
                        <label>Limit</label>
                        <select class="form-control" style="width: 100%;" v-model="query.limit">
                        <option value="10">10</option>
                        <option value="15">15</option>
                        <option selected value="30">30</option>
                        <option value="60">60</option>
                        <option value="100">100</option>
                        </select>
                    </div>
                    <div class="form-group col-md-3">
                        <label>Order</label>
                        <select class="form-control" style="width: 100%;" v-model="query.order">
                            <option selected="selected" value="-date">Newest</option>
                            <option value="date">Older</option>
                        </select>
                    </div>
                    <div class="form-group col-md-1">
                        <hr style="visibility: hidden;" />
                        <button class="btn btn-primary mr-2"
                        @click.prevent="loadInvoices()"><i class="fa fa-search"></i> </button>
                    </div>
                </div>

                <div class="card-tools">
                    <button class="btn btn-tool" @click.prevent="clear()">
                        <i class="fa fa-eraser" data-toggle="tooltip" data-placement="left" title="Clear"></i>
                    </button>
                    <button class="btn btn-tool" @click.prevent="print()">
                        <i class="fa fa-print" data-toggle="tooltip" data-placement="left" title="Print"></i>
                    </button>
                    <button type="button" class="btn btn-tool" data-card-widget="maximize">
                        <i class="fas fa-expand" data-toggle="tooltip" data-placement="left" title="Expand"></i>
                    </button>
                </div>
            </div>
            <!-- /.card-header -->
            <div class="card-body table-responsive p-0">
            <table class="table table-hover">
                <thead>
                <tr>
                    <th>ID</th>
                    <th>Date</th>
                    <th class="text-center">Amount</th>
                    <th class="text-center">Deducted</th>
                    <th class="text-center">Final</th>
                    <th>Description</th>
                    <th class="text-center">Status</th>
                    <th class="text-center">Action</th>
                </tr>
                </thead>
                <tbody>
                    <tr v-for="invoice in invoices" :key="invoice.id">
                        <td>{{ invoice.id }}</td>
                        <td>{{ invoice.date }}</td>
                        <td class="text-center">${{ invoice.amount | withComma }}</td>
                        <td class="text-center">${{ invoice.deduction | withComma }}</td>
                        <td class="text-center">${{ invoice.final | withComma }}</td>
                        <td>{{ invoice.description }}</td>
                        <td class="text-center">
                            <span class="alert btn-xs" :class="{
                                'alert-success': invoice.status == 'paid',
                                'alert-primary': invoice.status == 'approved',
                                'alert-danger': invoice.status == 'deductible'}"> {{ invoice.status | capitalize }} 
                            </span>
                        </td>
                        <td class="text-center">
                            <button class="btn btn-tool" @click="edit(invoice)"><i class="fa fa-edit"></i></button>
                        </td>
                    </tr>
                </tbody>
            </table>
            </div>
            <!-- /.card-body -->
            <div class="card-footer">
                <ul class="pagination pagination-sm m-0 float-right">
                    <li class="page-item">
                        <a class="page-link" href="#" @click.prevent="previousPage">Previous</a>
                    </li>
                    <li class="page-item">
                        <a class="page-link">{{ currentPage }} / {{ pageCount }}</a>
                    </li>
                    <li class="page-item">
                        <a class="page-link" href="#" @click.prevent="nextPage">Next</a>
                    </li>
                </ul>
            </div>

            <div class="overlay" v-if="!isLoaded">
                <i class="fas fa-2x fa-sync-alt fa-spin"></i>
            </div>
            <!-- /.overlay -->
        </div>
        <!-- /.card -->
    </div>

    <InvoiceModel 
    v-if="wallet.id" 
    :wallet="wallet" 
    :invoice="selectedInvoice" 
    :show="show"
    @updated="updateWallet"
    @close="closeInvoiceModel" />

</div>
</template>
<script>
import InvoicesTotal from '@/components/wallets/side/InvoicesTotal'
import InvoiceModel from '@/components/wallets/Model'
import { RepositoryFactory } from '@/services/repositories/ManagementAPI/RepositoryFactory' 
const WalletsRepo = RepositoryFactory.get('wallets')
const InvoicesRepo = RepositoryFactory.get('invoices')
const WebsitesRepo = RepositoryFactory.get('websites')

export default {
    components: {
        InvoiceModel,
        InvoicesTotal,
    },
    data() {
        return {
            wallet: {},
            wallet_id: 0,
            invoices: [],
            website: {},
            selectedInvoice: {},

            show: false,
            currentPage: 1,
            totalRecords: 0,
            isLoaded: true,
            query: {
                start_date: '',
                end_date: '',
                limit: 30,
                order: '-date',
                search: 0
            }
        }
    },
    mounted() {
        var self = this
        $('#daterange').daterangepicker({
            ranges   : {
                'Today'       : [moment(), moment()],
                'Yesterday'   : [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days' : [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month'  : [moment().startOf('month'), moment().endOf('month')],
                'Last Month'  : [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                },
                startDate: moment().subtract(29, 'days'),
                endDate  : moment()
            },
            function (start, end) {
                self.query.start_date = start.format('YYYY-MM-DD')
                self.query.end_date = end.format('YYYY-MM-DD')
            }
        )
    },
    created() {
        this.loadWallet()
        this.loadInvoices()
    },
    updated() {
        $('[data-toggle="tooltip"]').tooltip()
    },
    methods: {
        loadInvoices: function() {
            this.isLoaded = false
            InvoicesRepo.page(this.wallet_id, this.currentPage, this.query.limit, this.query.start_date, this.query.end_date, this.query.order).then(result => {
                this.totalRecords = result.data.count
                this.invoices = result.data.results

                this.isLoaded = true
            }).catch(err => {
                this.isLoaded = true
            })
        },
        loadWallet: function() {
            WalletsRepo.get(this.wallet_id).then(result => {
                this.wallet = result.data
                this.wallet_id = this.wallet.id
                this.loadWebsite(this.wallet.website_id)
            })
        },
        loadWebsite: function(website_id) {
            WebsitesRepo.get(website_id).then(result => {
                this.website = result.data
            })
        },
        edit: function(invoice) {
            this.selectedInvoice = invoice
            this.show = true
        },
        print: function() {

        },
        clear: function() {
            this.query.start_date = ''
            this.query.end_date = ''
            this.loadInvoices()
        },
        updateWallet: function() {
            this.loadWallet()
        },
        closeInvoiceModel: function(invoice) {
            this.show = false
            this.selectedInvoice = {}
        },
        nextPage: function() {
            if(this.currentPage != this.pageCount || this.currentPage > this.pageCount) {
                this.currentPage = this.currentPage + 1
                this.loadInvoices()
            }
        },
        previousPage: function() {
            if(this.currentPage != 1 ) {
                this.currentPage = this.currentPage - 1
                this.loadInvoices()
            }
        },
  },
  computed: {
    pageCount: function() {
            return Math.ceil(this.totalRecords / this.query.limit)
        },
    },
    watch: {
        '$route.params.id': {
            handler: function (id) {
                if(typeof id !== 'undefined') {
                    this.wallet_id = id
                }
            },
            deep: true,
            immediate: true
        },
    }
}
</script>