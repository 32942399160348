<template>
    <!-- Modal -->
    <div class="modal fade"  id="AdScriptModal" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-body  container">
                    <h1 class="text-xl">
                        <span v-if="adscript.id">UPDATE</span> <span v-else>ADD</span> AD SCRIPT 
                        <i @click="closeModal()" data-dismiss="modal" class="fas fa-times float-right cursor-pointer"></i>
                    </h1>
                    
                    <hr class="my-3">
                    <div class="row">
                        <div class="form-group col-md-12">
                            <label>Ad ID</label>
                            <input v-model="adscript.ad_id"  type="text" class="form-control border-top-0 border-right-0 border-left-0" placeholder="Ad id">
                        </div>
                        <div class="form-group col-md-6">
                            <label>Width</label>
                            <input v-model="adscript.ad_width" type="text" class="form-control border-top-0 border-right-0 border-left-0" placeholder="Width">
                        </div>
                        <div class="form-group col-md-6">
                            <label>Height</label>
                            <input v-model="adscript.ad_height" type="text" class="form-control border-top-0 border-right-0 border-left-0" placeholder="Height">
                        </div>
                        <div class="form-group col-md-12">
                            <label>Div ID</label>
                            <input v-model="adscript.div_id"  type="text" class="form-control border-top-0 border-right-0 border-left-0" placeholder="Div ID">
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="col-md-10">
                        <small v-if="adscript.id">
                            <a href="#" class="text-red float-left" @click.prevent="deleteAdTag(adscript.id)">Delete</a>
                        </small>
                        <div v-if="!adscript.id" class="note-btn-group btn-group note-font">
                            <button type="button" @click="fillMobile()" class="note-btn btn btn-light btn-sm note-btn-bold" tabindex="-1" title="" aria-label="Mobile" data-original-title="Mobile">
                                <i data-feather="smartphone" ></i>
                            </button>
                            <button type="button" @click="fillTablet()" class="note-btn btn btn-light btn-sm note-btn-underline" tabindex="-1" title="" aria-label="Tablet" data-original-title="Tablet">
                                <i data-feather="tablet"></i>
                            </button>
                            <button type="button" @click="fillDesktop()" class="note-btn btn btn-light btn-sm" tabindex="-1" title="" aria-label="Desktop" data-original-title="Desktop">
                                <i data-feather="monitor"></i>
                            </button>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <button type="button" @click.prevent="submit()" class="btn btn-primary btn-block">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal -->
</template>
<script>
import { RepositoryFactory } from '@/services/repositories/PlayerAPI/RepositoryFactory'
const AdScriptsRepo = RepositoryFactory.get('adscripts')

export default {
    props: {
        adscript: {
            type: Object,
            required: false
        },
    },
    data() {
        return {
            website_id: 0
        }
    },
    mounted() {
        this.$feather.replace()
    },
    methods: {
        submit() {
            if(this.adscript.id) {
                this.update()
            } else {
                this.create()
            }
        },
        create() {
            this.adscript.website_id = this.website_id
            AdScriptsRepo.post(this.adscript).then(result => {
                this.adscript = result.data
                this.$notify({
                    group: 'ops',
                    type: 'success',
                    title: 'Operation success',
                    text: 'Successfully created ad script.'
                })
                this.updateList()
            }).catch(error => {
                this.$notify({
                    group: 'ops',
                    type: 'error',
                    title: 'Operation failed',
                    text: 'Failed create ad script.'
                })
            })
        },
        update() {
            AdScriptsRepo.patch(this.adscript.id, this.adscript).then(result => {
                this.$notify({
                    group: 'ops',
                    type: 'success',
                    title: 'Operation success',
                    text: 'Successfully updated ad script.'
                })
                this.updateList()
            }).catch(error => {
                this.$notify({
                    group: 'ops',
                    type: 'error',
                    title: 'Operation failed',
                    text: 'Failed update ad script.'
                })
            })
        },
        deleteAdTag(adscript_id) {
            var reply = confirm(`Are you sure you want to remove this Ad Tag`)
            if(!reply) return
            
            AdTagsRepo.delete(adscript_id).then(result => {
                this.$notify({
                    group: 'ops',
                    type: 'success',
                    title: 'Operation success',
                    text: 'Successfully deleted selceted ad script.'
                })
                this.adscript = {}
                this.updateList()
            }).catch(error => {
                this.$notify({
                    group: 'ops',
                    type: 'error',
                    title: 'Operation failed',
                    text: 'Failed delete the selected ad script.'
                })
            })
        },
        updateList() {
            this.$emit('updateList')
        },
        closeModal() {
            $(`#AdScriptModal`).modal('toggle')
            this.$emit('modalClosed')
        },
        fillMobile() {
            this.adscript.ad_width = 320
            this.adscript.ad_height = 100
            this.adscript.div_id = 'div-gpt-ad-'
        },
        fillTablet() {
            this.adscript.ad_width = 300
            this.adscript.ad_height = 250
            this.adscript.div_id = 'div-gpt-ad-'

        },
        fillDesktop() {
            this.adscript.ad_width = 336
            this.adscript.ad_height = 280
            this.adscript.div_id = 'div-gpt-ad-'
        }
    },
    watch: {
        '$route.query.website': { 
            handler: function (website) {
                if(typeof website !== 'undefined') {
                    // check if user is not loaded 
                    // website placements_groups before
                    if(this.website_id !== website) {
                        // match website with passed from query
                        this.website_id = website
                    }
                } else {
                    this.$router.push({name: "website"})
                }
            },
            deep: true,
            immediate: true
        },
    }
}
</script>
<style scoped>
@media (min-width: 992px) {
    .modal-lg  {
        max-width: 800px;
    }    
}
</style>