<template>
  <div id=""> 
      <LoginPage  @login='login($event)'  />
  </div>
</template>

<script>
import LoginPage from './LoginPage'
export default {
    name: 'Login',
    components: {
      LoginPage,
    },
    data() {
      return {
        username: '',
        password: '',
        error: null
      }
  },
  methods: {
    login: function (event) {
        const { username, password } = event
        this.$store.dispatch('AUTH_REQUEST', { username, password }).then(result => {
          this.$router.push({name: 'dashboard'})
        }).catch(err => {
          this.$notify({
              group: 'auth',
              type: 'error',
              title: 'Faild to login',
              text: err.response.data.detail
          })
      })
    }
  },
  created () {

  },
  computed: {

  }

}
</script>
<style>
    body {
        background-color: #e4e4e4;  
    }
   
</style>