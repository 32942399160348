<template>
    <div class="">
        <div class="d-flex align-items-start">
            <!--check/uncheck all -->
            <div class="w-8 h-10 d-flex justify-content-center align-items-center" id="videocheckuncheckbox" data-toggle="tooltip" data-placement="top" title="check/uncheck all videos">
                <input  @click="checkUncheckAll($event)" type="checkbox" />
            </div>
            <!--check/uncheck all -->
            <!--search bar-->
            <form class="card w-48" v-on:submit="$event.preventDefault()">
                <div class="card-body d-flex flex-row justify-content-between align-items-center  p-0">
                    <i class="px-2 fas fa-search text-muted"></i>
                    <div class="">
                        <input class="form-control form-control-borderless"  type="search" placeholder="Search Files" v-model="search_text">
                    </div>
                    <i class="px-2 fas fa-angle-down text-muted"></i>
                </div>
                
            </form>
            <!--search bar-->
            <button @click="restoreVideo()" :class="{'btn-primary' : displayRestoreBtn , 'btn-secondary' : !displayRestoreBtn}" class="btn  mx-4">Restore Video</button>
            <button @click="deleteVideo()" :class="{'btn-danger' : displayDeleteBtn , 'btn-secondary' : !displayDeleteBtn}" class="btn">Delete Video</button>
        </div>    
        <div class="pt-2" v-if="videos.length > 0" >
            <div class="row">
                <div :class="{'col-md-4': displayForm , 'col-md-2': !displayForm}" v-for="video in filteredVideos" :key="video.id">
                    <div class="card cursor-pointer"  @click="selectVideoCard(video)">
                        <!--card top icons-->
                        <div class="d-flex justify-content-between m-2">
                            <input  :data-index="video.id" @click="selectVideo($event,video)" type="checkbox" class="" id="videocheckbox">
                            <i class="text-muted text-sm fas fa-ellipsis-v"></i>
                        </div>
                        <!--card top icons-->
                        <!--card body-->
                        <div class="flex justify-content-center align-items-center h-56 m-auto px-4 overflow-hidden">
                            <img :src="video.poster" class="rounded-md shadow-md">
                        </div>
                        <div class="m-auto text-dark text-md font-weight-normal ">{{video.name}}</div>
                        <div class="m-auto text-muted text-sm pb-2">{{video.size}} KB</div>
                        <!--card body-->
                    </div>
                </div>
            </div>
            <div class="overlay" v-if="!isLoaded">
                <point-spinner />
            </div>
        </div>
        <div class="card d-flex justify-content-center align-items-center mt-2 h-screen" v-else>
            <i class="fas fa-hand-point-left text-xl"></i>
            <h1 class="display-5 text-lg font-weight-bold mt-12">The Trash is Empty</h1>
            <div  class="mt-12">
                <button   class="btn btn-primary" id="backtovideos" @click="$emit('back-to-videos')" >Go Back To Videos</button>
            </div>
        </div> 
        <ul class="pagination pagination-sm m-0 float-right">
          <li class="page-item">
            <a class="page-link" href="#" @click.prevent="previousPage">Previous</a>
          </li>
          <li class="page-item">
            <a class="page-link">{{ currentPage }} / {{ pageCount }}</a>
          </li>
          <li class="page-item">
            <a class="page-link" href="#" @click.prevent="nextPage">Next</a>
          </li>
        </ul>               
    </div>
</template>
<script>
export default {
    props: {
        displayForm: {
            type: Boolean,
            required: true
        },
        videos: {
            type: Array,
            required: true
        }
    },
    data() { 
        return {
            isLoaded: true,
            displayDeleteBtn: false,
            displayRestoreBtn: false,
            selectedVideos: [],
            search_text: '',
            currentPage: 1,
            totalRecords: 0,
            resultsLimit: 10,            
        }
    },
    methods: {
        selectVideoCard(video) {
            document.querySelector(`input[data-index="${video.id}"]`).click()
        },
        restoreVideo() {
            if(this.displayRestoreBtn) {
                let reply =  confirm('Are You Sure ??')
                if (reply) {
                    this.$emit('restore-videos' , this.selectedVideos)
                    this.selectedVideos = []
                }
            }
            else
                alert("you should select video first")
        },
        deleteVideo() {
            if(this.displayDeleteBtn) {
                let reply =  confirm('Are You Sure ??')
                if (reply) {
                    this.$emit('delete-videos' , this.selectedVideos)
                    this.selectedVideos = []
                }
            }    
            else
                alert("you should select video first")    
        },
        selectVideo(e,video) {
            e.stopPropagation()
            if(this.selectedVideos.includes(video))
                this.selectedVideos = this.selectedVideos.filter((ele) => { return ele.id !== video.id })
            else
                this.selectedVideos.push(video)    
        },
        checkAll(e) {
            this.videos.forEach((video)=>{
                document.querySelector(`input[data-index="${video.id}"]`).click()
            })
            e.target.checked = true 
        },
        uncheckAll(e) {
            this.selectedVideos.forEach((video)=>{
                document.querySelector(`input[data-index="${video.id}"]`).click()
            })
            e.target.checked = false            
        },
        checkUncheckAll(e) {
            this.selectedVideos.length === 0 ? this.checkAll(e) : this.uncheckAll(e) 
        },
        nextPage: function() {
            if(this.currentPage != this.pageCount || this.currentPage > this.pageCount) {
                this.currentPage = this.currentPage + 1
                //this.loadVideos()
            }
        },
        previousPage: function() {
            if(this.currentPage != 1 ) {
                this.currentPage = this.currentPage - 1
                //this.loadVideos()
            }
        },        
    },
    mounted() {
        $('#videocheckuncheckbox').tooltip()
    },
    computed: {
        pageCount: function() {
            return Math.ceil(this.totalRecords / this.resultsLimit)
        },
        filteredVideos: function() {
            return this.videos.filter((ele) => {
                return ele.name.toLowerCase().includes(this.search_text.toLowerCase())
            })
        },
    },
    watch: {
        selectedVideos: function (val) {
            if(val.length == 0 ) {
                this.displayDeleteBtn = false
                this.displayRestoreBtn = false    
            }

            if(val.length > 0) {
                this.displayRestoreBtn = true    
                this.displayDeleteBtn = true
            }
            
        }
    }
}
</script>
<style scoped>
.form-control-borderless {
    border: none;
}

.form-control-borderless:hover, .form-control-borderless:active, .form-control-borderless:focus {
    border: none;
    outline: none;
    box-shadow: none;
}
</style>