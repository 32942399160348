import Vue from 'vue'
import Router from 'vue-router'
// accounts pages
import Login from '@/components/account/Login.vue'
//import Signup from '@/components/account/Signup.vue'
import ForgotPassword from '@/components/account/ForgotPassword'
import ResetPassword from '@/components/account/ResetPassword'
import VerifiyAccount from '@/components/account/VerifiyAccount.vue'

// main pages
import Home from '@/views/Home.vue'
import Dashboard from '@/views/Dashboard'

// websites pages
import Websites from '@/views/Websites.vue'

// players for websites
import Players from '@/views/Players.vue'

// videos for websites
import Videos from '@/views/Videos.vue'

// placements groups pages
import PlacementsGroups from '@/views/PlacementsGroups.vue'

// placements groups data pages
import PlacementsGroupsData from '@/views/PlacementsGroupsData.vue'

// statistics pages
import Statistics from '@/views/Statistics.vue'
import NewStatistics from '@/components/statistics/data/New.vue'
import DataStatistics from '@/views/statistics/Data.vue'
import GroupStatistics from '@/views/statistics/Group.vue'
import UserStatistics from '@/views/statistics/User.vue'
import WebsitesStatistics from '@/views/statistics/Websites.vue'

// group types pages
import GroupsTypes from '@/views/GroupsTypes.vue'

// tags pages
import Tags from '@/views/Tags'

// suppliers pages
import Suppliers from '@/views/Suppliers'

// shares pages
import Shares from '@/views/Shares'
import SuppliersShares from '@/views/shares/Suppliers'
import AdCrewShares from '@/views/shares/AdCrew'
import PMPShares from '@/views/shares/PMP'
import NetworkShares from '@/views/shares/Network'

// analytics
import Search from '@/views/Analytics.vue'
import Reports from '@/views/Reports.vue'

// users pages
import UsersList from '@/components/users/List.vue'
import User from '@/components/users/Profile.vue'
import NewUser from '@/components/users/New.vue'

import Whitelist from '@/views/Whitelist.vue'
import Profile from '@/views/Profile.vue'

import Wallets from '@/views/Wallets.vue'
import Wallet from '@/components/wallets/Wallet.vue'

// credentials pages
import Credentials from '@/views/Credentials.vue'
import GroupsCredentials from '@/components/credentials/groups.vue'
import Permissions from '@/components/credentials/permissions.vue'
import Assignments from '@/components/credentials/assignments.vue'
import Services from '@/components/credentials/services.vue'
import APIKeys from '@/views/APIKeys.vue'

// settings 
import Settings from '@/views/Settings'
import Metrics from '@/views/settings/Metrics'
import API from '@/views/settings/API'

// errors
import Error404 from '@/views/Errors/404.vue'
//import Error505 from '@/views/Errors/505.vue'
import ErrorDenied from '@/views/Errors/Denied.vue'
import ErrorSuspended from '@/views/Errors/Suspended.vue'

import store from '@/store/store'

Vue.use(Router)

const logoutUser = (to, from, next) => {
  store.dispatch('AUTH_LOGOUT')
  next({name: 'login'})
}

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: { 
      requiresAuth: false
    }
  },
  {
    path: '/forgot',
    name: 'forgot',
    component: ForgotPassword,
    meta: { 
      requiresAuth: false
    }
  },
  {
    path: '/reset/:key',
    name: 'reset',
    component: ResetPassword,
    meta: { 
      requiresAuth: false
    }
  },
  {
    path: '/verifiy/:key',
    name: 'verifiy',
    component: VerifiyAccount,
    meta: { 
      requiresAuth: false
    }
  },
  {
    // the main user dashboard
    path: '/',
    component: Home,
    meta: { 
      requiresAuth: true
    },
    children: [
      {
        path: '',
        name: 'dashboard',
        component: Dashboard,
      },
      {
        path: 'search',
        name: 'search',
        component: Search,
      },
      {
        path: 'reports',
        name: 'reports',
        component: Reports,
      },
      {
        path: 'messages',
        name: 'messages',
        component: Dashboard,
      },
      {
        path: 'websites/:id?',
        name: 'websites',
        component: Websites,
      },
      {
        path: 'groups_types/:id?',
        name: 'groups_types',
        component: GroupsTypes,
      },
      {
        path: 'tags/:id?',
        name: 'tags',
        component: Tags,
      },
      {
        path: 'placements_groups/:id?',
        name: 'placements_groups',
        component: PlacementsGroups,
      },
      {
        path: 'players/:id?',
        name: 'players',
        component: Players,
      },
      {
        path: 'videos/:id?',
        name: 'videos',
        component: Videos,
      },
      {
        path: 'placements_groups_data/:id?',
        name: 'placements_groups_data',
        component: PlacementsGroupsData,
      }, 
      {
        path: 'statistics/',
        name: 'statistics',
        component: Statistics,
        children: [
          {
            path: 'new',
            name: 'new_statistics',
            component: NewStatistics,
          }, 
          {
            path: 'data/:id?',
            name: 'data_statistics',
            component: DataStatistics,
          }, 
          {
            path: 'group/:id?',
            name: 'group_statistics',
            component: GroupStatistics,
          }, 
          {
            path: 'user/group/:id?',
            name: 'user_statistics',
            component: UserStatistics,
          }, 
          {
            path: 'website/:id?',
            name: 'website_statistics',
            component: WebsitesStatistics,
          },
        ],
      },
      {
        path: 'demand_sources/:id?',
        name: 'demand_sources',
        component: Suppliers,
      }, 
      {
        path: 'shares',
        name: 'shares',
        component: Shares,
        children: [ 
          {
            path: 'demand_sources/:id?',
            name: 'demand_sources-shares',
            component: SuppliersShares
          },
          {
            path: 'adcrew/:id?',
            name: 'adcrew-shares',
            component: AdCrewShares
          },
          {
            path: 'pmp/:id?',
            name: 'pmp-shares',
            component: PMPShares
          },
          {
            path: 'network/:id?',
            name: 'network-shares',
            component: NetworkShares
          },
        ]
      }, 
      {
        path: 'users',
        name: 'users',
        component: UsersList,
      },
      {
        path: 'users/new',
        name: 'new_user',
        component: NewUser
      },
      {
        path: 'users/:id?',
        name: 'user',
        component: User,
        props: {
          id: {
            type: Number,
          },
        }, 
      },
      {
        path: 'credentials',
        component: Credentials,
        children: [
          {
            path: '',
            name: 'credentails',
          },
          {
            path: 'groups',
            name: 'groups_credentails',
            component: GroupsCredentials
          },
          {
            path: 'permissions',
            name: 'permissions',
            component: Permissions
          },
          {
            path: 'assignments',
            name: 'assignments',
            component: Assignments
          },
          {
            path: 'services',
            name: 'services',
            component: Services
          },
          {
            path: 'api-keys/:id?',
            name: 'api_keys',
            component: APIKeys
          }
        ]
      },
      {
        path: 'payments',
        name: 'payments',
        component: Wallets,
      },
      {
        path: 'payments/:id?',
        name: 'payment',
        component: Wallet,
      },
      {
        path: 'Whitelabel/:id?',
        name: 'Whitelabel',
        component: Whitelist,
      },
      {
        path: 'profile',
        name: 'profile',
        component: Profile,
      },
      {
        path: 'settings',
        name: 'settings',
        component: Settings,
        children: [ 
          {
            path: 'metrics/:id?',
            name: 'metrics',
            component: Metrics
          },
          {
            path: 'api/:id?',
            name: 'api',
            component: API
          },
        ]
      },
      {
        path: 'logout',
        name: 'logout',
        component: ErrorDenied,
        beforeEnter: logoutUser,
      },
      {
        path: 'denied',
        name: 'denied',
        component: ErrorDenied,
        meta: { 
          requiresAuth: true
        }
      },
      {
        path: 'suspended',
        name: 'suspended',
        component: ErrorSuspended,
        meta: { 
          requiresAuth: true
        }
      },
      {
        // Error message for unavailable pages
        path: '*',
        name: 'Not Found',
        component: Error404,
        meta: { 
          requiresAuth: true
        }
      },
    ]
  },
]})
