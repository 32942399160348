<template>
<div class="row">
    <div class="col-md-3 col-sm-6 col-12">
    <div class="info-box bg-gradient-info">
        <span class="info-box-icon">
            <i class="fa fa-users"></i><br />
            {{ users.total }}
        </span>
        <div class="info-box-content">
            <span class="info-box-text">Active</span>
            <span class="info-box-number">{{ users.active }}</span>
        </div>
        <div class="info-box-content">
            <span class="info-box-text">Suspended</span>
            <span class="info-box-number">{{ users.suspended }}</span>
        </div>
        <div class="info-box-content">
            <span class="info-box-text">Verified</span>
            <span class="info-box-number">{{ users.verified }}</span>
        </div>
        <div class="info-box-content">
            <span class="info-box-text">Unverified</span>
            <span class="info-box-number">{{ users.unverified }}</span>
        </div>
        <!-- /.info-box-content -->
    </div>
    <!-- /.info-box -->
    </div>
    <!-- /.col -->
    <div class="col-md-3 col-sm-6 col-12">
    <div class="info-box bg-gradient-info">
        <span class="info-box-icon">
            <i class="fa fa-globe"></i><br />
            {{ websites.count.total }}
        </span>

        <div class="info-box-content">
            <span class="info-box-text">Active</span>
            <span class="info-box-number">{{ websites.count.active }}</span>
        </div>
        <div class="info-box-content">
            <span class="info-box-text">Suspended</span>
            <span class="info-box-number">{{ websites.count.suspended }}</span>
        </div>
        <!-- /.info-box-content -->
    </div>
    <!-- /.info-box -->
    </div>
    <!-- /.col -->
    <div class="col-md-3 col-sm-6 col-12">
    <div class="info-box bg-gradient-info">
        <span class="info-box-icon">
            <i class="fa fa-sitemap"></i><br />
            {{ networks.total }}
        </span>

        <div class="info-box-content">
            <span class="info-box-text">Users</span>
            <span class="info-box-number">{{ networks.users }}</span>
        </div>
        <div class="info-box-content">
            <span class="info-box-text">Websites</span>
            <span class="info-box-number">{{ networks.websites }}</span>
        </div>
        <!-- /.info-box-content -->
    </div>
    <!-- /.info-box -->
    </div>
    <!-- /.col -->
    <div class="col-md-3 col-sm-6 col-12">
    <div class="info-box bg-gradient-info">
        <span class="info-box-icon">
            <i class="fa fa-ticket-alt"></i><br />
            {{ tickets.total }}
        </span>

        <div class="info-box-content">
            <span class="info-box-text">Open</span>
            <span class="info-box-number">{{ tickets.open }}</span>
        </div>
        <div class="info-box-content">
            <span class="info-box-text">On Hold</span>
            <span class="info-box-number">{{ tickets.on_hold }}</span>
        </div>
        <div class="info-box-content">
            <span class="info-box-text">Closed</span>
            <span class="info-box-number">{{ tickets.closed }}</span>
        </div>
        <!-- /.info-box-content -->
    </div>
    <!-- /.info-box -->
    </div>
    <!-- /.col -->
</div>

</template>

<script>
import { RepositoryFactory } from '@/services/repositories/ManagementAPI/RepositoryFactory'
const WebsitesRepo = RepositoryFactory.get('websites')
const AuthRepo = RepositoryFactory.get('auth_status')

export default {
    data() {
        return {
            users: {
                total: 0,
                active: 0,
                suspended: 0,
                verified: 0,
                unverified: 0
            },
            networks: {
                total: 0,
                users: 0,
                websites: 0 
            },
            websites: {
                count: {
                    total: 0,
                    active: 0,
                    suspended: 0
                }
            },
            tickets:{
                total: 0,
                open: 0,
                on_hold: 0,
                closed: 0
            }
        }
    },
    created() {
        this.loadUsersStatus()
        this.loadWebsiteStatus()
    },
    methods: {
        loadUsersStatus: function() {
            AuthRepo.status().then(result => {
                this.users = result.data.users
                this.networks = result.data.networks
            }).catch(err => {})
        },
        loadWebsiteStatus: function() {
            WebsitesRepo.status().then(result => {
                this.websites = result.data
            }).catch(err => {})
        }
    },
}
</script>