<template>
    <div class="card">
        <div class="card-header">
            <h3 v-if="website" class="card-title">
                ( <router-link :to="{name: 'websites'}"> {{ website.name }} </router-link>) List
            </h3>

            <div class="card-tools">
                <div class="btn-group btn-group-sm">
                    <button class="btn btn-primary" @click.prevent="displayWebsiteReport()">
                        <i class="fa fa-chart-bar"></i> Site Report
                    </button>
                    <button class="btn btn-primary" @click.prevent="showFillRateModel = true">
                        <i class="fa fa-fill"></i> Fill Rate
                    </button>
                    <button class="btn btn-primary dropdown" data-toggle="dropdown">
                        Shares <i class="fa fa-caret-down"></i>
                        
                        <div class="dropdown-menu" x-placement="bottom-start">
                            <a class="dropdown-item" tabindex="-1" href="#" @click.prevent="showAdCrewSharesModel = true">AdCrew Shares</a>
                            <a class="dropdown-item" v-if="user_role() == 'admin'" tabindex="-1" href="#" @click.prevent="showPMPSharesModel = true">PMP Shares</a>
                            <a class="dropdown-item" tabindex="-1" href="#" @click.prevent="showNetworkSharesModel = true">Network Shares</a>
                        </div>
                    </button>
                </div>
                <div class="mt-2 input-group input-group-sm" style="width: 170px;">
                    <input type="search" name="table_search" class="form-control float-right" placeholder="Search" v-model="search_text">
                    <div class="input-group-append">
                        <button class="btn btn-default" @click.prevent="loadSearchResult()"><i class="fas fa-search"></i></button>
                    </div>
                </div>
            </div>
        </div>
        <!-- /.card-header -->
        <div class="card-body table-responsive p-0">
            
        <table class="table table-hover responsive">
            <thead>
            <tr>
                <th>Name</th>
                <th>Group</th>
                <th>Status</th>
                <th class="text-center">Action</th>
            </tr>
            </thead>
            <tbody>
                <tr v-for="placement_group in placement_groups" :key="placement_group.id" v-animate-css="'slideInLeft'">
                    <td>{{ placement_group.name }}</td>
                    <td>{{ GroupName(placement_group.group_id) }}</td>
                    <td>
                        <span class="alert alert-success btn-xs" :class="{
                            'alert-success': placement_group.status == 'active',
                            'alert-danger': placement_group.status == 'suspended'}"> {{ placement_group.status | capitalize }} 
                        </span>
                    </td>
                    <td class="text-center">
                    <div class="btn-group">
                        <router-link :to="{name: 'placements_groups', params: {'id': placement_group.id}, query: {'website': placement_group.website_id} }" class="btn btn-default btn-sm">
                        <i class="fa fa-edit"></i> Edit</router-link>
                        <router-link :to="{name: 'placements_groups_data', query: {'placement_group': placement_group.id} }" class="btn btn-default btn-sm">
                        <i class="fa fa-align-justify"></i>
                        Data</router-link>
                        <button class="btn btn-default btn-sm" @click.prevent="displayReport(placement_group)"><i class="fa fa-chart-bar"></i> Reports</button>
                    </div>
                    </td>
                </tr>
            </tbody>
        </table>

        </div>

        <div class="overlay" v-if="!isLoaded">
            <point-spinner />
        </div>

        <AdCrewSharesModel 
            v-if="website.id" 
            :website_id="website.id" 
            :groups="groups"
            :show="showAdCrewSharesModel" 
            @close="AdCrewModelClosed" 
        />
        <PMPSharesModel 
            v-if="website.id && user_role() == 'admin'" 
            :website_id="website.id"
            :groups="groups"
            :show="showPMPSharesModel" 
            @close="PMPModelClosed" 
        />
        <NetworkSharesModel 
            v-if="website.id"
            :website_id="website.id"
            :groups="groups"
            :show="showNetworkSharesModel" 
            @close="NetworkModelClosed" 
        />
        <FillRateModel 
            v-if="website.id"
            :website_id="website.id"
            :groups="groups"
            :show="showFillRateModel" 
            @close="FillRateModelClosed" 
        />

        <!-- 
        <div class="overlay dark" v-if="hasError">
        <i class="fas fa-2x fa-exclamation-triangle"></i>
        </div> 
        -->
    </div>
</template>

<script>

import AdCrewSharesModel from '@/components/shares/adcrew/Model'
import PMPSharesModel from '@/components/shares/pmp/Model'
import NetworkSharesModel from '@/components/shares/network/Model'
import FillRateModel from '@/components/fill_rate/Model'

export default {
    props: {
        website: {
            required: true,
            type: Object
        },
        groups: {
            required: true,
            type: Array
        },
        placement_groups: {
          required: true,
          type: Array
        },
        isLoaded: {
          required: false,
          type: Boolean
        }
    },
    components: {
        AdCrewSharesModel,
        PMPSharesModel,
        NetworkSharesModel,
        FillRateModel
    },
    data() {
        return {
            showAdCrewSharesModel: false,
            showPMPSharesModel: false,
            showNetworkSharesModel: false,
            showFillRateModel: false,
            search_text: ""
        }
    },
    created() {

    },
    methods: {
        GroupName: function(group_id) {
            var group = this.groups.filter(element => { return element.id == group_id })[0]
            if(group)
                return group.name
        },
        AdCrewModelClosed() {
            this.showAdCrewSharesModel = false
        },
        PMPModelClosed() {
            this.showPMPSharesModel = false
        },
        NetworkModelClosed() {
            this.showNetworkSharesModel = false
        },
        FillRateModelClosed() {
            this.showFillRateModel = false
        },
        displayWebsiteReport() {
            var data = {
                name: this.website.name,
                id: this.website.id
            }
            this.$emit('displayWebsiteReport', data)
        },
        displayReport(pg) {
            var data = {
                name: pg.name,
                id: pg.id
            }
            this.$emit('displayReport', data)
        },
        loadSearchResult() {
            this.$emit("search", this.search_text)
        },
        user_role() {
            return this.$store.getters.user.role
        }
    },
    watch: {
      search_text: function() {
        this.loadSearchResult()
      }
    }
}
</script>

<style scoped>

</style>