import Repository from './../Repository';

const resource = "/shares/supplier";
export default {
    page(supplier) {
        return Repository.get(`${resource}/?supplier=${supplier}`);
    },
    all(supplier) {
        return Repository.get(`${resource}/?supplier=${supplier}`);
    },
    get(id) {
        return Repository.get(`${resource}/${id}/`);
    },
    post(data) {
        return Repository.post(`${resource}/`, data);
    },
    delete(id) {
        return Repository.delete(`${resource}/${id}/`);
    },
}