<template>
     <!-- Monetization -->
    <div class="border mb-2 rounded-lg font-weight-bold text-dark over-flow-visible" v-if="monetization">
        <nav class="m-2">
            <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                <li class="nav-item has-treeview menu-close">
                    <a class="nav-link flex align-items-center text-success" style="cursor: pointer;">
                    <i class="nav-icon fas fa-dollar-sign"></i>
                    <div class=""> Monetization </div><i class="fas fa-angle-left ml-auto"></i> 
                    </a>
                    <ul class="m-3 nav nav-treeview">
                        <li class="nav-item py-4 font-weight-normal">
                            <div class="d-flex justify-content-start align-items-center">
                                <div class="col-md-6">
                                    Monetize 
                                </div>
                                <div class="col-md-6">
                                    <div class="inline">
                                        <div class="custom-control custom-switch p-0 py-2">
                                            <label class="switch" for="montize">
                                                <input type="checkbox" class="primary" id="montize" v-model="monetization.monetize">
                                                <span class="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>    
                        </li>
                        <li class="nav-item mb-4 font-weight-normal" style="display:none;">
                            <div class="d-flex justify-content-start align-items-center">
                                <div class="col-md-6 ">
                                    Disable player if ad blocker detected <i class="fas fa-question-circle"></i>
                                </div>
                                <div class="col-md-6">
                                    <div class="inline">
                                        <div class="custom-control custom-switch p-0 py-2">
                                            <label class="switch" for="disablePlayerAdBlock">
                                                <input type="checkbox" class="primary" id="disablePlayerAdBlock" v-model="monetization.disable_player_ad_block">
                                                <span class="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>    
                        </li>
                        <li class="nav-item mb-4 font-weight-normal" style="display:none;">
                            <div class="d-flex justify-content-start align-items-center">
                                <div class="col-md-6 ">
                                    Disable autoplay if ad blocker detected <i class="fas fa-question-circle"></i>
                                </div>
                                <div class="col-md-6">
                                    <div class="inline">
                                        <div class="custom-control custom-switch p-0 py-2">
                                            <label class="switch" for="disableAutoPlayAdBlock">
                                                <input type="checkbox" class="primary" id="disableAutoPlayAdBlock" v-model="monetization.disable_autoplay_ad_block">
                                                <span class="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>    
                        </li>
                        <li class="nav-item py-4 font-weight-normal">
                            <div class="d-flex justify-content-start">

                                 <select class="form-control" v-model="monetization.schedule.type">
                                    <option value="vmap">VMAP</option>
                                    <option value="vast" selected>VAST</option>
                                    <option value="multiple_vast">Multiple VAST</option>
                                </select>
            
                                <button type="button" class="btn btn-primary mx-2" 
                                v-if="monetization.schedule.type === 'vmap'"
                                @click.prevent="isPreroll = true"
                                :class="{'btn-light' : isPreroll}"> Preroll </button>

                                <button type="button"  class="btn btn-secondary mx-2"
                                v-if="monetization.schedule.type === 'vmap'" 
                                @click.prevent="isMidroll = true" 
                                :class="{'btn-light' : isMidroll}"> Midroll </button>

                                <button type="button" class="btn btn-success mx-2"
                                v-if="monetization.schedule.type === 'vmap'" 
                                @click.prevent="isPostroll = true"
                                :class="{'btn-light' : isPostroll}"> Postroll </button>

                                <button type="button" class="btn btn-danger mx-2"
                                v-if="monetization.schedule.type === 'vast'" 
                                @click.prevent="isVast = true"
                                :class="{'btn-light' : isVast}"> Vast </button>

                                <button type="button" class="btn btn-danger mx-2"
                                v-if="monetization.schedule.type === 'multiple_vast'" 
                                @click.prevent="isMultipleVast = true"
                                :class="{'btn-light' : isMultipleVast}"> Multiple Vast </button>

                                <button type="button" class="btn btn-danger mx-2" 
                                @click.prevent="isDisplayBanner = true"
                                :class="{'btn-light' : isDisplayBanner}"> Display Banner </button>
                            </div>
                        </li>

                        <li v-if="isPreroll || monetization.schedule.preroll.length > 0" >
                            <Schedule 
                            :schedules=monetization.schedule.preroll
                            schedule_type="preroll"
                            @delete=onScheduleDelete
                            @changed=onScheduleChanged />
                        </li>

                        <li v-if="isMidroll || monetization.schedule.midroll.length > 0" >
                            <Schedule 
                            :schedules=monetization.schedule.midroll
                            schedule_type="midroll"
                            @delete=onScheduleDelete
                            @changed=onScheduleChanged />
                        </li>

                        <li v-if="isPostroll || monetization.schedule.postroll.length > 0" >
                            <Schedule 
                            :schedules=monetization.schedule.postroll
                            schedule_type="postroll"
                            @delete=onScheduleDelete
                            @changed=onScheduleChanged />
                        </li>

                        <li v-if="isDisplayBanner || monetization.schedule.display_banner != null" >
                            <Schedule 
                            :schedules=monetization.schedule.display_banner
                            schedule_type="display_banner"
                            @delete=onScheduleDelete
                            @changed=onScheduleChanged />
                        </li>

                        <li v-if="isVast || monetization.schedule.vast != null" >
                            <Schedule 
                            :schedules=monetization.schedule.vast
                            schedule_type="vast"
                            @delete=onScheduleDelete
                            @changed=onScheduleChanged />
                        </li>

                        <li v-if="isMultipleVast || monetization.schedule.multiple_vast.length > 0" >
                            <Schedule 
                            :schedules=monetization.schedule.multiple_vast
                            schedule_type="multiple_vast"
                            @delete=onScheduleDelete
                            @changed=onScheduleChanged />
                        </li>
                        <li>
                            <hr><br>
                            <button class="btn btn-success float-right" @click.prevent="update()">Save</button>
                            <br>
                        </li>
                    </ul>
                </li>
            </ul>
        </nav>
    </div>
    <!-- Monetization -->
</template>
<script>
import Schedule from './Schedule.vue'

import { RepositoryFactory } from '@/services/repositories/PlayerAPI/RepositoryFactory'
const MonetizationRepo = RepositoryFactory.get('monetization')
const SchedulesRepo = RepositoryFactory.get('schedules')

export default {
    props: {
        monetization: {
            type: Object,
        }
    },
    components: {
        Schedule
    },
    data() {
        return {
            isPreroll: false,
            isMidroll: false,
            isPostroll: false,
            isDisplayBanner: false,
            isVast: false,
            isMultipleVast: false,
            //schedule: {}
        }
    },
    methods: {
        update() {
            this.updateMonetization()
            this.updateSchedule()
        },
        updateMonetization() {
            MonetizationRepo.patch(this.monetization.id, this.monetization).then(result => {
                this.$notify({
                    group: 'ops',
                    type: 'success',
                    title: 'Operation success',
                    text: 'Successfully updated monetization.'
                })
            }).catch(error => {
                this.$notify({
                    group: 'ops',
                    type: 'error',
                    title: 'Operation failed',
                    text: 'Failed update monetization.'
                })
            })
        },
        updateSchedule() {
            SchedulesRepo.patch(this.monetization.schedule.id, this.monetization.schedule).then(result => {
                this.$notify({
                    group: 'ops',
                    type: 'success',
                    title: 'Operation success',
                    text: 'Successfully updated schedule.'
                })
            }).catch(error => {
                this.$notify({
                    group: 'ops',
                    type: 'error',
                    title: 'Operation failed',
                    text: 'Failed updated schedule.'
                })
            })

        },
        onScheduleDelete(type) {
            switch (type) {
                case 'preroll':
                    this.isPreroll = false
                    this.monetization.schedule.preroll = []
                    break
                
                case 'midroll':
                    this.isMidroll = false
                    this.monetization.schedule.midroll = []
                    break
                
                case 'postroll':
                    this.isPostroll = false
                    this.monetization.schedule.postroll = []
                    break
                
                case 'display_banner':
                    this.isDisplayBanner = false
                    this.monetization.schedule.display_banner = null
                    break

                case 'vast':
                    this.isVast = false
                    this.monetization.schedule.vast = null
                    break
                
                case 'multiple_vast':
                    this.isMultipleVast = false
                    this.monetization.schedule.multiple_vast = []
                    break
            }
            // this.updateSchedule()
        },
        onScheduleChanged(selection) {
            switch (selection.type) {
                case 'preroll':
                    this.monetization.schedule.preroll = selection.data
                    break
                
                case 'midroll':
                    this.monetization.schedule.midroll = selection.data
                    break
                
                case 'postroll':
                    this.monetization.schedule.postroll = selection.data
                    break
                
                case 'display_banner':
                    if(selection.data.id) {
                        this.monetization.schedule.display_banner = selection.data.id
                        break;
                    }
                    this.monetization.schedule.display_banner = selection.data[0]
                    break

                case 'vast':
                    if(selection.data.id) {
                        this.monetization.schedule.vast = selection.data.id
                        break;
                    }
                    this.monetization.schedule.vast = selection.data[0]
                    break
                
                case 'multiple_vast':
                    this.monetization.schedule.multiple_vast = selection.data
                    break
            }
            // this.updateSchedule()
        }
    },
    watch: {
        '$route.query.website': { 
            handler: function (website) {
                if(typeof website !== 'undefined') {
                    // check if user is not loaded 
                    // website placements_groups before
                    if(this.website_id !== website) {
                        // match website with passed from query
                        this.website_id = website
                    }
                } else {
                    this.$router.push({name: "website"})
                }
            },
            deep: true,
            immediate: true
        },
    }
}
</script>