<template>
    <!-- Style -->
    <div class="border mb-2 rounded-lg font-weight-bold text-dark" v-if="styles">
        <nav class="m-2">
            <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                <li class="nav-item has-treeview menu-close">
                    <a class="nav-link flex align-items-center text-danger" style="cursor: pointer;">
                    <i class="nav-icon fas fa-paint-brush"></i>
                    <div class=""> Style </div><i class="fas fa-angle-left ml-auto"></i> 
                    </a>
                    <ul class="m-3 nav nav-treeview">
                        <li class="row">
                            <div class="col-md-4">
                                <input type="file" ref="file" @change="onChangeFileUpload" style="display: none;" />
                                <img class="img-fluid cursor-pointer logo-standerd border" :src="styles.logo_url || imagePath" @click="$refs.file.click()" style="background-color: #ccc;">
                                <a href="#" @click="showLogoBrowserModal()" class="btn-block">Browse</a>
                            </div>
                            <div class="col-md-8">
                                <label>Powered By</label>
                                <input v-model="styles.powered_by_alt" type="text" class="form-control" id="poweredBy" >
                                <br />
                                <label>Clickthrought URL</label>
                                <input v-model="styles.clickthrough_url" type="text" class="form-control" id="clickthrough_url">
                                <br />
                            </div>
                            <div class="col-md-12 mt-10">
                                <label for="custom_css">Custom CSS</label>
                                <textarea class="form-control" id="custom_css" rows="6" v-model="styles.custom_css"></textarea>
                                <br />
                            </div>
                        </li>
                        <li>
                            <hr><br>
                            <button class="btn btn-success float-right" @click.prevent="update()">Save</button>
                            <br>
                        </li>
                    </ul>
                </li>
            </ul>
        </nav>
        <logos-modal :player_id="styles.player" />
    </div>
    <!-- Style -->
</template>
<script>
import LogosModal from './ContentBrowser/Logos'

import { RepositoryFactory } from '@/services/repositories/PlayerAPI/RepositoryFactory'
const StylesRepo = RepositoryFactory.get('styles')

export default {
    components: {
        LogosModal
    },
    props: {
        styles: {
            type: Object,
        }
    },
    data() {
        return {
            isLogoLoaded: false,
            selectedFile: '',
            imagePath: '/assets/img/boxed-bg.png'
        }
    },
    methods: {
        update() {
            delete this.styles.logo_url
            StylesRepo.patch(this.styles.id, this.styles).then(result => {
                this.styles = result.data
                this.$notify({
                    group: 'ops',
                    type: 'success',
                    title: 'Operation success',
                    text: 'Successfully updated the player styles.'
                })
            }).catch(error => {
                this.$notify({
                    group: 'ops',
                    type: 'error',
                    title: 'Operation failed',
                    text: 'Failed to update the player styles.'
                })
            })
        },
        onUpload() {
            let formData = new FormData();
            formData.append('logo_url', this.selectedFile, this.selectedFile.name);
            StylesRepo.patch(this.styles.id, formData).then(result => {
                this.$notify({
                    group: 'ops',
                    type: 'success',
                    title: 'Operation success',
                    text: 'Successfully uploaded logo.'
                })
            }).catch(err => {
                this.$notify({
                    group: 'ops',
                    type: 'error',
                    title: 'Operation failed',
                    text: 'Failed to upload logo.'
                })
            })
        },
        onChangeFileUpload() {
            this.selectedFile = this.$refs.file.files[0]
            this.imagePath = URL.createObjectURL(this.selectedFile)
            this.onUpload()
        },
        showLogoBrowserModal() {
            $(`#logoBrowserModal`).modal()
      },
    },
}
</script>
<style scoped>
    .logo-standerd {
        display: block;
        height: 256px;
        max-height: 165px;
        width: 300px;
    }
    .logo-standerd:hover {
        box-shadow: 0 3px 6px rgba(0,0,0,.16),0 3px 6px rgba(0,0,0,.23)!important;    
    }
</style>