import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    permissions: '',
    selectedPermissions: '',
    fullListPermissions: '',
    groups: '',
    selectedGroups: '',
    fullListGroups: '',
  },
  getters: {
    permissions: state => state.permissions,
    selectedPermissions: state => state.selectedPermissions,
    fullListPermissions: state => state.fullListPermissions,
    groups: state => state.groups,
    selectedGroups: state => state.selectedGroups,
    fullListGroups: state => state.fullListGroups,
  },
  mutations: {
    SET_PERMISSIONS: (state, action) => {
      state.permissions = action
    },
    SET_SELECTED_PERMISSIONS: (state, action) => {
      state.selectedPermissions = action
    },
    SET_GROUPS: (state, action) => {
      state.groups = action
    },
    SET_SELECTED_GROUPS: (state, action) => {
      state.selectedGroups = action
    },
  },
  actions: {
    REMOVE_SELECTED_PERMISSIONS: ({commit, dispatch, state}, selectedPermissions) => {
      selectedPermissions.forEach(element => {
        state.permissions.pop({'id': element.id})
      });
    },
  }
})
