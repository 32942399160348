<template>
<div>
      <!-- Default box -->
      <div class="card card-solid">
        <div class="card-header row">
        <div class="col-md-4">
          <h3 class="card-title"> Statistics Graph </h3>
        </div>

        <div class="col-md-3 col-xs-12 ">
            <select id="website" name="website" class="form-control select2bs4">
                <option selected disabled hidden> -- Websites --</option>
                <option v-for="website in websites" :key="website.id" :value="website.id" >{{ website.name }}</option>
            </select>
        </div>
        <div class="col-md-3 col-xs-12">
            <select id="group" name="group" class="form-control select2bs4" :disabled="!selectedWebsite">
                <option disabled selected> -- Ad Type -- </option>
                <option value="all"> All </option>
                <option v-for="group in groups" :key="group.id" :value="group.id" >{{ group.name }}</option>
            </select>
        </div>
        <div class="col-md-2 col-xs-12">
            <select id="type" class="form-control select2bs4">
                <option value="user">User</option>
                <option selected value="admin">Admin</option>
            </select>
        </div>

        </div>
        <div class="card-body table-responsive pb-0">
            <ChartJS :data="statistics" /> 
            <hr />
            <Sparklines :data="statistics" /> 
            <hr />
            <Yesterday :data="statistics" />
        </div>
        <!-- /.card-body -->
      </div>
      <!-- /.card -->
</div>
</template>

<script>
import ChartJS from '@/components/analytics/ChartJS'
import Sparklines from '@/components/analytics/Sparklines'
import Yesterday from '@/components/analytics/Yesterday'

import { RepositoryFactory } from '@/services/repositories/ManagementAPI/RepositoryFactory';
const WebsitesRepo = RepositoryFactory.get('websites');
const GroupsRepo = RepositoryFactory.get('groups');
const DataStatistics = RepositoryFactory.get('data_statistics')
const GroupsStatistics = RepositoryFactory.get('groups_statistics')
const WebsitesStatistics = RepositoryFactory.get('websites_statistics')
const UserGroupsStatistics = RepositoryFactory.get('user_groups_statistics')

export default {
    name: 'Dashboard',
    components: {
        ChartJS,
        Sparklines,
        Yesterday
    },
    data() {
        return {
            websites: [],
            selectedWebsite: "",
            groups: [],
            selectedGroup: "",
            
            selectedType: "",
            
            statistics: [],
            currentPage: 1,
            totalRecords: 0,
            resultsLimit: 30,
            isLoading: false
        }
    },
    created() {
        this.loadWebsites()
    },
    mounted() {
        //Initialize Select2 Elements
        $('.select2bs4').select2({
            theme: 'bootstrap4'
        })

        var self = this
        $('#website').on("change", function(e) { 
            self.selectedWebsite = $(this).val()
        });
        
        $('#group').on("change", function(e) { 
            self.selectedGroup = $(this).val()
        });

        $('#type').on("change", function(e) { 
            self.selectedType = $(this).val()
        });
    },
    methods: {
        loadWebsites: function() {
            WebsitesRepo.all().then(result => {
                this.websites = result.data.results
            })
        },
        loadGroups: function() {
            GroupsRepo.page(this.selectedWebsite).then(result => {
                this.groups = result.data.results
            })
        },
        loadWebsiteStatistics: function() {
            this.isLoading = true
            WebsitesStatistics.page(this.selectedWebsite, this.currentPage, this.resultsLimit).then(result => {
                this.totalRecords = result.data.count
                this.statistics = result.data.results
            }).catch(err => {})
            this.isLoading = false
        },
        loadGroupStatistics: function(group_id) {
            this.isLoading = true
            GroupsStatistics.page(group_id, this.currentPage, this.resultsLimit).then(result => {
                this.totalRecords = result.data.count
                this.statistics = result.data.results
            }).catch(err => {})
            this.isLoading = false
        },
        loadUserGroupStatistics: function(group_id) {
            this.isLoading = true
            UserGroupsStatistics.page(group_id, this.currentPage, this.resultsLimit).then(result => {
                this.totalRecords = result.data.count
                this.statistics = result.data.results
            }).catch(err => {})
            this.isLoading = false
        },
        reloadResults: function() {
            if(this.selectedGroup === 'all') {
                this.loadWebsiteStatistics()
            } else {
                if(this.selectedType === 'admin') {
                    this.loadGroupStatistics(this.selectedGroup)
                } else if(this.selectedType === 'user') {
                    this.loadUserGroupStatistics(this.selectedGroup)
                }
            }
        },
    },
    computed: {
        pageCount: function() {
            return Math.ceil(this.totalRecords / this.resultsLimit)
        },
    },
    watch: {
        selectedWebsite: function(id) {
            this.loadGroups()
        },
        selectedGroup: function(id) {
            this.reloadResults(id)
        },
        selectedType: function(id) {
            this.reloadResults(id)
        },
    },
}
</script>
<style>

</style>