<template>
    <div class="card" v-animate-css="'fadeInLeft'">

        <div class="card-header">
            <h3 class="card-title">
                <span v-if="player.id">Options for ({{ player.name }})</span>
                <span v-else>Create new player</span>
            </h3>
            <div class="card-tools">
                <div class="overlay" v-if="isLoading">
                    <i class="fas fa-sync-alt fa-spin fa-1x"></i>
                </div>
                <div class="card-tools" v-if="!isLoading">
                  <button class="btn btn-tool btn-sm" @click.prevent="clear()" v-if="player.id">
                        <i class="fa fa-plus"></i>
                    </button>

                    <button class="btn btn-tool btn-sm" @click.prevent="reloadPlayer(player.id)" v-if="player.id">
                        <i class="fa fa-sync" v-if="!refreshing"></i>
                        <i class="fa fa-sync fa-spin fa-1x" v-if="refreshing"></i>
                    </button>

                    <button class="btn btn-tool dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" v-if="player.id">
                        <i class="fas fa-ellipsis-v"></i>
                    </button>
                    <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton" v-if="player.id">
                        <li class="dropdown-item">
                            <a @click="showEmbedCodeModel(player)" class="btn-block">
                            <i class="fas fa-code mr-2"></i>Embed</a>
                        </li>
                        <li class="dropdown-item">
                            <router-link :to="{name: 'analytics', query: {'player': player.id} }" class="btn-block">
                                <i class="fas fa-chart-line mr-2"></i>Analytics
                            </router-link>
                        </li>   
                        <li class="dropdown-item">
                            <a class="btn-block" @click.prevent="copyLink(player.id)">
                                <i class="fas fa-link mr-2"></i>Copy link
                            </a>
                        </li>   
                        <li class="dropdown-item">
                            <a class="btn-block" @click.prevent="clearCacheForPlayer(player.id)">
                                <i class="fas fa-eraser mr-2"></i>Clear cache
                            </a>
                        </li>   
                    </ul>        
                </div>
            </div>
        </div>

        <div class="card-body">
            <Player :player=player :website_id=website_id @updateList="updateList" @refreshList="refreshList" />
            <Format :format="player.format" />
            <BasicSetting :settings="player.settings" />
            <AdCrewSetting :settings="player.adcrew_settings" />
            <Monetization :monetization="player.monetization" />
            <StyleComponent :styles="player.style" />
            <ContentSelection :content="player.content" />
            <Blocklist :blocklist="player.blocklist" />
            <Preview :player="player" />
        </div>

    </div>
</template>
<script>
import Player from './Player'
import BasicSetting from './Settings/Basic'
import AdCrewSetting from './Settings/AdCrew'
import Monetization from './Monetization'
import StyleComponent from './Style'
import ContentSelection from './ContentSelection'
import Format from './Format'
import Blocklist from './Blocklist'
import Preview from './Preview'

import EmbedCodeModel from './embed/Code.vue'
import { RepositoryFactory as ManagementAPI } from '@/services/repositories/ManagementAPI/RepositoryFactory'
const PurgeCacheRepo = ManagementAPI.get('purge_cache')

import { RepositoryFactory as PlayersAPI } from '@/services/repositories/PlayerAPI/RepositoryFactory'
const PlayersRepo = PlayersAPI.get('players')

export default {
    components: {
        Player,
        BasicSetting,
        AdCrewSetting,
        Monetization,
        StyleComponent,
        ContentSelection,
        Format,
        Blocklist,
        Preview,
        EmbedCodeModel
    },
    props: {
        player: {
            type: Object,
            required: false
        },
        website_id: {
            type: Number | String,
        },
        isLoading: {
            required: false,
            type: Boolean
        }
    },
    data() {
        return {
          refreshing: false
        }
    },
    methods: {
        clear: function() {
            this.player = {}
        },
        updateList: function(data) {
            this.$emit('updateList', data)
        },
        refreshList: function(data) {
            this.$emit('updateList', data)
        },
              showEmbedCodeModel(player) {
        this.selectedPlayer = player
        $(`#embedCodeModal`).modal()
      },
      reloadPlayer(player_id) {
        this.refreshing = true
        PlayersRepo.get(player_id).then(result => {
          this.player = result.data
          this.refreshing = false
        }).catch(error => {
          this.$notify({
              group: 'ops',
              type: 'error',
              title: 'Operation failed',
              text: 'Failed to reload player'
          })
          this.refreshing = false
        })
      },
      closeEmbedCodeModel() {},
      clearCacheForPlayer(player_id) {
        var link = `https://services.adcrew.co/api/v1/players/public/${player_id}/`
        PurgeCacheRepo.purge(link).then(result => {
          this.$notify({
              group: 'ops',
              type: 'success',
              title: 'Operation success',
              text: 'Successfully cleared cache.'
          })
        }).catch(error => {
            this.$notify({
                group: 'ops',
                type: 'error',
                title: 'Operation Faild',
                text: 'Faild to clear cache.'
            })
        });
      },
      copyLink(player_id) {
        var link = `https://services.adcrew.co/api/v1/players/public/${player_id}/`
        var testingCodeToCopy = document.createElement("textarea")
        testingCodeToCopy.value = link
        // Make sure we cant change the text of the textarea
        testingCodeToCopy.setAttribute('readonly', '');
        
        // Hide the textarea off the screnn
        testingCodeToCopy.style.position = 'absolute';
        testingCodeToCopy.style.left = '-9999px';
        
        // Add the textarea to the page
        document.body.appendChild(testingCodeToCopy);

        testingCodeToCopy.select()
        try {
            document.execCommand('copy')
            this.$notify({
                group: "notifications",
                type: 'success',
                title: "Clipboard",
                text: "Link Copied to clipboard"
            })
        } catch {
            this.$notify({
                group: "notifications",
                type: 'warn',
                title: "Clipboard",
                text: "Failed to copy link"
            })
        }
        testingCodeToCopy.remove()
      },
    },
}
</script>
<style lang="css" >
.over-flow-visible{
    overflow-y: scroll;
}
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 16px;
  float:right;
}

/* Hide default HTML checkbox */
.switch input {display:none;}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: .6px;
  bottom: .1px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}
input.primary:checked + .slider {
  background-color: #2196F3;
}
input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>

<style scoped>
.table-responsive{
  overflow : visible;
}
.dropdown-toggle::after {
  display: none;
}
a {
  cursor: pointer;
}
a:hover {
  color: #0056b3!important;
}
</style>