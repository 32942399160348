<template>
    <!-- Content Header (Page header) -->
    <section class="content-header" v-animate-css="'fadeInDown'">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>{{ pageName | capitalizeAndReplace}}</h1>
          </div>
          <div class="col-sm-6" v-if="pageName" >
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{name: 'home'}">Home</router-link>
              </li>
              <li class="breadcrumb-item active">
                <template v-for="(path, index) in crumbs">
                  <router-link :to="{path: path.to}">{{ path.text | capitalizeAndReplace }}</router-link>
                  <span> / </span>
                </template>
              </li>
            </ol>
          </div>
        </div>
      </div><!-- /.container-fluid -->
    </section>

</template>
<script>
export default {
    name: 'Breadcrumb',
    props: {
      pageName: String,
    },
    computed:{
      pageNameRoute:function(){
        return this.$route.name
      },
      crumbs: function() {
        let pathArray = this.$route.path.split("/")
        pathArray.shift()
        let breadcrumbs = pathArray.reduce((breadcrumbArray, path, idx) => {
          breadcrumbArray.push({
            path: path,
            to: breadcrumbArray[idx - 1]
              ? "/" + breadcrumbArray[idx - 1].path + "/" + path
              : "/" + path,
            text: this.$route.matched[idx].meta.breadCrumb || path,
          });
          return breadcrumbArray;
        }, [])
        return breadcrumbs;
      }
    },
}
</script>

<style scoped>

</style>