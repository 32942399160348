<template>
<div class="card bg-gradient" >
    <div class="card-header border-0">
        <h3 class="card-title">
            <i class="fas fa-th mr-1"></i>
            {{ share.type }} Share: 
        </h3>
        <div class="card-tools">
            <button type="button" class="btn btn-tool btn-sm" data-card-widget="collapse">
                <i class="fas fa-minus"></i>
            </button>
            <button type="button" class="btn btn-tool btn-sm" data-card-widget="remove" @click="close">
                <i class="fas fa-times"></i>
            </button>
        </div>
    </div>
    <div class="card-body">
        <div class="row">
            <input type="hidden" v-model="share.website_id" />
            <div class="col-md-3">
                <label for="text">Type</label>
                <select class="form-control" v-model="share.type">
                    <option value="adcrew"> AdCrew </option>
                    <option value="pmp"> PMP </option>
                    <option value="network"> Network </option>
                </select>
            </div>
            <div class="col-md-5">
                <label for="text">Group</label>
                <select class="form-control" v-model="share.group_id">
                    <option v-for="group in formats" :value="group.id" :key="group.id"> {{ group.name }} </option>
                </select>
            </div>
            <div class="col-md-4">
                <label for="text">Share</label>
                <input class="form-control" required="" style="text-align: center;" v-model="share.share" />
            </div>
        </div>

    </div>
</div>
</template>
<script>
export default {
    props: {
        formats: {
            required: true,
            type: Array
        },
        collect: 0
    },
    data() {
        return {
            share: {
                type: '',
                share: 0,
                group_id: 0,
            },
        }
    },
    created() {
    },
    methods: {
        FormatName(id) {
            var group = this.groups.filter(element => { return element.id == id })[0]
            if(group)
                return group.name
        },
        submit() {
            this.$emit('submit', this.share);
        },
        close() {
            this.share = undefined
            this.$emit('close', '')      
        }
    },
    watch: {
        'collect': function () {
            this.submit()
        },
    }
}
</script>