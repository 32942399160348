<template>
<div>
  <div class="row">
    <div class="col-md-5">
      <WebsitesList :websites='websites' :isLoaded='isLoaded' @search="loadSearchResult"/>

      <ul class="pagination pagination-sm m-0 float-right">
          <li class="page-item">
            <a class="page-link" href="#" @click.prevent="previousPage">Previous</a>
          </li>
          <li class="page-item">
            <a class="page-link">{{ currentPage }} / {{ pageCount }}</a>
          </li>
          <li class="page-item">
            <a class="page-link" href="#" @click.prevent="nextPage">Next</a>
          </li>
      </ul>
      <br />
    </div>
      
      <div class="col-md-7">
        <WebsiteView v-if="$route.params.id"
        :website="website" 
        :isLoading="isLoading" 
        @updateList="updateList" 
        @refreshList="refreshList" />
        
        <WebsiteStagesView v-else
        :website="website" 
        :isLoading="isLoading" 
        @updateList="updateList" 
        @refreshList="refreshList" />
        
      </div>
  </div>
</div>
</template>
<script>
import WebsitesList from '@/components/websites/List'
import WebsiteView from '@/components/websites/Website'
import WebsiteStagesView from '@/components/websites/WebsiteStages'

import { RepositoryFactory } from '@/services/repositories/ManagementAPI/RepositoryFactory';
const WebsitesRepo = RepositoryFactory.get('websites');

export default {
  components: {
    WebsitesList,
    WebsiteView,
    WebsiteStagesView
  },
  data() {
    return {
      websites: [],
      website: {
        ad_type: []
      },
      isLoaded: false,
      isLoading: false,

      currentPage: 1,
      totalRecords: 0,
      resultsLimit: 10,

      search_text: ""
    }
  },
  created: function() {
    this.loadWebsites()
  },
  methods: {
    loadWebsites: function() {
      this.isLoaded = false
      WebsitesRepo.page(this.currentPage, this.resultsLimit, this.search_text).then(result => {
        this.totalRecords = result.data.count
        this.isLoaded = true
        this.websites = result.data.results
      }).catch(err => {
        this.websites = []
      })
    },
    loadWebsite: function(website) {
      this.isLoading = true
      WebsitesRepo.get(website).then(result => {
        this.website = result.data
        this.isLoading = false
      }).catch(err => {
        this.website = {}
      })
    },
    loadSearchResult(search_text) {
      this.search_text = search_text
      this.currentPage = 1

      WebsitesRepo.page(this.currentPage, this.resultsLimit, search_text).then(result => {
        this.totalRecords = result.data.count
        this.websites = result.data.results
      }).catch(err => {
        this.websites = []
      })
    },
    updateList: function(website) {
        this.websites.push(website)
        this.$router.replace({params: {'id': website.id}})
    },
    refreshList: function(action) {
        if(action)
            this.loadWebsites()
    },
    nextPage: function() {
      if(this.currentPage != this.pageCount || this.currentPage > this.pageCount) {
        this.currentPage = this.currentPage + 1
        this.loadWebsites()
      }
    },
    previousPage: function() {
      if(this.currentPage != 1 ) {
        this.currentPage = this.currentPage - 1
        this.loadWebsites()
      }
    },
  },
  computed: {
    pageCount: function() {
      return Math.ceil(this.totalRecords / this.resultsLimit)
      },
    },
    watch: {
      '$route.params.id': {
          handler: function (id) {
              if(typeof id !== 'undefined') {
                this.loadWebsite(id)
              }
          },
          deep: true,
          immediate: true
      },
    }
}
</script>
<style scoped>
.center {
  margin: auto;
  width: 50%;
  background-color: gray;
  padding: 10px;
}
</style>