import Repository from '../Repository';

const resource = "/group_totals";
export default {
    get(id) {
        return Repository.get(`${resource}/${id}/`)
    },
    update(id, data) {
        return Repository.put(`${resource}/${id}/`, data)
    },
    page(id, page, limit=30, start_date='', end_date='', order='-date') {
        var offset = (page - 1) * limit
        
        var date_filter = ''
        if(start_date && end_date) {
            date_filter = `&start_date=${start_date}&end_date=${end_date}`
        }
        if(start_date && !end_date) {
            date_filter = `&date=${start_date}`
        }
        return Repository.get(`${resource}/?pgid=${id}${date_filter}&limit=${limit}&offset=${offset}&o=${order}`);
    },
    status() {
        return Repository.get(`${resource}/status/`)
    }
}