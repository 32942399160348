<template>
<div class="row">
    <div class="col-md-6">
        <SuppliersList 
            :suppliers="suppliers" 
            :groups_types="groups_types"
            :isLoaded="isLoaded"
            @search="loadSearchResult" 
        />

        <ul class="pagination pagination-sm m-0 float-right">
          <li class="page-item">
            <a class="page-link" href="#" @click.prevent="previousPage">Previous</a>
          </li>
          <li class="page-item">
            <a class="page-link">{{ currentPage }} / {{ pageCount }}</a>
          </li>
          <li class="page-item">
            <a class="page-link" href="#" @click.prevent="nextPage">Next</a>
          </li>
        </ul>
    </div>

    <div class="col-md-6">
        <SupplierView :supplier="supplier" :api_list="apiList" :isLoading="isLoading" @updateList="updateList" @refreshList="refreshList"/>
    </div>
</div>
</template>

<script>
import SuppliersList from '@/components/suppliers/List'
import SupplierView from '@/components/suppliers/Supplier'

import { RepositoryFactory } from '@/services/repositories/ManagementAPI/RepositoryFactory' 
const SuppliersRepo = RepositoryFactory.get('suppliers')
const APIRepo = RepositoryFactory.get('external_apis')
const GroupsTypesRepo = RepositoryFactory.get('groups_types')

export default {
    name: "Suppliers",
    components: {
        SuppliersList,
        SupplierView
    },
    data: function() {
        return {
            suppliers: [],
            supplier: {
                name: "",
                status: "",
                schedule:  ""
            },
            api_list: {},
            api_types: [],
            groups_types: [],
            
            isLoaded: false,
            isLoading: false,

            currentPage: 1,
            totalRecords: 0,
            resultsLimit: 10,
            query: {},
            search_text: ""
        }
    },
    created() {
        this.loadGroupsTypesData()
        this.loadSuppliers()
        this.loadAPI()
    },
    methods: {
        loadAPI: function() {
            APIRepo.types().then(result => {
                this.api_types = result.data.results
                var self = this
                this.api_types.forEach(function(element) {
                    var type = {}
                    APIRepo.type(element.type).then(result => {
                        self.api_list[element.type] = result.data.results
                    })
                })
            })
        },
        loadGroupsTypesData: function() {
            GroupsTypesRepo.page(1, 1000).then(result => {
                this.groups_types = result.data.results
            })
        },
        loadSuppliers: function() {
            this.isLoaded = false
            this.query = {
                page: this.currentPage,
                limit: this.resultsLimit,
                search: this.search_text
            }
            SuppliersRepo.query(this.query).then(result => {
                this.totalRecords = result.data.count
                this.suppliers = result.data.results
                this.isLoaded = true
            }).catch(err => {})
        },
        loadSupplier: function(id) {
            this.isLoading = true
            SuppliersRepo.get(id).then(result => {
                this.supplier = result.data
                this.isLoading = false
            }).catch(err => {})
        },
        updateList: function(supplier) {
            this.suppliers.push(supplier)
            this.$router.replace({params: {'id': supplier.id}})
        },
        refreshList: function(action) {
            if(action)
                this.loadSuppliers()
        },
        loadSearchResult(search_text) {
            this.search_text = search_text
            this.currentPage = 1

            this.query = {
                page: this.currentPage, 
                limit: this.resultsLimit,
                search: search_text
            }

            SuppliersRepo.query(this.query).then(result => {
                this.totalRecords = result.data.count
                this.suppliers = result.data.results
            }).catch(err => {})
        },
        nextPage: function() {
            if(this.currentPage != this.pageCount || this.currentPage > this.pageCount) {
                this.currentPage = this.currentPage + 1
                this.loadSuppliers()
            }
        },
        previousPage: function() {
            if(this.currentPage != 1 ) {
                this.currentPage = this.currentPage - 1
                this.loadSuppliers()
            }
        }
    },
    computed: {
        pageCount: function() {
            return Math.ceil(this.totalRecords / this.resultsLimit)
        },
        apiList: function() {
            return this.api_list
        }
    },
    watch: {
        '$route.params.id': {
            handler: function (id) {
                if(typeof id !== 'undefined') {
                    this.loadSupplier(id)
                }
            },
            deep: true,
            immediate: true
        },
    }
}
</script>

<style>

</style>