import Repository from '../Repository';

const resource = "/auth/api-keys";
export default {
    page(page=1, limit=10) {
        var offset = (page - 1) * limit
        return Repository.get(`${resource}/?limit=${limit}&offset=${offset}`);
    },
    get(id) {
        return Repository.get(`${resource}/${id}/`);
    },
    post(data) {
        return Repository.post(`${resource}/`, data);
    },
    update(id, data) {
        return Repository.put(`${resource}/${id}/`, data);
    },
    patch(id, data) {
        return Repository.patch(`${resource}/${id}/`, data);
    },
}