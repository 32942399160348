<template>
<div>
    <div class="card" v-animate-css="'fadeInLeft'">
    <div class="card-header">
        <h3 class="card-title">Website</h3>
        <div class="card-tools">
                <div class="overlay" v-if="isLoading">
                    <i class="fas fa-sync-alt fa-spin fa-1x"></i>
                </div>

                <button class="btn btn-tool btn-sm" v-if="!isLoading" @click.prevent="clear()">
                    <i class="fa fa-plus"></i>
                </button>
        </div>
        <!-- /.card-tools -->
    </div>
    <!-- /.card-header -->
    <div class="card-body">
        <form role="form">
            <div class="row">
                <div class="col-md-12">
                    <!-- text input -->
                    <div class="form-group">
                    <label>Name</label>
                    <input type="text" class="form-control" v-model="website.name">
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Category</label>
                        <select class="form-control" v-model="website.category">
                            <option v-for="category in categories" :key="category.id" :value="category.value"
                            :selected="website.category"> {{ category.display_name }} </option>
                        </select>
                    </div>
                </div>
                <div class="col-md-8">
                    <!-- text input -->
                    <div class="form-group">
                    <label>URL</label>
                    <input type="text" class="form-control" v-model="website.url">
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-8">
                    <div class="form-group">
                        <div class="btn-group btn-group-toggle">
                            <label class="btn btn-sm btn-default"  :class="{'text-green': website.status === 'active'}">
                                <input type="radio" value="active" v-model="website.status"> Active
                            </label>
                            <label class="btn btn-sm btn-default" :class="{'text-yellow': website.status === 'pending'}">
                                <input type="radio" value="pending" v-model="website.status"> Pending
                            </label>
                            <label class="btn btn-sm btn-default" :class="{'text-red': website.status === 'suspended'}">
                                <input type="radio" value="suspended" v-model="website.status"> Suspended
                            </label>
                            <label class="btn btn-sm btn-default" :class="{'text-yellow': website.status === 'in-active'}">
                                <input type="radio" value="in-active" v-model="website.status"> In-Active
                            </label>
                            <label class="btn btn-sm btn-default" :class="{'text-red': website.status === 'draft'}">
                                <input type="radio" value="draft" v-model="website.status"> Draft
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group float-right">
                        <button class="btn btn-success" @click.prevent="submit()"> Save </button>
                    </div>
                </div>
            </div>

        </form>

        </div>
    <!-- /.card-body -->
    </div>
    <!-- /.card -->

</div>
</template>

<script>
import { RepositoryFactory } from '@/services/repositories/ManagementAPI/RepositoryFactory'
const WebsitesRepo = RepositoryFactory.get('websites')

export default {
    props: {
        website: {
            type: Object
        },
        isLoading: {
            required: false,
            type: Boolean
        }
    },
    components: {
    },
    data() {
        return {
            categories: []
        }
    }, 
    created() {
        this.getWebsitesCategories()
    },
    methods: {
        submit: function() {
            if(typeof this.website.id === 'undefined') {
                this.create(this.website)
            } else {
                this.update(this.website)
            }
        },
        clear: function() {
            this.errors = []
            this.messages = []

            this.website = {}
            this.$router.push({name: 'websites'})
        },
        create: function(website) {
            WebsitesRepo.post(website).then(result => {
                if(typeof result.data.id !== 'undefined' ) 
                    this.website = result.data
                    this.$notify({
                        group: 'ops',
                        type: 'success',
                        title: 'Operation success',
                        text: 'Successfully added with ID: ' + result.data.id
                    })
                    this.$emit('updateList', this.website)
            }).catch(err => {
                this.$notify({
                    group: 'ops',
                    type: 'error',
                    title: 'Operation failed',
                    text: err.response.data
                })
            })
        },
        update: function(website) {
            WebsitesRepo.patch(this.website.id, website).then(result => {
                if(typeof result.data.id !== 'undefined' ) 
                    this.website = result.data
                    this.$notify({
                        group: 'ops',
                        type: 'success',
                        title: 'Operation success',
                        text: 'Successfully updated'
                    })
                    this.$emit('refreshList', true)
            }).catch(err => {
                this.$notify({
                    group: 'ops',
                    type: 'error',
                    title: 'Operation failed',
                    text: err.response.data
                })
            })
        },
        getWebsitesCategories: function () {
            WebsitesRepo.categories().then(result => {
                this.categories = result.data.actions.POST.category.choices
            }).catch(error => {
                console.log(error)
            })
        },
    },
}
</script>

<style scoped>
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

</style> 