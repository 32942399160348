import Repository from './Repository';

const resource = "/profiles";
export default {
    get(id) {
        return Repository.get(`${resource}/${id}/`);
    },
    update(id, data) {
        return Repository.put(`${resource}/${id}/`, data);
    },
    patch(id, data) {
        return Repository.patch(`${resource}/${id}/`, data);
    },
    upload(id, data) {
        return Repository.patch(`${resource}/${id}/`, data);
    },
}