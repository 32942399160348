<template>
<div>
  <WalletLists />
</div>
</template>
<script>
import WalletLists from '@/components/wallets/List'

export default {
  components: {
    WalletLists
  }
}
</script>