<template>
<form class="form-horizontal">
    <div class="form-group row">
        <label for="inputName" class="col-sm-2 col-form-label">Role</label>
        <div class="col-sm-10">
            <select class="custom-select" v-model="user.profile.user_role">
                <option v-for="(role , key) in roles" :key="key" 
                :value="key"> {{ role }} </option>
            </select>
        </div>
    </div>
    <div class="form-group row">
        <label for="inputName" class="col-sm-2 col-form-label">Groups</label>
        <div class="col-sm-10">
            <select multiple class="custom-select" style="height: 250px;" v-model="user.profile.groups">
                <option v-for="group in groups" :key="group.id" :value="group.id"> {{ group.name }} </option>
            </select>
        </div>
    </div>

    <div class="form-group row">
        <div class="offset-sm-2 col-sm-10">
            <button type="submit" @click.prevent="updatePermissions()" class="btn btn-primary">Update</button>
        </div>
    </div>

    <br />
    <hr />
    <br />

    <div class="form-group row">
        <label for="inputName" class="col-sm-2 col-form-label">Add Exception</label>
        <div class="col-md-7">
            <select class="custom-select" v-model="selectedPermission">
                <option v-for="permission in permissions" :key="permission.id" :value="permission.id"> {{ permission.name }}</option>
            </select>
        </div>
        <div class="col-md-2">
            <select class="custom-select" v-model="selectedExceptionType">
                <option v-for="(shortname, type) in exception_types" :key="type" :value="type"> {{ shortname }}</option>
            </select>
        </div>
        <div class="col-md-1">
            <a href="#" class="btn btn-success btn-block" @click.prevent="addException()" > <i class="fa fa-plus"></i> Add</a>
        </div>
    </div>
    <div class="form-group row">
        <label for="inputName" class="col-sm-2 col-form-label"></label>
        <div class="col-sm-10">
            <ul class="custom-select-list">
                <li v-for="exception in exceptions" :key="exception.id" v-animate-css="'bounceInLeft'"> 
                    {{ permissions[exception.permission].name }}
                    <span class="alert btn-xs" :class="exception.type == 'grant'? 'alert-success' : 'alert-danger'"> 
                        <i> {{ exception_types[exception.type] }} </i>
                    </span>
                    <a href="#" class="float-right remove-btn" @click.prevent="deleteException(exception.id)" ><i class="fa fa-close"></i> Delete</a>
                </li>
            </ul>
        </div>
    </div>
        
</form>
</template>

<script>
import { RepositoryFactory } from '@/services/repositories/AuthAPI/RepositoryFactory';
const ProfilesRepo = RepositoryFactory.get('profile');
const GroupsRepo = RepositoryFactory.get('groups');
const PermissionsRepo = RepositoryFactory.get('permissions');
const ExceptionsRepo = RepositoryFactory.get('exceptions');

export default {
    components: {
    },
    props: {
        user: {
            type: Object,
            default: () => ({profile: {}})
        },
    },
    data() {
        return {
            user_role: '',
            roles: {
                'admin': 'Admin',
                'developer': 'Developer',
                'account_manager': 'Account Manager',
                'ad_operation': 'Ad operation',
                'network': 'Network',
                'publisher': 'Publisher',
                'pre_activation': 'Pre Activation',
            },
            selectedRole: '',
            exception_types: {
                'grant': 'Grant',
                'deny': 'Deny'
            },
            groups: [],
            permissions: [],
            exceptions: [],

            selectedGroups: [],
            selectedPermission: [],
            selectedExceptionType: [],
        }
    },
    mounted() {
        this.getGroups()
        this.getPermissions()
    },
    methods: {
        getGroups: function() {
            GroupsRepo.all().then(result => {
                this.groups = result.data.results
            })
        },
        getPermissions: function() {
            PermissionsRepo.all().then(result => {
                this.permissions = result.data.results
            })
        },
        getExceptions: function() {
            ExceptionsRepo.user(this.user.id).then(result => {
                this.exceptions = result.data.results
            })
        },
        addException: function() {
            var exception = {
                permission: this.selectedPermission,
                type: this.selectedExceptionType,
                user: this.user.id
            }
            ExceptionsRepo.add(exception).then(result => {
                this.exceptions.push(result.data)
                this.$notify({
                    group: 'ops',
                    type: 'success',
                    title: 'Operation Success',
                    text: 'Successfuly added new exception.'
                })
            }).catch(err => {
                this.$notify({
                    group: 'ops',
                    type: 'error',
                    title: 'Operation failed',
                    text: 'Failed to add exception.'
                })
            })
        },
        deleteException: function(id) {
            ExceptionsRepo.delete(id).then(result => {
                this.exceptions.pop({'id': id})
                this.$notify({
                    group: 'ops',
                    type: 'success',
                    title: 'Operation Success',
                    text: 'Successfuly deleted the selected exception.'
                })
            }).catch(err => {
                this.$notify({
                    group: 'ops',
                    type: 'error',
                    title: 'Operation failed',
                    text: 'Failed to delete the selected exception.'
                })
            })
        },
        updatePermissions: function() {
            var profile_permissions = {
                user: this.user.id,
                user_role: this.user.profile.user_role,
                groups: this.user.profile.groups
            }
            ProfilesRepo.patch(this.user.id, profile_permissions).then(result => {
                this.$notify({
                    group: 'ops',
                    type: 'success',
                    title: 'Operation success',
                    text: 'Successfuly updated.'
                })
            }).catch(err => {
                this.$notify({
                    group: 'ops',
                    type: 'error',
                    title: 'Operation failed',
                    text: 'Failed to updated.'
                })
            })
        },
    },
    computed: {
    }, 
    watch: {
        user: {
            handler: function(user) {
                if(user.id) {
                    this.getExceptions()
                }
            },
            deep: true,
            immediate: true
        }
    }
}
</script>

<style>
.groups-lists {
    height: 250px;
}
.custom-select-list {
    list-style-type: none;
    text-align: left;
    margin: 5px 0 0 0;
    padding: 10px;
    border: 1px #CCCCCC solid;
    height: 300px;
    overflow-y: scroll;
    border-radius: 2px;
}

.custom-select-list li:first-child {
    padding: 15px;
}

.custom-select-list li {
    display: block;
    padding: 15px;
    min-height: 45px;
    border-bottom: 1px #f2f2f2 solid;
}

.custom-select-list .remove-btn {
    color: #ad3737;
}
</style>