<template>
    <div class="card">
        <div class="card-header">
          <h3 class="card-title">List</h3>
          <div class="card-tools">
            <div class="input-group input-group-sm" style="width: 150px;">
                <input type="search" name="table_search" class="form-control float-right" placeholder="Search">
                <div class="input-group-append">
                    <button type="submit" class="btn btn-default"><i class="fas fa-search"></i></button>
                </div>
            </div>
          </div>
        </div>
        <!-- /.card-header -->
        <div class="card-body table-responsive p-0">              
          <table class="table table-hover">
              <thead>
              <tr>
                  <th>Domain</th>
                  <th>Network</th>
                  <th>Status</th>
                  <th class="text-center">Action</th>
              </tr>
              </thead>
              <tbody>
                  <tr v-for="whitelist in whitelists" :key="whitelist.id" v-animate-css="'slideInLeft'">
                    <td>{{ whitelist.domain }}</td>
                    <td>{{ Network(whitelist.network) }}</td>
                    <td>
                      <span class="alert alert-success btn-xs" :class="{
                          'alert-success': whitelist.status == 'active',
                          'alert-warning': whitelist.status == 'pending',
                          'alert-danger': whitelist.status == 'suspended'}"> {{ whitelist.status | capitalize }} 
                      </span>
                    </td>
                    <td class="text-center">
                      <div class="btn-group">
                        <router-link :to="{name: 'Whitelabel', params: {'id': whitelist.id} }" class="btn btn-default btn-sm">
                          <i class="fa fa-edit"></i>
                        </router-link>
                    </div>
                    </td>
                  </tr>
              </tbody>
          </table>

        </div>

        <div class="overlay" v-if="!isLoaded">
          <point-spinner />
        </div>

        <!-- <div class="overlay dark" v-if="hasError">
          <i class="fas fa-2x fa-exclamation-triangle"></i>
        </div> -->
    </div>
</template>

<script>
export default {
    props: {
        whitelists: {
          required: true,
          type: Array,
          default: () => []
        },
        networks: {
          type: Array,
          default: () => []
        },
        isLoaded: {
          required: false,
          type: Boolean
        }
    },
    methods: {
      Network: function(user_id) {
        var network = this.networks.filter(network => { return network.id == user_id })[0]
        if(network)
          return network.first_name + " " + network.last_name
        return "None"
      },
    }
}
</script>

<style scoped>

</style>