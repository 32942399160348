<template>
<div class="col-md-12 col-sm-12">
    <div class="card">
        <div class="card-header">
            <h3 class="card-title">List 
                <router-link :to="{name: 'new_user'}" class="btn btn-tool btn-sm">
                    <i class="fas fa-plus"></i>
                </router-link>
            </h3>

            <div class="card-tools">
                <div class="input-group input-group-sm" style="width: 150px;">
                    <input type="search" name="table_search" class="form-control float-right" placeholder="Search" v-model="search_text">

                    <div class="input-group-append">
                        <button class="btn btn-default" @click.prevent="loadSearchResult()"><i class="fas fa-search"></i></button>
                    </div>
                </div>
            </div>
        </div>
        <!-- /.card-header -->
        <div class="card-body table-responsive p-0">
        <table class="table table-hover">
            <thead>
            <tr>
                <th></th>
                <th>Full Name</th>
                <th>Username</th>
                <th>Role</th>
                <th>Email</th>
                <th>Status</th>
                <th class="text-center">Verified</th>
                <th>Last login</th>
                <th class="text-center">Action</th>
            </tr>
            </thead>
            <tbody>
                <tr v-for="user in users" :key="user.id" v-animate-css="'slideInLeft'">
                    <td>
                        <img class="img-circle list-img-circle elevation-2" v-if="user.profile" :src="user.profile.image || '/assets/img/AdminLTELogo.png'" alt="User Avatar">
                        <img class="img-circle list-img-circle elevation-2" v-else :src="'/assets/img/AdminLTELogo.png'" alt="User Avatar">
                    </td>
                    <td>{{ user.first_name }} {{ user.last_name }}</td>
                    <td>{{ user.username }}</td>
                    <td> <span v-if="user.profile">{{ user.profile.user_role | capitalizeAndReplace }}</span> </td>
                    <td>{{ user.email }}</td>
                    <td>
                        <span class="alert btn-xs" :class="user.is_active? 'alert-success' : 'alert-danger'"> 
                            <span v-if="user.is_active"> Active </span>
                            <span v-if="!user.is_active"> Suspended </span>
                        </span>
                    </td>
                    <td class="text-center">
                        <span v-if="user.profile"> 
                            <i class="fa" :class="user.profile.is_verified? 'fa-check-circle success' : 'fa-times-circle warning' "></i>
                        </span>
                    </td>
                    <td>{{ user.last_login }}</td>
                    <td class="text-center">
                        <div class="btn-group btn-group-sm">
                            <router-link class="btn btn-primary" :to="{name: 'user', params: {id: user.id}}"><i class="fa fa-edit"></i> Edit</router-link>
                            <router-link class="btn btn-default" :to="{name: 'wallets', query: {account: user.id}}"><i class="fa fa-wallet"></i> Wallet</router-link>
                            <!-- <button class="btn btn-danger" @click="deleteUser(user.id)"><i class="fa fa-trash"></i></button> -->
                        </div>
                    </td>
                </tr>
                <!-- <tr v-if="users.length <= 0">
                    <td>
                        <h5 class="text-center">No data available currently.</h5>
                    </td>
                </tr>
                -->
            </tbody>
        </table>
        </div>
        <!-- /.card-body -->
        <div class="card-footer">
            <ul class="pagination pagination-sm m-0 float-right">
                <li class="page-item">
                    <a class="page-link" href="#" @click.prevent="previousPage">Previous</a>
                </li>
                <li class="page-item">
                    <a class="page-link">{{ currentPage }} / {{ pageCount }}</a>
                </li>
                <li class="page-item">
                    <a class="page-link" href="#" @click.prevent="nextPage">Next</a>
                </li>
            </ul>
        </div>

        <div class="overlay" v-if="!isLoaded">
            <point-spinner />
        </div>
    </div>
    <!-- /.card -->
</div>
</template>

<script>
import { RepositoryFactory } from '@/services/repositories/ManagementAPI/RepositoryFactory';
const UsersRepository = RepositoryFactory.get('users');

export default {
    data() {
        return {
            currentPage: 1,
            totalRecords: 0,
            resultsLimit: 10,
            users: [],
            search_text: "",
            isLoaded: true,
            hasError: false
        }
    },
    created: function() {
        this.loadUsers()
    },
    methods: {
        nextPage: function() {
            if(this.currentPage != this.pageCount || this.currentPage > this.pageCount) {
                this.currentPage = this.currentPage + 1
                this.loadUsers()
            }
        },
        previousPage: function() {
            if(this.currentPage != 1 ) {
                this.currentPage = this.currentPage - 1
                this.loadUsers()
            }
        },
        loadUsers: function() {
            this.isLoaded = false
            UsersRepository.page(this.currentPage).then(result => {
                this.totalRecords = result.data.count
                this.users = result.data.results
                this.isLoaded = true
                this.hasError = false
            }).catch(err => {
                this.isLoaded = true
                this.hasError = true
                this.users = []
            })
        },
        loadSearchResult: function(search_text) {
            this.search_text = search_text
            this.currentPage = 1
            
            this.query = {
                page: this.currentPage, 
                limit: this.resultsLimit,
                search: search_text
            }

            UsersRepository.query(this.query).then(result => {
                this.totalRecords = result.data.count
                this.users = result.data.results
            }).catch(err => {
                this.users = []
            })
        },
        loadWebsite: function() {
            UsersRepository.page(this.currentPage).then(result => {
                this.totalRecords = result.data.count
                this.users = result.data.results
            }).catch(err => {
                this.users = []
            })
        },
        deleteUser: function(id) {
            UsersRepository.delete(id).then(result => {
                console.log("user deleted.")
            }).catch(err => {
                console.log("user deletion failed.")
            })
        }
    },
    computed: {
        pageCount: function() {
            return Math.ceil(this.totalRecords / this.resultsLimit)
        },
    },
    watch: {
      search_text: function() {
        this.loadSearchResult(this.search_text)
      }
    }
}
</script>

<style scoped>
.list-img-circle {
    height: 45px;
    width: 45px;
}
.warning {
    font-size: 1.5em;
    color: #ffc107;

}
.success {
    font-size: 1.5em;
    color: #1e7e34;
}
</style>