<template>
    <table class="table table-hover">
        <thead>
        <tr>
            <th>Name</th>
        </tr>
        </thead>
        <tbody>
            <tr v-for="api in apis" :key="api.id" v-animate-css="'slideInLeft'">
                <td>{{ api.name }}</td>
                <td class="text-center">
                <div class="btn-group">
                    <router-link :to="{name: 'apis', params: {'id': api.id} }" class="btn btn-default btn-sm">
                    <i class="fa fa-edit"></i> Edit</router-link>
                </div>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
export default {
    props: {
        apis: {
          required: true,
          type: Array
        },
        isLoaded: {
          required: false,
          type: Boolean
        }
    },
}
</script>

<style scoped>

</style>