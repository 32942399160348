import Vue from 'vue'

Vue.mixin({
    methods: {
      filterIDList(array, field) {
        let list = []
        array.forEach(element => {
            list.push(element[field])
        })
        return list
    }
  }
})
