import Vue from "vue"
import moment from 'moment'

Vue.filter("capitalize", function(value) {
    if (!value) return ''
    value = value.toString()
    return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter("capitalizeAndReplace", function(value) {
    if (!value) return ''
    value = value.toString()
    return value.charAt(0).toUpperCase() + value.slice(1).replace('_', ' ')
})

Vue.filter("withComma", function(value) {
    if (!value) return value
    return Number(value).toLocaleString(2)
})

Vue.filter('formatDateTimezone', function(value) {
  if (value) {
    return moment(String(value)).format('YYYY-MM-DD hh:mm:ss A Z')
  }
})

Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('YYYY-MM-DD hh:mm A')
  }
})

Vue.filter('urlStrip', function(url) {
  if (url) {
    return url.replace(/(^\w+:|^)\/\//, '');
  }
})
