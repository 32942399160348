<template>
<div>
    <div class="card" v-animate-css="'fadeInLeft'">
        <div class="card-header">
            <h3 class="card-title">
                <span v-if="!supplier.id"> Add </span>
                <span v-if="supplier.id"> Edit </span>
                Demand Source
            </h3>
            <div class="card-tools">
                <div class="overlay" v-if="isLoading">
                    <i class="fas fa-sync-alt fa-spin fa-1x"></i>
                </div>

                <button class="btn btn-tool btn-sm" v-if="!isLoading" @click.prevent="clear()">
                    <i class="fa fa-plus"></i>
                </button>
            </div>
            <!-- /.card-tools -->
        </div>
        <!-- /.card-header -->
        <div class="card-body">
                <div class="row">
                    <div class="col-md-6">
                        <!-- text input -->
                        <div class="form-group">
                            <label>Name</label>
                            <input type="text" class="form-control" v-model="supplier.name">
                        </div>
                    </div>
                    <div class="col-md-2">
                        <!-- text input -->
                        <div class="form-group">
                            <label>Currency</label>
                            <select class="form-control" v-model="supplier.currency">
                                <option v-for="(name, code) in currency" :key="code" :value="code">{{ name }}</option>
                            </select>
                        </div>
                    </div>
                <div class="col-md-4">
                    <div class="bootstrap-timepicker">
                        <div class="form-group">
                            <label>Schedule Time</label>
                            <div class="input-group date" id="timepicker" data-target-input="nearest">
                                <input type="text" class="form-control datetimepicker-input" data-target="#timepicker" v-model="supplier.schedule">
                                <div class="input-group-append" data-target="#timepicker" data-toggle="datetimepicker">
                                    <div class="input-group-text"><i class="far fa-clock"></i></div>
                                </div>
                            </div>
                            <!-- /.input group -->
                        </div>
                    <!-- /.form group -->
                    </div>

                </div>
                <div class="col-md-4">
                    <!-- text input -->
                    <div class="form-group">
                        <label>Advertiser ID</label>
                        <input type="text" class="form-control" v-model="supplier.advertiser_id">
                    </div>
                </div>
                <div class="col-md-8">
                    <!-- text input -->
                    <div class="form-group">
                        <label>API</label>
                        <select class="form-control" v-model="supplier.api">
                            <optgroup v-for="(apis, type) in api_list" :label="type | capitalize" :key="apis">
                                <option v-for="api in apis" :key="api.id" :value="{id :api.id, type: api.type}">{{ api.name }} - {{ api.accountEmail }}</option>
                            </optgroup>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                        <div class="btn-group btn-group-toggle">
                            <label class="btn btn-sm btn-default"  :class="{'text-green': supplier.status === 'active'}">
                                <input type="radio" value="active" v-model="supplier.status"> Active
                            </label>
                            <label class="btn btn-sm btn-default" :class="{'text-red': supplier.status === 'suspended'}">
                                <input type="radio" value="suspended" v-model="supplier.status"> Suspended
                            </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <div class="btn-group btn-group-toggle">
                                <label class="btn btn-sm btn-default"  :class="{'text-red': supplier.type === 'manual'}">
                                    <input type="radio" value="manual" v-model="supplier.type"> Manual
                                </label>
                                <label class="btn btn-sm btn-default" :class="{'text-green': supplier.type === 'automatic'}">
                                    <input type="radio" value="automatic" v-model="supplier.type"> Automatic
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group float-right">
                            <button class="btn btn-success" @click.prevent="submit()"> Save </button>
                        </div>
                    </div>

                <div class="col-md-12">
                    <errors :errors="errors"/>
                    <messages :messages="messages" />
                </div>
            </div>
        </div>
    <!-- /.card-body -->
    </div>
    <!-- /.card -->

    <div class="row">
        <AdsView v-if="supplier.id" :supplier="supplier" />
    </div>

</div>
</template>

<script>
import { RepositoryFactory } from '@/services/repositories/ManagementAPI/RepositoryFactory' 
const SuppliersRepo = RepositoryFactory.get('suppliers')

import AdsView from './Ads'

export default {
    props: {
        supplier: {
            required: true,
            type: Object,
        },
        api_list: {
            required: true,
            type: Object
        },
        isLoading: {
            required: false,
            type: Boolean
        },
    },
    components: {
        AdsView,
    },
    data() {
        return {
            currency: {
                'USD': 'USD',
                'EUR': 'EUR',
                'CAD': 'CAD'
            },
            messages: [],
            errors: []
        }
    },
    created() {
        this.getSupportedCurrencies()
        //Timepicker
        $(function () {
            var vm = this
            $('#timepicker').datetimepicker({
                format: 'LT',
                onSelect: function() { 
                    vm.supplier.schedule = $(this).datepicker('getDate'); 
                }
            })
        });
    },
    methods: {
        submit: function() {
            if(typeof this.supplier.id === 'undefined') {
                this.create(this.supplier)
            } else {
                this.update(this.supplier)
            }
        },
        clear: function() {
            this.errors = []
            this.messages = []

            this.supplier = {
                schedule:  ""
            }
        },
        create: function(supplier) {
            this.errors = []
            this.messages = []

            SuppliersRepo.post(supplier).then(result => {
                if(typeof result.data.id !== 'undefined' ) 
                    this.supplier = result.data
                    this.messages.push("Successfully added with ID: " + result.data.id)
                    this.$emit('updateList', this.supplier)
            }).catch(err => {
                this.errors.push(err)
                this.errors.push( err.response.data )
            })
        },
        update: function(supplier) {
            this.errors = []
            this.messages = []

            SuppliersRepo.patch(this.supplier.id, supplier).then(result => {
                if(typeof result.data.id !== 'undefined' ) 
                    this.supplier = result.data
                    this.messages.push("Successfully updated.")
                    this.$emit('refreshList', true)
            }).catch(err => {
                this.errors.push(err)
                this.errors.push( err.response.data )
            })
        },
        delete: function(id) {},
        getSupportedCurrencies: function () {
            SuppliersRepo.options().then(result => {
                this.currency = result.data.actions.POST.currency.choices
            }).catch(error => {
                console.log(error)
            })
        },
    },
}
</script>