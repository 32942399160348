<template>
<form class="form-horizontal">
    <div class="form-group row">
        <label for="inputName" class="col-sm-2 col-form-label">First Name</label>
        <div class="col-sm-10">
            <input type="text" class="form-control" v-model="user.first_name" placeholder="First Name">
        </div>
    </div>
    <div class="form-group row">
        <label for="inputName" class="col-sm-2 col-form-label">Last Name</label>
        <div class="col-sm-10">
            <input type="text" class="form-control" v-model="user.last_name" placeholder="Last Name">
        </div>
    </div>
    
    <div class="form-group row">
        <label for="inputName" class="col-sm-2 col-form-label">Phone Number</label>
        <div class="col-sm-10">
            <input type="text" class="form-control" v-model="profile.phone_number" placeholder="Phone Number">
        </div>
    </div>
    <div class="form-group row">
    <label for="inputEmail" class="col-sm-2 col-form-label">Address</label>
    <div class="col-sm-10">
        <textarea class="form-control" v-model="profile.address" placeholder="Address"></textarea>
    </div>
    </div>
    <div class="form-group row">
    <label for="inputName2" class="col-sm-2 col-form-label">City</label>
    <div class="col-sm-10">
        <input type="text" class="form-control" v-model="profile.city" placeholder="City">
    </div>
    </div>
    <div class="form-group row">
    <label for="inputExperience" class="col-sm-2 col-form-label">State</label>
    <div class="col-sm-10">
        <input type="text" class="form-control" v-model="profile.state" placeholder="State">
    </div>
    </div>
    <div class="form-group row">
    <label for="inputSkills" class="col-sm-2 col-form-label">Country</label>
    <div class="col-sm-10">
        <input type="text" class="form-control" v-model="profile.country" placeholder="Country">
    </div>
    </div>
    <div class="form-group row">
    </div>
    <div class="form-group row">
        <div class="offset-sm-2 col-sm-10">
            <button type="submit" @click.prevent="saveProfileInfo()" class="btn btn-primary">Update</button>
        </div>
    </div>

</form>
</template>

<script>

import { RepositoryFactory } from '@/services/repositories/AuthAPI/RepositoryFactory';
const UserRepo = RepositoryFactory.get('user');
const ProfileRepo = RepositoryFactory.get('profile');

export default {
    name: 'ProfileInfo',
    components: {
    },
    props: {
        user: {
            type: Object,
            default: () => ({})
        },
    },
    data() {
        return {
            profile: {
                type: Object,
                default: () => ({})
            }
        }
    },
    methods: {
        loadUserProfile: function(id) {
          ProfileRepo.get(id).then(result => {
            this.profile = result.data
          }).catch(err => {})
        },
        saveProfileInfo: function() {
            ProfileRepo.patch(this.user.id, this.profile).then(result => {
                UserRepo.patch(this.user.id, {
                    first_name: this.user.first_name,
                    last_name: this.user.last_name
                })
                this.$notify({
                    group: 'ops',
                    type: 'success',
                    title: 'Operation success',
                    text: 'Successfuly saved.'
                })
            }).catch(err => {
                this.$notify({
                    group: 'ops',
                    type: 'error',
                    title: 'Operation failed',
                    text: 'Failed to save, please try agian.'
                })
            })
        },
    },
    watch: {
        user: {
            handler: function(user) {
                if(user.id) {
                    this.loadUserProfile(user.id)
                }
            },
            deep: true,
            immediate: true
        }
    }

}
</script>