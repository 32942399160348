<template>
    <div class="card" v-animate-css="'fadeInLeft'">
        <div class="card-header">
            <h3 class="card-title">
                <span v-if="!pg_data.id"> Add </span>
                <span v-if="pg_data.id"> Edit </span>
                Placement Group Data
            </h3>
            <div class="card-tools">
                <div class="overlay" v-if="isLoading">
                    <i class="fas fa-sync-alt fa-spin fa-1x"></i>
                </div>

                <button class="btn btn-tool btn-sm" v-if="!isLoading" @click.prevent="clear()">
                    <i class="fa fa-plus"></i>
                </button>
            </div>
            <!-- /.card-tools -->
        </div>
        <!-- /.card-header -->
        <div class="card-body">
            <form role="form">
                <div class="row">
                    <div class="col-md-6">
                        <!-- text input -->
                        <div class="form-group">
                        <label>Name</label>
                        <input type="text" class="form-control" v-model="pg_data.name">
                        </div>
                    </div>
                    <div class="col-md-3">
                        <!-- text input -->
                        <div class="form-group">
                        <label>Type</label>
                        <select class="form-control" v-model="pg_data.group_id">
                            <option v-for="group_type in groups_types" :value="group_type.id" :key="group_type.id"> 
                                {{ group_type.name }} 
                            </option>
                        </select>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <!-- text input -->
                        <div class="form-group">
                        <label>Flag</label>
                        <select class="form-control" v-model="pg_data.flag">
                            <option value="default" :selected="pg_data.flag"> Default </option>
                            <option value="uncomplete" :selected="pg_data.flag"> Uncomplete </option>
                        </select>
                        </div>
                    </div>
                    <!-- <div class="col-md-12">
                        <hr class="py-1 fw-bold" />
                        <p class="h4 fw-bold">Dimensions</p>
                    </div> -->
                    <div class="col-md-4">
                        <div class="form-group">
                        <label>Type</label>
                            <select class="form-control" v-model="pg_data.type">
                                <option v-for="(name, value) in type" :key="value" :value="value"> {{ name }} </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <!-- text input -->
                        <div class="form-group">
                        <label>DFP Placement ID</label>
                        <input type="text" class="form-control" v-model="pg_data.dfp_placement_id">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <!-- text input -->
                        <div class="form-group">
                        <label>DFP Ad Unit ID</label>
                        <input type="text" class="form-control" v-model="pg_data.dfp_adunit_id">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                        <label>Serve With</label>
                            <select class="form-control" v-model="pg_data.serve_with">
                                <option v-for="(name, value) in serve_with" :key="value" :value="value"> {{ name }} </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                        <label>Supplier</label>
                            <select class="form-control" v-model="pg_data.supplier_id">
                                <option v-for="supplier in suppliers" :key="supplier.id" :value="supplier.id"
                                :selected="pg_data.supplier_id"> {{ supplier.name }} </option>
                            </select>
                        </div>
                    </div>
            </div>
            
            <!-- DFP Metrics List -->
            <ServeMetricsList :selectedMetrics="pg_data.metrics" @updateMetrics="updateMetrics" />
            <br /> <!-- END DFP Metrics List -->

            <div class="row">
                <div class="col-md-8">
                    <div class="form-group">
                        <div class="btn-group btn-group-toggle">
                            <label class="btn btn-sm btn-default"  :class="{'text-green': pg_data.status === 'active'}">
                                <input type="radio" value="active" v-model="pg_data.status"> Active
                            </label>
                            <label class="btn btn-sm btn-default" :class="{'text-red': pg_data.status === 'suspended'}">
                                <input type="radio" value="suspended" v-model="pg_data.status"> Suspended
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group float-right">
                        <button class="btn btn-success" @click.prevent="submit()"> Save </button>
                    </div>
                </div>

                <div class="col-md-12">
                    <errors :errors="errors"/>
                    <messages :messages="messages" />
                </div>
            </div>

            </form>
        </div>
    <!-- /.card-body -->
    </div>
    <!-- /.card -->

</template>

<script>
import { RepositoryFactory } from '@/services/repositories/ManagementAPI/RepositoryFactory' 
const GroupsDataRepo = RepositoryFactory.get('groups_data')

import ServeMetricsList from './ServeMetrics'

export default {
    props: {
        pg_data: {
            required: true,
            type: Object
        },
        placement_group: {
            required: true,
        },
        website: {
            required: true,
        },
        suppliers: {
            required: true,
            type: Array
        },
        groups_types: {
            required: true,
            type: Array
        },
        isLoading: {
            required: false,
            type: Boolean
        },
    },
    components: {
        ServeMetricsList
    },
    data() {
        return {
            type: {
                'PLACEMENT': 'Placement',
                'AD_UNIT': 'Ad Unit',
            },
            serve_with: {
                'none': 'None',
                'advertiser_id': 'Advertiser ID',
            },
            messages: [],
            errors: []
        }
    },
    methods: {
        submit: function() {
            if(typeof this.pg_data.id === 'undefined') {
                this.create(this.pg_data)
            } else {
                this.update(this.pg_data)
            }
        },
        clear: function() {
            this.errors = []
            this.messages = []

            this.pg_data = {
                placement_group_id: this.placement_group,
                website_id: this.website,
                metrics: [],
            }
        },
        create: function(pg_data) {
            this.errors = []
            this.messages = []
            pg_data['website_id'] = this.website
            
            GroupsDataRepo.post(pg_data).then(result => {
                if(typeof result.data.id !== 'undefined' ) 
                    this.pg_data = result.data
                    this.$notify({
                        group: 'ops',
                        type: 'success',
                        title: 'Operation success',
                        text: 'Successfully added with ID: ' + result.data.id
                    })
                    this.$emit('updateList', result.data)
            }).catch(err => {
                    this.$notify({
                        group: 'ops',
                        type: 'error',
                        title: 'Operation failed',
                        text: err.response.data
                    })
            })
        },
        update: function(pg_data) {
            this.errors = []
            this.messages = []
            pg_data['website_id'] = this.website

            GroupsDataRepo.patch(this.pg_data.id, pg_data).then(result => {
                if(typeof result.data.id !== 'undefined' ) 
                    this.pg_data = result.data
                    this.$notify({
                        group: 'ops',
                        type: 'success',
                        title: 'Operation success',
                        text: 'Successfully updated'
                    })
                    this.$emit('refreshList', true)
            }).catch(err => {
                this.$notify({
                    group: 'ops',
                    type: 'error',
                    title: 'Operation failed',
                    text: err.response.data
                })
            })
        },
        delete: function(id) {
        },
        updateMetrics: function(metrics) {
            this.pg_data.metrics = metrics
        },
        GroupsTypes(id) {
            let group_type = this.groups_types.filter(element => { return element.id == id })[0]
            if(group_type !== undefined)
                return group_type
        },
        GroupsTypesName(id) {
            let group_type = this.groups_types.filter(element => { return element.id == id })[0]
            if(group_type !== undefined)
                return group_type.name
        }
    },
}
</script>