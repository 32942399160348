<template>
<div class="row">
    <div class="col-md-6">
        <APIList :selectedType="selectedType" :types="types" :apis="apis" :isLoaded="isLoaded" @apiChanged="loadAPI" @typeChanged="loadAPIType" />

        <ul class="pagination pagination-sm m-0 float-right">
          <li class="page-item">
            <a class="page-link" href="#" @click.prevent="previousPage">Previous</a>
          </li>
          <li class="page-item">
            <a class="page-link">{{ currentPage }} / {{ pageCount }}</a>
          </li>
          <li class="page-item">
            <a class="page-link" href="#" @click.prevent="nextPage">Next</a>
          </li>
        </ul>
    </div>

    <div class="col-md-6">
        <APIView :selectedType="selectedType" :api="api" :isLoading="isLoading" @updateList="updateList" @refreshList="refreshList"/>
    </div>
</div>
</template>

<script>
import APIList from '@/components/settings/api/List'
import APIView from '@/components/settings/api/API'

import { RepositoryFactory } from '@/services/repositories/ManagementAPI/RepositoryFactory' 
const APIRepo = RepositoryFactory.get('external_apis')

export default {
    name: "APIs",
    components: {
        APIList,
        APIView
    },
    data: function() {
        return {
            isLoaded: false,
            isLoading: false,

            currentPage: 1,
            totalRecords: 0,
            resultsLimit: 10,
            isLoaded: false,
            types: [],
            apis: [],
            selectedAPI: {},
            selectedType: 'admanager'
        }
    },
    mounted() {
        this.loadTypes()
        this.loadAPIType(this.selectedType)
    },
    methods: {
        loadTypes: function() {
            this.isLoaded = false
            APIRepo.types().then(result => {
                this.types = result.data.results
                this.isLoaded = true
            }).catch(err => {
                console.log(err)
            })
        },
        loadAPIType: function(type) {
            APIRepo.type(type).then(result => {
                this.apis = result.data.results
            }).catch(err => {
                console.log(err)
            })
        },
        loadAPI: function(type, id) {
            APIRepo.get(type, id).then(result => {
                this.selectedAPI = result.data
            }).catch(err => {
                console.log(err)
            })
        },
        updateList: function(api) {
            this.apis.push(api)
        },
        refreshList: function(action) {
            if(action)
                this.loadAPI()
        }
    },
    computed: {
        pageCount: function() {
            return Math.ceil(this.totalRecords / this.resultsLimit)
        },
    },
    watch: {
        '$route.query.api': { 
            handler: function (api) {
                if(typeof api !== 'undefined') {
                } else {
                }
            },
            deep: true,
            immediate: true
        },
        '$route.params.id': {
            handler: function (id) {
                if(typeof id !== 'undefined') {
                }
            },
            deep: true,
            immediate: true
        },
    }
}
</script>

<style>

</style>