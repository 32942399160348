<template>
<div class="row">

    <div class="col-md-3 col-sm-6 col-12">
        <div class="info-box bg-gradient-info">
            <span class="info-box-icon">
                <i class="fa fa-dollar-sign"></i><br />
                <p>Today</p>
            </span>
            <div class="info-box-content">
                <span class="info-box-text">Revenue</span>
                <span class="info-box-number">{{ statistics.revenue.today | withComma }}</span>
            </div>
            <div class="info-box-content">
                <span class="info-box-text">Impressions</span>
                <span class="info-box-number">{{ statistics.impressions.today | withComma }}</span>
            </div>
            <!-- /.info-box-content -->
        </div>
        <!-- /.info-box -->
    </div>
    <!-- /.col -->

    <div class="col-md-3 col-sm-6 col-12">
        <div class="info-box bg-gradient-info">
            <span class="info-box-icon">
                <i class="fa fa-dollar-sign"></i><br />
                <p>Yesterday</p>
            </span>

            <div class="info-box-content">
                <span class="info-box-text">Revenue</span>
                <span class="info-box-number">{{ statistics.revenue.yesterday | withComma }}</span>
            </div>
            <div class="info-box-content">
                <span class="info-box-text">Impressions</span>
                <span class="info-box-number">{{ statistics.impressions.yesterday | withComma }}</span>
            </div>
            <!-- /.info-box-content -->
        </div>
        <!-- /.info-box -->
    </div>
    <!-- /.col -->

    <div class="col-md-3 col-sm-6 col-12">
        <div class="info-box bg-gradient-info">
            <span class="info-box-icon">
                <i class="fa fa-dollar-sign"></i><br />
                <p>7 days</p>
            </span>

            <div class="info-box-content">
                <span class="info-box-text">Revenue</span>
                <span class="info-box-number">{{ statistics.revenue.seven_days | withComma }}</span>
            </div>
            <div class="info-box-content">
                <span class="info-box-text">Impressions</span>
                <span class="info-box-number">{{ statistics.impressions.seven_days | withComma }}</span>
            </div>
            <!-- /.info-box-content -->
        </div>
        <!-- /.info-box -->
    </div>
    <!-- /.col -->

    <div class="col-md-3 col-sm-6 col-12">
        <div class="info-box bg-gradient-info">
            <span class="info-box-icon">
                <i class="fa fa-dollar-sign"></i><br />
                <p>Month</p>
            </span>

            <div class="info-box-content">
                <span class="info-box-text">Revenue</span>
                <span class="info-box-number">{{ statistics.revenue.last_month | withComma }}</span>
            </div>
            <div class="info-box-content">
                <span class="info-box-text">Impressions</span>
                <span class="info-box-number">{{ statistics.impressions.last_month | withComma }}</span>
            </div>
            <!-- /.info-box-content -->
        </div>
        <!-- /.info-box -->
    </div>
    <!-- /.col -->
        
</div>
</template>

<script>
import { RepositoryFactory } from '@/services/repositories/ManagementAPI/RepositoryFactory'
const WebsitesRepo = RepositoryFactory.get('websites')
const WebsiteStatisticsRepo = RepositoryFactory.get('websites_statistics')
const GroupStatisticsRepo = RepositoryFactory.get('groups_statistics')

export default {
    components: {
    },
    data() {
        return {
            statistics: {
                // general: {
                //     statistics: 0,
                //     complete: 0,
                //     uncomplete: 0,
                //     active: 0,
                //     suspended: 0
                // },
                // today: {
                //     revenue: 0,
                //     impressions: 0
                // },
                // yesterday: {
                //     revenue: 0,
                //     impressions: 0
                // },
                // last_7days: {
                //     revenue: 0,
                //     impressions: 0
                // },
                // current_month: {
                //     revenue: 0,
                //     impressions: 0
                // },
                // lifetime: {
                //     revenue: 0
                // },
                // overall: {
                //     viewable_rate: 0,
                //     cpm: 0
                // },
            }
        }
    },
    created() {
        this.loadWebsiteStatisticsStatus()
    },
    methods: {
        loadWebsiteStatisticsStatus: function() {
            WebsiteStatisticsRepo.status().then(result => {
                this.statistics = result.data
            }).catch(err => {})
        },
    },
}
</script>
<style scoped>
.info-box-icon p {
    font-size: 20px
}
</style>