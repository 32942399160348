<template>
    <div class="border  p-4 w-100 relative">

        <!-- Selected ad scripts from backend -->
        <div class="w-100"  >
            <div class="border bg-secondary bg-gradient my-2 px-4 py-2 d-flex rounded-md" v-for="ad_script_id in selectedScripts" :key="ad_script_id">
                <h3>{{ AdScriptName(ad_script_id) }}</h3>
                <a href="#" @click.prevent="unSelectAdScript(ad_script_id)" class="ml-auto"><i class="fas fa-times"></i></a>
            </div>
        </div>
        
        <input type="search" v-model="adscript_search" 
        placeholder="Select or add new ad scripts" 
        name="desktop-mobile" 
        class="form-control dropdown-toggle" 
        data-toggle="dropdown" 
        aria-haspopup="false" 
        aria-expanded="false" >
        
        <div class="dropdown-menu w-100">
            <a class="dropdown-item bg-secondary bg-gradient" href="#" @click.prevent="showAdScriptModal">ADD NEW AD SCRIPT</a>
            <!-- Select and Unselect new ad scripts -->
            <div class="dropdown-item d-flex align-items-center" v-for="ad_script in adscripts" :key="ad_script.id">
                <i class="nav-icon fas fa-pencil-alt cursor-pointer" @click.prevent="editAdScript(ad_script)" ></i>
                <a class="nav-icon"  @click.prevent="selectAdScript(ad_script.id)"  href="#">{{ad_script.ad_id}}</a>
            </div>
        </div>

        <ad-script-modal :adscript="selectedScript" @updateList="loadAdScripts"/>

    </div>
</template>
<script>
import AdScriptModal from './AdScriptModal.vue'

import { RepositoryFactory } from '@/services/repositories/PlayerAPI/RepositoryFactory'
const AdScriptsRepo = RepositoryFactory.get('adscripts')

export default {
    components: {
        AdScriptModal,
    },
    props: {
        schedules: {
            type: Array | Object,
            required : true
        },
        schedule_type: {
            type: String,
            required : true
        },
        deleteScedule: {
            type: Boolean
        }
    },
    data: () => {
        return {
            adscripts: [],
            selectedScript: {},
            selectedScripts: [],
            adscript_search: '',
            website_id: 0
        }
    },
    mounted() {
        this.loadAdScripts()
    },
    methods: {
        selectAdScript(adscript_id) {
            this.selectedScripts = [adscript_id]
            this.selectionChanged()
        },
        unSelectAdScript(adscript_id) {
            this.selectedScripts = null
            this.selectionChanged()
        },
        editAdScript(adscript) {
            this.selectedScript = adscript
            $(`#AdScriptModal`).modal()
        },
        selectionChanged() {
            var data = { 
                'data': this.selectedScripts,
                'type': this.schedule_type
            }
            this.$emit('changed', data)
        },
        showAdScriptModal() {
            $(`#AdScriptModal`).modal()
        },
        loadAdScripts() {
            var query = {
                website: this.website_id,
                limit: 1000
            }
            AdScriptsRepo.query(query).then(result => {
                this.adscripts = result.data.results
            }).catch(error => {})
        },
        AdScript(adscript_id) {
            var adscript = this.adscripts.filter(element => { return element.id == adscript_id })[0]
            if(adscript)
                return adscript
        },
        AdScriptName(adscript_id) {
            var adscript = this.adscripts.filter(element => { return element.id == adscript_id })[0]
            if(adscript)
                return adscript.ad_id
        }
    },
    computed: {
        filteredAdScripts: function() {
            return this.adscripts.filter((ele) => {
                return ele.name.includes(this.adscript_search)
            })
        },
    },
    watch: {
        'schedules': {
            handler: function (schedules) {
                this.selectedScripts = [ schedules.id || schedules[0] || schedules ]
            },
            deep: true,
            immediate: true
        },
        'deleteScedule': {
            handler: function (clear) {
                if(clear) {
                    this.selectedScripts = []
                    this.selectionChanged()
                    this.deleteScedule = false
                }
            },
            deep: true,
            immediate: true
        },
        '$route.query.website': { 
            handler: function (website) {
                if(typeof website !== 'undefined') {
                    // check if user is not loaded 
                    // website placements_groups before
                    if(this.website_id !== website) {
                        // match website with passed from query
                        this.website_id = website
                    }
                } else {
                    this.$router.push({name: "website"})
                }
            },
            deep: true,
            immediate: true
        },
    }
}
</script>
<style scoped>

</style>