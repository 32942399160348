<template>
    <!-- Modal -->
    <div class="modal fade"  id="AdTagModal" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-body  container">
                    <h1 class="text-xl">
                        <span v-if="adtag.id">UPDATE</span> <span v-else>ADD</span> AD TAG 
                        <i @click="closeModal()" data-dismiss="modal" class="fas fa-times float-right cursor-pointer"></i>
                    </h1>
                    
                    <hr class="my-3">
                    <div class="row">
                        <div class="form-group col-md-12">
                            <label >Name</label>
                            <input v-model="adtag.name"  type="text" class="form-control border-top-0 border-right-0 border-left-0"   placeholder="Add tag name">
                        </div>
                        <div class="form-group col-md-12">
                            <label >Add Tag Url</label>
                            <input v-model="adtag.url" type="text" class="form-control border-top-0 border-right-0 border-left-0"   placeholder="Add tag url">
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="col-md-10">
                        <small v-if="adtag.id">
                            <a href="#" class="text-red float-left" @click.prevent="deleteAdTag(adtag.id)">Delete</a>
                        </small>
                    </div>
                    <div class="col-md-2">
                        <button type="button" @click.prevent="submit()" class="btn btn-primary btn-block">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal -->
</template>
<script>
import { RepositoryFactory } from '@/services/repositories/PlayerAPI/RepositoryFactory'
const AdTagsRepo = RepositoryFactory.get('adtags')

export default {
    props: {
        adtag: {
            type: Object,
            required: false
        },
    },
    data() {
        return {
            website_id: 0
        }
    },
    methods: {
        submit() {
            if(this.adtag.id) {
                this.update()
            } else {
                this.create()
            }
        },
        create() {
            this.adtag.website_id = this.website_id
            AdTagsRepo.post(this.adtag).then(result => {
                this.adtag = result.data
                this.$notify({
                    group: 'ops',
                    type: 'success',
                    title: 'Operation success',
                    text: 'Successfully created ad tag.'
                })
                this.updateList()
            }).catch(error => {
                this.$notify({
                    group: 'ops',
                    type: 'error',
                    title: 'Operation failed',
                    text: 'Failed create ad tag.'
                })
            })
        },
        update() {
            AdTagsRepo.patch(this.adtag.id, this.adtag).then(result => {
                this.$notify({
                    group: 'ops',
                    type: 'success',
                    title: 'Operation success',
                    text: 'Successfully updated ad tag.'
                })
                this.updateList()
            }).catch(error => {
                this.$notify({
                    group: 'ops',
                    type: 'error',
                    title: 'Operation failed',
                    text: 'Failed update ad tag.'
                })
            })
        },
        deleteAdTag(adtag_id) {
            var reply = confirm(`Are you sure you want to remove this Ad Tag`)
            if(!reply) return
            
            AdTagsRepo.delete(adtag_id).then(result => {
                this.$notify({
                    group: 'ops',
                    type: 'success',
                    title: 'Operation success',
                    text: 'Successfully deleted selceted ad tag.'
                })
                this.adtag = {}
                this.updateList()
            }).catch(error => {
                this.$notify({
                    group: 'ops',
                    type: 'error',
                    title: 'Operation failed',
                    text: 'Failed delete the selected ad tag.'
                })
            })
        },
        updateList() {
            this.$emit('updateList')
        },
        closeModal() {
            $(`#AdTagModal`).modal('toggle')
            this.$emit('modalClosed')
        },
    },
    watch: {
        '$route.query.website': { 
            handler: function (website) {
                if(typeof website !== 'undefined') {
                    // check if user is not loaded 
                    // website placements_groups before
                    if(this.website_id !== website) {
                        // match website with passed from query
                        this.website_id = website
                    }
                } else {
                    this.$router.push({name: "website"})
                }
            },
            deep: true,
            immediate: true
        },
    }
}
</script>
<style scoped>
@media (min-width: 992px) {
    .modal-lg  {
        max-width: 800px;
    }    
}
</style>