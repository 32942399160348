<template>
<div class="row">
    <div class="col-md-6">
        <GroupsTypesList :types="types" :isLoaded="isLoaded" />

        <ul class="pagination pagination-sm m-0 float-right">
          <li class="page-item">
            <a class="page-link" href="#" @click.prevent="previousPage">Previous</a>
          </li>
          <li class="page-item">
            <a class="page-link">{{ currentPage }} / {{ pageCount }}</a>
          </li>
          <li class="page-item">
            <a class="page-link" href="#" @click.prevent="nextPage">Next</a>
          </li>
        </ul>
    </div>

    <div class="col-md-6">
        <GroupsTypeView :type="type" :isLoading="isLoading" @updateList="updateList" @refreshList="refreshList"/>
    </div>
</div>
</template>

<script>
import GroupsTypesList from '@/components/groups_types/List'
import GroupsTypeView from '@/components/groups_types/Type'

import { RepositoryFactory } from '@/services/repositories/ManagementAPI/RepositoryFactory' 
const GroupsTypesRepo = RepositoryFactory.get('groups_types')

export default {
    name: "GroupsTypes",
    components: {
        GroupsTypesList,
        GroupsTypeView
    },
    data: function() {
        return {
            types: [],
            type: {
                name: "",
                code: "",
                status: ""
            },
            isLoaded: false,
            isLoading: false,

            currentPage: 1,
            totalRecords: 0,
            resultsLimit: 10,
            isLoaded: false,
            websites: [],
        }
    },
    created() {
        this.loadGroupsTypes()
    },
    methods: {
        loadGroupsTypes: function() {
            this.isLoaded = false
            GroupsTypesRepo.page(this.currentPage).then(result => {
                this.totalRecords = result.data.count
                this.types = result.data.results
                this.isLoaded = true
            }).catch(err => {})
        },
        loadGroupType: function(id) {
            this.isLoading = true
            GroupsTypesRepo.get(id).then(result => {
                this.type = result.data
                this.isLoading = false
            }).catch(err => {})
        },
        updateList: function(type) {
            this.types.push(type)
            this.$router.replace({params: {'id': type.id}})
        },
        refreshList: function(action) {
            if(action)
                this.loadGroupsTypes()
        },
        nextPage: function() {
            if(this.currentPage != this.pageCount || this.currentPage > this.pageCount) {
                this.currentPage = this.currentPage + 1
                this.loadGroupsTypes()
            }
        },
        previousPage: function() {
            if(this.currentPage != 1 ) {
                this.currentPage = this.currentPage - 1
                this.loadGroupsTypes()
            }
        }
    },
    computed: {
        pageCount: function() {
            return Math.ceil(this.totalRecords / this.resultsLimit)
        },
    },
    watch: {
        '$route.params.id': {
            handler: function (id) {
                if(typeof id !== 'undefined') {
                    this.loadGroupType(id)
                }
            },
            deep: true,
            immediate: true
        },
    }
}
</script>

<style>

</style>