import Vue from 'vue'
import Vuex from 'vuex'
import VueLocalStorage from 'vue-localstorage';

import AuthStore from './modules/auth'
import WhitelabelStore from './modules/whitelabel'

Vue.use(Vuex)
Vue.use(VueLocalStorage);

export default new Vuex.Store({
  modules: {
    Auth: AuthStore,
    Whitelabel: WhitelabelStore
  }
})
