<template>
<div>
    <Top />
    <Revenue />

    <div class="row">
        <NetworkUsers />
        <NetworkWebsites />
        <NetworkWhitelabel />
    </div>

    <Graph />
    <div class="row">
        <Websites />
        <AdFormat />
    </div>
</div>
</template>

<script>
import Top from '@/components/dashboard/top'
import Graph from '@/components/dashboard/graph/Graph'
import Revenue from '@/components/dashboard/Revenue'
import Websites from '@/components/dashboard/Websites'
import AdFormat from '@/components/dashboard/Placement_Groups'

// Network dashboard
import NetworkUsers from '@/components/dashboard/network_actions/Users'
import NetworkWebsites from '@/components/dashboard/network_actions/Websites'
import NetworkWhitelabel from '@/components/dashboard/network_actions/Whitelabel'


export default {
    name: 'Dashboard',
    components: {
        Top,
        Graph,
        Revenue,
        Websites,
        AdFormat,

        NetworkUsers,
        NetworkWebsites,
        NetworkWhitelabel
    },
    data() {
        return {
        }
    },
    created() {

    },
    mounted() {
        $('[data-widget="treeview"]').Treeview('init')
    },
    methods: {
    },
    computed: {
    },
    watch: {
    },
}
</script>
<style>

</style>