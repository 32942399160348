<template>
    <div>
        <div class="" v-animate-css="'fadeInLeft'">
            <div class="card-header">
                <h3 class="card-title">Website</h3>
                <div class="card-tools">
                        <div class="overlay" v-if="isLoading || isSubmiting">
                            <i class="fas fa-sync-alt fa-spin fa-1x"></i>
                        </div>
        
                        <button class="btn btn-tool btn-sm" v-if="!isLoading" @click.prevent="clear()">
                            <i class="fa fa-plus"></i>
                        </button>
                </div>
                <!-- /.card-tools -->
            </div>
            <!-- /.card-header -->
            <div class="card-body">
                <!-- One "tab" for each step in the form: -->
                <div class="tab">
                    <WebsiteStage @submit="WebsiteData" :categories="categories" />
                </div>
                <div class="tab">

                    <PlacementStage 
                    v-for="count in placementsCount" 
                    @submit="PlacementData"
                    @close="close(`component-placement-${count}`)"
                    :collect="collect_data" 
                    :formats="formats" 
                    :id="`component-placement-${count}`" 
                    :key="`component-placement-${count}`" 
                    v-animate-css="'slideInDown'" />

                    <div class="clearfix">
                        <button class="btn btn-success clearfix" style="padding: 10px; margin: 10px" @click="newPlacement">
                            <i class="fas fa-plus"></i>
                            New Placement
                        </button>
                    </div>
                </div>
                <div class="tab">

                    <FillRateStage 
                    v-for="count in fillRatesCount" 
                    @submit="FillRateData"
                    @close="close(`component-fillrate-${count}`)"
                    :collect="collect_data" 
                    :formats="formats"
                    :id="`component-fillrate-${count}`" 
                    :key="`component-fillrate-${count}`" 
                    v-animate-css="'slideInDown'" />

                    <div class="clearfix">
                        <button class="btn btn-success clearfix" style="padding: 10px; margin: 10px" @click="newFillRate">
                            <i class="fas fa-plus"></i>
                            New Fill Rate
                        </button>
                    </div>
                </div>
                <div class="tab">

                    <SharesStage 
                    v-for="count in sharesCount" 
                    @submit="SharesData"
                    @close="close(`component-share-${count}`)"
                    :collect="collect_data" 
                    :formats="formats" 
                    :id="`component-share-${count}`" 
                    :key="`component-share-${count}`" 
                    v-animate-css="'slideInDown'" />

                    <div class="clearfix">
                        <button class="btn btn-success clearfix" style="padding: 10px; margin: 10px" @click="newShare">
                            <i class="fas fa-plus"></i>
                            New Share
                        </button>
                    </div>
                </div>
                <div class="tab">

                    <PlayerStage v-for="count in playersCount" 
                    @submit="PlayerData" 
                    @close="close(`component-player-${count}`)"
                    :collect="collect_data" 
                    :types="player_formats" 
                    :positions="player_positions" 
                    :id="`component-player-${count}`" 
                    :key="`component-player-${count}`" 
                    v-animate-css="'slideInDown'" />
                    
                    <div class="clearfix">
                        <button class="btn btn-success clearfix" style="padding: 10px; margin: 10px" @click="newPlayer">
                            <i class="fas fa-plus"></i>
                            New Player
                        </button>
                    </div>
                </div>
                <div style="overflow: auto;">
                    <div style="float: right;">
                        <button type="button" id="prevBtn" class="btn btn-secondary mr-2" @click.prevent="nextPrev(-1)">Previous</button>
                        <button type="button" id="nextBtn" class="btn btn-success mr-2" @click.prevent="nextPrev(1)">Next</button>
                    </div>
                </div>
                <!-- Circles which indicates the steps of the form: -->
                <div class="" style="text-align: center; margin-top: 40px;padding: 2%;">
                    <div class="multi-step checked">
                        <ul class="multi-step-list">
                            <li class="multi-step-item step active current">
                                <div class="item-wrap">
                                    <p class="item-title">Website</p>
                                </div>
                            </li>
                            <li class="multi-step-item step">
                                <div class="item-wrap">
                                    <p class="item-title">Placement Groups</p>
                                </div>
                            </li>
                            <li class="multi-step-item step">
                                <div class="item-wrap">
                                    <p class="item-title">Fill Rate</p>
                                </div>
                            </li>
                            <li class="multi-step-item step">
                                <div class="item-wrap">
                                    <p class="item-title">Shares</p>
                                </div>
                            </li>
                            <li class="multi-step-itemstep step">
                                <div class="item-wrap">
                                    <p class="item-title">Players</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <!-- /.card-body -->
        </div>
        <!-- /.card -->
    
    </div>
</template>

<script>
import { ManageRepoFactory } from '@/services/repositories/ManagementAPI/RepositoryFactory'
const WebsitesRepo = ManageRepoFactory.get('websites')
const GroupsTypesRepo = ManageRepoFactory.get('groups_types')

import { PlayerRepoFactory } from '@/services/repositories/PlayerAPI/RepositoryFactory'
const PlayerFormatsRepo = PlayerRepoFactory.get('formats')

import WebsiteStage from '@/components/websites/stages/Website'
import PlacementStage from '@/components/websites/stages/Placements'
import FillRateStage from '@/components/websites/stages/FillRate'
import SharesStage from '@/components/websites/stages/Shares'
import PlayerStage from '@/components/websites/stages/Players'

export default {
    props: {
        website: {
            type: Object
        },
        isLoading: {
            required: false,
            type: Boolean
        }
    },
    components: {
        WebsiteStage,
        PlacementStage,
        FillRateStage,
        SharesStage,
        PlayerStage
    },
    data() {
        return {
            current_tab: 0,
            placementsCount: 1,
            playersCount: 1,
            sharesCount: 1,
            fillRatesCount: 1,

            collect_data: 0,
            isSubmiting: false,
            categories: [],
            formats: [],
            player_formats: [],
            player_positions: [],

            data: {
                website: {},
                placements: [],
                fill_rates: [],
                shares: {
                    pmp: [],
                    adcrew: [],
                    network: []
                },
                players: []
            },
            clear_data: {
                website: {},
                placements: [],
                fill_rates: [],
                shares: {
                    pmp: [],
                    adcrew: [],
                    network: []
                },
                players: []
            },
        }
    }, 
    mounted() {
        this.showTab(this.current_tab); // Display the current tab
        this.loadFormats()
        this.getFormatTypes()
        this.getFormatPositions()
        this.getWebsitesCategories()
    },
    methods: {
        WebsiteData(website) {
            this.data.website = website
        },
        PlacementData(placement) {
            if(placement !== undefined)
                this.data.placements.push(placement)
        },
        SharesData(share) {
            if(share.type === 'adcrew') {
                delete share.type
                this.data.shares.adcrew.push(share)
            }

            if(share.type === 'pmp') {
                delete share.type
                this.data.shares.pmp.push(share)
            }

            if(share.type === 'network') {
                delete share.type
                this.data.shares.network.push(share)
            }
        },
        FillRateData(rate) {
            if(rate !== undefined)
                this.data.fill_rates.push(rate)
        },
        PlayerData(player) {
            if(player !== undefined)
                this.data.players.push(player)
        },
        submit: function() {
            if(typeof this.website.id === 'undefined') {
                this.create(this.website)
            }
        },
        close(id) {
       },
        clear: function() {
            this.data = this.clear_data
            this.placementsCount = 0
            this.playersCount = 0
            this.sharesCount = 0
            this.fillRatesCount = 0
        },
        create: function(website) {
            WebsitesRepo.post(website).then(result => {
                if(typeof result.data.id !== 'undefined' ) 
                    this.website = result.data
                    this.$notify({
                        group: 'ops',
                        type: 'success',
                        title: 'Operation success',
                        text: 'Successfully added with ID: ' + result.data.id
                    })
                    this.$emit('updateList', this.website)
            }).catch(err => {
                this.$notify({
                    group: 'ops',
                    type: 'error',
                    title: 'Operation failed',
                    text: err.response.data
                })
            })
        },
        showTab: function(n) {
            // This function will display the specified tab of the form...
            var x = document.getElementsByClassName("tab");
            x[n].style.display = "block";
            //... and fix the Previous/Next buttons:
            if (n == 0) {
                document.getElementById("prevBtn").style.display = "none";
            } else {
                document.getElementById("prevBtn").style.display = "inline";
            }
            if (n == (x.length - 1)) {
                document.getElementById("nextBtn").innerHTML = "Submit";
            } else {
                document.getElementById("nextBtn").innerHTML = "Next";
            }
            //... and run a function that will display the correct step indicator:
            this.fixStepIndicator(n)
        },
        nextPrev: function(n) {
            if(document.getElementById("nextBtn").innerHTML === "Submit") {
                //... collect stages data before submition
                this.collect_data = this.collect_data + 1
                document.getElementById("nextBtn").style.display = "none"
                this.isSubmiting = true

                self = this
                setTimeout(function() { self.submitStagesData(); }, 2000);
                return false;
            }
            // This function will figure out which tab to display
            var x = document.getElementsByClassName("tab");
            // Exit the function if any field in the current tab is invalid:
            // if (n == 1 && !this.validateForm()) return false;
            // Hide the current tab:
            x[this.current_tab].style.display = "none";
            // Increase or decrease the current tab by 1:
            this.current_tab = this.current_tab + n;
            // if you have reached the end of the form...
            // Otherwise, display the correct tab:
            this.showTab(this.current_tab);
        },
        validateForm: function() {
            // This function deals with validation of the form fields
            var x, y, i, valid = true;
            x = document.getElementsByClassName("tab");
            y = x[this.current_tab].getElementsByTagName("input");
            // A loop that checks every input field in the current tab:
            // for (i = 0; i < y.length; i++) {
            //     // If a field is empty...
            //     if (y[i].value == "") {
            //         // add an "invalid" class to the field:
            //         y[i].className += " invalid";
            //         // and set the current valid status to false
            //         valid = false;
            //     }
            // }
            // If the valid status is true, mark the step as finished and valid:
            if (valid) {
                document.getElementsByClassName("step")[this.current_tab].className += " finish";
            }
            return valid; // return the valid status
        },
        fixStepIndicator: function(n) {
            // This function removes the "active" class of all steps...
            var i, x = document.getElementsByClassName("step");
            for (i = 0; i < x.length; i++) {
                x[i].className = x[i].className.replace(" active", "");
            }
            //... and adds the "active" class on the current step:
            x[n].className += " active current";
        },
        newPlacement() {
            this.placementsCount++
        },
        newPlayer() {
            this.playersCount++
        },
        newFillRate() {
            this.fillRatesCount++
        },
        newShare() {
            this.sharesCount++
        },
        loadFormats: function() {
            this.isLoading = true
            GroupsTypesRepo.page(1, 1000).then(result => {
                this.formats = result.data.results
                this.isLoading = false
            }).catch(err => {})
        },
        getFormatTypes() {
            PlayerFormatsRepo.options().then(result => {
                this.player_formats = result.data.actions.POST.type.choices
            }).catch(error => {
                console.log(error)
            })
        },
        getFormatPositions: function () {
            PlayerFormatsRepo.options().then(result => {
                this.player_positions = result.data.actions.POST.position.choices
            }).catch(error => {
                console.log(error)
            })
        },
        getWebsitesCategories: function () {
            WebsitesRepo.categories().then(result => {
                this.categories = result.data.actions.POST.category.choices
            }).catch(error => {
                console.log(error)
            })
        },
        submitStagesData() {
            WebsitesRepo.stages(this.data).then(result => {
                if(typeof result.data.id !== 'undefined' ) 
                    this.website = result.data
                    this.$notify({
                        group: 'ops',
                        type: 'success',
                        title: 'Operation success',
                        text: 'Successfully added with ID: ' + result.data.id
                    })
                    this.isSubmiting = false
                    this.$emit('updateList', this.website)
                    this.$router.push( {name: 'websites', params: {id: result.data.website.id}} )
            }).catch(err => {
                this.$notify({
                    group: 'ops',
                    type: 'error',
                    title: 'Operation failed',
                    text: err.response.data
                })
            })
        }
    },
}
</script>
    
<style scoped>
#regForm {
  background-color: #ffffff;
  margin: 100px auto;
  font-family: Raleway;
  padding: 40px;
  width: 70%;
  min-width: 300px;
}

h1 {
  text-align: center;  
}

input {
  padding: 10px;
  width: 100%;
  font-size: 17px;
  font-family: Raleway;
  border: 1px solid #aaaaaa;
}

/* Mark input boxes that gets an error on validation: */
input.invalid {
  background-color: #ffdddd;
}

/* Hide all steps by default: */
.tab {
  display: none;
}


</style>
<style lang="scss">
    // Variables
$base-margin: 2em;
$base-padding: 1em;
$base-border-radius: .2em;
$screen-xs-max: 786px;

$text-color: #263238;
$text-color-inverted: #fff;
$clickable-hover: #d8f1ff;

$brand-primary: #4285f4;
$brand-success: #54b948;
$brand-danger: #d50000;

$gray-dark: #333;
$gray-light: #999;
$gray-lighter: #eee;

$icon-danger: '!';
$icon-success: '✓';

$animation-time: 0.5s;


// Multi-step code

.multi-step {
	margin: ($base-margin / 2) 0;
}

// Setting up flexbox for list
.multi-step-list {
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	list-style-type: none;
	padding: 0;
	.multi-step-item:first-child { margin-left: 0; }
	.multi-step-item:last-child { margin-right: 0; }
}

// Defaults for each 'step'
.multi-step-item {
	position: relative;
	width: 100%;
	margin: 0 ($base-margin / 6);
	@media only screen and (max-width: $screen-xs-max) {
		margin: 0 ($base-margin / 6);
	}
	z-index: 2;
	border-radius: $base-border-radius;

	// Step title and subtitle defaults
	.item-title,
	.item-subtitle {
		position: relative;
		margin: 0;
		z-index: 2;
	}
	@media only screen and (max-width: $screen-xs-max) {
		.item-subtitle { display: none; }
	}
	.item-title {
		color: $brand-primary;
		font-weight: 600;
		margin: 0;
	}

	// Different step states [ active, current, completed, error]
	&.active:hover { cursor: pointer; }
	&.current .item-title,
	&.current .item-subtitle {
		color: $text-color-inverted;
	}
	&.active.current:hover .item-title,
	&.active.current:hover .item-subtitle {
		color: $brand-primary;
	}
	&.error:after {
		position: absolute;
		top: 50%;
		z-index: 2;
		transform: translateY(-50%);
		right: .5em;

		content: $icon-danger;
		color: $brand-danger;
	}
}

// Creates the 'arrow' effect / background colors
.item-wrap {
	padding: $base-padding;
	position: relative;
	height: 100%;
	&:before, &:after {
		position: absolute;
		left: 0;
		content: ' ';
		width: 100%;
		height: 50.5%;
		z-index: 1;
		background-color: $gray-lighter;
	}

	// Top of the arrow
	&:before {
		top: 0;
		transform: skew(20deg);
		border-radius: .2em .2em 0 0;
	}
	// Bottom of the arrow
	&:after {
		bottom: 0;
		transform: skew(-20deg);
		border-radius: 0 0 .2em .2em;
	}
}

// Changing arrow colors based on state
.current .item-wrap:before,
.current .item-wrap:after {
	background-color: $brand-primary;
}

.active:hover .item-wrap:before,
.active:hover .item-wrap:after {
	background-color: $clickable-hover;
}

.multi-step-item.error {
	.item-title,
	.item-subtitle {
		padding-right: ($base-padding * 2);
	}
}

// Changing step styles based on :first/:last step
.multi-step-item:first-child .item-wrap,
.multi-step-item:last-child .item-wrap {
	width: 100%;
	border-radius: $base-border-radius;
	&:before, &:after { width: 50%; }
}

// If first step, only point on the right
.multi-step-item:first-child .item-wrap {
	background: linear-gradient(to right, $gray-lighter 95%, transparent 5%);
	&:before, &:after { left: 50%; }
}
.active.multi-step-item:first-child:hover .item-wrap {
	background: linear-gradient(to right, $clickable-hover 95%, transparent 5%);
}
.current.multi-step-item:first-child .item-wrap {
	background: linear-gradient(to right, $brand-primary 95%, transparent 5%);
}

// If last step, only indent on the left
.multi-step-item:last-child .item-wrap {
	background: linear-gradient(to left, $gray-lighter 95%, transparent 5%);
	&:before, &:after { right: 50%; }
}
.active.multi-step-item:last-child:hover .item-wrap {
	background: linear-gradient(to left, $clickable-hover 95%, transparent 5%);
}
.current.multi-step-item:last-child .item-wrap {
	background: linear-gradient(to left, $brand-primary 95%, transparent 5%);
}

// MSI Checked & Complete
.checked .multi-step-item.completed:after {
	position: absolute;
	top: 50%;
	z-index: 2;
	transform: translateY(-50%);
	right: .5em;
	content: $icon-success;
	color: $brand-success;
}

// MSI Numbered
.numbered .multi-step-item {
	counter-increment: step-counter;
	.item-wrap { padding-left: ($base-padding * 5); }

	// Adds number to step
	&:before {
		content: counter(step-counter);
		position: absolute;
		top: 50%;
		left: .75em;
		transform: translateY(-50%);
		min-width: 1.65em;
		padding: ($base-padding / 2) $base-padding;
		z-index: 2;
		font-size: .85em;
		background-color: $gray-light;
		color: $text-color-inverted;
		font-weight: 600;
		text-align: center;
		border-radius: $base-border-radius;
	}
}

// MSI w/ badge counts
.item-wrap .badge {
	position: absolute;
	right: .5em;
	top: 50%;
	transform: translateY(-50%);
	z-index: 3;
}
.error .item-wrap .badge {
	right: 2em;
	~ .item-title,
	~ .item-subtitle {
		padding-right: 3em;
	}
}

// MSI CSS Loader
.multi-step-loading { opacity: .75; }

.current.multi-step-loading:before {
	border-color: $text-color-inverted;
	border-top-color: transparent;
	opacity: 1;
}

.busy-css {
	z-index: 3;
	content: '';
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: -.5em;
	margin-left: -.5em;
	border-radius: 50%;
	width: 1em;
	height: 1em;
	border: .25em solid $gray-dark;
	border-top-color: transparent;
	animation: spin ($animation-time * 2) infinite linear;
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
</style>