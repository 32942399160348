<template>
<div>
    <div class="">
        <!-- /.card-header -->
        <!-- form start -->
        <form role="form">
        <div class="card-body">
            <div class="form-group">
                <label>Email</label>
                <input type="email" v-model="email_change.email" class="form-control" placeholder="Enter Email">
            </div>
            <div class="form-group">
                <label>Confirm Email</label>
                <input type="email" v-model="email_change.email_confirm" class="form-control" placeholder="Confirm Email">
            </div>
        </div>
        <!-- /.card-body -->

        <div class="card-footer">
            <a href="#" @click.prevent="changeEmail()" class="btn btn-primary">Update</a>
        </div>
        <br />
        </form>
    </div>

    <hr />

    <div class="">
        <!-- /.card-header -->
        <!-- form start -->
        <form role="form">
        <div class="card-body">
            <div class="form-group">
                <label>Old Password</label>
                <input type="password" v-model="password_change.old_password" class="form-control" placeholder="Old Password">
            </div>
            <div class="form-group">
                <label>New Password</label>
                <input type="password" v-model="password_change.new_password" class="form-control" placeholder="New Password">
            </div>
            <div class="form-group">
                <label>Confirm Password</label>
                <input type="password" v-model="password_change.confirmed_password" class="form-control" placeholder="Confirm password">
            </div>
        </div>
        <!-- /.card-body -->

        <div class="card-footer">
            <a href="#" @click.prevent="changePassword()" class="btn btn-primary">Update</a>
        </div>
        <br />

        </form>
    </div>
</div>
</template>
<script>
import { RepositoryFactory } from '@/services/repositories/AuthAPI/RepositoryFactory';
const UserRepo = RepositoryFactory.get('user');

export default {
    name: 'LoginInfo',
    components: {
    },
    props: {
        user: {
            type: Object
        },
    },
    data() {
        return {
            email_change: {
                user: this.user.id,
                email: null,
                email_confirm: null,
            },
            password_change: {
                user: this.user.id,
                old_password: null,
                new_password: null,
                confirmed_password: null,
            }
        }
    },
    methods: {
        changeEmail: function() {
            UserRepo.changeEmail(this.email_change).then(result => {
                this.$notify({
                    group: 'ops',
                    type: 'success',
                    title: 'Operation success',
                    text: result.data.message
                })
            }).catch(err => {
                this.$notify({
                    group: 'ops',
                    type: 'error',
                    title: 'Operation failed',
                    text: err.response.data
                })
            })
        },
        changePassword: function() {
            UserRepo.changePassword(this.password_change).then(result => {
                this.$notify({
                    group: 'ops',
                    type: 'success',
                    title: 'Operation success',
                    text: result.data.message
                })
            }).catch(err => {
                this.$notify({
                    group: 'ops',
                    type: 'error',
                    title: 'Operation failed',
                    text: err.response.data
                })
            })
        }
    }
}
</script>