<template>
<div>
    <div class="card col-md-6 col-sm-12 offset-3">
        <div class="card-header">
            <h1>New Data Statistics</h1>
            <div class="card-tools">
                <div class="overlay" v-if="isLoading">
                    <i class="fas fa-sync-alt fa-spin fa-1x"></i>
                </div>

                <button class="btn btn-primary btn-sm" v-if="!isLoading" @click.prevent="clear()">
                    <i class="fa fa-plus"></i>
                </button>
            </div>
        </div>
        <div class="card-body">
        <div class="row">
            <div class="form-group col-md-4" v-for="metric in display" :key="metric">
                <label> {{ metric | capitalizeAndReplace }} </label>
                <input type="text" class="form-control" v-model="statistic[metric]">
            </div>
            <!-- Date input form -->
            <div class="col-md-4">
                <div class="form-group">
                    <label>Date:</label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">
                                <i class="far fa-calendar-alt"></i>
                            </span>
                        </div>
                        <input type="text" class="form-control float-right" id="datepicker">
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label>Base Revenue</label>
                    <input type="text" class="form-control" v-model="statistic.base_revenue">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="form-group">
                    <div class="btn-group btn-group-toggle">
                        <label class="btn btn-sm btn-default" :class="{'text-green': statistic.status == 'active'}">
                            <input type="radio" value="active" v-model="statistic.status"> Active
                        </label>
                        <label class="btn btn-sm btn-default" :class="{'text-red': statistic.status == 'suspended'}">
                            <input type="radio" value="suspended" v-model="statistic.status"> Suspended
                        </label>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <div class="btn-group btn-group-toggle">
                        <label class="btn btn-sm btn-default" :class="{'text-green': statistic.complete == 'true' | statistic.complete}">
                            <input type="radio" value="true" v-model="statistic.complete"> Complete
                        </label>
                        <label class="btn btn-sm btn-default" :class="{'text-red': statistic.complete == 'false' | !statistic.complete}">
                            <input type="radio" value="false" v-model="statistic.complete"> Uncomplete
                        </label>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-sm-12">
                <button @click.prevent="add()" class="btn btn-primary btn-block">Add</button>
            </div>
            <!-- /.col -->
            </div>
        </div>
        <!-- /.form-box -->
    </div><!-- /.card -->
</div>
</template>

<script>
import { RepositoryFactory } from '@/services/repositories/ManagementAPI/RepositoryFactory'
const SystemRepo = RepositoryFactory.get('system')
const Statistics = RepositoryFactory.get('statistics')
const PlacementsGroupsDataRepo = RepositoryFactory.get('groups_data')

export default {
    components: {
    },
    data() {
        return {
            pg_data: {},
            statistic: {},
            metrics: [],
            signs: [],
            display: [],
            isLoading: false
        }
    },
    created() {
        this.loadSystemMetrics()
    },
    mounted() {
        var self = this
        $('#datepicker').datepicker({
            format: 'yyyy-mm-dd',
        })
    },
    methods: {
        loadSystemMetrics: function() {
            this.isLoading = true
            SystemRepo.metrics().then(result => {
                this.metrics = result.data.schema
                this.signs = result.data.signs
                this.display = result.data.display
                this.isLoading = false
            }).catch(err => {
                this.isLoading = false
            })
        },
        loadPGData(id) {
            PlacementsGroupsDataRepo.get(id).then(result => {
                this.pg_data = result.data
                this.clear()
            }).catch(err => {})
        },
        clear: function() {
            this.statistic = {
                placement_group_data_id: this.pg_data.id,
                placement_group_id: this.pg_data.placement_group_id,
                website_id: this.pg_data.website_id,
                supplier_share: null,
                status: "suspended",
            }
        },
        add: function() {
            this.statistic.date = $('#datepicker').val()
            Statistics.post(this.statistic).then(result => {
                this.$notify({
                    group: 'ops',
                    type: 'success',
                    title: 'Operations Success',
                    text: 'Statistics created successfuly.'
                })
            }).catch(err => {
                this.$notify({
                    group: 'ops',
                    type: 'error',
                    title: 'Operations failed',
                    text: "Failed to create statistics"
                })
            })
        },
    },
    computed: {

    },
    watch: {
        '$route.query.data': {
            handler: function (data) {
                if(typeof data !== 'undefined') {
                    this.loadPGData(data)
                }
                if(typeof data == 'undefined') {
                    this.$router.push({ name: "websites" })
                }
            },
            deep: true,
            immediate: true
        },
    }
}
</script>

<style scoped>

</style>