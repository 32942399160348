<template>
    <div class="card">
        <div class="card-header">
            <h3 class="card-title">API's List</h3>

            <div class="card-tools">
                <div class="input-group input-group-sm" style="width: 200px;">
                    <input type="search" name="table_search" class="form-control float-right" placeholder="Search">
                    <div class="input-group-append">
                        <button type="submit" class="btn btn-default"><i class="fas fa-search"></i></button>
                    </div>
                    <div class="btn-group">
                        <button type="button" class="btn btn-default btn-sm dropdown-toggle" data-toggle="dropdown" data-offset="-52" aria-expanded="true">
                            Types
                            <i class="fas fa-bars"></i>
                        </button>
                        <div class="dropdown-menu" role="menu">
                            <a href="#" class="dropdown-item" v-for="type in types" :key="type.id" @click="loadAPIType(type)">
                               {{ type.shortname }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- /.card-header -->
        <div class="card-body table-responsive p-0">

            <AdManager v-if="selectedType == 'admanager'" :apis="apis" />
            <AdSense v-else-if="selectedType == 'adsense'" :apis="apis" />
            <AniView v-else-if="selectedType == 'aniview'" :apis="apis" />
            <Netlink v-else-if="selectedType == 'netlink'" :apis="apis" />
            <Postquare v-else-if="selectedType == 'postquare'" :apis="apis" />
            
        </div>

        <div class="overlay" v-if="!isLoaded">
            <point-spinner />
        </div>

        <!-- <div class="overlay dark" v-if="hasError">
        <i class="fas fa-2x fa-exclamation-triangle"></i>
        </div> -->
    </div>
</template>

<script>
import AdManager from './types/AdManager.vue'
import AdSense from './types/AdSense.vue'
import AniView from './types/AniView.vue'
import Netlink from './types/Netlink.vue'
import Postquare from './types/Postquare.vue'

export default {
    props: {
        apis: {
          required: true,
          type: Array
        },
        types: {
            required: true,
          type: Array
        },
        isLoaded: {
          required: false,
          type: Boolean
        },
        selectedType: {
          required: false,
          type: String
        },
    },
    components: {
        AdManager,
        AdSense,
        AniView,
        Netlink,
        Postquare
    },
    data() {
        return {
            types: [],
            messages: [],
            errors: []
        }
    },
    created() {

    },
    methods: {
        loadAPIType: function(type) {
            this.$emit('typeChanged', type)
        },
        loadAPI: function(api) {
            this.$emit('apiChanged', api)
        },
    },
}
</script>

<style scoped>

</style>