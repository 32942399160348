<template>
<div class="col-md-4">
    <div class="card">
        <div class="card-header">
        <h3 class="card-title">Whitelabel Need Approval ({{ totalRecords }})</h3>

        <div class="card-tools">
            <button type="button" class="btn btn-tool" data-card-widget="collapse">
            <i class="fas fa-minus"></i>
            </button>
            <button type="button" class="btn btn-tool" data-card-widget="remove">
            <i class="fas fa-times"></i>
            </button>
        </div>
        </div>
        <!-- /.card-header -->
        <div class="card-body p-0">
        <ul class="products-list product-list-in-card pl-2 pr-2">
            <li class="item" v-for="website in whitelists" :key="website.id">
            <div class="product-img">
                <img :src="website.logo || '/assets/img/AdminLTELogo.png'" alt="Whitelabel Logo" class="img-size-50">
            </div>
            <div class="product-info">
                <router-link :to="{name: 'white_list', params: {'id': website.id} }" class="product-title">
                    {{ Network(website.network) }}
                    <span class="badge float-right" :class="{
                        'badge-success': website.status == 'active',
                        'badge-warning': website.status == 'pending',
                        'badge-danger': website.status == 'suspended'}"> {{ website.status | capitalize }} 
                    </span>
                </router-link>
                <span class="product-description">
                    {{ website.domain }}
                    <span class="float-right">
                        {{ website.updated_at | formatDate }} 
                    </span>
                </span>
            </div>
            </li>
            <!-- /.item -->
        </ul>
        </div>
        <!-- /.card-body -->
        <div class="card-footer text-center">
            <router-link :to="{name: 'white_list'}" class="uppercase">View All</router-link>
        </div>

        <!-- /.card-footer -->
        <div class="overlay" v-if="!isLoaded">
            <point-spinner />
        </div>
    </div>
</div>
</template>
<script>
import { RepositoryFactory } from '@/services/repositories/ManagementAPI/RepositoryFactory'
const UsersRepo = RepositoryFactory.get('users')
const WhiteListRepo = RepositoryFactory.get('whitelist')

export default {
    data() {
        return {
            whitelists: [],
            users: [],

            isLoaded: false,
            hasError: false,
            totalRecords: 0,
        }
    },
    created() {
        this.loadWhiteLists()
        this.loadUsers()
    },
    methods: {
        loadUsers: function() {
            this.isLoaded = false
            UsersRepo.query({'role': 'network'}, 1, 1000).then(result => {
                this.users = result.data.results //.filter(user => { return user.profile.user_role === 'network' })
            }).catch(err => {
                this.isLoaded = true
            })
        },
        loadWhiteLists: function() {
            this.isLoaded = false
            WhiteListRepo.query({'status': 'pending'}, 1).then(result => {
                this.totalRecords = result.data.count
                this.whitelists = result.data.results
                this.isLoaded = true
            }).catch(err => {
                this.isLoaded = true
            })
        },
        Network: function(user_id) {
            var network = this.users.filter(network => { return network.id == user_id })[0]
            if(network)
                return network.first_name + " " + network.last_name
            return "None"
      },
    },
}
</script>
<style>

</style>