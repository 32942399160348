<template>
    <div class="login-box" v-if="isNotAuthenticated">
        <div class="register-box">
    
        <CompanyLogo />
        <div class="card">

            <div class="card-body register-card-body" v-if="!verified">
                <div class="alert alert-danger"> This url is not valid or has expired! </div>
                <div class="dropdown-divider"></div>
                <router-link :to="{name: 'login'}" class="text-center">Return to login</router-link>
            </div>
            <div class="card-body register-card-body" v-if="verified">
                <div class="alert alert-success"> Your account has been verified. </div>
                <div class="dropdown-divider"></div>
                <router-link :to="{name: 'login'}" class="text-center">Return to login</router-link>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import CompanyLogo from '@/components/core/CompanyLogo'

export default {
    components: {
      CompanyLogo,
    },
    data() {
        return {
            errors: [],
            messages: [],
            verified: false,
            user: {
                key: null,
                type: 'account'
            }
        }
    }, 
    created() {
        this.verifiedKey()
    },
    methods: {
        verifiedKey: function() {
            this.user.key = this.$route.params.key
            this.$store.dispatch('VERIFY', this.user).then(result => {
                this.verified = true
            })
            .catch(err => {
                this.verified = false
            })
        },
    },
    computed: {
        isNotAuthenticated: function() {
            if(!this.$store.getters.isAuthenticated) {
                return true
            }
            this.$router.push('/')
        },
    }
}
</script>
<style>
    body {
        background-color: #e4e4e4;
    }
      .login-box {
    margin: 10% auto;
  }

</style>