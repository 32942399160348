import Repository from './../Repository';

const resource = "/shares/pmp";
export default {
    page(website) {
        return Repository.get(`${resource}/?website=${website}`);
    },
    all(website) {
        return Repository.get(`${resource}/?website=${website}`);
    },
    get(id) {
        return Repository.get(`${resource}/${id}/`);
    },
    post(data) {
        return Repository.post(`${resource}/`, data);
    },
    delete(id) {
        return Repository.delete(`${resource}/${id}/`);
    },
}