import Repository from './../Repository'

const resource = "/wallets/invoices"

export default {
    page(wallet, page=1, limit=10, start_date='', end_date='', order="-date", search='') {
        var offset = (page - 1) * limit
        if(search) { search = `&search=${search}` }
        
        var date_filter = ''
        if(start_date && end_date) {
            date_filter = `&start_date=${start_date}&end_date=${end_date}`
        }
        if(start_date && !end_date) {
            date_filter = `&date=${start_date}`
        }
        return Repository.get(`${resource}/?wallet=${wallet}${date_filter}&limit=${limit}&offset=${offset}&o=${order}${search}`);
    },
    get(id) {
        return Repository.get(`${resource}/${id}/`);
    },
    post(data) {
        return Repository.post(`${resource}/`, data);
    },
    update(id, data) {
        return Repository.put(`${resource}/${id}/`, data);
    },
    patch(id, data) {
        return Repository.patch(`${resource}/${id}/`, data);
    },
}