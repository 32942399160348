import Repository from './Repository';

const resource = "/whitelist";
export default {
    page(page, limit=10) {
        var offset = (page - 1) * limit
        return Repository.get(`${resource}/?limit=${limit}&offset=${offset}`);
    },
    query(filter, page, limit=10, search='') {
        var offset = (page - 1) * limit
        if(search) { search = `&search=${search}` }
        
        filter = new URLSearchParams(filter).toString()
        filter = `&${filter}`
        return Repository.get(`${resource}/?limit=${limit}&offset=${offset}${search}${filter}`);
    },
    get(id) {
        return Repository.get(`${resource}/${id}/`);
    },
    post(data) {
        return Repository.post(`${resource}/`, data);
    },
    patch(id, data) {
        return Repository.patch(`${resource}/${id}/`, data);
    },
    upload(id, data, onupload={}) {
        return Repository.patch(`${resource}/${id}/`, data, onupload);
    },
    delete(id) {
        return Repository.delete(`${resource}/${id}/`);
    },
    status() {
        return Repository.get(`${resource}/status/`);
    },
    upload(id, data, onupload={}) {
        return Repository.patch(`${resource}/${id}/`, data, onupload);
    },
    verify(data) {
        return Repository.post(`${resource}/check/`, data);
    },
    patch(id, data) {
        return Repository.patch(`${resource}/${id}/`, data);
    },
}