<template>
    <!-- Format -->
    <div class="border mb-2 rounded-lg font-weight-bold text-dark" v-if="format">
        <nav class="m-2">
            <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                <li class="nav-item has-treeview menu-close">
                    <a href="#" class="nav-link flex align-items-center text-warning">
                    <i class="nav-icon fas fa-plug"></i>
                    <div class=""> Format </div><i class="fas fa-angle-left ml-auto"></i> 
                    </a>
                    <ul class="m-3 nav nav-treeview">
                        <li class="nav-item font-weight-normal pb-6">
                            <div class="d-flex justify-content-start align-items-baseline input-group">
                                <div class="col-md-6">
                                    <label class="font-weight-normal " for="playerFormat">Type</label>
                                </div>
                                <div class="col-md-6 ">
                                    <select  class="custom-select " id="playerFormat" v-model="format.type">
                                        <option disabled>Choose format for player</option>
                                        <option v-for="(type, index) in types" :key=index :value=type.value > {{ type.display_name }} </option>
                                    </select>                                            
                                </div>
                            </div>
                        </li>
                        <li class="nav-item mb-4 font-weight-normal">
                            <div class="d-flex justify-content-start align-items-baseline input-group">
                                <div class="col-md-6">
                                    <label class="font-weight-normal " for="playerFormat">Player position</label>
                                </div>
                                <div class="col-md-6 ">
                                    <select  class="custom-select " id="playerFormat" v-model="format.position">
                                        <option disabled>Choose player position</option>
                                        <option v-for="(position, index) in positions" :key=index :value=position.value > {{ position.display_name }} </option>
                                    </select>
                                </div>
                            </div>
                        </li>
                        <li class="nav-item mb-4 font-weight-normal">
                            <div class="d-flex justify-content-start align-items-baseline input-group">
                                <div class="col-md-6">
                                    <label class="font-weight-normal " for="playerFormat">Companion position</label>
                                </div>
                                <div class="col-md-6 ">
                                    <select  class="custom-select " id="playerFormat" v-model="format.companion_position">
                                        <option disabled>Choose companion position</option>
                                        <option v-for="(position, index) in positions" :key=index :value=position.value > {{ position.display_name }} </option>
                                    </select>
                                </div>
                            </div>
                        </li>
                        <li class="nav-item mb-4 font-weight-normal">
                            <div class="d-flex justify-content-start align-items-baseline input-group">
                                <div class="col-md-6">
                                    <label class="font-weight-normal " for="playerFormat">Close button</label>
                                </div>
                                <div class="col-md-6">
                                    <div class="inline">
                                        <div class="custom-control custom-switch p-0 py-2">
                                            <label class="switch" for="autoplay">
                                                <input type="checkbox" class="primary" id="autoplay" 
                                                v-model="format.close_button">
                                                <span class="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <hr><br>
                            <button class="btn btn-success float-right" @click.prevent="update()">Save</button>
                            <br>
                        </li>
                    </ul>
                </li>
            </ul>
        </nav>
    </div>
    <!-- Format -->
</template>
<script>
import { RepositoryFactory } from '@/services/repositories/PlayerAPI/RepositoryFactory'
const FormatsRepo = RepositoryFactory.get('formats')

export default {
    props: {
        format: {
            type: Object,
            required: false
        },
    },
    data () {
        return {
            types: [],
            positions: []
        }
    },
    created () {
        this.getFormatTypes()
        this.getFormatPositions()
    },
    methods: {
        update: function () {
            FormatsRepo.patch(this.format.id, this.format).then(result => {
                this.format = result.data
                this.$notify({
                    group: 'ops',
                    type: 'success',
                    title: 'Operation success',
                    text: 'Successfully updated the player format.'
                })
            }).catch(error => {
                this.$notify({
                    group: 'ops',
                    type: 'error',
                    title: 'Operation failed',
                    text: 'Failed to update the player format.'
                })
            })
        },
        getFormatTypes: function () {
            FormatsRepo.options().then(result => {
                this.types = result.data.actions.POST.type.choices
            }).catch(error => {
                console.log(error)
            })
        },
        getFormatPositions: function () {
            FormatsRepo.options().then(result => {
                this.positions = result.data.actions.POST.position.choices
            }).catch(error => {
                console.log(error)
            })
        }
    }
}
</script>