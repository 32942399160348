<template>
    <!-- Modal -->
    <div class="modal fade" id="videoBrowserModal" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-lg" style="max-width: 60%;" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel"> View available videos</h5>
                    <div class="card-tools">
                        <button type="button" class="btn btn-tool btn-sm" @click.prevent="showUploadModal()">
                            <i class="fas fa-upload"></i> Upload
                        </button>
                        <button type="button" class="btn btn-tool btn-sm" @click.prevent="listVideos()">
                            <i class="fa fa-sync" v-if="!refreshing"></i>
                            <i class="fa fa-sync fa-spin fa-1x" v-if="refreshing"></i>
                            Refresh list
                        </button>
                    </div>
                    <button type="button" class="close" aria-label="Close" data-dismiss="modal" @click.prevent="closeModel()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div>
                        <div class="xl:flex p-2 w-full h-auto">
                            <div v-for="video, index in videos_list" @click="setPlayerVideo(video.url)" class="p-2 w-100 video-standerd items-center hover:bg-gray-200 cursor-pointer" data-category="1">
                                <a data-toggle="lightbox" :data-title="video.title" class="flex flex-col">
                                    <picture class="h-100">
                                        <source :srcset="video.preview+'?'+timestamp()" type="image/webp">
                                        <img :src="video.thumbnail" width="100%">
                                    </picture>
                                    <span class="font-normal mt-2">{{ video.title }}</span>
                                    <div class="flex mt-3 text-gray-600 font-light">
                                        <div class="mr-5">
                                            <i class="fas fa-clock"></i>
                                            {{ duration(video.length) }}
                                        </div>
                                        <div class="">
                                            <i class="fas fa-list"></i>
                                            {{ video.size }} MB
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <upload-video @uploaded="listVideos()" @closed="closeUploadModal()" />
    </div>
</template>
<script>
import UploadVideo from '../VideoUpload.vue'

import { RepositoryFactory } from '@/services/repositories/PlayerAPI/RepositoryFactory'
const ContentBrowserRepo = RepositoryFactory.get('content_browser')

export default {
    components: {
        UploadVideo
    },
    props: {
        player_id: {
            type: Number
        }
    },
    data () {
        return {
            videos_list: [],
            refreshing: false
        }
    },
    mounted() {
        this.listVideos()
    },
    methods: {
        timestamp() {
            return Date.now();
        },
        closeModel: function() {
            this.$emit("closed")
        },
        showUploadModal() {
            $(`#videoUploadModal`).modal()
        },
        closeUploadModal() {
            $('#videoUploadModal').modal('hide')
        },
        listVideos() {
            this.refreshing = true
            ContentBrowserRepo.getVideos().then(result => {
                this.videos_list = result.data
                this.refreshing = false
            }).catch(() => {
                this.refreshing = false
            })
        },
        setPlayerVideo(url) {
            ContentBrowserRepo.changeVideo({url: url, player_id: this.player_id}).then( () => {
                this.$notify({
                    group: 'ops',
                    type: 'success',
                    title: 'Operation success',
                    text: 'Successfully updated the player video.'
                })
            }).catch(() => {
                this.$notify({
                    group: 'ops',
                    type: 'error',
                    title: 'Operation failed',
                    text: 'Failed to update the player video.'
                })
            })
            this.closeModel()
        },
        reloadvideo() {
            this.$emit("video_updated")
        },
        duration(seconds_) {
            var sec_num = seconds_; // don't forget the second param
            var hours   = Math.floor(sec_num / 3600);
            var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
            var seconds = sec_num - (hours * 3600) - (minutes * 60);

            if (hours   < 10) {hours   = "0"+hours;}
            if (minutes < 10) {minutes = "0"+minutes;}
            if (seconds < 10) {seconds = "0"+seconds;}
            return hours+':'+minutes+':'+seconds;
        }
    }
}
</script>
<style scoped>
@media (min-width: 301px) {
    .modal-lg, .modal-xl {
        max-width: 800px;
    }
}
.tag {
    color: #008;
    font-family: monospace;
}
.attr-name , .attr-value , .text {
    font-family: monospace;
}
.attr-name {
    color: #606;
} 
.attr-value {
    color: #080;
}
.opacity-trans {
    -webkit-animation: fadeIn 1500ms ;
    -moz-animation: fadeIn 1500ms ;
    animation: fadeIn 1500ms ;
}
@-moz-keyframes fadeIn {
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-webkit-keyframes fadeIn {
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@keyframes fadeIn {
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
} 

.video-standerd:hover {
    box-shadow: 0 3px 4px rgba(0,0,0,.16),0 3px 6px rgba(0,0,0,.23)!important;    
}


</style>