<template>
<div class="mt-5">
    <div class="alert alert-danger" v-if="errors.length > 0" v-for="(error, key) in errors" :key="key"> 
        <fa-icon :icon="['fas', 'exclamation-triangle']" class="w-6 h-6 mr-2" /> {{ error }} 
    </div>
</div>
</template>
<script>
export default {
    name: 'DisplayErrors',
    props: {
      errors: Array,
    }
}
</script>

<style scoped>

</style>