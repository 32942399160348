<template>
    <!--basic setting-->
    <div class="border mb-2 rounded-lg font-weight-bold text-dark over-flow-visible" v-if="settings">
        <nav class="m-2">
            <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                <li class="nav-item has-treeview menu-close">
                    <a class="nav-link flex align-items-center text-danger" style="cursor: pointer;">
                        <i class="nav-icon fas fa-cog"></i>
                        <div class=""> AdCrew Settings </div><i class="fas fa-angle-left ml-auto"></i> 
                    </a>
                    <ul class="m-3 nav nav-treeview">
                        <li class="nav-item mb-4 font-weight-normal">
                            <div class="d-flex justify-content-start align-items-center">
                                <div class="col-md-11 ">
                                    Number of ad plays
                                </div>
                                <div class="col-md-1">
                                    <div class="form-group">
                                        <input type="number" min="1" max="1200" class="form-control" v-model="settings.total_ad_plays">
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="nav-item mb-2 font-weight-normal">
                            <div class="d-flex justify-content-start align-items-center">
                                <div class="col-md-11">
                                    Number of ad requests
                                </div>
                                <div class="col-md-1">
                                    <div class="form-group">
                                        <input type="number" min="1" max="1200" class="form-control" v-model="settings.total_ad_requests">
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="nav-item mb-2 font-weight-normal">
                            <div class="d-flex justify-content-start align-items-center">
                                <div class="col-md-11">
                                    Delay Between Requests
                                </div>
                                <div class="col-md-1">
                                    <div class="form-group">
                                        <input type="number" min="1" max="1200" class="form-control" v-model="settings.delay_between_requests">
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="nav-item mb-2 font-weight-normal">
                            <div class="d-flex justify-content-start align-items-center">
                                <div class="col-md-6">
                                    Debug
                                </div>
                                <div class="col-md-6">
                                    <div class="inline">
                                        <div class="custom-control custom-switch p-0 py-2">
                                            <label class="switch" for="debug">
                                                <input type="checkbox" class="primary" id="debug" v-model="settings.debug">
                                                <span class="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <hr><br>
                            <button class="btn btn-success float-right" @click.prevent="update()">Save</button>
                            <br>
                        </li>
                    </ul>
                </li>
            </ul>
        </nav>
    </div>
    <!--basic setting-->
</template>
<script>
import { RepositoryFactory } from '@/services/repositories/PlayerAPI/RepositoryFactory'
const AdCrewSettingsRepo = RepositoryFactory.get('adcrew_settings')

export default {
    props: {
        settings: {
            type:  Object,
        }
    },
    data () {
        return {

        }
    },
    methods: {
        update () {
            var settings_id = this.settings.id
            delete this.settings['id']
            AdCrewSettingsRepo.patch(settings_id, this.settings).then(result => {
                this.settings = result.data
                this.$notify({
                    group: 'ops',
                    type: 'success',
                    title: 'Operation success',
                    text: 'Successfully updated the player settings.'
                })
            }).catch(error => {
                this.$notify({
                    group: 'ops',
                    type: 'error',
                    title: 'Operation failed',
                    text: 'Failed to updated the player settings.'
                })
            })
        }
    }
}
</script>