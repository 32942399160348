<template>
    <div class="card" v-animate-css="'fadeInLeft'">
        <div class="card-header">
            <h3 class="card-title">
                <span v-if="!api.id"> Add </span>
                <span v-if="api.id"> Edit </span>
                API
            </h3>
            <div class="card-tools">
                <div class="overlay" v-if="isLoading">
                    <i class="fas fa-sync-alt fa-spin fa-1x"></i>
                </div>

                <button class="btn btn-tool btn-sm" v-if="!isLoading" @click.prevent="clear()">
                    <i class="fa fa-plus"></i>
                </button>
            </div>
            <!-- /.card-tools -->
        </div>
        <!-- /.card-header -->
        <div class="card-body">
            <form role="form">
                <div class="row">
                    <div class="col-md-8">
                        <!-- text input -->
                        <div class="form-group">
                            <label>Name</label>
                            <input type="text" class="form-control" v-model="api.name">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <!-- text input -->
                        <div class="form-group">
                            <label>Shortname</label>
                            <input type="text" class="form-control" v-model="api.shortname">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <!-- text input -->
                        <div class="form-group">
                            <label>Code</label>
                            <input type="text" class="form-control" v-model="api.code">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <!-- text input -->
                        <div class="form-group">
                            <label>Type</label>
                            <select class="form-control" v-model="api.type">
                                <option v-for="(name, code) in types" :key="code" :value="code">{{ name }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <!-- text input -->
                        <div class="form-group">
                            <label>Priority</label>
                            <input type="number" class="form-control" v-model="api.priority">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-8">
                        <div class="form-group">
                            <div class="btn-group btn-group-toggle">
                                <label class="btn btn-sm btn-default"  :class="{'text-green': api.status === 'active'}">
                                    <input type="radio" value="active" v-model="api.status"> Active
                                </label>
                                <label class="btn btn-sm btn-default" :class="{'text-red': api.status === 'suspended'}">
                                    <input type="radio" value="suspended" v-model="api.status"> Suspended
                                </label>
                                </div>
                            </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group float-right">
                            <button class="btn btn-success" @click.prevent="submit()"> Save </button>
                        </div>
                    </div>

                    <div class="col-md-12">
                        <errors :errors="errors"/>
                        <messages :messages="messages" />
                    </div>
                </div>
            </form>
        </div>
    <!-- /.card-body -->
    </div>
    <!-- /.card -->

</template>

<script>
import { RepositoryFactory } from '@/services/repositories/ManagementAPI/RepositoryFactory' 
const APIRepo = RepositoryFactory.get('external_apis')

export default {
    props: {
        api: {
            required: true,
            type: Object
        },
        isLoading: {
            required: false,
            type: Boolean
        },
        selectedType: {
            required: false,
            type: String
        },
    },
    data() {
        return {
            types: [],
            messages: [],
            errors: []
        }
    },
    created() {
        this.types()
    },
    methods: {
        submit: function() {
            if(typeof this.api.id === 'undefined') {
                this.create(this.api)
            } else {
                this.update(this.api)
            }
        },
        clear: function() {
            this.errors = []
            this.messages = []

            this.api = {}
        },
        create: function(api) {
            this.errors = []
            this.messages = []

            APIRepo.post(api).then(result => {
                if(typeof result.data.id !== 'undefined' ) 
                    this.api = result.data
                    this.messages.push("Successfully added with ID: " + result.data.id)
                    this.$emit('updateList', this.api)
            }).catch(err => {
                this.errors.push(err)
                this.errors.push( err.response.data )
            })
        },
        update: function(api) {
            this.errors = []
            this.messages = []

            APIRepo.patch(this.api.id, api).then(result => {
                if(typeof result.data.id !== 'undefined' ) 
                    this.api = result.data
                    this.messages.push("Successfully updated.")
                    this.$emit('refreshList', true)
            }).catch(err => {
                this.errors.push(err)
                this.errors.push( err.response.data )
            })
        },
        delete: function(id) {},
    },
}
</script>