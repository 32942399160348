<template>
    <div>
        <div class="card border">
            <div class="card-header d-flex align-items-center border-bottom">
                <div class="d-flex">
                    <i class="nav-icon text-lg fas fa-circle text-primary"></i>
                    <h3 class="text-lg">{{ schedule_type | capitalizeAndReplace }}</h3>
                </div>
                <div class="ml-auto">
                    <button class="btn btn-danger" @click.prevent="deleteEvent">
                        <i class="nav-icon fas fa-trash-alt text-light"></i>
                        DELETE
                    </button>
                </div>
            </div>
            <div class="card-body">
                <div class="input-group d-flex flex-column mb-8">
                    <label for="desktop-mobile"><i class="nav-icon fas fa-mobile-alt"></i> 
                        Ad <span v-if="schedule_type === 'display_banner'">Script</span>
                        <span v-else>Tag</span>
                    </label>
                    <ad-tag-selection v-if="schedule_type !== 'display_banner'" 
                    :schedules="schedules" 
                    :schedule_type="schedule_type" 
                    :deleteScedule=deleteScedule 
                    @changed=selectionChanged />

                    <ad-script-selection v-if="schedule_type === 'display_banner'" 
                    :schedules="schedules" 
                    :schedule_type="schedule_type"
                    :deleteScedule=deleteScedule
                    @changed=selectionChanged />
                </div>
                <!--  -->
            </div>
        </div>

    </div>
</template>
<script>
import AdTagSelection from './AdTags/Selection.vue'
import AdScriptSelection from './AdScripts/Selection.vue'

import { RepositoryFactory } from '@/services/repositories/PlayerAPI/RepositoryFactory'
const AdTagsRepo = RepositoryFactory.get('adtags')

export default {
    props: {
        schedules: {
            type: Array | Object,
            required : true
        },
        schedule_type: {
            type: String,
            required : true
        }
    },
    components: {
        AdTagSelection,
        AdScriptSelection
    },
    data() {
        return {
            deleteScedule: false
        }
    },
    methods: {
        deleteEvent() {
            this.deleteScedule = true
            this.$emit('delete', this.schedule_type)
        },
        selectionChanged(changed_data) {
            var data = { 
                'data': changed_data.data,
                'type': changed_data.type
            }
            this.$emit('changed', data)
        },
    },
    watch: {
    }
}
</script>
<style scoped>


</style>