<template>
<!-- Modal -->
<div id="invoiceModal" class="modal fade" role="dialog">
    <div class="modal-dialog">

        <!-- Modal content-->
        <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">
                        Invoice
                        <span> {{ invoice.date }}</span>
                        <span> - #{{ invoice.id }}</span>
                </h4>
                <button type="button" class="close" @click.prevent="close()">&times;</button>
            </div>
            <div class="modal-body">

                <!-- /.card-header -->
                <div class="row">
                    <input type="hidden" v-model="invoice.website_id" />
                    <input type="hidden" v-model="invoice.wallet" />
                    <div class="col-md-4">
                        <label for="text">Amount</label>
                        <input class="form-control text-center" disabled 
                         v-model="invoice.amount" />
                    </div>
                    <div class="col-md-4">
                        <label for="text">Deduction</label>
                        <input class="form-control text-center" required="" 
                        v-model="invoice.deduction" />
                    </div>
                    <div class="col-md-4">
                        <label for="text">Final</label>
                        <input class="form-control text-center" disabled
                        :value="invoiceFinal" />
                    </div>
                    <div class="col-md-4 mt-2">
                        <div class="form-group">
                            <label for="text">Status</label>
                            <div class="btn-group btn-group-toggle">
                                <label class="btn btn-sm btn-default"  :class="{'text-red': invoice.status === 'deductible'}">
                                    <input type="radio" value="deductible" v-model="invoice.status"> Deductible
                                </label>
                                <label class="btn btn-sm btn-default" :class="{'text-blue': invoice.status === 'approved'}">
                                    <input type="radio" value="approved" v-model="invoice.status"> Approved
                                </label>
                                <label class="btn btn-sm btn-default" :class="{'text-green': invoice.status === 'paid'}">
                                    <input type="radio" value="paid" v-model="invoice.status"> Paid
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="modal-footer">
                <div class="btn-group btn-group-toggle">
                    <button class="btn btn-success float-right" @click.prevent="update()">Update</button>
                    <button type="button" class="btn btn-danger" @click.prevent="close()">Close</button>
                </div>
            </div>
        </div>

    </div>
</div>
</template>

<script>
import { RepositoryFactory } from '@/services/repositories/ManagementAPI/RepositoryFactory' 
const InvoicesRepo = RepositoryFactory.get('invoices')

export default {
    props: {
        wallet: {
            required: true,
            type: Object,
        },
        invoice: {
            required: true,
            type: Object,
        },
        show: {
            required: true,
            type: Boolean
        }
    },
    created() {

    },
    updated() {
        $('[data-toggle="tooltip"]').tooltip()
    },
    methods: {
        update() {
            InvoicesRepo.patch(this.invoice.id, this.invoice).then(result => {
                this.$notify({
                    group: 'ops',
                    type: 'success',
                    title: 'Operation success',
                    text: 'Successfully updated ID: ' + result.data.id
                })
                this.recordUpdated()
            }).catch(err => {
                this.$notify({
                    group: 'ops',
                    type: 'danger',
                    title: 'Operation failed',
                    text: 'Failed to update invoice.'
                })
            })
        },
        recordUpdated: function() {
            this.$emit("updated")
        },
        clear() {
            this.errors = []
            this.messages = []
        },
        close() {
            this.$emit('close', true)
        }
    },
    computed: {
        invoiceFinal: function() {
            return (this.invoice.amount - this.invoice.deduction).toFixed(2)
        }
    },
    watch: {
        'show': {
            handler: function(show) {
                $('#invoiceModal').modal('toggle')
            },
            deep: true,
            immediate: true
        },
        'website': {
            handler: function(website) {
                if(typeof website !== 'undefined') {
                    this.clear()
                }
            },
            deep: true,
            immediate: true
        }
    }
}
</script>

<style>
    .modal-dialog {
        max-width: 35%;
    }
</style>