<template>
<!-- Modal -->
<div class="modal fade" id="embedCodeModal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="max-width: 60%;" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel"> {{player.name}} Player embed code</h5>
                <button type="button" class="close" aria-label="Close" data-dismiss="modal" @click.prevent="closeModel()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col">
                            <!--details-->
                            <div class="border mb-2 rounded-lg font-weight-bold text-dark">
                                <nav class="m-2">
                                    <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="true">
                                        <li class="nav-item has-treeview menu-open">
                                            <a href="#" class="nav-link flex align-items-center text-lg text-dark">
                                            <i class="nav-icon fas fa-cog"></i>
                                            <div class=""> Settings </div><i class="fas fa-angle-left ml-auto"></i> 
                                            </a>
                                            <ul class="my-3 nav nav-treeview">
                                                <li class="nav-item mb-2 font-weight-normal">
                                                    <div class="d-flex flex-row justify-content-between align-items-center">
                                                        <div class="m-2 text-lg">Desktop Size</div>
                                                        <div class="m-2">
                                                            <div class="form-group d-inline-block">
                                                                <label class="font-weight-bold text-sm">Width (px)</label>
                                                                <input type="number" min="1" max="1200" class="form-control border-top-0 border-right-0 border-left-0 italic" v-model="desktop_width">
                                                            </div>
                                                            <div class="d-inline-block font-weight-normal text-lg mx-2">X</div>
                                                            <div class="form-group d-inline-block mx-2">
                                                                <label class="font-weight-bold text-sm">Height (px)</label>
                                                                <input type="number" min="1" max="1200" class="form-control border-top-0 border-right-0 border-left-0 italic" v-model="desktop_height">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li class="nav-item mt-2 font-weight-normal">
                                                    <div class="d-flex flex-row justify-content-between align-items-center">
                                                        <div class="m-2 text-lg">Mobile Size</div>
                                                        <div class="m-2">
                                                            <div class="form-group d-inline-block">
                                                                <label class="font-weight-bold text-sm">Width (px)</label>
                                                                <input type="number" min="1" max="1200" class="form-control border-top-0 border-right-0 border-left-0 italic" v-model="mobile_width" >
                                                            </div>
                                                            <div class="d-inline-block font-weight-normal text-lg mx-2">X</div>
                                                            <div class="form-group d-inline-block mx-2">
                                                                <label class="font-weight-bold text-sm">Height (px)</label>
                                                                <input type="number" min="1" max="1200" class="form-control border-top-0 border-right-0 border-left-0 italic" v-model="mobile_height" >
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li class="nav-item mt-2 font-weight-normal">
                                                    <div class="d-flex flex-row justify-content-between align-items-center">
                                                        <div class="m-2 text-lg">
                                                            serve Player through DFP 
                                                        </div>
                                                        <div class="m-2">
                                                            <div class="custom-control custom-switch p-0 py-2">
                                                                <label class="switch" for="dfp">
                                                                    <input type="checkbox" class="primary" id="dfp" >
                                                                    <span class="slider round"></span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>    
                                                </li>
                                                <li class="nav-item mt-2 font-weight-normal">
                                                    <div class="d-flex flex-row justify-content-between align-items-center">
                                                        <div class="m-2 text-lg">
                                                            load Asynchronously
                                                        </div>
                                                        <div class="m-2">
                                                            <div class="custom-control custom-switch p-0 py-2">
                                                                <label class="switch" for="async">
                                                                    <input type="checkbox" class="primary" id="async" v-model="isAsyncChecked" >
                                                                    <span class="slider round"></span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>    
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                            <!--details-->
                        </div>
                        <div class="col">

                        <div class="d-flex flex-row justify-content-between align-items-center mb-2">
                            <h3 class="font-weight-bold text-lg" >Embed code</h3> 
                            <p id="codeCopied" class="bg-green rounded-md hidden opacity-trans"> <span class="m-2"><i class="far fa-check-circle"></i> Code Copied</span> </p>
                        </div>

                        <div class="border mb-2 rounded-lg font-weight-normal text-sm" @click="copyToClip('script')">
                            <div class="p-2">
                                <span id="script">
                                    <span class="tag">&#60;div </span>
                                    <span class="attr-name">id</span> 
                                    <span class="text">=</span>
                                    <span class="attr-value">"player-id-{{ player.id }}"</span>
                                    <span class="text">></span>
                                    <span class="tag">&#60;/div></span>
                                    <span class="tag">&#60;script </span>
                                    <span class="attr-name">type</span>
                                    <span class="text">=</span>
                                    <span class="attr-value">"text/javascript"</span>
                                    <span class="attr-name"> {{ isAsync }} </span>
                                    <span class="attr-name"> src</span>
                                    <span class="text">=</span>
                                    <span class="attr-value">"{{ player_js_url }}"</span>
                                    <span class="text">></span>
                                    <span class="tag">&#60;/script></span>
                                </span>
                            </div>
                        </div>
                        <p class="text-md font-weight-light">*copy the embed code above and paste it to your webpage</p>
                    </div>
                </div>    
            </div>
        </div>
    </div>
</div>
</div>
</template>
<script>
export default {
    props: {
        player: {
            type: Object
        }
    },
    data () {
        return {
            isAsyncChecked: true,
            desktop_width: 0,
            desktop_height: 0,
            mobile_width: 0,
            mobile_height: 0
        }
    },
    methods: {
        closeModel: function() {
            //$('#embedCodeModal').modal('toggle')
            this.$emit("closed")
        },
        copyToClip(containerid) {

            let node = document.getElementById(containerid)
            let copiedScript = ''
            node.childNodes.forEach((child)=> {
                copiedScript += child.innerText
            })
            navigator.clipboard.writeText(copiedScript).then(function() {
                document.getElementById('codeCopied').style.display = 'block'
                setTimeout(()=>{
                    document.getElementById('codeCopied').style.display =  null
                },1500)
            }, function(err) {
                console.error('Async: Could not copy text: ', err);
            });
            
            // range.setStart(node[0], 0)
            // range.setEnd(node[length], length)
            // console.log(range)
            // console.log(range.startContainer , Range.endContainer)
            // range.selectNode(element);
            // //window.getSelection().removeAllRanges(); // clear current selection
            // window.getSelection().addRange(range); // to select text
            // document.execCommand("copy");
            //window.getSelection().removeAllRanges();// to deselect
        }
    },
    computed: {
        player_options: function() {
            var player_options = {}

            player_options.container_id = "player-id-" + this.player.id
            
            if(this.desktop_width)
                player_options.desktop_width = this.desktop_width
            
            if(this.desktop_height)
                player_options.desktop_height = this.desktop_height

            if(this.mobile_width)
                player_options.mobile_width = this.mobile_width
            
            if(this.mobile_height)
                player_options.mobile_height = this.mobile_height

            return player_options
        },
        player_js_url: function() {
            return "https://player.adcrew.co/player.js"
        },
        isAsync: function() {
            return this.isAsyncChecked ? "async" : ''
        },
    }
}
</script>
<style scoped>
@media (min-width: 301px) {
    .modal-lg, .modal-xl {
        max-width: 800px;
    }
}
.tag {
    color: #008;
    font-family: monospace;
}
.attr-name , .attr-value , .text {
    font-family: monospace;
}
.attr-name {
    color: #606;
} 
.attr-value {
    color: #080;
}
.opacity-trans {
    -webkit-animation: fadeIn 1500ms ;
    -moz-animation: fadeIn 1500ms ;
    animation: fadeIn 1500ms ;
}
@-moz-keyframes fadeIn {
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-webkit-keyframes fadeIn {
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@keyframes fadeIn {
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}  
</style>