import Repository from './Repository'

const resource = "/suppliers"

export default {
    page(page=1, limit=10, search='') {
        var offset = (page - 1) * limit
        if(search) { search = `&search=${search}` }
        return Repository.get(`${resource}/?limit=${limit}&offset=${offset}${search}`);
    },
    query(params) {
        if(params.limit == undefined) params.limit = 50
        if(params.page == undefined) params.page = 1

        params.offset = (params.page - 1) * params.limit
        params = new URLSearchParams(params).toString()
        return Repository.get(`${resource}/?${params}`)
    },
    all(page=1, limit=10000) {
        var offset = (page - 1) * limit
        return Repository.get(`${resource}/?limit=${limit}&offset=${offset}`);
    },
    get(id) {
        return Repository.get(`${resource}/${id}/`);
    },
    post(data) {
        return Repository.post(`${resource}/`, data);
    },
    update(id, data) {
        return Repository.put(`${resource}/${id}/`, data);
    },
    patch(id, data) {
        return Repository.patch(`${resource}/${id}/`, data);
    },
}