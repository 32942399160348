<template>
    <div class="border  p-4 w-100 relative">

        <!-- Selected tags from backend -->
        <div class="w-100"  >
            <div class="border bg-secondary bg-gradient my-2 px-4 py-2 d-flex rounded-md" v-for="ad_tag_id in selectedTags" :key="ad_tag_id">
                <h3>{{ AdTagName(ad_tag_id) }}</h3>
                <a href="#" @click.prevent="unSelectAdTag(ad_tag_id)" class="ml-auto"><i class="fas fa-times"></i></a>
            </div>
        </div>
        
        <input type="search" v-model="adtag_search" 
        placeholder="Select or add new ad tags" 
        name="desktop-mobile" 
        class="form-control dropdown-toggle" 
        data-toggle="dropdown" 
        aria-haspopup="false" 
        aria-expanded="false" >
        
        <div class="dropdown-menu w-100">
            <a class="dropdown-item bg-secondary bg-gradient" href="#" @click.prevent="showAdTagModal">ADD NEW AD TAG</a>
            <!-- Select and Unselect new ad tags -->
            <div class="dropdown-item d-flex align-items-center" v-for="ad_tag in adtags" :key="ad_tag.id">
                <i class="nav-icon fas fa-pencil-alt cursor-pointer" @click.prevent="editTag(ad_tag)" ></i>
                <a class="nav-icon"  @click.prevent="selectAdTag(ad_tag.id)"  href="#">{{ad_tag.name}}</a>
            </div>
        </div>

        <ad-tag-modal :adtag="selectedTag" @updateList="loadAdTags"/>

    </div>
</template>
<script>
import AdTagModal from './AdTagModal.vue'

import { RepositoryFactory } from '@/services/repositories/PlayerAPI/RepositoryFactory'
const AdTagsRepo = RepositoryFactory.get('adtags')

export default {
    components: {
        AdTagModal,
    },
    props: {
        schedules: {
            type: Array | Object,
            required : true
        },
        schedule_type: {
            type: String,
            required : true
        },
        deleteScedule: {
            type: Boolean
        }
    },
    data: () => {
        return {
            adtags: [],
            selectedTag: {},
            selectedTags: [],
            adtag_search: '',
            website_id: 0
        }
    },
    mounted() {
        this.loadAdTags()
    },
    methods: {
        selectAdTag(adtag_id) {
            if(this.schedule_type == 'vast') {
                this.selectedTags = [adtag_id]
                this.selectionChanged()
                return
            }

            this.selectedTags.push(adtag_id)
            this.selectionChanged()
        },
        unSelectAdTag(adtag_id) {
            if(this.schedule_type == 'vast') {
                this.selectedTags = null
                this.selectionChanged()
                return
            }

            this.selectedTags.pop(adtag_id)
            this.selectionChanged()
        },
        editTag(tag) {
            this.selectedTag = tag
            $(`#AdTagModal`).modal()
        },
        selectionChanged() {
            var data = { 
                'data': this.selectedTags,
                'type': this.schedule_type
            }
            this.$emit('changed', data)
        },
        showAdTagModal() {
            $(`#AdTagModal`).modal()
        },
        loadAdTags() {
            var query = {
                website: this.website_id,
                limit: 1000
            }
            AdTagsRepo.query(query).then(result => {
                this.adtags = result.data.results
            }).catch(error => {})
        },
        AdTag(adtag_id) {
            var adtag = this.adtags.filter(element => { return element.id == adtag_id })[0]
            if(adtag)
                return adtag
        },
        AdTagName(adtag_id) {
            var adtag = this.adtags.filter(element => { return element.id == adtag_id })[0]
            if(adtag)
                return adtag.name
        }
    },
    computed: {
        filteredAdTags: function() {
            return this.adtags.filter((ele) => {
                return ele.name.includes(this.adtag_search)
            })
        },
    },
    watch: {
        'schedules': {
            handler: function (schedules) {
                if(this.schedule_type == 'vast') {
                    this.selectedTags = [ schedules.id || schedules[0] || schedules ]
                    return
                }

                let list = []
                this.schedules.forEach(function(element) {
                    if(element.id) {
                        list.push(element.id)
                    } else {
                        list.push(element)
                    }
                })
                this.selectedTags = list
            },
            deep: true,
            immediate: true
        },
        'deleteScedule': {
            handler: function (clear) {
                if(clear) {
                    this.selectedTags = []
                    this.selectionChanged()
                    this.deleteScedule = false
                }
            },
            deep: true,
            immediate: true
        },
        '$route.query.website': { 
            handler: function (website) {
                if(typeof website !== 'undefined') {
                    // check if user is not loaded 
                    // website placements_groups before
                    if(this.website_id !== website) {
                        // match website with passed from query
                        this.website_id = website
                    }
                } else {
                    this.$router.push({name: "website"})
                }
            },
            deep: true,
            immediate: true
        },
    }
}
</script>
<style scoped>

</style>