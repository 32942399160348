<template>
    <label for="agreeTerms">
        <a :href="terms" class="text-theme-1 ml-1 dark:text-gray-200">Privcy Policy</a>
        <a :href="privacy" class="text-theme-1 ml-10 dark:text-gray-200">Terms of Service</a>
    </label>
</template>

<script>
export default {
    computed: {
        terms: function() {
            if(this.$store.getters.whitelist.terms_link != null && this.$store.getters.whitelist.terms_link != '') {
                return this.$store.getters.whitelist.terms_link
            } else {
                return ''
            }
        },
        privacy: function() {
            if(this.$store.getters.whitelist.privacy_link != null && this.$store.getters.whitelist.privacy_link != '') {
                return this.$store.getters.whitelist.privacy_link
            } else {
                return ''
            }
        }
    }
}
</script>
