<template>
<div id="statisticsModal" class="modal fade" role="dialog">
    <div class="modal-dialog">
        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title mr-2">
                    {{ data.name }} - Data Statistics
                    <button class="btn btn-tool btn-sm" @click.prevent="clear()"><i class="fa fa-plus"></i></button>
                    <button class="btn btn-tool btn-sm" :class="{'fa-spin': reloading}" @click.prevent="reload(statistic.id)"><i class="fa fa-sync"></i></button>
                </h4>
                <button type="button" class="close" @click.prevent="close()">&times;</button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="form-group col-md-4" v-for="metric in display" :key="metric">
                        <label> {{ metric | capitalizeAndReplace }} </label>
                        <input type="text" class="form-control" v-model="statistic[metric]">
                    </div>
                    <!-- Date input form -->
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Date:</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <i class="far fa-calendar-alt"></i>
                                    </span>
                                </div>
                                <input type="text" class="form-control float-right" id="datepicker">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Base Revenue</label>
                            <input type="text" class="form-control" v-if="statistic.supplier_share" v-model="statistic.supplier_share.revenue_before">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label class="block">Calculated Share</label>
                            <label class="text-center" disabled v-if="statistic.supplier_share"> {{ statistic.supplier_share.share }}% </label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <div class="btn-group btn-group-toggle">
                                <label class="btn btn-sm btn-default" :class="{'text-green': statistic.status == 'active'}">
                                    <input type="radio" value="active" v-model="statistic.status"> Active
                                </label>
                                <label class="btn btn-sm btn-default" :class="{'text-red': statistic.status == 'suspended'}">
                                    <input type="radio" value="suspended" v-model="statistic.status"> Suspended
                                </label>
                            </div>
                        </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <div class="btn-group btn-group-toggle">
                            <label class="btn btn-sm btn-default"  :class="{'text-green': statistic.complete == 'true' | statistic.complete}">
                                <input type="radio" value="true" v-model="statistic.complete"> Complete
                            </label>
                            <label class="btn btn-sm btn-default" :class="{'text-red': statistic.complete == 'false' | !statistic.complete}">
                                <input type="radio" value="false" v-model="statistic.complete"> Uncomplete
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-sm-12">
                    <button @click.prevent="submit()" class="btn btn-primary btn-block">Save</button>
                </div>
                <!-- /.col -->
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { RepositoryFactory } from '@/services/repositories/ManagementAPI/RepositoryFactory'
const SystemRepo = RepositoryFactory.get('system')
const Statistics = RepositoryFactory.get('statistics')
const PlacementsGroupsDataRepo = RepositoryFactory.get('groups_data')

export default {
    props: {
        data_id: {
            type: Number | String,
            required: true
        },
        statistic: {
            type: Object
        },
        show: {
            required: true,
            type: Boolean
        },
        reloading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            data: {},
            metrics: [],
            signs: [],
            display: [],
            isLoading: false
        }
    },
    created() {
        this.loadSystemMetrics()
    },
    mounted() {
        var self = this
        $('#datepicker').datepicker({
            format: 'yyyy-mm-dd',
        })
        if(this.statistic.supplier_share == null) {
            this.statistic.supplier_share = {
                revenue_before: 0.00,
                revenue_after: 0.00,
                share: 0.00
            }
        }
    },
    methods: {
        submit: function() {
            if(this.statistic.id){
                this.update()
            } else {
                this.add()
            }
        },
        add: function() {
            this.statistic.date = $('#datepicker').val()
            Statistics.post(this.statistic).then(result => {
                this.$notify({
                    group: 'ops',
                    type: 'success',
                    title: 'Operation Success',
                    text: 'Statistics created successfuly with ID: ' + result.data.id
                })
            }).catch(err => {
                this.$notify({
                    group: 'ops',
                    type: 'error',
                    title: 'Operation failed',
                    text: "Failed to create statistics"
                })
            })
        },
        update: function() {
            this.statistic.date = $('#datepicker').val()
            Statistics.patch(this.statistic.id, this.statistic).then(result => {
                this.$notify({
                    group: 'ops',
                    type: 'success',
                    title: 'Operation Success',
                    text: 'Statistics updated successfuly.'
                })
            }).catch(err => {
                this.$notify({
                    group: 'ops',
                    type: 'error',
                    title: 'Operation failed',
                    text: "Failed to create statistics"
                })
            })
        },
        clear: function() {
            this.statistic = {
                placement_group_data_id: this.placement_group_data.id,
                placement_group_id: this.placement_group_data.placement_group_id,
                website_id: this.placement_group_data.website_id,
                status: "suspended",
            }
        },
        loadSystemMetrics: function() {
            this.isLoading = true
            SystemRepo.metrics().then(result => {
                this.metrics = result.data.schema
                this.signs = result.data.signs
                this.display = result.data.display
                this.isLoading = false
            }).catch(err => {
                this.isLoading = false
            })
        },
        loadPGData(id) {
            PlacementsGroupsDataRepo.get(id).then(result => {
                this.data = result.data
            }).catch(err => {})
        },
        close() {
            this.$emit('close', true)
        },
        reload(statistic_id) {
            this.$emit('reload', statistic_id)
        }
    },
    watch: {
        'show': {
            handler: function(show) {
                $('#statisticsModal').modal('toggle')
            },
            deep: true,
            immediate: true
        },
        'statistic': {
            handler: function (statistic) {
                if(statistic.placement_group_data_id) {
                    $('#datepicker').val(statistic.date)
                }
            },
            deep: true,
            immediate: true
        },
        'data_id': {
            handler: function (data_id) {
                if(data_id) {
                    this.loadPGData(data_id)
                }
            },
            deep: true,
            immediate: true
        },
    }
}
</script>

<style>
    .modal-dialog {
        max-width: 35%;
    }
    .served_metrics {
        display: block;
        background: #f8f8f8;
        color: #666;
        border-radius: 5px;
        border: 1px solid #e0dfdf;
    }
    .served_metrics_category {
        margin-top: 10px;
        float: left;
    }
</style>