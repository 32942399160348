<template>
    <!--basic setting-->
    <div class="border mb-2 rounded-lg font-weight-bold text-dark over-flow-visible" v-if="settings">
        <nav class="m-2">
            <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                <li class="nav-item has-treeview menu-close">
                    <a class="nav-link flex align-items-center" style="cursor: pointer;">
                    <i class="nav-icon fas fa-cog"></i>
                    <div class=""> Basic Settings </div><i class="fas fa-angle-left ml-auto"></i> 
                    </a>
                    <ul class="m-3 nav nav-treeview">
                        <li class="nav-item mb-4 mt-4 font-weight-normal">
                            <div class="d-flex justify-content-start align-items-center">
                                <div class="col-md-6">Player Size Desktop</div>
                                <div class="col-md-6 d-flex justify-content-end align-items-center">
                                    <div class="d-inline">
                                        <div class="form-group d-inline-block">
                                            <label>Width (px)</label>
                                            <input type="number" min="1" max="1200" class="form-control" 
                                            v-model="settings.desktop.width">
                                        </div>
                                        <div class="d-inline-block font-weight-bold mx-2">X</div>
                                        <div class="form-group d-inline-block mx-2">
                                            <label>Height (px)</label>
                                            <input type="number" min="1" max="1200" class="form-control" 
                                            v-model="settings.desktop.height">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="nav-item mb-4 mt-4 font-weight-normal">
                            <div class="d-flex justify-content-start align-items-center">
                                <div class="col-md-6">Player Size Mobile</div>
                                <div class="col-md-6 d-flex justify-content-end align-items-center">
                                    <div class="d-inline">
                                        <div class="form-group d-inline-block">
                                            <label>Width (px)</label>
                                            <input type="number" min="1" max="1200" class="form-control" 
                                            v-model="settings.mobile.width">
                                        </div>
                                        <div class="d-inline-block font-weight-bold mx-2">X</div>
                                        <div class="form-group d-inline-block mx-2">
                                            <label>Height (px)</label>
                                            <input type="number" min="1" max="1200" class="form-control" 
                                            v-model="settings.mobile.height">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="nav-item mb-4 mt-4 font-weight-normal">
                            <div class="d-flex justify-content-start align-items-center">
                                <div class="col-md-6 ">
                                    AutoPlay
                                </div>
                                <div class="col-md-6">
                                    <div class="inline">
                                        <div class="custom-control custom-switch p-0 py-1">
                                            <label class="switch" for="autoplay_setting">
                                                <input type="checkbox" class="primary" id="autoplay_setting" v-model="settings.autoplay.autoplay">
                                                <span class="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="settings.autoplay.autoplay" class="pl-8 mt-4">
                                <div class="d-flex align-items-center justify-content-start mb-4 col-md-12">
                                        Only when 
                                    <input type="number" class="mx-1 form-control w-12" min="0" max="100" 
                                    v-model="settings.autoplay.view_percentage">
                                    <strong class="mr-1">%</strong> in View
                                </div>
                                <div class="mb-4 d-flex justify-content-center">
                                    <div class="col-md-8">
                                        Only On Desktop
                                    </div>
                                    <div class="col-md-4">
                                        <div class="inline">
                                            <div class="custom-control custom-switch p-0 py-1">
                                                <label class="switch" for="desktoponly">
                                                    <input type="checkbox" class="primary" id="desktoponly" v-model="settings.autoplay.desktop_only">
                                                    <span class="slider round"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="mb-4 d-flex justify-content-center">
                                    <div class="col-md-8 ">
                                        if Ad is present
                                    </div>
                                    <div class="col-md-4">
                                    <div class="inline">
                                        <div class="custom-control custom-switch p-0 py-1">
                                            <label class="switch" for="adpresent">
                                                <input type="checkbox" class="primary" id="adpresent" v-model="settings.autoplay.ad_present">
                                                <span class="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                <div class="mb-4 d-flex justify-content-center">
                                    <div class="col-md-8 ">
                                        force Ads to play muted when the browser required it
                                    </div>
                                    <div class="col-md-4">
                                    <div class="inline">
                                        <div class="custom-control custom-switch p-0 py-1">
                                            <label class="switch" for="requiremuted">
                                                <input type="checkbox" class="primary" id="requiremuted" 
                                                v-model="settings.autoplay.require_muted">
                                                <span class="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </li>
                        <li class="nav-item mb-4 mt-4 font-weight-normal" style="">
                            <br />
                            <div class="d-flex justify-content-start align-items-center">
                                <div class="col-md-6 ">
                                    Pause video content when out of view
                                </div>
                                <div class="col-md-6">
                                    <div class="inline">
                                        <div class="custom-control custom-switch p-0 py-2">
                                            <label class="switch" for="pausecontent">
                                                <input type="checkbox" class="primary" id="pausecontent" 
                                                v-model="settings.autoplay.pause_content_out_view">
                                                <span class="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="nav-item mb-4 mt-4 font-weight-normal" style="">
                            <div class="d-flex justify-content-start align-items-center">
                                <div class="col-md-6 ">
                                    Pause Ad when out of view
                                </div>
                                <div class="col-md-6">
                                    <div class="inline">
                                        <div class="custom-control custom-switch p-0 py-2">
                                            <label class="switch" for="pausead">
                                                <input type="checkbox" class="primary" id="pausead" 
                                                v-model="settings.autoplay.pause_ad_out_view">
                                                <span class="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="nav-item mb-4 mt-4 font-weight-normal">
                            <hr><br>
                            <div class="d-flex justify-content-start align-items-center">
                                <div class="col-md-6">
                                    Append to Element
                                </div>
                                <div class="col-md-6">
                                    <div class="inline">
                                        <div class="custom-control custom-switch p-0 py-2">
                                            <label class="switch" for="append">
                                                <input type="checkbox" class="primary" id="append" 
                                                v-model="settings.append_to_element.append">
                                                <span class="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex justify-content-start align-items-center">
                                <div class="col-md-6">
                                    Insert before
                                </div>
                                <div class="col-md-6">
                                    <div class="inline">
                                        <div class="custom-control custom-switch p-0 py-2">
                                            <label class="switch" for="insert_before">
                                                <input type="checkbox" class="primary" id="insert_before" 
                                                v-model="settings.append_to_element.insert_before">
                                                <span class="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex justify-content-start align-items-center">
                                <div class="col-md-8">
                                    Selector
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <input type="text" class="form-control" id="selector" v-model="settings.append_to_element.selector" />
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="nav-item mb-4 mt-4 font-weight-normal">
                            <hr><br>
                            <div class="d-flex justify-content-start align-items-center">
                                <div class="col-md-6 font-weight-normal">
                                    Analytics
                                </div>
                                <div class="col-md-6">
                                    <div class="inline">
                                        <div class="custom-control custom-switch p-0 py-1">
                                            <label class="switch" for="analytics_setting">
                                                <input type="checkbox" class="primary" id="analytics_setting" v-model="settings.allow_analytics">
                                                <span class="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <hr><br>
                            <button class="btn btn-success float-right" @click.prevent="update()">Save</button>
                            <br>
                        </li>
                    </ul>
                </li>
            </ul>
        </nav>
    </div>
    <!--basic setting-->
</template>
<script>
import { RepositoryFactory } from '@/services/repositories/PlayerAPI/RepositoryFactory'
const SettingsRepo = RepositoryFactory.get('settings')

export default {
    props: {
        settings: {
            type:  Object,
        }
    },
    data () {
        return {

        }
    },
    methods: {
        update () {
            var settings_id = this.settings.id
            delete this.settings['id']
            SettingsRepo.patch(settings_id, this.settings).then(result => {
                this.settings = result.data
                this.$notify({
                    group: 'ops',
                    type: 'success',
                    title: 'Operation success',
                    text: 'Successfully updated the player settings.'
                })
            }).catch(error => {
                this.$notify({
                    group: 'ops',
                    type: 'error',
                    title: 'Operation failed',
                    text: 'Failed to updated the player settings.'
                })
            })
        }
    }
}
</script>