<template>
<div class="row">
    <!-- <div class="col-md-12">
        <hr class="py-1 fw-bold" />
        <p class="h4 fw-bold">Metrics</p>
    </div> -->
    <div class="col-md-12 served_metrics">
        <div v-for="(elements, index) in arranged_list" :key="index">
            <div class="col-md-3 served_metrics_category" v-for="(elements, type) in elements">
                <h5>{{ type | capitalize }} </h5>
                <hr>

                <div class="custom-control custom-checkbox"
                    v-for="element in elements" :key="element.id">
                    <input type="checkbox" class="custom-control-input" :id="element.code" :value="element.id" v-model="selectedMetrics">
                    <label class="custom-control-label" :for="element.code">{{ element.name }}</label>
                </div>
            </div>
            <div class="clearfix"></div>
        </div>
    </div>
</div>
</template>

<script>
import { RepositoryFactory } from '@/services/repositories/ManagementAPI/RepositoryFactory' 
const ServeMetricsRepo = RepositoryFactory.get('serve_metrics')

export default {
    props: {
        selectedMetrics: {
            required: false,
            type: Array,
            default: []
        },
        types: {}
    },
    data() {
        return {
            list: [],
            arranged_list: {},
            itemsPerChunk: 4
        }
    },
    created() {
        this.loadMetrics()
    },
    methods: {
        loadMetrics() {
            ServeMetricsRepo.all().then(result => {
                this.list = result.data.results
                this.arrangeMetrics()
            }).catch(err => {})
        },
        arrangeMetrics() {
            this.arranged_list = [{
                'Ad Sense': this.sort( this.filter('adsense') ),
                'Ad Server': this.sort( this.filter('adserver') ),
                'Ad Exchange': this.sort(this.filter('adexchange') ),
                'Total': this.sort( this.filter('total') ),
            }, {
                'AniView': this.sort( this.filter('aniview') ),
                'Netlink': this.sort( this.filter('netlink') ),
                'Custom': this.sort( this.filter('custom') ),
            }]
        },
        filter: function(type) {
            return this.list.filter(element => { return element.type === type })
        },
        sort: function(list) {
            return list.sort( (a, b) => (a.priority > b.priority) ? 1 : -1 )
        }
    },
    watch: {
        'selectedMetrics': {
            handler: function() {
                this.$emit('updateMetrics', this.selectedMetrics)
            }
        }
    }
}
</script>

<style scoped>
    .served_metrics {
        display: block;
        background: #f8f8f8;
        color: #666;
        border-radius: 5px;
        border: 1px solid #e0dfdf;
    }
    .served_metrics_category {
        margin-top: 10px;
        float: left;
    }
    .custom-control-label {
        font-weight: 200;
    }
</style>