<template>
    <div class="card">
        <div class="card-header">
        <h3 class="card-title">Demand Sources List</h3>

        <div class="card-tools">
            <div class="input-group input-group-sm" style="width: 150px;">
                <input type="search" name="table_search" class="form-control float-right" placeholder="Search" v-model="search_text">
                
                <div class="input-group-append">
                    <button class="btn btn-default" @click.prevent="loadSearchResult()"><i class="fas fa-search"></i></button>
                </div>
            </div>
        </div>
        </div>
        <!-- /.card-header -->
        <div class="card-body table-responsive p-0">
            
        <table class="table table-hover">
            <thead>
            <tr>
                <th>Name</th>
                <th>Advertiser ID</th>
                <th>Currency</th>
                <th>Type</th>
                <th>Status</th>
                <th class="text-center">Action</th>
            </tr>
            </thead>
            <tbody>
                <tr v-for="supplier in suppliers" :key="supplier.id" v-animate-css="'slideInLeft'">
                    <td>{{ supplier.name }}</td>
                    <td>{{ supplier.advertiser_id }}</td>
                    <td>{{ supplier.currency }}</td>
                    <td>{{ supplier.type | capitalize}}</td>
                    <td>
                    <span class="alert alert-success btn-xs" :class="{
                        'alert-success': supplier.status == 'active',
                        'alert-warning': supplier.status == 'pending',
                        'alert-danger': supplier.status == 'suspended'}"> {{ supplier.status | capitalize }} 
                    </span>
                    </td>
                    <td class="text-center">
                    <div class="btn-group">
                        <router-link :to="{name: 'demand_sources', params: {'id': supplier.id} }" class="btn btn-default btn-sm">
                        <i class="fa fa-edit"></i> Edit</router-link>
                        <button class="btn btn-default btn-sm" @click.prevent="displayShares(supplier)"><i class="fa fa-percent"></i> Shares</button>
                    </div>
                    </td>
                </tr>
            </tbody>
        </table>

        </div>

        <div class="overlay" v-if="!isLoaded">
            <point-spinner />
        </div>

        <SuppliersSharesModel :supplier="supplier" :groups_types="groups_types" :show="showSupplierSharesModel" @close="SupplierSharesModelClosed" />

        <!-- <div class="overlay dark" v-if="hasError">
        <i class="fas fa-2x fa-exclamation-triangle"></i>
        </div> -->
    </div>
</template>

<script>
import SuppliersSharesModel from '@/components/shares/suppliers/Model'

export default {
    props: {
        suppliers: {
          required: true,
          type: Array
        },
        groups_types: {
          required: true,
          type: Array
        },
        isLoaded: {
          required: false,
          type: Boolean
        }
    },
    components: {
        SuppliersSharesModel,
    },
    data() {
        return {
            supplier: {},
            showSupplierSharesModel: false,
            search_text: ""
        }
    },
    methods: {
        displayShares(supplier) {
            this.supplier = supplier
            this.showSupplierSharesModel = true
        },
        SupplierSharesModelClosed() {
            this.supplier = {}
            this.showSupplierSharesModel = false
        },
        loadSearchResult() {
            this.$emit('search', this.search_text)
        }
    },
    watch: {
      search_text: function() {
        this.loadSearchResult()
      }
    }
}
</script>

<style scoped>

</style>