import Repository from './Repository';

const resource = "/tags";
export default {
    page(website_id, page=1, limit=10, search='') {
        var offset = (page - 1) * limit
        if(search) { search = `&search=${search}` }
        return Repository.get(`${resource}/?website=${website_id}&limit=${limit}&offset=${offset}${search}`);
    },
    get(id) {
        return Repository.get(`${resource}/${id}/`);
    },
    post(data) {
        return Repository.post(`${resource}/`, data);
    },
    update(id, data) {
        return Repository.put(`${resource}/${id}/`, data);
    },
    patch(id, data) {
        return Repository.patch(`${resource}/${id}/`, data);
    },
    delete(id) {
        return Repository.delete(`${resource}/${id}/`);
    },
    status() {
        return Repository.get(`${resource}/status/`);
    }
}