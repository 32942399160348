<template>
        <!-- Content selection -->
    <div class="border mb-2 rounded-lg font-weight-bold text-dark over-flow-visible" v-if="content">
        <nav class="m-2">
            <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                <li class="nav-item has-treeview menu-close">
                    <a class="nav-link flex align-items-center text-primary" style="cursor: pointer;">
                    <i class="nav-icon fas fa-share-alt"></i>
                    <div class=""> Content Selection </div><i class="fas fa-angle-left ml-auto"></i> 
                    </a>
                    <ul class="m-3 nav nav-treeview">
                        <li class="nav-item font-weight-normal pb-6">
                            <div class="d-flex justify-content-start align-items-baseline input-group">
                                <div class="col-md-6">
                                    <label class="font-weight-normal " for="contentSelection">Content Type</label>
                                </div>
                                <div class="col-md-6 ">
                                    <select v-model="content.type" class="custom-select " id="contentSelection">
                                        <option disabled>Choose Content for Player</option>
                                        <option value="url">URL</option>
                                        <option value="video">Video</option>
                                        <option value="playlist">Playlist</option>
                                    </select>                                            
                                </div>
                            </div>
                        </li>
                        <li  class="nav-item font-weight-normal pb-6">
                            <div class="d-flex justify-content-start align-items-baseline input-group">
                                <div class="col-md-6">
                                    <label class="font-weight-normal" for="videoSelection">External URL</label>
                                </div>
                                <div class="col-md-6">
                                    <input type="text" class="form-control" v-model="content.external_url" :disabled="!(content.type === 'url')">
                                    <a href="#" @click="showVideoBrowserModal()" class="btn-block float-right">Browse</a>
                                </div>
                            </div>
                        </li>
                        <li  class="nav-item font-weight-normal pb-6">
                            <div class="d-flex justify-content-start align-items-baseline input-group">
                                <div class="col-md-6">
                                    <label class="font-weight-normal" for="videoSelection">Video</label>
                                </div>
                                <div class="col-md-6">
                                    <select v-model="content.video" class="custom-select " id="videoSelection" :disabled="!(content.type === 'video')">
                                        <option disabled>Choose video from Videos</option>
                                        <option v-for="video in videos" :key=video.id :value=video.id > {{ video.name }} </option>
                                    </select>                                            
                                </div>
                            </div>
                        </li>
                        <li  class="nav-item font-weight-normal pb-6">
                            <div class="d-flex justify-content-start align-items-baseline input-group">
                                <div class="col-md-6">
                                    <label class="font-weight-normal" for="playListSelection">Playlist</label>
                                </div>
                                <div class="col-md-6">
                                    <select v-model="content.playlist" class="custom-select " id="playListSelection" :disabled="!(content.type === 'playlist')">
                                        <option disabled>Choose video from Videos</option>
                                        <option v-for="playlist in playlists" :key=playlist.id :value=playlist.id > {{ playlist.name }} </option>
                                    </select>                                            
                                </div>
                            </div>
                        </li>
                        <li>
                            <hr><br>
                            <button class="btn btn-success float-right" @click.prevent="update()">Save</button>
                            <br>
                        </li>
                    </ul>
                </li>
            </ul>
        </nav>
        <videos-modal :player_id="content.player" />
    </div>
    <!-- Content selection -->
</template>
<script>
import VideosModal from './ContentBrowser/Videos'

import { RepositoryFactory } from '@/services/repositories/PlayerAPI/RepositoryFactory'
const VideosRepo = RepositoryFactory.get('videos')
const PlaylistsRepo = RepositoryFactory.get('playlists')
const ContentsRepo = RepositoryFactory.get('contents')

export default {
    components: {
        VideosModal
    },
    props: {
        content: {
            type: Object,
        }
    },
    data() {
        return {
            playlists: [],
            videos: [],
        }
    },
    created() {
        this.loadVideos()
        this.loadPlaylists()
    },
    methods: {
        update: function () {
            ContentsRepo.patch(this.content.id, this.content).then(result => {
                this.format = result.data
                this.$notify({
                    group: 'ops',
                    type: 'success',
                    title: 'Operation success',
                    text: 'Successfully updated the player content.'
                })
            }).catch(error => {
                this.$notify({
                    group: 'ops',
                    type: 'error',
                    title: 'Operation failed',
                    text: 'Failed to update the player content.'
                })
            })
        },
        loadVideos: function() {
            var params = {
                player: this.content.player,
                page: 1,
                limit: 1000
            }
            VideosRepo.query(params).then(result => {
                this.videos = result.data.results
            }).then(error => {

            })
        },
        loadPlaylists: function() {
            var params = {
                player: this.content.player,
                page: 1,
                limit: 1000
            }
            PlaylistsRepo.query(params).then(result => {
                this.playlists = result.data.results
            }).then(error => {
                
            })
        },
        showVideoBrowserModal() {
            $(`#videoBrowserModal`).modal()
      },
    },
    watch: {
        "content.type": function() {
            this.$emit('contentTypeChange' , this.content.type)
        }
    }
}
</script>