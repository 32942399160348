import Repository from './Repository';

const resource = "/"

export default {
    getLogos() {
        return Repository.get(`logo-content/`);
    },
    changeLogo(data) {
        return Repository.post(`logo-content/`, data);
    },
    getVideos() {
        return Repository.get(`video-content/`);
    },
    changeVideo(data) {
        return Repository.post(`video-content/`, data);
    },
}