<template>
<section class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-3">
            <UserCard :user="user" :profile="user.profile" />
            </div>
            <!-- /.col -->
            <div class="col-md-9">
            <div class="card">
                <div class="card-header p-2">

                    <ul class="nav nav-pills float-left">
                        <li class="nav-item"><a class="nav-link active" href="#profile" data-toggle="tab">User info</a></li>
                        <li class="nav-item"><a class="nav-link" href="#login" data-toggle="tab">Login Info</a></li>
                        <li class="nav-item"><a class="nav-link" href="#bank" data-toggle="tab">Bank info</a></li>
                        <li class="nav-item"><a class="nav-link" href="#permissions" data-toggle="tab">Permissions</a></li>
                        <li class="nav-item"><a class="nav-link" href="#assignments" data-toggle="tab">Assignments</a></li>
                        <li class="nav-item"><a class="nav-link" href="#api-keys" data-toggle="tab">API Keys</a></li>
                        <li class="nav-item"><a class="nav-link" href="#logs" data-toggle="tab">Logs</a></li>
                        <li class="nav-item"><a class="nav-link" href="#additional" data-toggle="tab">Additional</a></li>
                    </ul>
                    <ul class="nav nav-pills float-right">
                      <li>
                        <div class="btn-group">
                          <button type="button" class="btn btn-danger"
                          @click.prevent="suspendUser()"
                          v-if="user.is_active">Suspend User</button>
                          
                          <button type="button" class="btn btn-success"
                          @click.prevent="activateUser()"
                          v-if="!user.is_active">Activate User</button>
                          
                          <button type="button" class="btn btn-success"
                          @click.prevent="verifyUser()"
                          v-if="!user.profile.is_verified">Verify User</button>
                          
                          <button type="button" class="btn btn-danger"
                          @click.prevent="unverifyUser()"
                          v-if="user.profile.is_verified">Unverify User</button>
                          
                          <button type="button" class="btn btn-info" v-if="user.profile.user_role === 'publisher' || user.profile.user_role === 'network' || user.profile.user_role === 'pre_activation'"
                          @click.prevent="impersonateUser(user)">
                          <i class="fa fa-user-secret" aria-hidden="true"></i></button>
                        </div>
                      </li>
                    </ul>

                </div><!-- /.card-header -->
                <div class="card-body">
                  <div class="tab-content">
                      
                      <div class="active tab-pane" id="profile">
                        <ProfileInfo v-if="user" :user="user" v-animate-css="'fadeIn'" />
                      </div>
                      <!-- /.tab-pane -->
                      
                      <div class="tab-pane" id="login">
                        <LoginInfo v-if="user" :user="user" v-animate-css="'fadeIn'" />
                      </div>
                      <!-- /.tab-pane -->
                      
                      <div class="tab-pane" id="bank">
                        <BankInfo v-if="user" :user="user" v-animate-css="'fadeIn'" />
                      </div>
                      <!-- /.tab-pane -->
                      
                      <div class="tab-pane" id="permissions">
                        <Permissions v-if="user" :user="user" v-animate-css="'fadeIn'" />
                      </div>
                      <!-- /.tab-pane -->
                      
                      <div class="tab-pane" id="assignments">
                        <Assignments v-if="user" :user="user" :users="users" v-animate-css="'fadeIn'" />
                      </div>
                      <!-- /.tab-pane -->
                      
                      <div class="tab-pane" id="api-keys">
                        <!-- <APIKeys v-if="user" :user="user" v-animate-css="'fadeIn'" /> -->
                      </div>
                      <!-- /.tab-pane -->
                                            
                      <div class="tab-pane" id="logs">
                        <Logs v-if="user" :user="user" v-animate-css="'fadeIn'" />
                      </div>
                      <!-- /.tab-pane -->
                                            
                      <div class="tab-pane" id="additional">
                        <Additional v-if="user" :user="user" v-animate-css="'fadeIn'" />
                      </div>
                      <!-- /.tab-pane -->

                  </div>
                  <!-- /.tab-content -->
                  </div><!-- /.card-body -->
              </div>
              <!-- /.nav-tabs-custom -->
            </div>
            <!-- /.col -->
        </div>
        <!-- /.row -->
    </div><!-- /.container-fluid -->
</section>
</template>

<script>
import UserCard from '@/components/account/profile/UserCard'
import BankInfo from '@/components/account/profile/bank'
import ProfileInfo from '@/components/account/profile/profile'
import LoginInfo from '@/components/account/profile/login'
import Permissions from '@/components/account/profile/permissions'
import Assignments from '@/components/account/profile/assignments'
import Logs from '@/components/account/profile/logs'
import APIKeys from '@/components/account/profile/api'
import Additional from '@/components/account/profile/additional'

import { RepositoryFactory } from '@/services/repositories/AuthAPI/RepositoryFactory'
const AuthRepo = RepositoryFactory.get('auth')
const UserRepo = RepositoryFactory.get('user')
const ProfileRepo = RepositoryFactory.get('profile')
const BankRepo = RepositoryFactory.get('bank')
const UsersRepo = RepositoryFactory.get("users")

export default {
    name: 'Profile',
    components: {
      UserCard,
      ProfileInfo,
      BankInfo,
      LoginInfo,
      Permissions,
      Assignments,
      Logs,
      APIKeys,
      Additional
    },
    data() {
      return {
        profile: {},
        user: {},
        isLoaded: false,
        users: [],
      }
    },
    mounted(){
      this.loadUsers()
    },
    created() {
        this.getUserInfo(this.$route.params.id)
    },
    methods: {
        getUserInfo: function(id) {
          UserRepo.get(id).then(result => {
            this.user = result.data
          }).catch(err => {})
        },
        activateUser: function() {
          UserRepo.patch(this.user.id, {is_active: true}).then(result => {
            this.user.is_active = true
          }).catch(err => {})
        },
        suspendUser: function() {
          UserRepo.patch(this.user.id, {is_active: false}).then(result => {
            this.user.is_active = false
          }).catch(err => {})
        },
        verifyUser: function() {
          ProfileRepo.patch(this.user.id, {is_verified: true}).then(result => {
            this.user.profile.is_verified = true
          }).catch(err => {})
        },
        unverifyUser: function() {
          ProfileRepo.patch(this.user.id, {is_verified: false}).then(result => {
            this.user.profile.is_verified = false
          }).catch(err => {})
        },
        impersonateUser: function(user) {
          if(user.profile.user_role === 'publisher' || user.profile.user_role === 'network' || user.profile.user_role === 'pre_activation') {
            AuthRepo.impersonate(user.id).then(result => {
              this.$notify({
                group: 'ops',
                type: 'success',
                title: 'Operations Success',
                text: 'Redirecting ...'
              })
              let token = result.data.access
              let dashboard;
              if(user.profile.user_role === 'pre_activation') {
                dashboard = 'publisher'
              } else {
                dashboard = user.profile.user_role
              }
              let generated_url = `https://${dashboard}.adcrew.co/?impersonating=${token}`
              window.open(generated_url, '_blank').focus();
            }).catch(err => {
              this.$notify({
                group: 'ops',
                type: 'error',
                title: 'Faild to obtain access key',
                text: err
              })
            })
          } else {
            this.$notify({
                group: 'ops',
                type: 'info',
                title: 'Publishers and Networks only',
                text: 'Redirecting ...'
              })
          }
        },
        loadUsers: function() {
            UsersRepo.all().then(result => {
                this.users = result.data.results
            }).catch(err => {})
        },
    },
    computed: {
      current_user() {
        return this.$store.getters.user
      },
    },
    watch: {
      '$route.params.id': function (id) {
          if(typeof id !== 'undefined') {
              this.getUserInfo(id)
          }
      }
    },
}
</script>

<style scoped>

</style>