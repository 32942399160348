<template>
<div class="row">
    <div class="col-md-6">
        <MetricsList v-if="metrics" :metrics="metrics" :isLoaded="isLoaded" />

        <ul class="pagination pagination-sm m-0 float-right">
          <li class="page-item">
            <a class="page-link" href="#" @click.prevent="previousPage">Previous</a>
          </li>
          <li class="page-item">
            <a class="page-link">{{ currentPage }} / {{ pageCount }}</a>
          </li>
          <li class="page-item">
            <a class="page-link" href="#" @click.prevent="nextPage">Next</a>
          </li>
        </ul>
    </div>

    <div class="col-md-6">
        <MetricsView :metric="metric" :isLoading="isLoading" @updateList="updateList" @refreshList="refreshList"/>
    </div>
</div>
</template>

<script>
import MetricsList from '@/components/settings/metrics/List'
import MetricsView from '@/components/settings/metrics/Dimention'

import { RepositoryFactory } from '@/services/repositories/ManagementAPI/RepositoryFactory' 
const MetricsRepo = RepositoryFactory.get('serve_metrics')

export default {
    name: "Metrics",
    components: {
        MetricsList,
        MetricsView
    },
    data: function() {
        return {
            metrics: [],
            metric: {
                name: "",
                status: ""
            },
            isLoaded: false,
            isLoading: false,

            currentPage: 1,
            totalRecords: 0,
            resultsLimit: 10,
            isLoaded: false,
        }
    },
    mounted() {
        this.loadMetrics()
    },
    methods: {
        loadMetrics: function() {
            this.isLoaded = false
            MetricsRepo.page(this.currentPage).then(result => {
                this.totalRecords = result.data.count
                this.metrics = result.data.results
                this.isLoaded = true
            }).catch(err => {})
        },
        loadMetric: function(id) {
            this.isLoading = true
            MetricsRepo.get(id).then(result => {
                this.metric = result.data
                this.isLoading = false
            }).catch(err => {})
        },
        updateList: function(metric) {
            this.metrics.push(metric)
            this.$router.replace({params: {'id': metric.id}})
        },
        refreshList: function(action) {
            if(action)
                this.loadMetrics()
        },
        nextPage: function() {
            if(this.currentPage != this.pageCount || this.currentPage > this.pageCount) {
                this.currentPage = this.currentPage + 1
                this.loadMetrics()
            }
        },
        previousPage: function() {
            if(this.currentPage != 1 ) {
                this.currentPage = this.currentPage - 1
                this.loadMetrics()
            }
        }
    },
    computed: {
        pageCount: function() {
            return Math.ceil(this.totalRecords / this.resultsLimit)
        },
    },
    watch: {
        '$route.params.id': {
            handler: function (id) {
                if(typeof id !== 'undefined') {
                    this.loadMetric(id)
                }
            },
            deep: true,
            immediate: true
        },
    }
}
</script>

<style>

</style>